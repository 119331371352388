import React, { useEffect, useState } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import {
  FormController,
  removeWhiteSpace,
  useFaContent,
  useFeatureAppConfig,
  useGetInstalaciones,
  useGetWaidLitera,
  useOneFormContext,
} from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import {
  CarlineBase,
  FormDataGroup,
  FormDataHeaders,
  FormInfo,
  Horario,
  OneFormState,
  Steps,
} from 'src/types';
import { ThankYouLeads } from '.';
import {
  formatFields,
  formatFlags,
  formatHeaders,
  formDataBuilder,
  getDpsFields
} from '../format-fields';
import {
  getDescInterestCarline,
  getConfiguradorData,
  getOfertasData,
  getMatchData,
} from './helpers';

export enum LeadGenerico {
  Empezar,
  Mapa,
  Modelo,
  NombreApellido,
  EmailTelefono,
  Contacto,
  Confirmacion,
}

export interface LeadGenericoFormProps {
  steps: Steps[];
  leadData: Partial<FormDataGroup>;
  leadInfo: Partial<FormInfo>;
  firstStep: Steps;
  lastStep: Steps;
  inPage?: boolean;
  isConfigurador?: boolean;
}

export interface LeadsFormsProps {
  inPage?: boolean;
  isConfigurador?: boolean;
  isLeadDealer?: boolean;
  isOfertasOrMatch?: boolean;
  updatedFormData?: Partial<FormDataGroup>;
  updatedFormInfo?: Partial<FormInfo>;
}

export const isOfertasForm = (formName: string) => formName === 'lead-oferta';
const getDescFormObjective = (formName: string, carline: CarlineBase, headers: FormDataHeaders) =>
  isOfertasForm(formName) && carline?.campaign?.dfo?.ofertas
    ? carline.campaign.dfo.ofertas
    : headers.FORM_OBJECTIVE;

const getSfCampaignName = (formName: string, carline: CarlineBase) => {
	if (isOfertasForm(formName) && carline?.campaign?.salesforce) {
		const sfCampaignName = [];
		sfCampaignName["headers[SF_CAMPAIGN_NAME]"]=carline?.campaign?.salesforce;
		return { ...sfCampaignName };
	}
	return null;
	};

const getFormDetails = (callCenterCampaign: string) => {
	if (callCenterCampaign) {
		const formDetails = [];
		formDetails["headers[FORM_DETAILS]"]=callCenterCampaign;
		return { ...formDetails };
	}
	return null;
	};


export function LeadGenericoForm({
  steps,
  leadData,
  leadInfo,
  firstStep,
  lastStep,
  inPage,
  isConfigurador,
}: LeadGenericoFormProps) {
  const { initializeForm } = useOneFormContext();
  const store = useStore();
  const config = useFeatureAppConfig();
  const installations = useGetInstalaciones('VW_DDB_FRM_LEM_REC_LEM'); // Inicialización de las instalaciones
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const faContent = useFaContent();
  const waid = useGetWaidLitera();

  const getHorario = (horario: Horario) => {
    let horarioField = null;

    if (horario) {
      const horarioFormatted = horario === 'morning' ? 'MANANA' : horario === 'evening' ? 'MEDIODIA' : 'TARDE';

      horarioField = { 'fields[PERSON_PREFERRED_SLOT]': horarioFormatted };
    }
    return horarioField;
  };

  const sendForm = (): Promise<boolean> => {
    const { formData, dealersInfo }: OneFormState = store.getState();
    const { fields, headers, flags } = formData;
    const { selectedInstallation, salesGroup, selectedCarline } = dealersInfo;

    const {
      VGED_CommercialName__c,
      ShippingStreet,
      VGED_DealerCode__c,
      ShippingStateCode,
      KVPSCode__c,
      Id
    } = selectedInstallation;

    return new Promise<any>(async (resolve, reject) => {
      const { tc, today, referalUrl, url, device } = getParamsForm();

      const data = {
        ...formatHeaders(headers), // Headers comunes a todos los formularios de leads

        'headers[FORM_DATE_INSERTION]': today,
        'headers[FORM_URL]': url,
        'headers[FORM_NAME]': `vw-oneform-${fields.formName}`,
        'headers[FORM_VERSION]': `vw-oneform-${fields.formName}-${process.env.VERSION}`,
        'headers[ORIGIN_DEVICE_TYPE]': device,
        'headers[ORIGIN_TRACKING_CODE]': tc,
        'headers[FORM_OBJECTIVE]': fields.formObjective ? fields.formObjective : getDescFormObjective(fields.formName, selectedCarline, headers),
		...getSfCampaignName(fields.formName, dealersInfo?.selectedCarline),
		...getFormDetails(fields.callCenterCampaign),

        ...formatFlags(flags), // Flags comunes a todos los formularios de leads
        ...formatFields(fields.formFields), // Fields comunes a todos los formularios de leads

        'fields[DPS_BRAND]': 'Volkswagen',
        'fields[SF_LEAD_RECORDTYPE_ID]': '0121i000000D7NyAAK',
        'fields[SF_LEAD_RECORDTYPE_NAME]': 'Sales Assistant',
        'fields[SF_LEAD_SALES_INITIAL_ACTION]': 'Direct to Sales Assistant',
        'fields[PERSON_EMAIL]': removeWhiteSpace(fields.email),
        'fields[PERSON_TREATMENT]': fields.trato,
        'fields[PERSON_NAME]': fields.name,
        'fields[PERSON_PHONE_MOBILE_FULL]': `${fields.prefix}${fields.cellphone}`,
        'fields[PERSON_PHONE_MOBILE]': fields.cellphone,
        'fields[PERSON_PHONE_PREFIX]': fields.prefix,
        'fields[PERSON_POSTAL_CP]': fields.postalCode,
        'fields[PERSON_PREFERRED_CHANNEL]': fields.contacto,
        'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
        'fields[PERSON_RGPD_PERFILADO]': fields.tmk,
        'fields[PERSON_SURNAME_1]': fields.surname,
        'fields[PERSON_SURNAME_2]': fields.secondSurname,
        'fields[SF_LEAD_INSTALLATION_ADDRESS]': ShippingStreet,
        'fields[SF_LEAD_INSTALLATION_CODE]': VGED_DealerCode__c,
        'fields[SF_LEAD_INSTALLATION_ID]': Id,
        'fields[SF_LEAD_INSTALLATION_NAME]': VGED_CommercialName__c,
        'fields[SF_LEAD_INSTALLATION_PROVINCE_CODE]': ShippingStateCode.toString().padStart(2, '0'),
        // 'fields[VEH_INTEREST_FUEL]': 'PENDIENTE', -> Pendiente mapear
        'fields[VEH_INTEREST_BRAND]': 'Volkswagen',
        'fields[VEH_INTEREST_ID]': dealersInfo?.selectedCarline?.code,
        'fields[VEH_INTEREST_MODEL]': getDescInterestCarline(dealersInfo, fields.modelo),
        'fields[VEH_INTEREST_SALESGROUP]': salesGroup?.salesGroupName,
        'fields[VEH_INTEREST_SALESGROUP_ID]': salesGroup?.salesGroupId,
        'fields[VEH_INTEREST_NEW_OR_USED]': 'New Car',
        'fields[VEH_INTEREST_LINK]': inPage ? referalUrl : url,
		'fields[COMMENTS_PERSON]': fields.comments,
		'fields[COMMENTS_CONTACT_CENTER]': fields.commentsCC,
        ...getDpsFields(faContent),
        //...getSalesGroup(salesGroup),
        ...getHorario(fields.horario),

        ...(await getOfertasData(formData, dealersInfo)),
        ...(await getMatchData(formData, dealersInfo, faContent)),
        ...(await getConfiguradorData(formData)),

      };
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formDataInfo = formDataBuilder(dataParsed);
      // const response = await dpsSend(formDataInfo);
      const response = await oneformBFFNdpsSend(formDataInfo);

      if (response && response.data && response.data.status && response.data.status.code && response.data.status.code == '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const leadGenericoData: Partial<FormDataGroup> = {
    headers: {
      // DESC_CAMPAIGNBLOCK: '9. VW LEM',
      FK_SYSTEM: 'VW_DDB_FRM_LEM_REC_LEM',
      DPS_TOKEN: 'VW_DDB_FRM_LEM_REC_LEM',
      FORM_ID: waid ? waid : 'B09C01_LEM_WA1',
      ...leadData.headers,
    },
    flags: {
      PERSON_RGPD_ADAPTED: 'true',
      FLAG_IS_MYSTERY_LEAD: 'false',
      FLAG_RELACION_MARCA_SIN_IC: 'false',
      FLAG_SF_INTEGRATION: 'true',
      FLAG_ALLOWS_DATA_ENRICHMENT: 'false',
      // FLAG_ISQUALIFIED: 'false',
      // FLAG_MAILRECEPCIONLEAD: 'true',
      // REQUIERE_CONTACTO_DEALER: 'false',
    },
    fields: {
      ...leadData.fields
    },
  };

  const leadGenericoInfo: Partial<FormInfo> = {
    formStarted: true,
    fullScreen: firstStep.fullScreen ? true : false,
    lastStep,
    sendFormCallback: sendForm,
    numberOfScreens: steps.length,
    screenIndex: firstStep.screenIndex,
    step: firstStep,
    steps,
    stepsHistory: [steps[0].screenIndex],
    thankYouComponent: <ThankYouLeads inPage={inPage} />,
    notALayer: inPage ? true : false,
    formTheme: inPage ? 'main' : 'inverted',
    formType: inPage ? 'inPage' : 'layer',
    ...leadInfo,
  };

  useEffect(() => {
    initializeForm(leadGenericoInfo, leadGenericoData);
    setLoading(false);
  }, []);

  return loading ? null : <FormController steps={steps} screenType={inPage ? 'full-screen' : 'layer-screen'} />;
}

import dayjs from 'dayjs';
import { PincodeData } from 'src';
import { getPersonAttributes, getLeadAttributes, getVehicleAttributes, dealerAttributes, orderAttributes } from '.';
import { getTestLead, getTestPerson } from '.';

export const checkIfIsTestPincode = (pincode: string) => pincode.startsWith('PINCODE');


export const getTestPincode = (pincode: string): PincodeData => {

  const noPermite = pincode.includes('NOPERMITE') || pincode.includes('NO-PERMITE');
  const isElectrico = pincode.includes('ID');

  const content = [];

	if (pincode.includes('NPERSON'))  {
    content.push({
      attributes: [...getTestPerson(noPermite, getDate(pincode, 'PERSON'))]
    });
  }
  else if (pincode.includes('PERSON'))  {
    content.push({
      attributes: [...getPersonAttributes(noPermite, getDate(pincode, 'PERSON'))]
    });
  }

	if (pincode.includes('NLEAD'))  {
    content.push({
      attributes: [...getTestLead(getDate(pincode, 'LEAD'))]
    });
  }
  else if (pincode.includes('LEAD'))  {
    content.push({
      attributes: [...getLeadAttributes(getDate(pincode, 'LEAD'))]
    });
  }

  if (pincode.includes('VEHICLE'))  {
    content.push({
      attributes: [...getVehicleAttributes(isElectrico)]
    });
  }
  if (pincode.includes('ORDER'))  {
    content.push({
      attributes: [...orderAttributes]
    });
  }
  if (pincode.includes('DEALER'))  {
    content.push({
      attributes: [...dealerAttributes]
    });
  }

  return {
    status: {
      result: 'success',
      timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      code: 1,
      time: 0.5742380619049072,
    },
    params: {
      pincode: pincode,
      token: 'VW_DDB_FORM_TEST',
    },
    content: content,
  };
}

const getDate = (pincode: string, match: string) => {
  const days = pincode.match(new RegExp(match + "(\\d+)"));
  if (days) {
    return dayjs().subtract(parseInt(days[1]),'day').format('YYYY-MM-DD HH:mm:ss');
  }
  return null;
}
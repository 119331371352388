import {
  Text,
  styled,
  Container,
  ContainerGutter,
  CTA,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { useSelector } from 'react-redux';
import { OneFormState } from 'src/types';

const PaddingWrapper = styled.div`
  padding-top: 32px;
`;

const TextColor = styled.span`
  color: #6a767d;
`;

const linkLegalesEurocopa = 'https://crm.volkswagen.es/cms/2021/legal_concurso_eurocopa_2021.pdf';
interface LegalesProps {
  showBasesLegales?: boolean;
}
export const LegalFullScreenSinConsentmientos = (props: LegalesProps) => {
  const { hideLegal, customLegal } = useSelector((state: OneFormState) => state.formInfo);
  return (
    <PaddingWrapper>
      <Container gutter={ContainerGutter.static200} wrap={'alway'}>
        <Text bold appearance={TokenTextAppearance.copy200}>
          <TextColor>Texto legal de Volkswagen</TextColor>
        </Text>
        <Text appearance={TokenTextAppearance.copy100}>
          <TextColor>
          El responsable de tus datos es Volkswagen Group España Distribución, S.A.U. 
          Vamos a utilizar tus datos para realizar un control de calidad sobre los productos 
          y servicios contratados, mediante la realización de la presente encuesta de satisfacción, 
          y contactarte para resolución de incidencias, reclamaciones o quejas. Tus datos podrán ser 
          comunicados a las empresas del grupo para las finalidades de controlar la calidad de los productos 
          y servicios, así como contactarte para resolución de incidencias, reclamaciones o quejas.
          </TextColor>
        </Text>
        <Text appearance={TokenTextAppearance.copy100}>
          <TextColor>
          En el supuesto de haber consentido, tus datos y las respuestas de la presente encuesta 
          de satisfacción serán comunicadas al Concesionario. Tienes derecho a oponerte al tratamiento, 
          limitar el mismo, acceder, rectificar, suprimir los datos y ejercer tu derecho a portabilidad, 
          mediante el envío de un correo electrónico a:{' '}
            <CTA tag="a" href="mailto:atencioncliente@volkswagen.es" target="_blank" emphasis="tertiary">
              <b>atencioncliente@volkswagen.es</b>
            </CTA>
            .
          </TextColor>
        </Text>
        <Text appearance={TokenTextAppearance.copy100}>
          <TextColor>
            Puedes consultar la información adicional en la{' '}
            <CTA
              tag="a"
              href="https://www.volkswagen.es/es/volkswagen-espana/politica-privacidad.html"
              target="_blank"
              emphasis="tertiary"
            >
              <b>política de privacidad</b>
            </CTA>
            .
          </TextColor>
        </Text>
        {props.showBasesLegales ? (
          <Text staticSize appearance={TokenTextAppearance.copy100}>
            <TextColor>
              Consulta las{' '}
              <CTA tag="a" href={linkLegalesEurocopa} target="_blank" emphasis="tertiary">
                <b>Bases Legales</b>
              </CTA>{' '}
              de la promoción.
            </TextColor>
          </Text>
        ) : null}
      </Container>
    </PaddingWrapper>
  );
};

import { combineReducers } from 'redux';
import { VehiclesInfo, DealersInfo, FormDataGroup, FormInfo, OneFormState } from 'src/types';
import { vehiclesReducer, dealersReducer, formDataReducer, formInfoReducer } from './';

export const formInfoInitialState: FormInfo = {
  firstMapLoad: true,
  formEnded: false,
  formStarted: false,
  fullScreen: false,
  genericError: false,
  formSubmitted: false,
  multiStepScreenIndex: null,
  numberOfScreens: null,
  screenIndex: null,
  showFinalScreen: false,
  step: null,
  steps: null,
  stepsHistory: null,
  thankYouComponent: null,
  triggerExitAnimation: false,
  userIsAuthenticated: false,
  waitForNextStep: false,
  trackingIsDisabled: false
};

export const formData: FormDataGroup = { headers: {}, fields: {} };
export const dealersInfo: DealersInfo = {
  dealers: null,
  installations: null,
  selectedCarline: null,
  points: null,
  selectedDealer: null,
  favouriteDealer: null,
  urlDealer: null,
  selectedInstallation: null,
  dealersOrInstallationsMode: null,
  carlines: null,
  oferta: null,
  salesGroup: null,
  match: null,
  citaCarlines: null,
};
export const vehiclesInfo: VehiclesInfo = { 
  brands: null, 
  models: null, 
  brandSelected: null, 
  modelSelected: null 
};

export const initialState: OneFormState = {
  formInfo: formInfoInitialState,
  formData,
  dealersInfo,
  vehiclesInfo,
};

export const oneFormReducer = combineReducers({
  formData: formDataReducer,
  formInfo: formInfoReducer,
  dealersInfo: dealersReducer,
  vehiclesInfo: vehiclesReducer,
});

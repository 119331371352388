import {
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  styled,
  Text,
  ThemeProvider,
  TokenTextAppearance,
  TokenTextColor,
} from '@volkswagen-onehub/components-core';
import { DashboardTile, TileSize } from '@volkswagen-onehub/myvw-shared-components/dist/dashboard/dashboardTile';
import { TileGridContainer } from '@volkswagen-onehub/myvw-shared-components/dist/dashboard/tileGridContainer';
import { TileGridLayout } from '@volkswagen-onehub/myvw-shared-components/dist/dashboard/tileGridLayout';
import React from 'react';
import { NuevaCitaPosventaFormController } from 'src/forms/CitaPosventa/NuevaCitaPosventa';

const BlueBackground = styled.div`
  background: radial-gradient(circle at center bottom, rgb(0, 67, 122) 0%, rgb(0, 30, 80) 100%);
  color: white;
  height: 100%;
`;

export const CitaPreviaTile = (props: { setSelectedJourney: React.Dispatch<React.SetStateAction<JSX.Element>> }) => {
  const { setSelectedJourney } = props;
  return (
    <DashboardTile border={false} size={TileSize.L}>
      <ThemeProvider theme="inverted">
        <BlueBackground>
          <TileGridLayout>
            <TileGridContainer startColumn={3} columns={20}>
              <Container
                padding={{
                  top: { 0: ContainerPadding.dynamic0250, 960: ContainerPadding.dynamic0130 },
                  bottom: ContainerPadding.dynamic0130,
                }}
                wrap={"always"}
                gutter={ContainerGutter.dynamic0130}
              >
                <Text appearance={TokenTextAppearance.headline300} color={TokenTextColor.inherit}>
                  ¿Necesitas<Text bold> pasar por el&nbsp;taller?</Text>
                </Text>
                <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.inherit}>
                  Elige el día, la hora y el Servicio Oficial que prefieras para que cuiden de tu Volkswagen.
                </Text>
                <CTA
                  tag={'button'}
                  emphasis="primary"
                  onClick={() => {
                    setSelectedJourney(<NuevaCitaPosventaFormController />);
                  }}
                >
                  Pide tu cita
                </CTA>
              </Container>
            </TileGridContainer>
          </TileGridLayout>
        </BlueBackground>
      </ThemeProvider>
    </DashboardTile>
  );
};

import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Spinner,
  styled,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { CloseHandleV2 } from '@volkswagen-onehub/layer-manager';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getFranjaAndDayFrom,
  isNull,
  weekDay_DayNumber_De_Month,
  year_MonthNumber_DayNumber,
  getToAndFromForCheckCita,
} from 'src/feature-app';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import {
  Assessors,
  Availability,
  CalendarSlot,
  DealersData,
  Horario as HorarioType,
  OneFormState,
  Points,
  Slot,
  SlotsTime,
} from 'src/types';
import { getEarliestSlot } from 'src/feature-app/NewMap/sort-points';
import { Close } from '@volkswagen-onehub/icons-core';

const ZIndexLayer = styled.div`
  z-index: 25;
  position: relative;
`;

const Content = styled.div`
  background-color: white;
  position: relative;
  padding: 44px 31px;
  flex: auto;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 560px) {
    padding: 52px 46px;
  }
  @media screen and (min-width: 960px) {
    padding: var(--size-grid001);
  }
`;

const CloseDiv = styled.div`
  position: absolute;
  top: var(--size-dynamic0050);
  right: var(--size-dynamic0050);
  cursor: pointer;
`;

const CTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 44px;

  @media screen and (min-width: 960px) {
    flex-direction: row;
    align-items: center;
  }
`;
interface LayerComponentProps {
  selectedPoint: Points;
  handleShowSlots: (point: Points) => string | null;
  handleDmsMapExit: (slot: CalendarSlot, point: Points) => void;
  handleNoDmsMapExit: (franjaHoraria: string, day: string, point: Points) => void;
  getAvailability: (dealer: DealersData, start: number, end: number) => Promise<Availability>;
  // handleAvailability: (a: Availability, d: DealersData) => void;
  closeLayerCallback?: CloseHandleV2<any, any>;
  // waitForNextStep: any;
  handleShowCalendarOnClick: (point: Points) => void;
}

export function ReservaCitaLayer(props: LayerComponentProps) {
  return (
    <Content>
      <ReservaCita {...props} />
    </Content>
  );
}

interface SlotHorario {
  from: number;
  to: number;
}

function ReservaCita(props: LayerComponentProps) {
  const {
    selectedPoint,
    handleShowSlots,
    handleDmsMapExit,
    handleNoDmsMapExit,
    getAvailability,
    // handleAvailability,
    closeLayerCallback,
    // waitForNextStep
    handleShowCalendarOnClick,
  } = props;

  const trackingManager = useTrackingManager();
  const [assessorName, setAssessorName] = useState('');

  const { dealer } = selectedPoint.properties;
  const { motivo, horario, motivoLabel }: { motivo: string; motivoLabel: string; horario: HorarioType } = useSelector(
    (state: OneFormState) => state.formData.fields
  );
  const { name, address, city, zipCode } = dealer.markerInfo.dealerInfo;

  const [loading, setLoading] = useState(false);
  const [DMSError, setDMSError] = useState(false);
  const [slotNotAvailable, setSlotNotAvailable] = useState(false);
  const slotHorarioRef = useRef<SlotHorario>(null);

  useEffect(() => {
    checkSlot();
    if (selectedPoint) {
      getAssessorName();
    }
  }, []);

  const checkSlot = () => {
    const { slots } = dealer;
    let from: number;
    let to: number = null;
    if (!isNull(slots)) {
      from = getEarliestSlot(slots);
    }

    slotHorarioRef.current = { from, to };
  };

  const getAssessorName = () => {
    const { assessors } = dealer.dmsInfo;
    if (horario !== 'before' && dealer?.slots[horario]) {
      const assessorCode = dealer.slots[horario].assessorCode;
      const assessor = findAssessor(assessors, assessorCode);
      setAssessorName(assessor.name);
    } else {
      // Primero se comprueba si el dealer tiene disponibles slots en mañana, mediodía o tarde
      // y luego se comparan estos slots con el earliestSlot para seleccionarlo.
      const { slots } = selectedPoint.properties.dealer;
      const keys = Object.keys(slots) as SlotsTime[];
      keys.map((time: SlotsTime) => {
        if (slots[time] && selectedPoint.properties.earliestSlot === slots[time].from) {
          const slot: Slot = slots[time];
          const assessor = findAssessor(assessors, slot.assessorCode);
          setAssessorName(assessor.name);
        }
      });
    }
  };

  const findAssessor = (assessors: Assessors[], code: string) => {
    return assessors.find((assessor) => assessor.code === code);
  };

  const handleCitaDisponible = (slot: CalendarSlot, assessors: Assessors[]) => {
    setLoading(false);
    closeLayerCallback({}, {}, {});
    handleDmsMapExit(slot, selectedPoint);
    return;
  };

  const handleCitaNoDisponible = (availability: Availability) => {
    setLoading(false);
    if (availability?.content?.calendar) {
      setSlotNotAvailable(true);
      setDMSError(false);
    } else {
      const { franjaHoraria } = getFranjaAndDayFrom(slotHorarioRef.current.from);
      setDay(weekDay_DayNumber_De_Month(slotHorarioRef.current.from));
      setFranjaHoraria(franjaHoraria.toLowerCase());
      setDMSError(true);
      setSlotNotAvailable(false);
    }
  };

  //
  const [day, setDay] = useState(null);
  const [franjaHoraria, setFranjaHoraria] = useState(null);

  const continueWithoutDms = (from: number, point: Points) => {
    const { day, franjaHoraria } = getFranjaAndDayFrom(from);

    handleNoDmsMapExit(franjaHoraria, day, point);
  };

  const checkCita = async () => {
    setLoading(true);
    const { from, to } = getToAndFromForCheckCita(slotHorarioRef.current.from);

    const availability = await getAvailability(dealer, from, to);

    if (availability?.content?.calendar) {
      const fromFormatted = year_MonthNumber_DayNumber(slotHorarioRef.current.from);
      const selectedDay = availability.content.calendar.find((day) => day.date === fromFormatted);
      const assessors = dealer.dmsInfo.assessors;

      if (selectedDay) {
        const finalSlot = selectedDay.slots
          .map((slot) => {
            if (slot.from === slotHorarioRef.current.from) {
              return slot;
            }
          })
          .filter((a) => a !== undefined);

        finalSlot.length > 0 ? handleCitaDisponible(finalSlot[0], assessors) : handleCitaNoDisponible(availability);
      } else {
        handleCitaNoDisponible(availability);
      }
    } else {
      handleCitaNoDisponible(availability);
    }
  };

  return (
    <>
      <CloseDiv
        onClick={(e) => {
          e.preventDefault();
          closeLayerCallback({}, {}, {});
        }}
      >
        <Close />
      </CloseDiv>
      <Container
        gutter={ContainerGutter.static400}
        horizontalAlign={'flex-start'}
        wrap={'always'}
      >
        {!loading && !slotNotAvailable && !DMSError ? (
          <>
            <Text appearance={TokenTextAppearance.headline300} bold textAlign={TextAlignment.left}>
              ¿Quieres reservar esta cita?
            </Text>
            <Container
              gutter={ContainerGutter.static300}
              stretchContent
              wrap={'always'}
              horizontalAlign={'flex-start'}
              padding={{ top: ContainerPadding.static300 }}
            >
              <div>
                <Text appearance={TokenTextAppearance.headline200} bold textAlign={TextAlignment.left}>
                  Motivo de tu visita
                </Text>
                <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
                  {motivoLabel ? motivoLabel : motivo ? motivo : 'Revisión de mantenimiento'}
                </Text>
              </div>
              <div>
                <Text appearance={TokenTextAppearance.headline200} bold textAlign={TextAlignment.left}>
                  Fecha y hora
                </Text>
                <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
                  {handleShowSlots(selectedPoint)}
                </Text>
              </div>
              <div>
                <Text appearance={TokenTextAppearance.headline200} bold textAlign={TextAlignment.left}>
                  Taller
                </Text>
                <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
                  {name}
                </Text>
                <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left} wordBreak>
                  {address},<br /> {zipCode} {city}
                </Text>
                {assessorName ? (
                  <Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.left}>
                    Asesor {assessorName}
                  </Text>
                ) : null}
              </div>
            </Container>
            <CTAWrapper>
              <BreakpointWrapper max={Breakpoints.b560}>
                <CTA
                  tag="button"
                  emphasis="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    trackingManager.trackFormCTAClick(
                      {
                        contentId: 'Sí, quiero esta cita',
                      },
                      dealer
                    );
                    checkCita();
                  }}
                  stretchContent
                >
                  Sí­, quiero esta cita
                </CTA>
                <div style={{ height: 24, width: 0 }} />
                <CTA
                  tag="button"
                  emphasis="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    trackingManager.trackFormButtonClick(
                      {
                        contentId: 'Ver más horas',
                      },
                      dealer
                    );
                    handleShowCalendarOnClick(selectedPoint);
                    closeLayerCallback({}, {}, {});
                  }}
                  stretchContent
                >
                  Ver más horas
                </CTA>
              </BreakpointWrapper>
              <BreakpointWrapper min={Breakpoints.b560}>
                <CTA
                  tag="button"
                  emphasis="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    trackingManager.trackFormCTAClick(
                      {
                        contentId: 'Sí, quiero esta cita',
                      },
                      dealer
                    );
                    checkCita();
                  }}
                >
                  Sí, quiero esta cita
                </CTA>
                <div style={{ height: 24, width: 32 }} />
                <CTA
                  tag="button"
                  emphasis="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    trackingManager.trackFormButtonClick(
                      {
                        contentId: 'Ver más horas',
                      },
                      dealer
                    );
                    handleShowCalendarOnClick(selectedPoint);
                    closeLayerCallback({}, {}, {});
                  }}
                >
                  Ver más horas
                </CTA>
              </BreakpointWrapper>
            </CTAWrapper>
          </>
        ) : null}

        {loading ? (
          <>
            <Spinner variant="large" />
            <Text>Confirmando con la agenda del taller</Text>
          </>
        ) : null}
        {!loading && DMSError ? (
          <>
            <Container
              gutter={ContainerGutter.static350}
              wrap={'always'}
              horizontalAlign={'flex-start'}
            >
              <Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
                <Text bold>No hemos podido confirmar tu cita </Text>para el{' '}
                <span style={{ textTransform: 'lowercase' }}>{handleShowSlots(selectedPoint)}</span>
              </Text>
              <Text textAlign={TextAlignment.left}>
                ¿Quieres continuar con el proceso y que <span style={{ textTransform: 'capitalize' }}>{name}</span> se
                ponga en contacto contigo para cerrar tu cita el {day} por la {franjaHoraria}?
              </Text>
            </Container>
            <CTAWrapper>
              <BreakpointWrapper max={Breakpoints.b560}>
                <CTA
                  tag="button"
                  emphasis="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    trackingManager.trackFormCTAClick(
                      {
                        contentId: 'Continuar',
                      },
                      dealer
                    );
                    checkCita();
                  }}
                  stretchContent
                >
                  Continuar
                </CTA>
                <div style={{ height: 24, width: 0 }} />
                <CTA
                  tag="button"
                  emphasis="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    trackingManager.trackFormButtonClick(
                      {
                        contentId: 'Buscar una nueva cita',
                      },
                      dealer
                    );
                    closeLayerCallback({}, {}, {});
                  }}
                  stretchContent
                >
                  Buscar una nueva cita
                </CTA>
              </BreakpointWrapper>
              <BreakpointWrapper min={Breakpoints.b560}>
                <CTA
                  tag="button"
                  emphasis="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    trackingManager.trackFormCTAClick(
                      {
                        contentId: 'Continuar',
                      },
                      dealer
                    );
                    continueWithoutDms(slotHorarioRef.current.from, selectedPoint);
                    closeLayerCallback({}, {}, {});
                  }}
                >
                  Continuar
                </CTA>
                <div style={{ height: 24, width: 32 }} />
                <CTA
                  tag="button"
                  emphasis="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    trackingManager.trackFormButtonClick(
                      {
                        contentId: 'Buscar una nueva cita',
                      },
                      dealer
                    );
                    closeLayerCallback({}, {}, {});
                  }}
                >
                  Buscar una nueva cita
                </CTA>
              </BreakpointWrapper>
            </CTAWrapper>
          </>
        ) : null}
        {!loading && slotNotAvailable ? (
          <Container
            gutter={ContainerGutter.static500}
            wrap={'always'}
            horizontalAlign={'flex-start'}
          >
            <Text>Lamentablemente la cita seleccionada no está disponible</Text>
            <CTA
              tag="button"
              emphasis="primary"
              onClick={(e) => {
                e.preventDefault();
                trackingManager.trackFormButtonClick(
                  {
                    contentId: 'Seguir buscando',
                  },
                  dealer
                );
                handleShowCalendarOnClick(selectedPoint);
                closeLayerCallback({}, {}, {});
              }}
            >
              Seguir buscando
            </CTA>
          </Container>
        ) : null}
      </Container>
    </>
  );
}

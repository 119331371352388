export const getVehicleAttributes = (isElectrico?:boolean) => {
  return [
    {
      name: 'ID',
      value: 'VEHICLE',
    },
    {
      name: 'attributes',
      value: 'es.vged.dps.salesforce.rest.entities.SfAttributesRest@5674eb[type=Vehicle__c,url=/services/data/v53.0/sobjects/Vehicle__c/a0Y1n00000HU1nzEAD]',
    },
    {
      name: 'Id',
      value: 'a0Y1n00000HU1nzEAD',
    },
    {
      name: 'OwnerId',
      value: '0050Y000001UE3gQAG',
    },
    {
      name: 'IsDeleted',
      value: 'false',
    },
    {
      name: 'Name',
      value: 'a0Y1n00000HU1nz',
    },
    {
      name: 'CreatedDate',
      value: '2019-08-23T11:00:59.000+0000',
    },
    {
      name: 'CreatedById',
      value: '0050Y000001UE3gQAG',
    },
    {
      name: 'LastModifiedDate',
      value: '2021-12-11T04:12:11.000+0000',
    },
    {
      name: 'LastModifiedById',
      value: '0050Y000001UE3gQAG',
    },
    {
      name: 'SystemModstamp',
      value: '2021-12-11T04:12:11.000+0000',
    },
    {
      name: 'LastViewedDate',
      value: 'null',
    },
    {
      name: 'LastReferencedDate',
      value: 'null',
    },
    {
      name: 'Affected_EA_189__c',
      value: 'false',
    },
    {
      name: 'COD_comision__c',
      value: 'null',
    },
    {
      name: 'CarNet__c',
      value: 'false',
    },
    {
      name: 'TMAIMG__c',
      value: 'BQ12CX',
    },
    {
      name: 'Warranty_extension__c',
      value: '0',
    },
    {
      name: 'Included_mainetance__c',
      value: '0',
    },
    {
      name: 'DESC_car_model_salesgroup__c',
      value: isElectrico ? 'ID.3 Pro' : 'Golf Last Edition',
    },
    {
      name: 'PKL_order_brand__c',
      value: 'Volkswagen',
    },
    {
      name: 'Frame__c',
      value: isElectrico ? 'WVWZZZE1ZLP007840' : 'WVWZZZAUZLW014456',
    },
    {
      name: 'Fuel__c',
      value: isElectrico ? 'ELÉCTRICO' : 'GASOLINA',
    },
    {
      name: 'Gear_type__c',
      value: 'NO INFORMADO',
    },
    {
      name: 'Installed_PR_packages__c',
      value: '',
    },
    {
      name: 'KM_cobertura_garantia__c',
      value: '0',
    },
    {
      name: 'Assistance_years__c',
      value: '4',
    },
    {
      name: 'DESC_Model__c',
      value: isElectrico ? 'ID.3' : 'GOLF',
    },
    {
      name: 'Registration__c',
      value: '1934LBW',
    },
    {
      name: 'Repaired_EA_189__c',
      value: 'false',
    },
    {
      name: 'TrimLevel_Model__c',
      value: 'null',
    },
    {
      name: 'VW_Connect__c',
      value: 'true',
    },
    {
      name: 'Vehicle_age_yearly_reg__c',
      value: '3',
    },
    {
      name: 'Audi_Connect__c',
      value: '0.0000000000000',
    },
    {
      name: 'Car_power__c',
      value: '85',
    },
    {
      name: 'Carline_Code__c',
      value: isElectrico ? '30350' : '30316',
    },
    {
      name: 'DAT_registration__c',
      value: '2019-10-17',
    },
    {
      name: 'DESC_model_group__c',
      value: 'NO INFORMADO',
    },
    {
      name: 'IDClimapro__c',
      value: 'TEST_IDClimapro__c',
    },
    {
      name: 'Long_full_drive__c',
      value: 'false',
    },
    {
      name: 'PersonAccount__c',
      value: 'null',
    },
    {
      name: 'Salesgroup_Code__c',
      value: isElectrico ? '33236' : '39237',
    },
    {
      name: 'Vehicle_age_monthly_reg__c',
      value: '26',
    },
    {
      name: 'years_warranty_coverage__c',
      value: '2',
    },
    {
      name: 'years_warranty_extension__c',
      value: '0',
    },
  ];
}

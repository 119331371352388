import { styled } from '@volkswagen-onehub/components-core';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useStore, useSelector, useDispatch } from 'react-redux';
import { InputButton } from '.';
import { OneFormState } from 'src/types';
import { useOneFormContext } from 'src/feature-app/OneForm';

type CTAWrapperProps = {
  ctaWidth?: string;
  wrapperWidth?: string;
};

const CTAWrapper = styled.div<CTAWrapperProps>`
  text-align: left;
  margin: -10px;
  margin-top: 32px;
  max-width: ${(props) => (props.wrapperWidth ? props.wrapperWidth : null)};

  @media all and (max-height: 624px) {
    margin-top: 24px;
  }
  & > button {
    margin: 12px;
    width: ${(props) => (props.ctaWidth ? `${props.ctaWidth}px` : 'auto')};
  }
`;

interface CTAValues {
  value?: string;
  label: string;
}

interface MultipleOptionCTAsProps {
  ctaValues: CTAValues[];
  name: string;
  minSelected?: number;
  maxSelected?: number;
  ignoreHandleClick?: boolean;
  wrapperWidth?: string;
  exclusiveCTA?: boolean;
  exclusiveCTAValues?: CTAValues[];
}

interface CTAsRef {
  [ctaName: string]: HTMLButtonElement;
}

export function MultipleOptionCTAs(props: MultipleOptionCTAsProps) {
  const dispatch = useDispatch();
  const { 
    ctaValues, 
    ignoreHandleClick, 
    name, 
    maxSelected = ctaValues.length, 
    minSelected = 1, 
    wrapperWidth,
    exclusiveCTA,
    exclusiveCTAValues
  } = props;
  const { formData } = useSelector((state: OneFormState) => state);

  const { register, setValue } = useFormContext();
  const [selectedValue, setSelectedValue] = useState<string[]>(null);
  const [selectedLabel, setSelectedLabel] = useState<string[]>(null);
  const [ disabledAll, setDisabledAll ] = useState<boolean>(false);
  const [ disabledExclusiveCTA, setDisabledExclusiveCTA ] = useState<boolean>(false);
  const [aux, setAux] = useState(null);
  const ctaRefs = useRef<CTAsRef>({});

  useEffect(() => {
    if (formData.fields[name]) {
      const aux = formData.fields[name].split(',');
      let selectedVal = [];
      let selectedLabel = [];
      for (let i = 0; i < aux.length; i++) {
        const auxDos = ctaValues.find((e) => aux[i] === e.value);
        if (auxDos) {
          selectedVal.push(auxDos.value);
          selectedLabel.push(auxDos.label);
        }
      }
      setSelectedLabel(selectedLabel);
      setSelectedValue(selectedVal);
    }
  }, []);

  const handleOnClick = (cta: CTAValues) => {
    if (ctaRefs.current) {
      const ref = ctaRefs.current[cta.label];
      if (ref) {
        ref.blur();
      }
    }
    dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS', payload: { [name] : '' }});
    if (selectedValue === null) {
      setSelectedValue([cta.value]);
      setSelectedLabel([cta.label]);
      dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS', payload: { [name] : [cta.label] }});
    } else if (!selectedLabel.includes(cta.label)) {    
      if (selectedLabel.length < maxSelected) {
        const aux = selectedValue.concat([cta.value]);
        const auxTwo = selectedLabel.concat([cta.label]);
        setSelectedValue(aux);
        setSelectedLabel(auxTwo);
        dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS', payload: { [name] : auxTwo.join(', ') }});
      }
    } else if (selectedLabel.includes(cta.label)) {
      const aux = selectedValue.filter((a) => a !== cta.value);
      const auxTwo = selectedLabel.filter((a) => a !== cta.label);
      if (aux === []) {
        setSelectedValue(null);
        setSelectedLabel(null);
      } else {
        setSelectedValue(aux);
        setSelectedLabel(auxTwo);
        dispatch({ type: 'UPDATE_LITERAL_FIELDS_STEPS', payload: { [name] : auxTwo.join(', ') }});
      }
    } else {
      setSelectedValue(null);
      setSelectedLabel(null);
      setValue(name, null);
      dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: null } });
    }
  };

  useEffect(()=>{
    if(exclusiveCTA && selectedValue) {
      if(selectedValue.length === 0) {
        setDisabledAll(false);
        setDisabledExclusiveCTA(false);
      } else if(selectedValue.includes(exclusiveCTAValues[0].value)) {
        setDisabledAll(true);
        setDisabledExclusiveCTA(false);
      } else {
        setDisabledAll(false);
        setDisabledExclusiveCTA(true);
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    if (selectedValue) {
      if (selectedLabel.length >= minSelected && selectedLabel.length <= maxSelected) {
        setValue(name, selectedValue);
        setAux(selectedValue);
      } else {
        setValue(name, null);
      }
    } else {
      setValue(name, null);
    }
  }),
    [selectedValue];

  return (
    <CTAWrapper className="muliple-cta-wrapper" wrapperWidth={wrapperWidth}>
      {ctaValues.map((cta: CTAValues, index: number) => (
        <InputButton
          tag="button"
          name={name}
          label={cta.label}
          innerRef={(ref: any) => {
            register(ref, {
              required: true,
            });
            if (!ctaRefs.current || !ctaRefs.current[cta.label]) {
              ctaRefs.current[cta.label] = ref;
            }
          }}
          ignoreHandleClick={ignoreHandleClick}
          selectedValue={
            selectedLabel === null
              ? ''
              : selectedLabel.find((e) => e === cta.label)
              ? selectedLabel.find((e) => e === cta.label)
              : ''
          }
          size={'small'}
          key={index}
          onClick={(e) => {
            e.preventDefault();
            handleOnClick(cta);
          }}
          disabled={disabledAll}
        />
      ))}
      {
        exclusiveCTA ? (
          <>
          {
            exclusiveCTAValues.map((cta: CTAValues, index: number) => (
              <InputButton
                tag="button"
                name={name}
                label={cta.label}
                innerRef={(ref: any) => {
                  register(ref, {
                    required: true,
                  });
                  if (!ctaRefs.current || !ctaRefs.current[cta.label]) {
                    ctaRefs.current[cta.label] = ref;
                  }
                }}
                ignoreHandleClick={ignoreHandleClick}
                selectedValue={
                  selectedLabel === null
                    ? ''
                    : selectedLabel.find((e) => e === cta.label)
                    ? selectedLabel.find((e) => e === cta.label)
                    : ''
                }
                size={'small'}
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  handleOnClick(cta);
                }}
                disabled={disabledExclusiveCTA}
              />
            ))
          }
          </>
        ) : null
      }
      <input type="hidden" name={name} value={selectedValue} />
    </CTAWrapper>
  );
}

import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { dpsSend } from 'src/bff';
import { IdPerson, IdVehicle, IdDealer, IdOrder } from 'src/types';
import { DetalleMotivo } from 'src/components';
import { FormController, useOneFormContext } from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { formDataBuilder } from 'src/forms/format-fields';
import { sanitizeKvps } from 'src/forms/Cem/helpers';
import { ThankYouCEMSegundaEntregaValoracion, ConfirmacionCEMSegundaEntrega } from 'src/forms/Cem/CEMSegundaEntrega';

export interface CEMSegundaEntregaValoracionFormProps {
	handleError: () => void,
	getDealer: IdDealer;
	idPerson: IdPerson;
	idVehicle: IdVehicle;
	idDealer: IdDealer;
	idOrder: IdOrder;
}

export enum CEMSegundaEntregaValoracionSteps {
	Detalles,
	Confirmacion,
}

export function CEMSegundaEntregaValoracionForm(props: CEMSegundaEntregaValoracionFormProps) {
	const {
		handleError,
		getDealer,
		idPerson,
		idVehicle,
		idDealer,
		idOrder,
	} = props;
	const { initializeForm } = useOneFormContext();
	const { formData } = useSelector((state: OneFormState) => state);
	const store = useStore();
	const dispatch = useDispatch();
	const [loadingPresubmit, setLoadingPresubmit] = useState(true);

	const urlParams = new URLSearchParams(window.location.search);
	const adviser = urlParams.get('adviser');
	const pincode = urlParams.get('pincode');
	const rating = urlParams.get('rating');
	const agency = urlParams.get('agency');

	// Presubmit

	useEffect(() => {
		const sendPresubmit = async () => {
			const result = await sendForm(true);

			if (!result) {
				handleError();
			}
			setLoadingPresubmit(false);
		};

		if (getDealer) sendPresubmit();
	}, [getDealer]);


	// Definición de steps

	const steps: Steps[] = [
		{
			title: (
				<>
					<Text bold>Recibido. </Text>
					<Text>¿Te gustaría compartirnos algo más?</Text>
				</>
			),
			fields: <OneElementTemplateFullScreen element={<DetalleMotivo label='Escríbelo aquí' name="detalles" numberOfMaxLength={300} />} />,
			screenIndex: CEMSegundaEntregaValoracionSteps.Detalles,
			name: 'Detalles',
			outputs: ['detalles'],
			outputsText: ['detalles'],
		},
		{
			fields: <ConfirmacionCEMSegundaEntrega idPerson={idPerson} />,
			screenIndex: CEMSegundaEntregaValoracionSteps.Confirmacion,
			name: 'Confirmacion',
			outputs: ['confirmacion'],
			outputsText: ['confirmacion'],
		},
	];


	// Datos de envío

	const CEMSegundaEntregaValoracionData: Partial<FormDataGroup> = {
		headers: {},
		fields: {
			formName: 'cem-segunda_entrega-valoracion',
			formType: 'otherform',
			pageCategory: 'Encuesta',
		}, // Analítica
	};

	const sendForm = (isPresubmit?: boolean): Promise<boolean> => {
		const { formData }: OneFormState = store.getState();
		const { fields } = formData;

		return new Promise<any>(async (resolve, reject) => {
			const { tc, today, url, device, location } = getParamsForm();

			const sendData = {
				'headers[DESC_BRAND]': 'VW',
				'headers[DESC_CAMPAIGNBLOCK]': '1. VW On boarding',
				'headers[DESC_FORM_DETAIL]': 'CEM Segunda entrega valoración',
				'headers[DESC_FORMOBJECTIVE]': 'Encuesta',
				'headers[DESC_GDPR_ADAPTED]': true,
				'headers[DESC_ID_CAMPAIGN]': '7017Q0000001RqVQAU',
				'headers[DESC_TOKEN]': 'VW_DDB_FRM_CEM_SEGUNDA_ENTREGA_VALORACION',
				'headers[DESC_WAID_LITERA]': '',
				'headers[COD_FORM_ID]': 'vw-oneform-cem-segunda_entrega-valoracion',
				'headers[DESC_DEVICETYPE]': device,
				'headers[DESC_ID_CLIMAPRO]': idPerson ? idPerson.ID_Person_DIM_IMP__c : '',
				'headers[DESC_ID_VEHICLE_CLIMAPRO]': idVehicle ? idVehicle.IDClimapro__c : '',
				'headers[DESC_URLORIGIN]': url,
				'headers[FECHA_INSERTION_DATE]': today,
				'headers[TC]': tc,

				'fields[DESC_FORM_VERSION]': 'vw-oneform-cem-segunda_entrega-valoracion-' + process.env.VERSION,
				'fields[DESC_NUMBER_ORDER]': idOrder ? idOrder.IDClimapro__c : '',
				'fields[DAT_REGISTRATION_DATE]': idOrder.DAT_delivery__c ? idOrder.DAT_delivery__c.substring(0, 10) : '',
				'fields[DESC_ACTUAL_VEHICLE_MODEL]': idVehicle ? idVehicle.DESC_Model__c : '',
				'fields[DESC_VEH_PLATE]': idVehicle ? idVehicle.Registration__c : '',
				'fields[DESC_ADDRESS]': idPerson ? idPerson.FORM_Full_Address__c : '',
				'fields[DESC_DEALER]': getDealer ? getDealer.TXT_V_Installation_Data_Name__c : '',
				'fields[DESC_DEALER_CODE_KVPS]': getDealer ? sanitizeKvps(getDealer.TXT_V_KVPS_code__c) : '',
				'fields[DESC_DEALER_CODE]': getDealer ? getDealer.TXT_V_Installation_Code__c : '',
				'fields[DESC_EMPLOYEE_ID]': adviser,
				'fields[DESC_EMAIL_ADDRESS]': idPerson ? idPerson.TXT_Email__c : '',
				'fields[NUM_PHONE]': idPerson ? idPerson.NUM_Phone_mobile__c : '',
				'fields[DESC_FRAME]': idVehicle ? idVehicle.Frame__c : '',
				'fields[DESC_NAME]': idPerson ? idPerson.FirstName : '',
				'fields[DESC_SURNAME_1]': idPerson ? idPerson.LastName : '',
				'fields[DESC_SURNAME_2]': idPerson ? idPerson.TXT_2_Surname__c : '',
				'fields[DESC_PROVINCE]': idPerson ? idPerson.TXT_Province__c : '',
				'fields[DESC_POSTAL_CODE]': idPerson ? idPerson.TXT_Postal_Code__c : '',
				'fields[DESC_LOPD]': 'PERMITE',
				'fields[DESC_LOPD_GDPR_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
				'fields[DESC_LSSI]': fields.lssi ? fields.lssi : 'NO INFORMADO',
				'fields[DESC_AUXFIELD1]': rating,
				'fields[TRADICIONAL_AGENCIA]': agency,

				'flags[ES_AGENCIA]': agency ? agency == 'AGENCIA' ? 'true' : 'false' : null,
			};

			if (!isPresubmit) {
				sendData['headers[PINCODE]]'] = pincode;
				sendData['headers[DESC_TOKEN]]'] = 'VW_DDB_FRM_CEM_SEGUNDA_ENTREGA_VALORACION_CONTESTADO';
				sendData['fields[DESC_AUXFIELD2]'] = fields.detalles;
			}
			const dataStringified = JSON.stringify(sendData);
			const dataParsed = JSON.parse(dataStringified);
			const formData = formDataBuilder(dataParsed);

			const response = await dpsSend(formData, isPresubmit);

			if (response && response.data && response.data.content && response.data.content.code === '200') {
				dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
				resolve(true);
			} else {
				resolve(false);
			}
		});
	};


	// Inicialización del form

	const getFormInfo = (): {
		firstStep: Steps;
		lastStep: Steps;
		stepsHistory: number[];
		numberOfScreens: number;
		newSteps?: Steps[];
	} => {
		return {
			firstStep: steps[0],
			lastStep: steps[steps.length - 1],
			stepsHistory: [0],
			numberOfScreens: steps.length,
			newSteps: null,
		};
	};

	useEffect(() => {
		const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

		const CEMSegundaEntregaValoracionInfo: Partial<FormInfo> = {
			formStarted: true,
			sendFormCallback: sendForm,
			fullScreen: firstStep.fullScreen ? true : false,
			lastStep,
			numberOfScreens,
			screenIndex: firstStep.screenIndex,
			formTheme: 'main',
			notALayer: true,
			formType: 'inPage',
			step: firstStep,
			steps: newSteps ? newSteps : steps,
			stepsHistory,
			thankYouComponent: <ThankYouCEMSegundaEntregaValoracion />,
		};
		initializeForm(CEMSegundaEntregaValoracionInfo, CEMSegundaEntregaValoracionData);
	}, [getDealer]);

	return steps && !loadingPresubmit ? <FormController steps={steps} screenType="full-screen" /> : null;
}

import { getParamsForm } from 'src/feature-app/get-params-form';
import { sanitizeKvps } from 'src/forms/Cem/helpers';
import dayjs from 'dayjs';

const today = dayjs();

const urlParams = new URLSearchParams(window.location.search);
const adviser = urlParams.get('adviser');
const kvps = urlParams.get('kvps');
const pincode = urlParams.get('pincode');

const { tc, url, device } = getParamsForm();

export const getData = (idPerson, idVehicle, idDealer, idOrder) => {
	return {
		'headers[DESC_BRAND]': 'VW',
		'headers[DESC_CAMPAIGNBLOCK]': '1. VW On boarding',
		'headers[DESC_FORM_DETAIL]': 'CEM Segunda entrega',
		'headers[DESC_FORMOBJECTIVE]': 'Encuesta',
		'headers[DESC_GDPR_ADAPTED]': true,
		'headers[DESC_ID_CAMPAIGN]': '7017Q000000DYTfQAO',
		'headers[DESC_TOKEN]': 'VW_DDB_FRM_CEM_SEGUNDA_ENTREGA_INVITACION',
		'headers[DESC_WAID_LITERA]': '',
		'headers[COD_FORM_ID]': 'vw-oneform-cem-segunda_entrega-invitacion',
		'headers[DESC_DEVICETYPE]': device,
		'headers[DESC_ID_CLIMAPRO]': idPerson ? idPerson.ID_Person_DIM_IMP__c : '',
		'headers[DESC_ID_VEHICLE_CLIMAPRO]': idVehicle ? idVehicle.IDClimapro__c : '',
		'headers[DESC_URLORIGIN]': url,
		'headers[FECHA_INSERTION_DATE]': today,
		'headers[PINCODE]': pincode,
		'headers[TC]': tc,

		'fields[DESC_FORM_VERSION]': 'vw-oneform-cem-segunda_entrega-invitacion-' + process.env.VERSION,
		'fields[DESC_NUMBER_ORDER]': idOrder ? idOrder.IDClimapro__c : '',
		'fields[DAT_REGISTRATION_DATE]': idOrder ? idOrder.DAT_delivery__c.substring(0, 10) : '',
		'fields[DESC_ACTUAL_VEHICLE_MODEL]': idVehicle ? idVehicle.DESC_Model__c : '',
		'fields[DESC_VEH_PLATE]': idVehicle ? idVehicle.Registration__c : '',
		'fields[DESC_ADDRESS]': idPerson ? idPerson.FORM_Full_Address__c : '',
		'fields[DESC_DEALER]': idDealer ? idDealer.TXT_V_Installation_Data_Name__c : '',
		'fields[DESC_DEALER_CODE_KVPS]': idDealer ? sanitizeKvps(idDealer.TXT_V_KVPS_code__c) : '',
		'fields[DESC_DEALER_CODE]': idDealer ? idDealer.TXT_V_Installation_Code__c : '',
		'fields[DESC_EMPLOYEE_ID]': adviser,
		'fields[DESC_EMAIL_ADDRESS]': idPerson ? idPerson.TXT_Email__c : '',
		'fields[NUM_PHONE]': idPerson ? idPerson.NUM_Phone_mobile__c : '',
		'fields[DESC_FRAME]': idVehicle ? idVehicle.Frame__c : '',
		'fields[DESC_NAME]': idPerson ? idPerson.FirstName : '',
		'fields[DESC_SURNAME_1]': idPerson ? idPerson.LastName : '',
		'fields[DESC_SURNAME_2]': idPerson ? idPerson.TXT_2_Surname__c : '',
		'fields[DESC_PROVINCE]': idPerson ? idPerson.TXT_Province__c : '',
		'fields[DESC_POSTAL_CODE]': idPerson ? idPerson.TXT_Postal_Code__c : '',
		'fields[DESC_LOPD]': 'PERMITE',
	};
};

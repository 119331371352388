import { Spinner, Text, styled } from '@volkswagen-onehub/components-core';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DomainAttributesValues } from 'src';
import { useFeatureServices, useTrackingManager } from 'src/feature-app';
import { DpsData, EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { CarlineBase, DealersData, IdDealer, IdLead, IdPerson } from 'src/types';
import { LEMContactadoForm } from './LEMContactadoForm';
import { LEMNoContactadoForm } from './LEMNoContactadoForm';
import { GenericErrorLayer } from 'src/components/layers';
import { checkIdLeadHasCarline } from './use-initialize-lem-form';
import { createPincodeEntity } from '../dps-utils';
import { LoadingEncuestas } from 'src/components';
import { getInstallations } from 'src/bff';
import { arrayToObject } from 'src/helpers';

export interface LEMFormProps {
  idLead: IdLead;
  idPerson: IdPerson;
  dpsData: DpsData;
  installation: IdDealer;
  handleError: () => void;
  presubmit: boolean;
  trackFormLoad: () => void;
}

export function LEMController() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [usado, setUsado] = useState(false);
  const [caducado, setCaducado] = useState(false);
  const [presubmit, setPresubmit] = useState(false);
  const [contactado, setContactado] = useState(false);
  const today = dayjs(new Date());
  const trackingManager = useTrackingManager();
  const dpsDataRef = useRef(null);
  const idLeadRef = useRef(null);
  const idPersonRef = useRef(null);
  const [installations, setInstallations] = useState(null);
  const [installation, setInstallation] = useState(null);
  const dispatch = useDispatch();
  const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;

  const handleNext = (value: any) => {};
  const handleComplete = () => {
    const dpsData = oneFormService.getDpsData();

    if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
      handleError();
    } else if (dpsData.pincode.pincodeUsed) {
      setUsado(true);
      setLoading(false);
    } else {
      const idLead = createPincodeEntity(dpsData, 'LEAD');
      const idPerson = createPincodeEntity(dpsData, 'PERSON');

      dpsDataRef.current = dpsData;
      idLeadRef.current = idLead;
      idPersonRef.current = idPerson;

      const leadCreatedDate = dayjs(idLead.CreatedDate);
      const fechaEnvio = dpsData.fe ? dayjs(atob(dpsData.fe), 'DD/MM/YYYY') : null;
      let onTime = false;
      if (fechaEnvio) {
        onTime = today.diff(fechaEnvio, 'day') < 7;
      }
      else {
        onTime = today.diff(leadCreatedDate, 'day') < 7;
      }

      if (dpsData.push !== 'true' && onTime) {
        setPresubmit(true);
        setContactado(dpsData.contactado === 'true');
        setAllInstallations();
      } 
      else if (dpsData.push == 'true') {
        setContactado(dpsData.contactado === 'true');
        setAllInstallations();
      } 
      else {
        setCaducado(true);
      }
      setLoading(false);
    }
  };

  const setAllInstallations = () => {
    getInstallations().then((dealers) => {
      const installationsDomainAtt = dealers.map((inst) => inst.attributes);
      setInstallations(installationsDomainAtt);
    });
  }

  // Guardamos la instalacion por Id (idLead.Dealership__c)
	useEffect(() => {
    if (installations && !installation) {
      const dpsData = oneFormService.getDpsData();
      const idLead = createPincodeEntity(dpsData, 'LEAD');

      let found = false;
      let i = 0;
      while (i < installations.length && found === false) {
        const found = installations[i].find((ele: DomainAttributesValues) => {
          if (
            typeof ele.value != 'undefined' && typeof ele.name != 'undefined' &&
            (ele.name.includes('Id') &&
            ele.value.includes(idLead.Dealership__c))
          ) {
            return ele;
          }
          return false;
        });
        if (found) {
          setInstallation(arrayToObject(installations[i]));
        }
        i++;
      }
    }
	}, [installations]);

  const handleError = () => {
    setError(true);
    setLoading(false);
  };

  const trackFormLoad = () => {
    if (idLeadRef.current) {
      const customDealer: DealersData = {
        kvps: idLeadRef.current.TXT_DESC_DEALER_CODE__c,
        name: idLeadRef.current.DESC_Dealer_Code__c,
        markerInfo: { dealerInfo: { zipCode: idLeadRef.current.TXT_Postal_Code__c } },
      };
      dispatch({ type: 'SET_SELECTED_DEALER', payload: customDealer });
    }

    trackingManager.trackFormLoad();
  };

  useEffect(() => {
    const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

    oneFormCoreDpsIsLoaded.subscribe({
      next(value) {
        handleNext(value);
      },
      error(err) {
        console.error(err);
        handleError();
      },
      complete() {
        handleComplete();
      },
    });
  }, []);

  // función para layer de error
  const handleTryAgain = async () => {
    // oneFormService.
    setError(false);
    setLoading(true);
    await oneFormService.retryPincodeLoad(true);
    handleComplete();
  };

  if (loading) {
    return <LoadingEncuestas />;
  } else if (error) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, algo ha pasado"
          bodycopy="Por alguna razón no hemos podido cargar el cuestionario."
          ctaLayer="Reintentar"
          notShowCTA={true}
          icon={false}
          notALayer={true}
        />
      </>
    );
  } else if (caducado) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, este formulario ha caducado"
          bodycopy="Parece que el cuestionario ya no está disponible. ¿Quieres contarnos cómo ha sido tu experiencia?"
          ctaLayer="Contacta con nosotros"
          ctaType="a"
          urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
          icon={false}
          notALayer={true}
        />
      </>
    );
  } else if (usado) {
    return (
      <>
        <GenericErrorLayer
          title="Uy, ya has respondido a este cuestionario"
          bodycopy="Parece que ya nos has contado cómo fue tu experiencia. ¿Quieres añadir algo más?"
          ctaLayer="Contacta con nosotros"
          ctaType="a"
          urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
          icon={false}
          notALayer={true}
        />
      </>
    );
  } else if(installation) {
    return (
      <>
        {contactado ? (
          <LEMContactadoForm
            dpsData={dpsDataRef.current}
            idLead={idLeadRef.current}
            idPerson={idPersonRef.current}
            installation={installation}
            handleError={handleError}
            presubmit={presubmit}
            trackFormLoad={trackFormLoad}
          />
        ) : (
          <LEMNoContactadoForm
            dpsData={dpsDataRef.current}
            idLead={idLeadRef.current}
            idPerson={idPersonRef.current}
            installation={installation}
            handleError={handleError}
            presubmit={presubmit}
            trackFormLoad={trackFormLoad}
          />
        )}
      </>
    );
  }
  else {
    return null
  }
}

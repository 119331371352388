import { CarlineBase } from 'src/types';
/**
 * Devuelve únicamente los carlines preseleccionados desde el cms en una array con carlineCodes tipo [30155, 30205].
 * @param carlines
 * @param faContentCarlines
 * @returns
 */
export const filterPreselectedFaContent = (carlines: CarlineBase[], faContentCarlines: number[]) => {
  return faContentCarlines
    .map((carlineCode) => carlines.find((carline) => carline.code == carlineCode))
    .filter((c) => c !== undefined);
};

import React, { useEffect, useRef, useState } from 'react';
import { useFeatureServices, useFaContent, useTrackingManager } from 'src/feature-app';
import { DpsData, EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { CEMSegundaEntregaForm, NoMeInteresaCEMSegundaEntrega } from 'src/forms/Cem/CEMSegundaEntrega';
import { GenericErrorLayer } from 'src/components/layers';
import { createPincodeEntity } from 'src/forms/dps-utils';
import { getInstallationsLegacy, getInstallationsEntregaIdeal } from 'src/bff';
import { DomainAttributesValues, IdDealer } from 'src';
import { LoadingEncuestas } from 'src/components';
import { arrayToObject } from 'src/helpers';


export function CEMSegundaEntregaController() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [usado, setUsado] = useState(false);
	const [interes, setInteres] = useState(false);

	const [idPerson, setIdPerson] = useState(null);
	const [idVehicle, setIdVehicle] = useState(null);
	const [idDealer, setIdDealer] = useState(null);
	const [idOrder, setIdOrder] = useState(null);

	const [dealerCode, setDealerCode] = useState<string>(null);
	const [dealer, setDealer] = useState(null);
	const [dealers, setDealers] = useState(null);

	const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;

	const handleNext = (value: any) => { };
	const handleComplete = () => {
		const dpsData = oneFormService.getDpsData();

		if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
			handleError();
		} else if (dpsData.pincode.pincodeUsed) {
			setUsado(true);
			setLoading(false);
		} else {
			setIdPerson(createPincodeEntity(dpsData, 'PERSON'));
			setIdVehicle(createPincodeEntity(dpsData, 'VEHICLE'));
			setIdDealer(createPincodeEntity(dpsData, 'DEALER'));
			setIdOrder(createPincodeEntity(dpsData, 'ORDER'));

			setInteres(dpsData.interes === 'true');

			if (dpsData.dealer) {
				setDealerCode(dpsData.dealer);
			}
			else if (typeof idDealer.TXT_V_Installation_Code__c != 'undefined') {
				setDealerCode(idDealer.TXT_V_Installation_Code__c);
			}
			else if (typeof idDealer.TXT_Installation_Code__c != 'undefined') {
				setDealerCode(idDealer.TXT_Installation_Code__c);
			}

			setLoading(false);
		}
	};

	useEffect(() => {
		if (dealerCode) {
			setDealerAndDealers();
		}
	}, [dealerCode]);

	const setDealerAndDealers = async () => {
		const installations = await (await getInstallationsLegacy()).map((inst) => inst.attributes);
		const filter = await getInstallationsEntregaIdeal() as [any];

		let dealer = null;
		for (const inst of installations) {
			const installation = arrayToObject(inst as [DomainAttributesValues]) as IdDealer;
			if (installation.TXT_V_Installation_Code__c == dealerCode) {
				dealer = installation;
			}
			else if (installation.TXT_Installation_Code__c == dealerCode) {
				dealer = installation;
			}
		}
		setDealer(dealer);

		if (dealer && interes) {
			const dealers = [];
			for (const inst of installations) {
				const installation = arrayToObject(inst as [DomainAttributesValues]) as IdDealer;
				if (installation.TXT_V_Installation_Data_Name__c == dealer.TXT_V_Installation_Data_Name__c) {
					if (filter.includes(installation.TXT_V_Installation_Code__c)) {
						dealers.push(installation);
					}
				}
			}
			setDealers(dealers);
		}
	};

	const handleError = () => {
		setError(true);
		setLoading(false);
	};

	useEffect(() => {
		const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

		oneFormCoreDpsIsLoaded.subscribe({
			next(value) {
				handleNext(value);
			},
			error(err) {
				console.error(err);
				handleError();
			},
			complete() {
				handleComplete();
			},
		});
	}, []);

	if (loading) {
		return <LoadingEncuestas />;
	} else if (error) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, algo ha pasado"
					bodycopy="Por alguna razón no hemos podido cargar el cuestionario."
					ctaLayer="Reintentar"
					notShowCTA={true}
					icon={false}
					notALayer={true}
				/>
			</>
		);
	} else if (usado) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, ya has respondido a este cuestionario"
					bodycopy="Parece que ya nos has contado cómo fue tu experiencia. ¿Quieres añadir algo más?"
					ctaLayer="Contacta con nosotros"
					ctaType="a"
					urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
					icon={false}
					notALayer={true}
				/>
			</>
		);
	}
	else {
		return (
			<>
				{interes ? (
					<CEMSegundaEntregaForm
						dealers={dealers}
						idPerson={idPerson}
						idVehicle={idVehicle}
						idDealer={idDealer}
						idOrder={idOrder}
					/>
				) : (
					<NoMeInteresaCEMSegundaEntrega
						handleError={handleError}
						idPerson={idPerson}
						idVehicle={idVehicle}
						idDealer={dealer}
						idOrder={idOrder}
					/>
				)}
			</>
		);
	}
}

import {
  styled,
  Text,
  ThemeProvider,
  TokenTextAppearance,
  TokenTextColor,
} from '@volkswagen-onehub/components-core';
import { ArrowDown } from 'src/icons-core-imports';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, useStore } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import {
  isNumber,
  useLoaded,
  useTrackingManager,
} from 'src/feature-app';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { TIME_FOR_OPACITY_MS, TIME_FOR_TRANSFORM_MS } from 'src/globals';
import { OneFormState, Steps } from 'src/types';

interface navigationWrapperProps {
  fullScreen?: boolean;
  isLastScreen?: boolean;
  notALayer?: boolean;
  colorTheme?: string;
  formMin?: boolean;
  position?: string;
}

const NavigationWrapper = styled.div<navigationWrapperProps>`
  display: ${(props) => (props.notALayer ? 'block' : props.fullScreen ? 'none' : props.isLastScreen ? 'none' : 'block')};
  position: ${(props) => (!props.notALayer ? 'fixed' : props.position ? props.position : 'relative')};
  bottom: 0;
  
  width: 100%;
  background-color: ${(props) => (props.notALayer ? 'transparent' : props.colorTheme === 'main' ? 'none' : '#001e50')};
  &:first-child {
    width: 100%;
  }

  @media screen and (min-width: 960px) {
    width: ${(props) => (props.notALayer ? '100%' : '960px')};
  }

  &.navigation-enter {
    transform: translate(0%, 50%);
    opacity: 0;
  }
  &.navigation-enter-active {
    transform: translate(0, 0);
    opacity: 1;
    transition: transform ${TIME_FOR_TRANSFORM_MS}, opacity ${TIME_FOR_OPACITY_MS} ease-in;
  }
  &.navigation-enter-done {
  }
  &.navigation-exit {
    transform: translate(0, 0);
    opacity: 1;
  }
  &.navigation-exit-active {
    transform: translate(0, -100%);
    opacity: 0.01;
    transition: all ${TIME_FOR_TRANSFORM_MS}, opacity ${TIME_FOR_OPACITY_MS} ease-in;
  }
`;

interface ProgressWrapperProps {
  notALayer?: boolean;
}

const ProgressWrapper = styled.div<ProgressWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(props) => (props.notALayer ? 'var(--size-grid010)' : '187px')};
  
  @media all and (min-width: 560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid013)' : '303px')};
  }
  @media all and (min-width: 960px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid008)' : '320px')};
  }
  @media all and (min-width: 1280px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid006)' : '320px')};
  }
  @media all and (min-width: 1920px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '320px')};
  }
  @media all and (min-width: 2560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid004)' : '320px')};
  }
`;

const BarWrapper = styled.div`
  width: 100%;
  position: relative;
`;

interface UpperBarProps {
  width?: number;
  colorTheme?: string;
}
const UpperBar = styled.div<UpperBarProps>`
  position: absolute;
  width: ${(props) => `${props.width}%`};
  height: 2px;
  background: ${(props) => (props.colorTheme === 'main' ? '#001E50' : '#ffffff')};
  transition: all 0.3s;
`;

const LowerBar = styled.div<UpperBarProps>`
  position: absolute;
  width: 100%;
  height: 2px;
  background: ${(props) => (props.colorTheme === 'main' ? '#DFE4E8' : '#6a767d')} ;
`;

const TextWrapper = styled.div`
  align-self: flex-end;
  margin-top: 8px;
`;

const DisplayWrapper = styled.div<navigationWrapperProps>` 
  display: flex;
  justify-content: space-between;
  align-items: ${(props) => (props.notALayer ? 'flex-end' : null)};

  width: 100%;
  padding-bottom: ${(props) => (props.notALayer ? '16px' : props.fullScreen ? '0' : props.isLastScreen ? '0' : '20px')};
  padding-left: var(--size-grid002);
  padding-right:${(props) => (props.notALayer ? 'var(--size-grid002)' : 'var(--size-grid001)')};

  @media screen and (min-width: 560px) {
    padding-bottom: ${(props) => (props.notALayer ? '16px' : props.fullScreen ? '0' : props.isLastScreen ? '0' : '24px')};
  }
  @media screen and (min-width: 960px) {
    padding-left:${(props) => (props.notALayer ? 'var(--size-grid003)' : '120px')};
    padding-right: ${(props) => (props.notALayer ? 'var(--size-grid003)' : '40px')};
    padding-bottom: ${(props) => (props.notALayer ? '16px' : props.fullScreen ? '0' : props.isLastScreen ? '0' : '44px')};
  }
  @media screen and (min-width: 1280px) {
    padding-left: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '120px')};
    padding-right: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '40px')};
  }
  @media screen and (min-width: 1920px) {
    padding-left: ${(props) => (props.notALayer ? 'var(--size-grid006)' : '120px')};
    padding-right: ${(props) => (props.notALayer ? 'var(--size-grid006)' : '40px')};
  }
  @media screen and (min-width: 2560px) {
    padding-left: ${(props) => (props.notALayer ? 'var(--size-grid007)' : '120px')};
    padding-right: ${(props) => (props.notALayer ? 'var(--size-grid007)' : '40px')};
  }
`;

const BackgroundPositionWrapper = styled.div<ProgressWrapperProps>`
 position: ${(props) => (props.notALayer ? 'relative' : null)};
 width: 100%; 
`;

const BackgroundColorNotALayer = styled.div<navigationWrapperProps>`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #FFFFFF;
  border-top: ${(props) => props.position === 'fixed' ? '1px solid #DFE4E8' : null } ;
  height: 56px;
  z-index: -1;
`;

const SVGColorWrapper = styled.div<{isValid: boolean}>`
  svg {
    color: ${(props)=>(props.isValid ? '#001E50' : '#6A767D')};
  }
`;

const NextButtonWrapper = styled.div<ProgressWrapperProps>`
  padding-bottom: ${(props) => (props.notALayer ? '8px' : null)};
`;

const NextButton = styled.button<{isValid: boolean}>`
  height: 64px;
  width: 64px;
  border-radius: 50%;
  border: none;
  background-color: ${(props)=>(props.isValid ? '#00B0F0' : '#C2CACF')};
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

function InnerNavigation(props: any) {
  const { formClicked, setFormClicked, notALayer, colorTheme, soloProgreso, isFullScreen, isLastScreen, position } = props;
  const { formInfo, formData } = useSelector(
    (state: OneFormState) => state
  );
  const { fields } = formData;
  const { screenIndex, numberOfScreens, multiStepScreenIndex, step, steps } = useSelector(
    (state: OneFormState) => state.formInfo
  );
  const { getValues, errors, triggerValidation, clearError } = useFormContext();
  const store = useStore();
  const { handleNextStep, setShowPreviousStep, isValueSelected, setIsValueSelected, setError } = useOneFormContext();
  const [isValid, setIsValid] = useState<boolean>(false);
  const trackingManager = useTrackingManager();

  //Navigation

  const checkIfValid = async () => {
    const valid = await triggerValidation();
    clearError();

    if (valid) {   
      //En CarlineSelect la opción inicial incluye string vacía desde cambio a components core v64.3.0
      if(fields.modelo === ""){
        setIsValid(false);
      }
      else if(screenIndex === 0 && !formClicked) {
        if(fields.formName === 'no_compra-ya_tengo') {
          setIsValid(true);
          setError(false);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(true);
        setError(false);
      }
    } else {
      setIsValid(false);
    }
  };

  useEffect(()=>{
    if(fields.modelo !== "" || fields.model !== ""){
      setIsValid(true);
      setError(false);
    }
  },[fields.modelo, fields.model])
  

  useEffect(() => {
    const checkValidity = async () => await checkIfValid();
    if (formClicked || isValueSelected) {
      checkValidity();
      if (formClicked) setFormClicked(false);
      if (isValueSelected) setIsValueSelected(false);
    }
  }, [formClicked, isValueSelected]);

  useEffect(() => {
    setTimeout(() => {
      if (steps) {
        const currentStep = steps.find((step) => step.screenIndex === screenIndex);
        const { notALayer } = store.getState().formInfo;
        if (
          !notALayer && (
            (screenIndex === 0 && !steps[screenIndex].multiStep) ||
            screenIndex === numberOfScreens - 1 ||
            (currentStep && currentStep.hidePreviousStep) ||
            screenIndex > numberOfScreens
          )
        ) {
          setShowPreviousStep(false);
        } else {
          setShowPreviousStep(true);
        }
      }
    }, 100);

    const checkValidity = async () => await checkIfValid();

    setTimeout(() => {
      checkValidity();
    }, 600);
  }, [screenIndex]);

  useEffect(() => {
    const {
      step,
      multiSteps,
      multiStepScreenIndex,
    }: { step: Steps; multiSteps: Steps[]; multiStepScreenIndex: number } = store.getState().formInfo;

    setTimeout(() => {
      if (multiSteps && isNumber(multiStepScreenIndex) && step && step.multiStep) {
        if (multiSteps[multiStepScreenIndex].hidePreviousStep) {
          setShowPreviousStep(false);
        } else {
          setShowPreviousStep(true);
        }
      } else {
        setShowPreviousStep(true);
      }
    }, 0);

    const checkValidity = async () => await checkIfValid();

    setTimeout(() => {
      checkValidity();
    }, 600);
  }, [multiStepScreenIndex]);

  const getPercentage = (): string => {
    const percentage = ((screenIndex / (numberOfScreens - 1)) * 100).toFixed(0);
    if(numberOfScreens - 1 == 1) {
      return '50';
    }
    else if(percentage === '0') {
      return '5';
    }
    else {
      return percentage;
    }
  };

  return (
    <BackgroundPositionWrapper notALayer={notALayer}>
      {
        notALayer ? <BackgroundColorNotALayer className='background-color' position={position}/> : null
      }
      <DisplayWrapper 
        className="display-wrapper" 
        notALayer={notALayer}
        fullScreen={isFullScreen}
        isLastScreen={isLastScreen}
      >
        <ProgressWrapper notALayer={notALayer}>
          <BarWrapper>
            <LowerBar colorTheme={colorTheme} />
            <UpperBar width={parseFloat(getPercentage())} colorTheme={colorTheme} />
          </BarWrapper>
          <TextWrapper>
            <Text color={TokenTextColor.primary} appearance={TokenTextAppearance.copy100}>
              {getPercentage()}% completado
            </Text>
          </TextWrapper>
        </ProgressWrapper>
        {
          soloProgreso ? null : (
            <NextButtonWrapper notALayer={notALayer}>
              <ThemeProvider
                theme={{
                  interaction: {
                    recipe250: {
                      default: isValid ? 'color.blue-200' : 'color.grey-400',
                      hover: isValid ? 'color.blue-200' : 'color.grey-400',
                      active: isValid ? 'color.blue-200' : 'color.grey-400',
                      disabled: isValid ? 'color.blue-200' : 'color.grey-400',
                      checked: isValid ? 'color.blue-200' : 'color.grey-400',
                    },
                  },
                }}
              >
                <NextButton
                  onClick = {async (e:any) => {
                    if(fields.modelo === ""){
                      setError(true);
                    }else{
                      trackingManager.trackFormStepButtonClick({
                        contentId: 'Siguiente',
                      });
                      handleNextStep(e);
                    }
                  }}
                  isValid={isValid}
                >
                  <SVGColorWrapper isValid={isValid}>
                    <ArrowDown />
                  </SVGColorWrapper>
                </NextButton>
              </ThemeProvider>
            </NextButtonWrapper>
          )
        }  
      </DisplayWrapper>
    </BackgroundPositionWrapper>
  );
}

interface NavigationProps {
  formClicked?: boolean;
  setFormClicked?: React.Dispatch<React.SetStateAction<boolean>>;
  notALayer?: boolean;
  colorTheme?: string;
  refProps?: any;
  formMin?: boolean;
  soloProgreso: boolean;
  position?: string;
}

export function Navigation(props: NavigationProps) {
  const { colorTheme, notALayer, position } = props;
  const { screenIndex, step } = useSelector((state: OneFormState) => state.formInfo);
  const loaded = useLoaded();
  const [isLastScreen, setIsLastScreen] = useState(false);
  const { isFullScreen } = useOneFormContext();
  const store = useStore();

  useEffect(() => {
    const { formInfo } = store.getState();
    const { step, lastStep } = formInfo;
    if (step && lastStep) {
      step.screenIndex === lastStep.screenIndex ? setIsLastScreen(true) : setIsLastScreen(false);
    }
  }, [step]);

  return (
    <CSSTransition timeout={500} in={loaded} classNames="navigation">
      <NavigationWrapper
        fullScreen={isFullScreen}
        isLastScreen={isLastScreen}
        className="navigation-wrapper"
        notALayer={notALayer}
        colorTheme={colorTheme}
        position={position}
      >
        {isLastScreen ? null : <InnerNavigation {...props} />}
      </NavigationWrapper>
    </CSSTransition>
  );
}

import { Breakpoints, styled, Text, Layout, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import React, { useEffect } from 'react';
import { LssiTkmQuestions, PoliticaPrivacidad, LastNavigation } from 'src/components';
import { useDispatch } from 'react-redux';
import { IdPerson } from 'src/types';

const GeneralconfirmacionWrapper = styled.div``;

interface ConfirmacionCEMOneShopProps {
  idPerson: IdPerson;
}
export const ConfirmacionCEMOneShop = (props: ConfirmacionCEMOneShopProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.idPerson.LSSI__c === 'PERMITE') {
      dispatch({ type: 'UPDATE_FIELDS', payload: { lssi: 'NO INFORMADO', tkm: 'NO INFORMADO' } });
    }
  }, [props.idPerson]);

  return (
    <GeneralconfirmacionWrapper>
      <Layout
        allowOverflowingContent
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.b960]: [
            { name: '.', columns: 3 },
            { name: 'a', columns: 18 },
            { name: '.', columns: 3 },
          ],
          [Breakpoints.b1280]: [
            { name: '.', columns: 5 },
            { name: 'a', columns: 14 },
            { name: '.', columns: 5 },
          ],
          [Breakpoints.b1920]: [
            { name: '.', columns: 6 },
            { name: 'a', columns: 12 },
            { name: '.', columns: 6 },
          ],
          [Breakpoints.b2560]: [
            { name: '.', columns: 7 },
            { name: 'a', columns: 10 },
            { name: '.', columns: 7 },
          ],
        }}
      >
        {props.idPerson.LSSI__c === 'PERMITE' ? (
          <div style={{ paddingBottom: '32px' }}>
            <Text appearance={TokenTextAppearance.headline300}>
              <Text bold>¿Listo</Text> para enviar?
            </Text>
          </div>
        ) : (
          <>
            <Text appearance={TokenTextAppearance.headline300}>
              ¡Gracias! Unas <Text bold>últimas preguntas</Text> para tenerlo todo claro
            </Text>
            <LssiTkmQuestions title={false} themeColor="main" />
          </>
        )}

        <PoliticaPrivacidad color="#001e50" />

        <LastNavigation
          loadingCopy="Enviando tu respuesta"
          KoTitle="Uy, algo ha pasado"
          KoBody="Por alguna razón no hemos podido guardar tu respuesta. ¿Lo intentamos de nuevo?"
        />
      </Layout>
    </GeneralconfirmacionWrapper>
  );
};

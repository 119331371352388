import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Text,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { CloseHandleV2, FocusLayerSizeV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import React from 'react';
import { useStore } from 'react-redux';
import { LayerManagerWrapper, useFeatureAppEnvironment, useFeatureServices } from 'src/feature-app';
import { LssiTkmQuestionsProps, LegalItem } from '.';
import { useGetIdPerson } from 'src/feature-app';

const getLssiTitle = (isOfertas: boolean) => {
  return isOfertas ? (
    <Text appearance={TokenTextAppearance.copy200}>
      ¿Te gustaría recibir comunicaciones comerciales sobre productos y servicios de nuestra{' '}
      <span style={{ whiteSpace: 'nowrap' }}>
        marca?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
      </span>
    </Text>
  ) : (
    <Text appearance={TokenTextAppearance.copy200}>
      ¿Te gustaría recibir descuentos y novedades de{' '}
      <span style={{ whiteSpace: 'nowrap' }}>
        Volkswagen?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
      </span>
    </Text>
  );
};

const getLssiText = (isOfertas: boolean) => {
  return isOfertas
    ? 'Te enviaremos por cualquier medio información sobre descuentos, novedades, próximos lanzamientos, invitaciones a eventos y/o promociones especiales'
    : 'Si quieres, podemos enviarte información sobre próximos lanzamientos, invitaciones a eventos y promociones especiales.';
};

const getTmkTitle = (isOfertas: boolean) => {
  return isOfertas ? (
    <Text appearance={TokenTextAppearance.copy200}>
      ¿Consientes que elaboremos un perfil tuyo como usuario para poder enviarte información comercial sobre los
      productos y servicios de nuestra marca que creamos que puedan resultar más de tu interés según tus{' '}
      <span style={{ whiteSpace: 'nowrap' }}>
        preferencias?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
      </span>
    </Text>
  ) : (
    <Text appearance={TokenTextAppearance.copy200}>
      ¿Te gustaría que elaboremos un perfil tuyo para ofrecerte información comercial sobre productos y servicios de
      nuestras marcas que creamos que te puedan interesar más según tus{' '}
      <span style={{ whiteSpace: 'nowrap' }}>
        preferencias?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
      </span>
    </Text>
  );
};

const getTmkText = (isOfertas: boolean) => {
  return isOfertas
    ? 'Para <b>elaborar tu perfil</b> utilizaremos toda aquella información que disponemos de ti derivada de tu relación con nosotros como son la tipología de productos y/o servicios contratados, así como cualquier interacción con nuestra marca. Para ello, utilizaremos tanto la información que nos hayas aportado directamente como la que nos puedan hacer llegar terceros a los que hayas consentido que nos hagan llegar esta información. Gracias a esta información seremos capaces de enviarte información comercial sobre nuestros productos y servicios que consideramos que serán de más interés para ti que las genéricas. En el caso que consientas, no recibirás comunicaciones comerciales genéricas sino lo más personalizadas posible según tus preferencias para hacer que tu experiencia con la marca sea lo más positiva posible.'
    : 'Acceder a tu perfil nos ayuda a aprender de ti para mostrarte los servicios y ofertas que más te interesan.';
};

export const LssiTkmQuestionsComerciales = ({ title, themeColor }: LssiTkmQuestionsProps) => {
  const store = useStore();
  const idPerson = useGetIdPerson();
  const lssiPermite: boolean = idPerson?.LSSI__c === 'PERMITE';

  const { formName, quieroContacto } = store.getState().formData.fields;
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const env = useFeatureAppEnvironment();

  const isOfertasForm = (formName) => {
    return formName === 'callme-oferta' || formName === 'lead-oferta';
  };

  const handleInfoLayer = (
    key: 'lssi' | 'tmk',
    renderFn: (state: any, close: CloseHandleV2<any, any>) => JSX.Element
  ) => {
    const layer = layerManager.openFocusLayer(
      renderFn,
      { body: key === 'tmk' ? getTmkText(true) : getLssiText(true) },
      { size: FocusLayerSizeV2.A, userCloseable: true }
    );

    return layer;
  };

  const renderInfo = (state: { title: string; body: string }, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <Container wrap={'always'} padding={ContainerPadding.static450}>
          <Container
            gutter={ContainerGutter.static200}
            padding={{ bottom: ContainerPadding.static300 }}
            wrap={'always'}
          >
            <Text>
              <span dangerouslySetInnerHTML={{ __html: state.body }} />
            </Text>
          </Container>
          <CTA
            tag="button"
            emphasis="primary"
            onClick={(e) => {
              e.preventDefault();
              close({}, {}, {});
            }}
            size={'small'}
          >
            Entendido
          </CTA>
        </Container>
      </LayerManagerWrapper>
    );
  };

  return (
    <div style={{ paddingTop: '32px' }}>
      {!lssiPermite ?
        <>
          {title && (
            <>
              <BreakpointWrapper max={Breakpoints.b1600}>
                <div style={{ paddingBottom: '32px' }}>
                  <Text appearance={TokenTextAppearance.headline300}>
                    Y unas <Text bold>últimas preguntas</Text>
                  </Text>
                </div>
              </BreakpointWrapper>
              <BreakpointWrapper min={Breakpoints.b1600}>
                <div style={{ paddingBottom: '32px' }}>
                  <Text appearance={TokenTextAppearance.headline300}>
                    Y unas <Text bold>últimas preguntas</Text>
                  </Text>
                </div>
              </BreakpointWrapper>
            </>
          )}
          <LegalItem
            title={getLssiTitle(isOfertasForm(formName))}
            onClick={() => handleInfoLayer('lssi', renderInfo)}
            themeColor={themeColor}
            name="lssi"
          />
          <LegalItem
            title={getTmkTitle(isOfertasForm(formName))}
            onClick={() => handleInfoLayer('tmk', renderInfo)}
            themeColor={themeColor}
            name="tmk"
          />
        </>
        :
        <>
          {title && (
            <div style={{ paddingBottom: '32px' }}>
              <Text appearance={TokenTextAppearance.headline300}>
                <Text bold>¿Listo</Text> para enviar?
              </Text>
            </div>
          )}
        </>
      }
    </div>

  );
};

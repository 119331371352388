import React, { useEffect, useState } from "react";
import {
    Breakpoints,
    BreakpointWrapper,
    Container,
    ContainerGutter,
    ContainerPadding,
    CTA,
    FixedWidthContainer,
    Layout,
    Text,
    TextAlignment,
    TokenTextAppearance,
    TokenTextColor,
} from "@volkswagen-onehub/components-core";
import {
    getParamsForm,
    getTrackingForm,
    LayerManagerWrapper,
    removeWhiteSpace,
    useFeatureAppEnvironment,
    useFeatureServices,
    useOneFormContext,
    useTrackingManager,
} from "src/feature-app";
import styled from "styled-components";
import { useDispatch, useStore } from "react-redux";
import { Apellido, Email, GenericErrorLayer, LegalFullScreenSinConsentmientos, LegalItem, Nombre, PoliticaPrivacidad } from "src/components";
import { FormDataGroup, OneFormState } from "src/types";
import { formDataBuilder, formatHeaders } from "src/forms/format-fields";
import { dpsSend } from "src/bff";
import { TestIDForm } from "./";
import { CloseHandleV2, FocusLayerSizeV2, LayerManagerV2 } from "@volkswagen-onehub/layer-manager";
import { useWindowWidth } from "@react-hook/window-size";

interface VideoProps {
    isMediumScreen: boolean;
};

const Video = styled.video<VideoProps>`
    display: block;
    width: ${(props) => props.isMediumScreen ? "50%" : "100%"};
    margin: ${(props) => props.isMediumScreen ? "auto" : "0 auto"};
    padding-bottom: var(--size-grid001);
`;

const ErrorMessage = styled.div`
  width: max-content;
  padding: 4px 8px;
  position: relative;
  border-radius: 4px;
  background-color: #e4002c;
  color: #ffffff;
`;

const initialData: Partial<FormDataGroup> = {
    headers: {
        DESC_BRAND: 'VW',
        DESC_GDPR_ADAPTED: 'true',
        DESC_TOKEN: 'VW_DDB_FRM_TEST_ID_2023',
        DESC_FORMOBJECTIVE: 'Suscripción Newsletter',
        COD_FORM_ID: 'vw-newsletter-electricos-adhoc-2023-test_id',
    },
    fields: {
        formName: 'newsletter-electricos-adhoc-2023-test_id',
        formType: 'newsletter',
        pageCategory: 'Suscripción Newsletter',
    }, // Analítica
};

const infoLayerCopies = {
    lssi: {
        title: 'Estarás mejor informado.',
        body: 'Si nos das tu consentimiento desde Volkswagen Group España Distribución, S.A. como responsable de la marca Volkswagen podremos enviarte por cualquier medio, ya sea por teléfono, correo electrónico, sms o aplicaciones de mensajería instantánea información comercial lo que puede incluir, a título enunciativo, pero no limitativo, descuentos, novedades, próximos lanzamientos, invitaciones a eventos o a concursos, entre otras comunicaciones comerciales.',
    },
};


export function EmailForm() {
    const [email, setEmail] = useState<string>(null);
    const [showError, setShowError] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { initializeForm } = useOneFormContext();
    const trackingManager = useTrackingManager();
    const [renderFormID, setRenderFormID] = useState(false);
    const store = useStore();
    const { formData }: OneFormState = store.getState();
    const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
    const env = useFeatureAppEnvironment();
    const windowWidth = useWindowWidth();
    const [error, setError] = useState(false);
    const [isMediumScreen, setIsMediumScreen] = useState<boolean>(false);


    useEffect(() => {
        initializeForm(
            {},
            initialData,
        );
    }, []);

    useEffect(() => {
        setIsMediumScreen(windowWidth >= 960 && windowWidth < 1280);
    }, [windowWidth]);

    const handleInfoLayer = (
        key: 'lssi',
        renderFn: (state: any, close: CloseHandleV2<any, any>) => JSX.Element
    ) => {
        const layer = layerManager.openFocusLayer(
            renderFn,
            { ...infoLayerCopies[key] },
            { size: FocusLayerSizeV2.A, userCloseable: true }
        );

        return layer;
    };

    const renderInfo = (state: { title: string; body: string }, close: CloseHandleV2<any, any>) => {
        return (
            <LayerManagerWrapper store={store} env={env}>
                <Container wrap={'always'} padding={ContainerPadding.static450}>
                    <Container
                        gutter={ContainerGutter.static200}
                        padding={{ bottom: ContainerPadding.static300 }}
                        wrap={'always'}
                    >
                        <Text bold>{state.title}</Text>
                        <Text>{state.body}</Text>
                    </Container>
                    <CTA
                        tag='button'
                        emphasis='primary'
                        onClick={(e) => {
                            e.preventDefault();
                            close({}, {}, {});
                        }}
                        size={'small'}
                    >
                        Entendido
                    </CTA>
                </Container>
            </LayerManagerWrapper>
        );
    };

    const sendForm = (): Promise<boolean> => {

        const { fields } = formData;

        return new Promise<any>(async (resolve, reject) => {
            const { tc, today, url, device } = getParamsForm();

            const data = {
                ...formatHeaders(initialData.headers),
                'headers[DESC_URLORIGIN]': url,
                'headers[DESC_CAMPAIGNBLOCK]': '4. VW Newsletter',
                'headers[DESC_DEVICETYPE]': device,
                'headers[DESC_ID_CAMPAIGN]': '7017Q000000DUOnQAO',
                'headers[FECHA_INSERTION_DATE]': today,
                'headers[TC]': tc,
                'headers[DESC_VN_VO]': 'VN',

                'fields[DESC_NAME]': fields.name,
                'fields[DESC_SURNAME_1]': fields.surname,

                'fields[DESC_FORM_VERSION]': `vw-oneform-${initialData.fields.formName}-${process.env.VERSION}`,
                'fields[DESC_EMAIL_ADDRESS]': removeWhiteSpace(email),
                'fields[DESC_LOPD]': 'PERMITE',
                'fields[DESC_TRANF_DATA_THIRD]': 'NO INFORMADO',
                'fields[DESC_LOPD_GDPR_PERFILADO]': 'NO INFORMADO',
                'fields[DESC_LSSI]': fields.lssi ? fields.lssi : 'NO INFORMADO',
            };
            const dataStringified = JSON.stringify(data);
            const dataParsed = JSON.parse(dataStringified);

            const formData = formDataBuilder(dataParsed);
            const response = await dpsSend(formData);

            if (response.data && response.data.content && response.data.content.code === '200') {
                dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
                trackingManager.trackFormSubmissionSuccessMessageLoad(
                    {
                        contentId: undefined,
                    },
                    getTrackingForm(response));
                setRenderFormID(true);
            } else {
                setError(true);
            }

        });
    };

    if (error) {
        return (
            <>
                <GenericErrorLayer
                    title="Uy, algo ha pasado"
                    bodycopy="Por alguna razón no hemos podido realizar la petición."
                    notShowCTA={true}
                    icon={false}
                    notALayer={true}
                />
            </>
        );
    } else {

        return (

            renderFormID ?
                <TestIDForm email={email} name={formData.fields.name} surname={formData.fields.surname} />
                :
                <>
                    <Layout
                        allowOverflowingContent
                        appearance={{
                            [Breakpoints.default]: [
                                { name: 'a', columns: 24 },
                                { name: 'b', columns: 24 },
                            ],
                            [Breakpoints.b1280]: [
                                { name: 'a', columns: 11 },
                                { name: '.', columns: 1 },
                                { name: 'b', columns: 11 },
                                { name: '.', columns: 1 },
                            ]
                        }}>

                        <Video isMediumScreen={isMediumScreen} preload="auto" autoPlay loop muted height="auto">
                            <source src="https://assets.volkswagen.com/is/content/volkswagenag/video_landing_test-id_entry-point-0x540-2000k" type="video/mp4" />
                            Your browser does not support the video tag.
                        </Video>

                        <>
                            <Container padding={{ left: ContainerPadding.static400, right: ContainerPadding.static400 }}>
                                <Text appearance={TokenTextAppearance.headline300}>Demuestra que eres un pro y <Text bold>entra en el sorteo de uno de los dos cursos de conducción eléctrica.</Text></Text>
                                <Container padding={{ top: ContainerPadding.static530, bottom: ContainerPadding.static350 }}>
                                    <Text appearance={TokenTextAppearance.copy200}>
                                        El curso de la escuela ID. es una
                                        <Text bold> experiencia única al volante de nuestros eléctricos con pilotos profesionales a tu lado. {' '}</Text>
                                        Para participar, haz nuestro test de solo 5 preguntas.
                                    </Text>
                                </Container>
                                <FixedWidthContainer columns={{
                                    [Breakpoints.default]: 20,
                                    [Breakpoints.b1280]: 10
                                }}>
                                    <Container padding={{ bottom: ContainerPadding.static350 }}>
                                        <Text appearance={TokenTextAppearance.copy200}>
                                            ¿Te animas a estar al día de todo sobre los eléctricos, demostrar lo que sabes y participar en el sorteo?
                                        </Text>
                                    </Container>
                                </FixedWidthContainer>
                                <Layout
                                    allowOverflowingContent
                                    appearance={{
                                        [Breakpoints.default]: [
                                            { name: 'a', columns: 24 },
                                            { name: 'b', columns: 24 },
                                        ],
                                        [Breakpoints.b1280]: [
                                            { name: 'a', columns: 11 },
                                            { name: '.', columns: 2 },
                                            { name: 'b', columns: 11 },
                                        ]
                                    }}
                                >
                                    <BreakpointWrapper min={Breakpoints.b1280}>
                                        <Nombre setFieldValue />
                                        <Email setEmail={setEmail} setShowError={setShowError} name="email" paginaConfirmacion={false} required={true} paddingTop='24px' />
                                    </BreakpointWrapper>
                                    <BreakpointWrapper min={Breakpoints.b1280}>
                                        <Apellido setFieldValue name="surname" label="Primer apellido" required={true} />
                                    </BreakpointWrapper>
                                    <BreakpointWrapper max={Breakpoints.b1280}>
                                        <Nombre setFieldValue />
                                        <Apellido setFieldValue name="surname" label="Primer apellido" required={true} />
                                        <Email setEmail={setEmail} setShowError={setShowError} name="email" paginaConfirmacion={false} required={true} paddingTop='24px' />
                                    </BreakpointWrapper>
                                </Layout>

                            </Container>
                            <Container wrap={'always'} gutter={ContainerGutter.static150} padding={{ top: ContainerPadding.static400, left: ContainerPadding.static400, right: ContainerPadding.static400 }}>
                                <LegalItem
                                    title={'¿Te gustaría también recibir descuentos y novedades de Volkswagen?'}
                                    onClick={() => handleInfoLayer('lssi', renderInfo)}
                                    themeColor={'main'}
                                    name='lssi'
                                />
                                <PoliticaPrivacidad color="#001e50" />
                                {
                                    showError ? (
                                        <>
                                            <ErrorMessage>
                                                <Text appearance={TokenTextAppearance.label150} color={TokenTextColor.inherit} textAlign={TextAlignment.left}>
                                                    Necesitamos esta información para seguir
                                                </Text>
                                            </ErrorMessage>
                                        </>
                                    ) : null
                                }
                                <CTA
                                    tag="button"
                                    emphasis="primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (email && !showError) {
                                            dispatch({ type: 'UPDATE_FIELDS', payload: { email: email } });
                                            sendForm();
                                        } else {
                                            setShowError(true);
                                        }
                                    }}
                                >
                                    Enviar y participar
                                </CTA>
                            </Container>
                        </>
                    </Layout>

                    <Container gutter={ContainerGutter.static300} padding={{ top: ContainerPadding.dynamic0450, left: ContainerPadding.dynamic0200, right: ContainerPadding.dynamic0200 }}>
                        <LegalFullScreenSinConsentmientos />
                    </Container >
                </>
        )
    }

}

import React, { useState, useEffect } from 'react';
import { TextAreaExtended } from 'src/components/inputs';
import {
  Text,
  Container,
  ContainerPadding,
  ContainerGutter,
  TextAlignment,
  styled,
  InputAppearance,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { useFormContext } from 'react-hook-form';

const TextColor = styled.span`
  color:  #6A767D;
`;

export const Experiencia = () => {
  const [currentValue, setCurrentValue] = useState('');
  const { register } = useFormContext();

  const adMaxLength = () => {
    const aux = document.getElementsByTagName('textarea');
    aux[0].setAttribute('maxlength', '350');
  };

  useEffect(() => {
    adMaxLength();
  }, []);

  const handleChange = (e: any) => {
    const aux = e.currentTarget.value;
    e.preventDefault();
    setCurrentValue(aux);
  };
  return (
    <Container
      gutter={ContainerGutter.static250}
      padding={{ top: ContainerPadding.static300 }}
      wrap={'always'}
      horizontalAlign={'flex-start'}
    >
      <TextAreaExtended
        label='Escríbelo aquí'
        name='experiencia'
        onChange={(e: any) => handleChange(e)}
        appearance={InputAppearance.Default}
        isFloating
        innerRef={register({
          required: {
            value: true,
            message: 'Requerido',
          },
        })}
        maxLength={350}
      />

      <Text appearance={TokenTextAppearance.copy100} textAlign={TextAlignment.left} staticSize>
        <TextColor>
          <span style={{ whiteSpace: 'nowrap' }}><sup>1</sup>Tu</span>{' '}testimonio es muy interesante por lo que en un futuro lo compartiremos, junto con tu nombre, en nuestra web.
        </TextColor>
      </Text>
    </Container>
  );
};

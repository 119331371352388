import { Layout, Breakpoints } from '@volkswagen-onehub/components-core';
import React from 'react';
import { LegalFullScreenSinConsentmientos } from 'src/components';

export const LegalesSinConsentimientosCEM = () => {
  return (
    <Layout
      allowOverflowingContent
      appearance={{
        [Breakpoints.default]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ]
      }}
    >
      <LegalFullScreenSinConsentmientos/>
    </Layout>
  );
};

import axios, { AxiosResponse } from 'axios';
import { Availability, DealersData } from 'src/types';
import { FeatureAppConfig } from 'src/feature-app';
import { getMolecularUrl } from 'src/bff';
import dayjs from 'dayjs';
import { useGetBrand } from 'src/feature-app/hooks';

export const getSlots = async (
  config: FeatureAppConfig,
  dealer: DealersData,
  start?: number,
  end?: number
): Promise<AxiosResponse<Availability>> => {
  const groupBrand = useGetBrand();
  const { firstDayOfMonth, lastDayOfMonth } = getFirstAndLastDayOfMonth();

  const from = start ? start : firstDayOfMonth;
  const to = end ? end : lastDayOfMonth;

  const MOLECULAR_URL = getMolecularUrl(); // Legacy

  const { kvps, codInstalacion } = dealer;
  const slots = await axios.get(
    `${MOLECULAR_URL}/request-eapi-availability.php?group_brand=${groupBrand}&kvps=${kvps}&cod_instalacion=${codInstalacion}&timestamp_from=${from}&timestamp_to=${to}&check_dealer=true`
  );

  return slots;
};

export const getFirstAndLastDayOfMonth = (
  monthNumber?: string,
  yearNumber?: string
): { firstDayOfMonth: number; lastDayOfMonth: number } => {
  let firstDayOfMonth;
  let lastDayOfMonth;

  if (yearNumber && monthNumber) {
    firstDayOfMonth = Number(
      dayjs()
        .locale('es')
        .utc()
        .month(Number(monthNumber) - 1) // Months are zero indexed, so January is month 0.
        .year(Number(yearNumber))
        .startOf('month')
        .format('x')
    );
    lastDayOfMonth = Number(
      dayjs()
        .locale('es')
        .utc()
        // .add(1, 'month')
        .month(Number(monthNumber) - 1)
        .year(Number(yearNumber))
        .endOf('month')
        .format('x')
    );
  } else {
    firstDayOfMonth = Number(dayjs().add(1, 'day').locale('es').utc().format('x'));
    lastDayOfMonth = Number(
      dayjs()
        .locale('es')
        .utc()
        // .add(1, 'month')
        .endOf('month')
        .format('x')
    );
  }

  return { firstDayOfMonth, lastDayOfMonth };
};

export const getToAndFromForCheckCita = (citaTo: number): { from: number; to: number } => {
  const from = Number(dayjs().locale('es').utc().format('x'));
  const to = Number(dayjs(citaTo).locale('es').utc().format('x'));

  return { from, to };
};

import React, { useState } from 'react';
import { RadioButton, styled, Text, TokenTextAppearance, TokenTextColor } from '@volkswagen-onehub/components-core';
import { Info } from 'src/icons-core-imports';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { OneFormState } from 'src/types';

const LabelInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const InfoWrapper = styled.div`
  margin-left: 16px;
  cursor: pointer;
  svg {
    fill: #001e50;
  }
`;

interface ChecksEnvioProps {
  name: string;
  isPosventa?: boolean;
  isProducto?: boolean;
}

export const ctasCheckEnvio = (isPosventa: boolean, isProducto: boolean) => {
  const ctas = [
    { 
      label: 'Sí, mis respuestas pueden ser enviadas y analizadas junto a mi nombre y bastidor del vehículo', 
      value: (isProducto ? '1' : '3') 
    },
    {
      label: 'Mis respuestas pueden ser enviadas pero sin mencionar mi nombre ni el bastidor de mi vehículo',
      extraInfo: 'Seleccionando esta opción, el ' + (isPosventa ? 'Servicio Oficial'  : 'punto de venta') + ' no podrá ponerse en contacto contigo en relación a tus comentarios.',
      value: '2'
    },
    {
      label: 'No, mis respuestas solo deben ser analizadas de manera anónima',
      extraInfo: 'Seleccionando esta opción, tus respuestas serán analizadas de modo agregado junto al resto de respuestas de todos los entrevistados y no se trasladarán a los comentarios abiertos.',
      value: '0' 
    },
  ];
  return ctas;
}

export const ChecksEnvio = (props: ChecksEnvioProps) => {
  const { name, isPosventa, isProducto } = props;
  
  const { handleNextStep } = useOneFormContext();
  const dispatch = useDispatch();
  const { formData } = useSelector((state: OneFormState) => state);
  const { fields } = formData;
  const [firstCta, secondCta, thirdCta] = ctasCheckEnvio(isPosventa, isProducto);
  const [firstOpen, setFirstOpen] = useState<boolean>(false);
  const [secondOpen, setSecondOpen] = useState<boolean>(false);

  const [value, setValue] = useState(fields[name]);
  const { register } = useFormContext();

  const [checkedOne, setCheckedOne] = useState(fields[name] == firstCta.value);
  const [checkedTwo, setCheckedTwo] = useState(fields[name] == secondCta.value);
  const [checkedThree, setCheckedThree] = useState(fields[name] == thirdCta.value);

  const handleOnClick = (e: any) => {
    dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: e.currentTarget.value } });
    setTimeout(
      () => {
        handleNextStep();
      }, 0
    );
  };

  return (
    <div style={{ paddingTop: '40px' }}>
      <div style={{ paddingBottom: '20px', paddingLeft: '12px' }}>
        <RadioButton
          value={firstCta.value}
          label={firstCta.label}
          name={name}
          onClick={(e) => {
            setValue(firstCta.value);
            setCheckedOne(true);
            setCheckedTwo(false);
            setCheckedThree(false);
            handleOnClick(e);
          }}
          checked={checkedOne}
        />
      </div>
      <div style={{ paddingBottom: '20px', paddingLeft: '12px' }}>
        <LabelInfoWrapper className="label-info-wrapper">
          <RadioButton
            value={secondCta.value}
            label={secondCta.label}
            name={name}
            onClick={(e) => {
              setValue(secondCta.value);
              setCheckedOne(false);
              setCheckedTwo(true);
              setCheckedThree(false);
              handleOnClick(e);
            }}
            checked={checkedTwo}
          />
          <InfoWrapper
            onClick={(e) => {
              e.preventDefault();
              setFirstOpen(!firstOpen);
            }}
          >
            <Info />
          </InfoWrapper>
        </LabelInfoWrapper>
        {firstOpen ? (
          <div style={{ paddingTop: '8px', paddingLeft: '36px' }}>
            <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
              {secondCta.extraInfo}
            </Text>
          </div>
        ) : null}
      </div>
      <div style={{ paddingLeft: '12px' }}>
        <LabelInfoWrapper className="label-info-wrapper">
          <RadioButton
            value={thirdCta.value}
            label={thirdCta.label}
            name={name}
            onClick={(e) => {
              setValue(thirdCta.value);
              setCheckedOne(false);
              setCheckedTwo(false);
              setCheckedThree(true);
              handleOnClick(e);
            }}
            checked={checkedThree}
          />
          <InfoWrapper
            onClick={(e) => {
              e.preventDefault();
              setSecondOpen(!secondOpen);
            }}
          >
            <Info />
          </InfoWrapper>
        </LabelInfoWrapper>
        {secondOpen ? (
          <div style={{ paddingTop: '8px', paddingLeft: '36px' }}>
            <Text appearance={TokenTextAppearance.copy100} color={TokenTextColor.secondary}>
              {thirdCta.extraInfo}
            </Text>
          </div>
        ) : null}
      </div>
      <input
        type="hidden"
        name={name}
        value={value}
        ref={register({
          required: {
            value: true,
            message: 'Requerido',
          },
        })}
      />
    </div>
  );
};

import axios, { AxiosResponse } from 'axios';
import { AppadapResponse } from 'src/types';
import { getMolecularUrl, reportErrorSentry } from 'src/bff';

export const appadapAppointmentCreate = async (formData: FormData): Promise<AxiosResponse<AppadapResponse>> => {
  try {
    const MOLECULAR_URL = getMolecularUrl(); // Legacy
    const response = await axios.post(`${MOLECULAR_URL}/request-eapi-appointment-create.php`, formData);
    return response;
  } catch (error) {
    reportErrorSentry(error);
    return null;
  }
};

import { CloseHandleV2, FocusLayerSizeV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { AvailabilityErrorLayer } from 'src/components';
import {
  getSlots,
  LayerManagerWrapper,
  useFeatureAppConfig,
  useFeatureAppEnvironment,
  useMapGeneralController,
} from 'src/feature-app';
import { useFeatureServices } from 'src/feature-app/hooks/use-feature-services';
import { LoadingLayer } from 'src/forms/CitaPosventa/NuevaCitaPosventa';
import {
  Assessors,
  Availability,
  CalendarSlot,
  DealersData,
  DmsInfo,
  Horario,
  OneFormState,
  Points,
} from 'src/types';
import { createNoDmsSlotAndAssessor } from 'src/feature-app/NewMap';
import { CalendarioCita } from 'src/feature-app/CalendarioCita';
import { useOneFormContext } from 'src/feature-app/OneForm';

interface AvailabilityHistory {
  dealerkvps: string;
  availabilityHistory: AvailabilityHistoryDealer[];
}

interface AvailabilityHistoryDealer {
  dealer: DmsInfo;
  from: number;
  to: number;
  availability: Availability;
}





export const JourneyCitaPosventaDealerController = () => {
  const { formData, formInfo, dealersInfo } = useSelector((state: OneFormState) => state);
  const { horario, dealer, dealerKvps }: { horario?: Horario; dealer?: DealersData; dealerKvps?: string } =
    formData.fields;
  const { points } = dealersInfo;
  const { handleNextStep } = useOneFormContext();
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const store = useStore();
  const dispatch = useDispatch();
  const env = useFeatureAppEnvironment();
  const config = useFeatureAppConfig();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  // const [selectedPoint, handleSelectedPoint] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingPoint, setLoadingPoint] = useState(true);

  const availabilityHistory = useRef<AvailabilityHistory[]>([]);
  const loadingLayer = useRef(null);
  const { selectedPoint, handleSelectedPoint } =
    useMapGeneralController();

  const [pointsToList, setPointsToList] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);

  useEffect(() => {
    setLoadingPoint(true);
    // setTimeout(() => {
    const point = points.find((point: Points) => {
      if (point.properties.dealer.kvps === dealer?.kvps || point.properties.dealer.kvps === dealerKvps) {
        return point;
      }
    });
    // handleShowCalendarOnClick(point);
    setLoadingPoint(false);
    // }, 0);
  }, []);


  const getAvailability = async (
    dealer: DealersData,
    start: number,
    end: number,
    skipHandleAvailability?: boolean
  ): Promise<Availability> => {
  
    const cachedAvailability = checkIfAvailabilityIsInCache(dealer, start);

    if (cachedAvailability) {
      return cachedAvailability;
    } else {
      setLoading(true);
      let response: AxiosResponse<Availability>;
      if (availabilityHistory.current.length === 0) {
     
        response = await getSlots(config, dealer);
      } else {
        response = await getSlots(config, dealer, start, end);
      }
      const availability = response.data;
      setLoading(false);
      if (!availability.content.calendar) {
        // Error desde VGED
        // handleAvailabilityErrorLayer();
        // Nuevo layer sin conexión DMS
        saveAvailabilityInCache(dealer, start, end, availability);
        return null;
      } else {
        saveAvailabilityInCache(dealer, start, end, availability);
        return availability;
      }
    }
  };

  const checkIfAvailabilityIsInCache = (dealer: DealersData, start: number): Availability | null => {
    const dealerHistory = availabilityHistory.current.find((a) => a.dealerkvps === dealer.kvps);

    if (dealerHistory) {
      const availabiliyDealer = dealerHistory.availabilityHistory.find(
        (dealerAv) => dealerAv.from === start || start <= dealerAv.to || Math.abs(start - dealerAv.to) < 1000 // Cuando compara dos valores practicamente identicos no hace bien la resta y devuelve error.
      );
      return availabiliyDealer ? availabiliyDealer.availability : null;
    }
    return null;
  };

  const getAvailabilityFromDealer = (kvps: string): AvailabilityHistory | null => {
    const dealerHistory = availabilityHistory.current.find((a) => a.dealerkvps === kvps);

    return dealerHistory ? dealerHistory : null;
  };

  const saveAvailabilityInCache = (dealer: DealersData, start: number, end: number, availability: Availability) => {
    const dealerInAvailability = availability.content.dealer;
    const availabilityHistoryDealer: AvailabilityHistoryDealer = {
      dealer: dealerInAvailability ? dealerInAvailability : dealer.dmsInfo,
      from: start,
      to: end,
      availability,
    };

    const dealerHistoryIndex = availabilityHistory.current.findIndex((a) => a.dealerkvps === dealer.kvps);
    // Si ya existe el dealer se añade a la array de availability.
    if (dealerHistoryIndex !== -1) {
      availabilityHistory.current[dealerHistoryIndex].availabilityHistory.push(availabilityHistoryDealer); // Testear
    } else {
      // Si no existe el dealer se crea una entrada en el histórico.
      availabilityHistory.current = [
        ...availabilityHistory.current,
        { dealerkvps: dealer.kvps, availabilityHistory: [availabilityHistoryDealer] },
      ];
    }
  };

  const getRandomInt = (max: number): number => {
    const min = 1;
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleDmsMapExit = (slot: CalendarSlot | CalendarSlot[], point: Points) => {
    const { dealer } = point.properties;
    const dealerAvailability = getAvailabilityFromDealer(dealer.kvps);
    if (dealerAvailability && dealerAvailability.availabilityHistory[0].dealer) {
      const dealerFromDms = dealerAvailability.availabilityHistory[0].dealer;
      dealer.dmsInfo = dealerFromDms;
    }
    const { assessors } = dealer.dmsInfo;
    let assessor: Assessors;
    // En ocasiones un mismo slot puede tener distintos asesores
    // por lo que hay que seleccionar uno de manera aleatoria.
    if (Array.isArray(slot)) {
      const max = slot.length;
      const index = getRandomInt(max) - 1;
      // slot = slot[selectedIndex];
      assessor = findAssessor(slot[index], assessors);
    } else {
      assessor = findAssessor(slot, assessors);
    }

    if (!assessor) {
      assessor = {
        code: 'Undefined',
        name: 'Todos',
      };
    }

    const appointmentDealer = createAppointmentDealer(dealer);

    dispatch({
      type: 'UPDATE_FIELDS',
      payload: { assessor, slot, dealer, appointmentDealer },
    });
    // setWaitForNextStep(false);
    handleNextStep();
  };

  const findAssessor = (slot: CalendarSlot, assessors: Assessors[]): Assessors => {
    return assessors.find((assessor) => {
      return assessor.name === slot.assessor || assessor.code === slot.assessorCode;
    });
  };

  const createAppointmentDealer = (dealer: DealersData) => {
    const { markerInfo, dmsInfo } = dealer;

    return {
      assessors: dmsInfo.assessors ? [...dmsInfo.assessors] : undefined,
      dealerId: dmsInfo.dealerId ? { ...dmsInfo.dealerId } : { ...markerInfo.dealerInfo.dealerId },
      dmsAvailable: dmsInfo.dmsAvailable ? dmsInfo.dmsAvailable : false,
      email: dmsInfo.email ? dmsInfo.email : markerInfo.dealerInfo.mail,
      name: dmsInfo.name ? dmsInfo.name : markerInfo.dealerInfo.name,
      phone: dmsInfo.phone ? dmsInfo.phone : markerInfo.dealerInfo.phone,
      overhaulingServiceAvialable: dmsInfo.overhaulingServiceAvialable ? dmsInfo.overhaulingServiceAvialable : false,
      pickupCarServiceAvailabe: dmsInfo.pickupCarServiceAvailabe ? dmsInfo.pickupCarServiceAvailabe : false,
      replacementCarServiceAvailable: dmsInfo.replacementCarServiceAvailable
        ? dmsInfo.replacementCarServiceAvailable
        : false,
      reference: dmsInfo.reference,
    };
  };

  const handleNoDmsMapExit = (franjaHoraria: string, day: string, point: Points) => {
    let { dealer } = point.properties;
    const { slot, assessor } = createNoDmsSlotAndAssessor(franjaHoraria, day);

    dealer = {
      ...dealer,
      dmsInfo: {
        ...dealer.dmsInfo,
        dmsAvailable: false,
        pickupCarServiceAvailabe: false,
        replacementCarServiceAvailable: false,
        overhaulingServiceAvialable: false,
      },
    };

    const appointmentDealer = createAppointmentDealer(dealer);

    dispatch({
      type: 'UPDATE_FIELDS',
      payload: { slot, assessor, dealer, appointmentDealer },
    });

    handleNextStep();
  };

  const handleHideCalendarOnClick = () => {
    handleSelectedPoint(null);
    setShowCalendar(false);
  };

  const renderCalendar = (): JSX.Element => {
    const { centerToCalculateDistance, userIsGeoLocated } = useMapGeneralController();

    return (
      <CalendarioCita
        selectedPoint={selectedPoint}
        availability={null}
        getAvailability={getAvailability}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot} // Comprobar
        handleDmsMapExit={handleDmsMapExit}
        handleNoDmsMapExit={handleNoDmsMapExit}
        center={centerToCalculateDistance.current}
        horario={horario}
        handleHideCalendarOnClick={handleHideCalendarOnClick}
        userIsGeoLocated={userIsGeoLocated}
        calendarIsStep
      />
    );
  };

  const renderAvailabilityErrorLayer = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <AvailabilityErrorLayer closeLayerCallback={close} />
      </LayerManagerWrapper>
    );
  };
  const renderLoadingLayer = () => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <LoadingLayer copy="Consultando con la agenda del taller" />
      </LayerManagerWrapper>
    );
  };

  //manejadores layers

  const handleLoadingLayer = () => {
    if (loading) {
      const layer = layerManager.openFocusLayer(renderLoadingLayer, {}, { size: FocusLayerSizeV2.A });
      loadingLayer.current = layer;
    } else {
      if (loadingLayer.current) {
        loadingLayer.current.close();
        loadingLayer.current = null;
      }
    }
  };

  useEffect(() => {
    handleLoadingLayer();
  }, [loading]);

  return !loadingPoint ? renderCalendar() : null;
};

import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OneFormState, Day, EntregaIdealHorarioResponse } from 'src/types';
import dayjs, { Dayjs } from 'dayjs';
import { MultipleCTASelection } from 'src/components';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { Info, ChevronLeft, ChevronRight } from 'src/icons-core-imports';
import {
	Breakpoints,
	Text,
	TextAlignment,
	Layout,
	Tabs,
	TokenTextAppearance,
	BreakpointWrapper,
	CTA,
	LayoutRowGap,
	TokenTextColor,
	Container,
	ContainerPadding
} from '@volkswagen-onehub/components-core';
import {
	CalendarioContainer,
	FranjaContainer,
	HorasContainer,
	AllDaysWrapper,
	CalendarHandlerWrapper,
	Capitalize,
	DayPositionWrapper,
	DayWrapper,
	GreyText
} from 'src/forms/Cem/CEMSegundaEntrega/CitaCEMSegundaEntregaStyles';
import { getHorariosInstallation } from 'src/bff/api/get-installations';

interface CitaCEMSegundaEntregaProps {
	fechaName: string;
	horaName: string;
}

export const CitaCEMSegundaEntrega = (props: CitaCEMSegundaEntregaProps) => {
	const {
		fechaName,
		horaName
	} = props;

	const dispatch = useDispatch();
	const tabsRef = useRef<HTMLDivElement>(null);

	const { formData, dealersInfo } = useSelector((state: OneFormState) => state);
	const { fields } = formData;
	const { selectedInstallation } = dealersInfo;

	const weekDays = ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'];
	const [monthObjDays, setMonthObjDays] = useState<Day[]>(null);
	const [previousMonthBtn, setPreviousMonthBtn] = useState<boolean>(false);
	const [nextMonthBtn, setNextMonthBtn] = useState<boolean>(true);

	const today = dayjs(new Date());
	const [startDay, setStartDay] = useState<Dayjs>(today);
	const [endDay, setEndDay] = useState<Dayjs>(today);
	const [selectedDay, setSelectedDay] = useState<Dayjs>(fields[fechaName] ? dayjs(fields[fechaName], 'YYYY-MM-DD') : null);
	const [availableDays, setAvailableDays] = useState<EntregaIdealHorarioResponse[]>([]);
	const [showMonth, setShowMonth] = useState<Dayjs>(selectedDay);

	const [franjasManana, setFranjasManana] = useState([]);
	const [franjasTarde, setFranjasTarde] = useState([]);
	const [infoFranjasManana, setInfoFranjasManana] = useState<string>('');
	const [infoFranjasTarde, setInfoFranjasTarde] = useState<string>('');
	const [showFranja, setShowFranja] = useState<number>(0);
	const [resetFranjas, setResetFranjas] = useState<boolean>(false);


	useEffect(() => {
		getHorarios(today);
	}, []);


	// CALENDARIO

	useEffect(() => {
		if (startDay && endDay && selectedDay && showMonth) {
			handleBuildCalendar();
		}
	}, [startDay, endDay, selectedDay, showMonth]);

	const getHorarios = async (dayJs: Dayjs) => {
		const thisMonth = await getHorariosInstallation(selectedInstallation.TXT_V_Installation_Code__c, dayJs.format('YYYY'), dayJs.format('MM'));
		const nextMonth = await getHorariosInstallation(selectedInstallation.TXT_V_Installation_Code__c, dayJs.add(1, 'month').format('YYYY'), dayJs.add(1, 'month').format('MM'));

		let day = null;
		let availableDays = [];
		if (thisMonth && thisMonth.length > 0) {
			day = createDay(thisMonth[0].date);
			availableDays = thisMonth.concat(nextMonth);
		}
		else if (nextMonth && nextMonth.length > 0) {
			day = createDay(nextMonth[0].date);
			const anotherMonth = await getHorariosInstallation(selectedInstallation.TXT_V_Installation_Code__c, dayJs.add(2, 'month').format('YYYY'), dayJs.add(2, 'month').format('MM'));
			availableDays = nextMonth.concat(anotherMonth);
		}

		setAvailableDays(availableDays);
		setStartDay(day);
		setEndDay(createDay(availableDays[availableDays.length - 1].date));
		setSelectedDay(fields[fechaName] ? createDay(fields[fechaName]) : day);
		setShowMonth(fields[fechaName] ? createDay(fields[fechaName]) : day);
	};

	const handleBuildCalendar = () => {
		let year = Number(showMonth.format('YYYY'));
		let month = Number(showMonth.format('M')) - 1;

		const firstDayOfMonth = dayjs().year(year).month(month).date(1);
		const firstDayInweek = firstDayOfMonth.format('d'); // The day of the week, with Sunday as 0
		const daysInMonth = dayjs().year(year).month(month).daysInMonth();

		let objDays: Day[] = [];

		// Se genera una array con espacios vacios para rellenar la primera fila del calendario y sincronizarla con la columna del dia de la semana
		if (firstDayInweek === '0') {
			objDays = new Array(6).fill('');
		} else {
			objDays = new Array(parseInt(firstDayInweek) - 1).fill('');
		}

		let day = firstDayOfMonth;

		for (let i = 1; i <= daysInMonth; i++) {
			const isActive = selectedDay ? selectedDay.isSame(day, 'day') : false;
			objDays.push(createObjDay(day, isActive));
			// Increment 1 day
			day = day.add(1, 'day');
		}

		setMonthObjDays(objDays);
		
	};

	const createObjDay = (dayJs: Dayjs, active = false) => {
		const objDay: Day = {
			dayNumber: dayJs.format('D'), // 1 - 31
			dayOfTheWeek: dayJs.format('d'), // 0 - 6
			date: dayJs.format('YYYY-MM-DD'),
			disabled: isDayDisabled(dayJs),
			active: active,
		};
		return objDay;
	};

	const createDay = (str: string) => {
		return dayjs(str, 'YYYY-MM-DD');
	};

	const isDayDisabled = (dayJs: Dayjs) => {
		if (endDay && dayJs.isAfter(endDay, 'day')) {
			return true;
		}
		else if (dayJs.isBefore(startDay, 'day')) {
			return true;
		}
		return !availableDays.some(item => item.date === dayJs.format('YYYY-MM-DD'));
	};

	const getOffsetMonth = (n: number) => {
		if (!showMonth) {
			return today;
		}
		else if (n > 0) {
			return showMonth.add(n, 'month');
		}
		else if (n < 0) {
			return showMonth.subtract(n * (-1), 'month');
		}
		else {
			return showMonth;
		}
	};

	const handleNextMonth = (e: any) => { // Carga del siguiente mes
		e.preventDefault();
		if (nextMonthBtn) setShowMonth(getOffsetMonth(1));
	};

	const handlePreviousMonth = (e: any) => { // Carga del anterior mes
		e.preventDefault();
		if (previousMonthBtn) setShowMonth(getOffsetMonth(-1));
	};

	const handleSelectDay = (day: Day) => {
		setSelectedDay(createDay(day.date)); // Seleccion del dia
		handleResetFranjas();
		handleBuildFranjas(createDay(day.date));
	};

	useEffect(() => {
		if (selectedDay) {
			dispatch({ type: 'UPDATE_FIELDS', payload: { [fechaName]: selectedDay.format('YYYY-MM-DD') } });
		}
	}, [selectedDay]);

	useEffect(() => {
		if (showMonth && startDay && endDay) {
			setPreviousMonthBtn(startDay.isBefore(showMonth, 'month'));
			setNextMonthBtn(endDay.isAfter(showMonth, 'month'));
		}
	}, [showMonth, startDay, endDay]);


	// FRANJAS HORARIAS

	useEffect(() => {
		if (selectedDay) {
			handleBuildFranjas(selectedDay);
		}
	}, [selectedDay]);

	const handleBuildFranjas = (dayJs: Dayjs) => {
		const turnos = availableDays.find(item => item.date === dayJs.format('YYYY-MM-DD'))?.turnos;

		if (turnos) {
			createFranjas(turnos);
		}
		
		if (fields[horaName] && dayjs(fields[horaName], 'HH').isAfter(dayjs('15', 'HH'))) {
			setShowFranja(1); // Mostrar franja de tarde
		}
	};

	const createFranjas = (turnos) => {
		const manana = [];
		const tarde = [];

		turnos.forEach(turno => {
			const slots = generateTimeSlots(turno.from, turno.to);
			slots.forEach(slot => {
				const time = dayjs(`1970-01-01T${slot.label}`);
				if (time.isBefore(dayjs('1970-01-01T15:00'))) {
					manana.push(slot);
				} else {
					tarde.push(slot);
				}
			});
		});

		if(manana.length > 0) {
			setInfoFranjasManana('La duración de la cita será de una hora aproximadamente.');
		}
		else {
			setInfoFranjasManana('No hay horas disponibles.');
		}

		if(tarde.length > 0) {
			setInfoFranjasTarde('La duración de la cita será de una hora aproximadamente.');
		}
		else {
			setInfoFranjasTarde('No hay horas disponibles.');
		}

		setFranjasManana(manana);
		setFranjasTarde(tarde);
	};

	const generateTimeSlots = (from, to) => {
		const slots = [];
		let current = dayjs(`1970-01-01T${from}`);
		const end = dayjs(`1970-01-01T${to}`);

		while (current.isBefore(end)) {
			const label = current.format('HH:mm');
			slots.push({ label, value: label });
			current = current.add(30, 'minute');
		}

		return slots;
	};

	const handleResetFranjas = () => {
		setResetFranjas(true);
		setTimeout(() => {
			setResetFranjas(false);
		}, 100);
	};

	const handleChangeFranja = (index: number) => {
		setShowFranja(index)
		handleResetFranjas()
	};


	return (
		<Layout appearance={{
			[Breakpoints.default]: [
				{ name: '.', columns: 2 },
				{ name: 'a', columns: 20 },
				{ name: '.', columns: 2 },
				{ name: '.', columns: 4 },
				{ name: 'b', columns: 16 },
				{ name: '.', columns: 4 }
			],
			[Breakpoints.b960]: [
				{ name: '.', columns: 2 },
				{ name: 'a', columns: 12 },
				{ name: '.', columns: 2 },
				{ name: 'b', columns: 6 },
				{ name: '.', columns: 2 }
			]
		}}
		>
			<CalendarioContainer>
				<CalendarHandlerWrapper className="calendar-handler-wrapper">
					<CTA
						tag="button"
						emphasis="tertiary"
						onClick={handlePreviousMonth}
						disabled={!previousMonthBtn}
						ariaLabel="aria label"
						icon={<ChevronLeft />}
					/>
					<BreakpointWrapper min={Breakpoints.b960}>
						<Capitalize
							onClick={handlePreviousMonth}
							isDisabled={!previousMonthBtn}
						>
							{getOffsetMonth(-1).locale('es').format('MMMM')}
						</Capitalize>
					</BreakpointWrapper>
					<Text bold appearance={TokenTextAppearance.copy200}>
						<Capitalize isDisabled={false} hasTitle>
							{showMonth ? showMonth.locale('es').format('MMMM') : null} {showMonth ? showMonth.format('YYYY') : null}
						</Capitalize>
					</Text>
					<BreakpointWrapper min={Breakpoints.b960}>
						<Capitalize
							onClick={handleNextMonth}
							isDisabled={!nextMonthBtn}
						>
							{getOffsetMonth(1).locale('es').format('MMMM')}
						</Capitalize>
					</BreakpointWrapper>
					<CTA
						tag="button"
						emphasis="tertiary"
						onClick={handleNextMonth}
						disabled={!nextMonthBtn}
						ariaLabel="aria label"
						icon={<ChevronRight />}
					/>
				</CalendarHandlerWrapper>
				<AllDaysWrapper className="all-days-wrapper">
					<Layout
						appearance={{
							[Breakpoints.default]: [
								{ name: '.', columns: 1 },
								{ name: 'a', columns: 3 },
								{ name: 'b', columns: 3 },
								{ name: 'c', columns: 3 },
								{ name: 'd', columns: 3 },
								{ name: 'e', columns: 3 },
								{ name: 'f', columns: 3 },
								{ name: 'g', columns: 3 },
								{ name: '.', columns: 2 },
							],
							[Breakpoints.b560]: [
								{ name: '.', columns: 2 },
								{ name: 'a', columns: 2 },
								{ name: '.', columns: 1 },
								{ name: 'b', columns: 2 },
								{ name: '.', columns: 1 },
								{ name: 'c', columns: 2 },
								{ name: '.', columns: 1 },
								{ name: 'd', columns: 2 },
								{ name: '.', columns: 1 },
								{ name: 'e', columns: 2 },
								{ name: '.', columns: 1 },
								{ name: 'f', columns: 2 },
								{ name: '.', columns: 1 },
								{ name: 'g', columns: 2 },
								{ name: '.', columns: 1 },
							],
							[Breakpoints.b960]: [
								{ name: '.', columns: 1 },
								{ name: 'a', columns: 3 },
								{ name: 'b', columns: 3 },
								{ name: 'c', columns: 3 },
								{ name: 'd', columns: 3 },
								{ name: 'e', columns: 3 },
								{ name: 'f', columns: 3 },
								{ name: 'g', columns: 3 },
								{ name: '.', columns: 2 },
							],
						}}
						rowGap={LayoutRowGap.static250}
					>
						{weekDays.map((day, index) => {
							if (index > 4) {
								return (
									<DayPositionWrapper>
										<DayWrapper key={index} className="day-wrapper">
											<Text bold appearance={TokenTextAppearance.copy200}>
												{day}
											</Text>
										</DayWrapper>
									</DayPositionWrapper>
								);
							} else {
								return (
									<DayPositionWrapper>
										<DayWrapper key={index} className="day-wrapper">
											<Text appearance={TokenTextAppearance.copy200}>
												<GreyText>{day}</GreyText>
											</Text>
										</DayWrapper>
									</DayPositionWrapper>
								);
							}
						})}
						{monthObjDays ? (monthObjDays.map((day, index) => {
							return (
								<DayPositionWrapper>
									<DayWrapper
										key={index}
										onClick={(e) => {
											e.preventDefault();

											if (!day.disabled) {
												if (tabsRef && tabsRef.current) {
													tabsRef.current.scrollIntoView({ behavior: 'smooth' });
												}
												handleSelectDay(day);
											}
										}}
										active={day.active}
										disabled={day.disabled}
										className="day-wrapper"
									>
										<Text appearance={TokenTextAppearance.copy200} color={TokenTextColor.inherit}>
											{day.dayNumber}
										</Text>
									</DayWrapper>
								</DayPositionWrapper>
							);
						})
						) : null}
					</Layout>
				</AllDaysWrapper>
			</CalendarioContainer>


			<FranjaContainer>
				<Text appearance={TokenTextAppearance.copy200} textAlign={TextAlignment.center}>
					Selecciona la franja horaria
				</Text>
				<HorasContainer>
					<div onClick={(e) => {
						e.preventDefault();
					}}
					>
						<Tabs idPrefix="franjas" defaultIndex={showFranja} variant="underlined" tabBarVerticalPosition="bottom"
						onChange={(index) => {
							handleChangeFranja(index);
						}}
						>
							{{
								title: <Text>Mañana</Text>,
								content: <OneElementTemplateFullScreen noPadding element={<><MultipleCTASelection reset={resetFranjas} ctaValues={franjasManana} name={horaName} /><Container padding={{top:ContainerPadding.static300}}><Text appearance={TokenTextAppearance.copy200}><Info variant="small" /> {infoFranjasManana}</Text></Container></>} />,
								key: 'MANANA'
							}}
							{{
								title: <Text>Tarde</Text>,
								content: <OneElementTemplateFullScreen noPadding element={<><MultipleCTASelection reset={resetFranjas} ctaValues={franjasTarde} name={horaName} /><Container padding={{top:ContainerPadding.static300}}><Text appearance={TokenTextAppearance.copy200}><Info variant="small" /> {infoFranjasTarde}</Text></Container></>} />,
								key: 'TARDE'
							}}
						</Tabs>
					</div>
				</HorasContainer>
			</FranjaContainer>
		</Layout>
	);
};

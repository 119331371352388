import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { DomainAttributesValues } from 'src';
import { getInstallationsLegacy } from 'src/bff';
import { LoadingEncuestas } from 'src/components';
import { useFeatureServices, useFaContent, useTrackingManager } from 'src/feature-app';
import { DpsData, EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { GenericErrorLayer } from 'src/components/layers';
import { createPincodeEntity } from 'src/forms/dps-utils';
import { arrayToObject } from 'src/helpers';
import { sanitizeKvps } from 'src/forms/Cem/helpers';
import { CEMVentaProductoForm } from 'src/forms/Cem/CEMVentaProducto/CEMVentaProductoForm';

export function CEMVentaProductoController() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [usado, setUsado] = useState(false);
	const [caducado, setCaducado] = useState(false);
	const today = dayjs(new Date());

	const dpsDataRef = useRef(null);
	const idPersonRef = useRef(null);
	const idVehicleRef = useRef(null);
	const idDealerRef = useRef(null);
	const idOrderRef = useRef(null);

	const [installation, setInstallation] = useState(null);
	const [installations, setInstallations] = useState(null);
	const [kvps, setKvps] = useState(null);

	const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;
	const dispatch = useDispatch();
	const faContent = useFaContent();

	const handleNext = (value: any) => { };
	const handleComplete = () => {
		const dpsData = oneFormService.getDpsData();

		if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
			handleError();
		} else if (dpsData.pincode.pincodeUsed) {
			setUsado(true);
			setLoading(false);
		} else {
			const idPerson = createPincodeEntity(dpsData, 'PERSON');
			const idVehicle = createPincodeEntity(dpsData, 'VEHICLE');
			const idDealer = createPincodeEntity(dpsData, 'DEALER');
			const idOrder = createPincodeEntity(dpsData, 'ORDER');

			dpsDataRef.current = dpsData;
			idPersonRef.current = idPerson;
			idVehicleRef.current = idVehicle;
			idDealerRef.current = idDealer;
			idOrderRef.current = idOrder;

			const envio = dpsData.env;
			if (!envio || today.diff(dayjs(envio), 'day') > 8) {
				setCaducado(true);
			} else if(!installations) {
				getInstallationsLegacy('VW_DDB_FRM_LEM_REC_LEM', true).then((dealers) => {
					const installationsDomainAtt = dealers.map((inst) => inst.attributes);
					setInstallations(installationsDomainAtt);

					if(dpsData.kvps) {
						setKvps(dpsData.kvps);
					}
					else if(typeof idDealer.TXT_V_KVPS_code__c != 'undefined'){
						setKvps(idDealer.TXT_V_KVPS_code__c);
					}
					else if(typeof idDealer.TXT_V_KVPS_code__c != 'undefined') {
						setKvps(sanitizeKvps(idDealer.TXT_V_KVPS_code__c))
					}
				});
			}
			setLoading(false);
		}
	};

	useEffect(() => {
		if (installations && kvps && !installation) {
			let found = false;
			let i = 0;
			while (i < installations.length && found === false) {
				const found = installations[i].find((ele: DomainAttributesValues) => {
					if (
						typeof ele.value != 'undefined' && typeof ele.name != 'undefined' &&
						ele.name.includes('KVPS') && // TXT_V_KVPS_code__c or TXT_V_KVPS_code__c
						ele.value.includes(kvps)
					) {
						return ele;
					}
					return false;
				});
				if (found) {
					let installation = arrayToObject(installations[i]);
					// Parche para SAFA
					if (installation.TXT_V_KVPS_code__c == '00494' || installation.TXT_V_Installation_Data_Name__c == 'SAFA FUENGIROLA') {
						installation.TXT_V_Installation_Data_Name__c = 'SAFAMOTOR';
					}
					setInstallation(installation);
				}
				i++;
			}
		}
	}, [installations, kvps]);

	const handleError = () => {
		setError(true);
		setLoading(false);
	};

	useEffect(() => {
		const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

		oneFormCoreDpsIsLoaded.subscribe({
			next(value) {
				handleNext(value);
			},
			error(err) {
				console.error(err);
				handleError();
			},
			complete() {
				handleComplete();
			},
		});
	}, []);

	if (loading) {
		return <LoadingEncuestas />;
	} else if (error) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, algo ha pasado"
					bodycopy="Por alguna razón no hemos podido cargar este cuestionario."
					ctaLayer="Reintentar"
					notShowCTA={true}
					icon={false}
					notALayer={true}
				/>
			</>
		);
	} else if (caducado) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, este formulario ha caducado"
					bodycopy="Parece que el cuestionario ya no está disponible. ¿Quieres contarnos cómo ha sido tu experiencia?"
					ctaLayer="Contacta con nosotros"
					ctaType="a"
					urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
					icon={false}
					notALayer={true}
				/>
			</>
		);
	} else if (usado) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, ya has respondido a este cuestionario"
					bodycopy="Parece que ya has compartido tu opinión con nosotros. ¿Quieres añadir algo más?"
					ctaLayer="Contacta con nosotros"
					ctaType="a"
					urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
					icon={false}
					notALayer={true}
				/>
			</>
		);
	} else {
		return (
			<CEMVentaProductoForm
				installation={installation}
				dpsData={dpsDataRef.current}
				idDealer={idDealerRef.current}
				idOrder={idOrderRef.current}
				idPerson={idPersonRef.current}
				idVehicle={idVehicleRef.current}
			/>
		);
	}
}

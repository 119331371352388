import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerGutter,
  CTA,
  Spinner,
  styled,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { Alert } from 'src/icons-core-imports';

import { CloseHandleV2 } from '@volkswagen-onehub/layer-manager';
import React, { useEffect, useState, useCallback } from 'react';

const Content = styled.div`
  background-color: white;
  padding: 44px 31px;
  flex: auto;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 560px) {
    padding: 52px 46px;
  }
  @media screen and (min-width: 960px) {
    padding: var(--size-grid001);
  }
`;

interface loadingSreenProps {
  isleads?: boolean;
  copy?: string;
}

export function LoadingScreen(props: loadingSreenProps) {
  return (
    <Content>
      <Spinner variant="large" />
      {props.copy ? <Text>{props.copy}</Text> : <Text>Enviando tu petición</Text>}
    </Content>
  );
}

interface KoScreenProps {
  retrySubmit: (e: any) => void;
  closeLayerCallback?: CloseHandleV2<any, any>;
  title?:string;
  bodyCopy?:string;
  isLEM?: boolean;
  hideSecondCTA?: boolean;
}

export function KoScreen(props: KoScreenProps) {
  const { retrySubmit, closeLayerCallback, title, bodyCopy, isLEM, hideSecondCTA } = props;

  return (
    <Content>
      <Container
        gutter={ContainerGutter.dynamic0100}
        wrap={'always'}
        horizontalAlign={'flex-start'}
      >
        <div style={{ textAlign: 'left' }}>
          <Alert variant="large" />
        </div>
        <Text appearance={TokenTextAppearance.headline300} bold textAlign={TextAlignment.left}>
          {title? title : 'Uy, algo ha pasado'}
        </Text>
        <Container
          gutter={ContainerGutter.static500}
          wrap={'always'}
          horizontalAlign={'flex-start'}
        >
          <Text textAlign={TextAlignment.left}>
            {bodyCopy? bodyCopy : 'Por alguna razón no hemos podido recoger tus datos. ¿Lo intentamos de nuevo?'}
            
          </Text>
          <BreakpointWrapper max={Breakpoints.b560}>
            <Container gutter={ContainerGutter.static350} wrap={'always'}>
              <CTA
                tag="button"
                emphasis="primary"
                onClick={(e) => {
                  e.preventDefault();
                  retrySubmit(e);
                  closeLayerCallback({}, {}, {});
                }}
                stretchContent
              >
                Reintentar
              </CTA> 
              { hideSecondCTA ? null :
                isLEM ? (
                  <CTA
                    tag="a"
                    emphasis="secondary"
                    href='https://www.volkswagen.es/es.html'
                    stretchContent
                  >
                    Ir a la web
                  </CTA>
                ) : (
                  <CTA
                    tag="button"
                    emphasis="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      closeLayerCallback({}, {}, {});
                    }}
                    stretchContent
                  >
                    Seguir navegando
                  </CTA>
                )
              }
            </Container>
          </BreakpointWrapper>
          <BreakpointWrapper min={Breakpoints.b560}>
            <Container gutter={ContainerGutter.static350} horizontalAlign={'flex-start'}>
              <CTA
                tag="button"
                emphasis="primary"
                onClick={(e) => {
                  e.preventDefault();
                  retrySubmit(e);
                  closeLayerCallback({}, {}, {});
                }}
              >
                Reintentar
              </CTA> 
              { hideSecondCTA ? null :
                isLEM ? (
                  <CTA
                    tag="a"
                    emphasis="secondary"
                    href='https://www.volkswagen.es/es.html'
                    stretchContent
                  >
                    Ir a la web
                  </CTA>
                ) : (
                  <CTA
                    tag="button"
                    emphasis="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      closeLayerCallback({}, {}, {});
                    }}
                  >
                    Seguir navegando
                  </CTA>
                )
              }
            </Container>
          </BreakpointWrapper>
          
        </Container>
      </Container>
    </Content>
  );
}

import {
  Breakpoints,
  Container,
  ContainerGutter,
  ContainerPadding,
  Layout,
  Text,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import { useStore } from 'react-redux';
import { LastNavigation, LegalesFormatoLayer, LssiTkmQuestions, PoliticaPrivacidad, GeneralConfirmacionLayerWrapper } from 'src/components';
import { OneFormState } from 'src/types';
import { removePTags } from './helpers';

export const ConfirmacionLeads = () => {
  const store = useStore();

  const { dealersInfo }: OneFormState = store.getState();
  const { oferta } = dealersInfo;

  return (
    <GeneralConfirmacionLayerWrapper>
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.b960]: [
            { name: '.', columns: 3 },
            { name: 'a', columns: 18 },
            { name: '.', columns: 3 },
          ],
        }}
      >
        <LssiTkmQuestions title />

        <PoliticaPrivacidad />

        <LastNavigation loadingCopy="Enviando tu petición" />

        <Container
          padding={{ top: ContainerPadding.static550, bottom: ContainerPadding.static515 }}
          gutter={ContainerGutter.static300}
          wrap={'always'}
        >
          <Text bold staticSize appearance={TokenTextAppearance.copy200}>
            Texto legal de Volkswagen
          </Text>
          {oferta ? (
            <Text staticSize appearance={TokenTextAppearance.copy100}>
               {removePTags(oferta.legal)}
            </Text>
          ) : null}
          <LegalesFormatoLayer noPadding={true} />
        </Container>
      </Layout>
    </GeneralConfirmacionLayerWrapper>
  );
};

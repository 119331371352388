import { styled } from '@volkswagen-onehub/components-core';

interface WithProps {
  paginaConfirmacion?: boolean;
  notALayer?: boolean;
  noPaddingTop?: boolean;
}

export const GeneralInputWidthWrapper = styled.div<WithProps>`
  width: 100%;
  padding-top: ${(props) => (props.paginaConfirmacion ? '0px' : props.noPaddingTop ? '0' : '44px')};
  @media all and (max-height: 624px) {
    padding-top: ${(props) => (props.paginaConfirmacion ? '0px' : props.noPaddingTop ? '0' : '24px')};
  }
  @media all and (min-width: 560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid013)' : '303px')};
  }
  @media all and (min-width: 960px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid008)' : '320px')};
  }
  @media all and (min-width: 1280px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid006)' : '320px')};
  }
  @media all and (min-width: 1920px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '320px')};
  }
  @media all and (min-width: 2560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid004)' : '320px')};
  }
`;

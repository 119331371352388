import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import {
  FormController,
  removeWhiteSpace,
  useFaContent,
  useGetInstalaciones,
  useGetWaidLitera,
  useOneFormContext
} from 'src/feature-app';
import { getParamsForm } from 'src/feature-app/get-params-form';
import {
  CarlineBase,
  FormDataGroup,
  FormDataHeaders,
  FormInfo,
  OneFormState,
  Steps,
} from 'src/types';
import { OneElementTemplate } from 'src/feature-app/Screen/Templates';
import { ThankYouLeads } from '.';
import { formatFields, formatFlags, formatHeaders, formDataBuilder, getDpsFields } from '../format-fields';
import { getIndexInSteps, removeStep, resetStepsScreenIndex } from '../helpers/add-remove-steps';
import {
  getDescInterestCarline,
  getConfiguradorComercialesData,
  getOfertasComercialesData
} from './helpers';
import { LeadGenericoFormProps } from './LeadGenericoForm';
import { MultipleCTASelection } from 'src/components';

const isOfertasForm = (formName: string) => formName === 'lead-oferta';
const getDescFormObjective = (formName: string, carline: CarlineBase, headers: FormDataHeaders) =>
  isOfertasForm(formName) && carline?.campaign?.dfo?.ofertas
    ? carline.campaign.dfo.ofertas
    : headers.DESC_FORMOBJECTIVE;

const getSfCampaignName = (formName: string, carline: CarlineBase) => {
	if (isOfertasForm(formName) && carline?.campaign?.salesforce) {
		const sfCampaignName = [];
		sfCampaignName["headers[SF_CAMPAIGN_NAME]"]=carline?.campaign?.salesforce;
		return { ...sfCampaignName };
	}
	return null;
	};

export const horarioContactoOptions = [
  {
    value: 'Mañana',
    detallesPrevStep: 'Por la mañana',
    label: 'Por la mañana',
  },
  {
    value: 'Mediodia',
    detallesPrevStep: 'Al mediodía',
    label: 'Al mediodía',
  },
  {
    value: 'Tarde',
    detallesPrevStep: 'Por la tarde',
    label: 'Por la tarde',
  },
  {
    value: 'A cualquier hora',
    detallesPrevStep: 'A cualquier hora',
    label: 'A cualquier hora',
  },
];

const stepHorario = {
  title: (
    <>
      <Text bold>¿Cúando </Text>prefieres que te llamemos?
      <sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
    </>
  ),
  fields: (
    <OneElementTemplate
      element={<MultipleCTASelection ctaValues={horarioContactoOptions} name="horarioContacto" nextOnClick />}
    />
  ),
  screenIndex: 100, // Se reiniciará más adelante
  name: 'HorarioContacto',
  outputs: ['horarioContacto'],
  outputsText: ['horarioContacto'],
};

export function LeadGenericoComercialesForm({ steps, leadData, leadInfo, firstStep, lastStep }: LeadGenericoFormProps) {
  const { initializeForm, handleNextStep } = useOneFormContext();
  const { contacto } = useSelector((state: OneFormState) => state.formData.fields);
  const store = useStore();
  const installations = useGetInstalaciones('LCV_DDB_FRM_LEM_REC_LEM'); // Inicialización de las instalaciones
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const faContent = useFaContent();
  const waid = useGetWaidLitera();

  const sendForm = (): Promise<boolean> => {
    const { formData, dealersInfo }: OneFormState = store.getState();
    const { fields, headers, flags } = formData;
    const { selectedInstallation, salesGroup, selectedCarline } = dealersInfo;

    const {
      VGED_CommercialName__c,
      ShippingStreet,
      VGED_DealerCode__c,
      ShippingStateCode,
      KVPSCode__c,
      Id
    } = selectedInstallation;

    return new Promise<any>(async (resolve, reject) => {
      const { tc, today, referalUrl, url, device } = getParamsForm();

      const data = {
        ...formatHeaders(headers), // Headers comunes a todos los formularios de leads

        'headers[FORM_DATE_INSERTION]': today,
        'headers[FORM_URL]': url,
        'headers[FORM_NAME]': `vw-oneform-${fields.formName}`,
        'headers[FORM_VERSION]': `vw-oneform-${fields.formName}-${process.env.VERSION}`,
        'headers[ORIGIN_DEVICE_TYPE]': device,
        'headers[ORIGIN_TRACKING_CODE]': tc,
        'headers[FORM_OBJECTIVE]': getDescFormObjective(fields.formName, selectedCarline, headers),
		...getSfCampaignName(fields.formName, dealersInfo?.selectedCarline),

        ...formatFlags(flags), // Flags comunes a todos los formularios de leads
        ...formatFields(fields.formFields), // Fields comunes a todos los formularios de leads

        'fields[DPS_BRAND]': 'Volkswagen CV',
        'fields[SF_LEAD_RECORDTYPE_ID]': '0121i000000D7NyAAK',
        'fields[SF_LEAD_RECORDTYPE_NAME]': 'Sales Assistant',
		'fields[SF_LEAD_SALES_INITIAL_ACTION]': 'Contact Center Qualification',
        'fields[PERSON_EMAIL]': removeWhiteSpace(fields.email),
        'fields[PERSON_NAME]': fields.name,
        'fields[PERSON_PHONE_MOBILE_FULL]': `${fields.prefix}${fields.cellphone}`,
        'fields[PERSON_PHONE_MOBILE]': fields.cellphone,
        'fields[PERSON_PHONE_PREFIX]': fields.prefix,
        'fields[PERSON_PREFERRED_CHANNEL]': fields.contacto,
        'fields[PERSON_PREFERRED_SLOT]': fields.contacto === 'Teléfono' ? fields.horarioContacto : undefined,
        'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
        'fields[PERSON_RGPD_PERFILADO]': fields.tmk,
        'fields[PERSON_SURNAME_1]': fields.surname,
        'fields[PERSON_SURNAME_2]': fields.secondSurname,
        'fields[SF_LEAD_INSTALLATION_ADDRESS]': ShippingStreet,
        'fields[SF_LEAD_INSTALLATION_CODE]': VGED_DealerCode__c,
        'fields[SF_LEAD_INSTALLATION_ID]': Id,
        'fields[SF_LEAD_INSTALLATION_NAME]': VGED_CommercialName__c,
        'fields[SF_LEAD_INSTALLATION_PROVINCE_CODE]': ShippingStateCode.toString().padStart(2, '0'),
        // 'fields[VEH_INTEREST_FUEL]': 'PENDIENTE', -> Pendiente mapear
        'fields[VEH_INTEREST_BRAND]': 'Volkswagen CV',
        'fields[VEH_INTEREST_ID]': dealersInfo?.selectedCarline?.code,
        'fields[VEH_INTEREST_MODEL]': getDescInterestCarline(dealersInfo, fields.modelo),
        'fields[VEH_INTEREST_SALESGROUP]': salesGroup?.salesGroupName,
        'fields[VEH_INTEREST_SALESGROUP_ID]': salesGroup?.salesGroupId,
        'fields[VEH_INTEREST_NEW_OR_USED]': 'New Car',
        'fields[VEH_INTEREST_LINK]': url,
        ...getDpsFields(faContent),
        //...getSalesGroup(salesGroup),
        //...getHorario(fields.horario),

        ...(await getOfertasComercialesData(formData, dealersInfo)),
        //...(await getMatchData(formData, dealersInfo, faContent)), Match no disponible en LCV
        ...(await getConfiguradorComercialesData(formData)),

      };
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formDataInfo = formDataBuilder(dataParsed);
      // const response = await dpsSend(formDataInfo);
      const response = await oneformBFFNdpsSend(formDataInfo);

      if (response && response.data && response.data.status && response.data.status.code && response.data.status.code == '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const updateSteps = (steps: Steps[]) => {
    dispatch({ type: 'UPDATE_STEPS', payload: steps });
    dispatch({ type: 'UPDATE_LAST_STEP', payload: steps[steps.length - 1] });
    dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: steps.length });
  };

  useEffect(() => {
    if (contacto === 'Teléfono' || contacto === 'Telefono') {
      const indexOfContacto = getIndexInSteps(steps, 'Contacto');
      const newSteps = [...steps];
      newSteps.splice(indexOfContacto + 1, 0, stepHorario);
      const finalSteps = resetStepsScreenIndex(newSteps);

      updateSteps(finalSteps);
      handleNextStep();
    } else if (contacto === 'Email') {
      const { steps } = store.getState().formInfo;
      const indexOfContacto = getIndexInSteps(steps, 'HorarioContacto');

      if (indexOfContacto === -1) {
        return;
      } else {
        const newSteps = removeStep(steps, 'HorarioContacto');
        const finalSteps = resetStepsScreenIndex(newSteps);

        updateSteps(finalSteps);
      }
    }
  }, [contacto]);

  const leadGenericoData: Partial<FormDataGroup> = {
    headers: {
      // DESC_CAMPAIGNBLOCK: '9. VW LEM',
      FK_SYSTEM: 'LCV_DDB_FRM_LEM_REC_LEM',
      DPS_TOKEN: 'LCV_DDB_FRM_LEM_REC_LEM',
      FORM_ID: waid ? waid : '',
      ...leadData.headers,
    },
    flags: {
      PERSON_RGPD_ADAPTED: 'true',
      FLAG_IS_MYSTERY_LEAD: 'false',
      FLAG_RELACION_MARCA_SIN_IC: 'false',
      FLAG_SF_INTEGRATION: 'true',
      FLAG_IS_COMPANY: 'false'
    },
    fields: { ...leadData.fields },
  };

  const leadGenericoInfo: Partial<FormInfo> = {
    formStarted: true,
    fullScreen: firstStep.fullScreen ? true : false,
    lastStep,
    sendFormCallback: sendForm,
    numberOfScreens: steps.length,
    screenIndex: firstStep.screenIndex,
    step: firstStep,
    steps,
    notALayer: false,
    stepsHistory: [steps[0].screenIndex],
    thankYouComponent: <ThankYouLeads />,
    ...leadInfo,
  };

  useEffect(() => {
    initializeForm(leadGenericoInfo, leadGenericoData);
    setLoading(false);
  }, []);

  return loading ? null : <FormController steps={steps} screenType="layer-screen" />;
}

import { CTA, styled, ThemeProvider, Spinner } from '@volkswagen-onehub/components-core';
import { ArrowRight } from '@volkswagen-onehub/icons-core/dist/ArrowRight';
import { Checkmark } from '@volkswagen-onehub/icons-core/dist/Checkmark';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TelefonoInput, PrefijoInput } from 'src/components';
import { OneFormState } from 'src/types';
import { useWindowWidth } from '@react-hook/window-size';
import { Phone } from '@volkswagen-onehub/icons-core/dist/Phone';
import { keyframes, css } from 'styled-components';
import { useTrackingManager, getTrackingForm, useOneFormContext } from 'src/feature-app';

const moveLeft = keyframes`
  from {
    /* left: 70%; */
    left: 75%;
  }
  to {
    left: 0;
    /* right: auto; */
  }
`;

const RaceFormWrapper = styled.div<{ width: number; isSending: boolean; isSent: boolean; showFullWidth: boolean }>`
  display: flex;
  margin-bottom: 24px;
  margin-top: 24px;
  width: ${(props) => {
    if (props.showFullWidth) {
      return '605px';
    }
    if (window.innerWidth < 418) {
      if (props.isSending || props.isSent) {
        return '100%';
        return `${props.width}px`;
      }
      return '75%';
    } else {
      if (props.isSending || props.isSent) {
        return '100%';
      }
      return 'fit-content';
    }
  }};

  min-height: 44px;
  position: relative;
`;

const PrefijoWrapper = styled.div`
  width: 78px;
  padding-top: 3px;
  margin-right: 24px;

  & label {
    color: #001e50 !important;
  }
`;

interface CTAWrapperProps{
  width: number;
  isSending: boolean;
  isSent: boolean;
  isPristine: boolean;
  showFullWidth: boolean;
  successful: boolean;
}
const CTAWrapper = styled.div<CTAWrapperProps>`
  position: absolute;
  left: ${(props) => (props.isSent || props.isSending ? '0' : '100%')};
  width: auto;
  animation: ${(props) => {
    if (props.isSending) {
      return css`
        ${moveLeft} 1s ease
      `;
    } else {
      return 'none';
    }
  }};

  & button {
    margin-left: ${(props) => (props.isSending || props.isSent ? '0px' : '15px')};
    margin-top: 5px;
    width: ${(props) => {
      if (props.isSending || props.isSent) {
        if (props.showFullWidth) {
          return '250px';
        } else {
          if (props.isSending) {
            return '250px';
          }
          return '100%';
        }
      }
      return '44px';
    }};
    height: 44px;
    background-color: ${(props) => (props.isSent ? '#029640' : '#ffaa27')};
    border: ${(props) => (props.isSent ? '2px solid #029640' : '2px solid #ffaa27')};
    /* transition: all 1s ease; */
    cursor: ${(props) => (props.isSent ? 'auto' : 'pointer')};

    @media all and (min-width: 375px) {
      margin-left: ${(props) => (props.isSending || props.isSent ? '0px' : '25px')};
    }
    @media all and (min-width: 560px) {
      margin-left: ${(props) => (props.isSending || props.isSent ? '0px' : '32px')};
    }

    & > span {
      height: 24px;
    }

    &:focus,
    &:hover::before,
    &:active::before {
      background-color: ${(props) => (props.isSent && !props.isPristine ? '#029640' : '#ffaa27')};
      position: relative;
      /* margin: 0px; */
      box-shadow: none;
    }
    &:hover::before {
      margin: 0px;
    }
  }
  & button[disabled] {
    cursor: not-allowed;
    background-color: ${(props) => (props.successful ? '#029640' : 'rgb(223, 228, 232)')};
    color: ${(props) => (props.successful ? '#ffffff' : 'inherit')};
    border: 2px ${(props) => (props.successful ? '#029640' : 'rgb(223, 228, 232)')};
  }
`;

interface CTAWrapperFullScreenProps {
  isSending: boolean;
  isSent: boolean;
  isPristine: boolean;
  showFullWidth: boolean;
  successful: boolean;
}

const CTAWrapperFullScreen = styled.div<CTAWrapperFullScreenProps>`
  position: absolute;
  left: ${(props) => (props.isSent || props.isSending ? '0' : '45%')};
  width: auto;

  animation: ${(props) => {
    if (props.isSending) {
      return css`
        ${moveLeft} 1s ease
      `;
    } else {
      return 'none';
    }
  }};

  @media all and (min-width: 1600px) {
    left: ${(props) => (props.isSent || props.isSending ? '0' : '49%')};
  }

  & button {
    margin-left: ${(props) => (props.isSending || props.isSent ? '0px' : '32px')};
    margin-top: 5px;
    width: ${(props) => (props.isSending ? '250px' : props.isSent || props.showFullWidth ? 'auto' : '44px')};
    height: 44px;
    background-color: ${(props) => (props.isSent && props.successful ? '#029640' : '#ffaa27')};
    border: ${(props) => (props.isSent && props.successful ? '2px solid #029640' : '2px solid #ffaa27')};
    cursor: ${(props) => (props.isSent && props.successful ? 'auto' : 'pointer')};

    & > span {
      height: 24px;
    }

    &:focus,
    &:hover::before,
    &:active::before {
      background-color: ${(props) => (props.isSent && props.successful && !props.isPristine ? '#029640' : '#ffaa27')};
      position: relative;
      /* margin: 0px; */
      box-shadow: none;
    }

    &:hover::before {
      margin: 0px;
    }
  }
  & button[disabled] {
    cursor: not-allowed;
    background-color: ${(props) => (props.successful ? '#029640' : 'rgb(223, 228, 232)')};
    color: ${(props) => (props.successful ? '#ffffff' : 'inherit')};
    border:  ${(props) => (props.successful ? '2px solid #029640' : '2px solid rgb(223, 228, 232)')};
  }
`;

interface RaceFormProps {
  isSent: boolean;
  isSending: boolean;
  disabledButton: boolean;
  sendForm: () => Promise<boolean>;
  isPristine: boolean;
  isFullScreen: boolean;
  copyWidth?: number; // Con el resize en algunos dispositivos móviles se pierde el tamaño necesario del cta y se descuadra.
  setIsPristine: React.Dispatch<React.SetStateAction<boolean>>;
  successful?: boolean;
  error?: boolean;
}

const showFullCta = (isFullScreen: boolean) => isFullScreen && window.innerWidth > 1279;

export const RaceForm = ({
  isSent,
  isSending,
  isPristine,
  disabledButton,
  sendForm,
  copyWidth,
  isFullScreen,
  setIsPristine,
  successful,
}: RaceFormProps) => {
  const initialSizeRef = useRef(null); // Hay que determinar el width del contenedor al principio para que cuando desaparezcan los campos de telf y prefijo no cambie el ancho y la animación del cta empiece desde otro sitio.
  const [containerWidth, setContainerWidth] = useState(0);
  const windowWidth = useWindowWidth();
  const { initializeForm } = useOneFormContext();
  const { formInfo } = useSelector((state: OneFormState) => state);
  const { formResponse } = formInfo;
  const trackingManager = useTrackingManager();
  const dispatch = useDispatch();

  useEffect(() => {
    initializeForm(
      {},
      {
        headers: {},
        fields: {
          formName: 'race',
          formType: 'otherform',
          pageCategory: 'other',
        },
      },
      isFullScreen
    );
  }, []);

  useEffect(() => {
    if (initialSizeRef) {
      if (copyWidth && copyWidth <= 346) {
        // El contenedor crece hasta 346px
        setContainerWidth(copyWidth);
      } else {
        setContainerWidth(initialSizeRef.current.offsetWidth);
      }
    }
  }, [windowWidth, copyWidth]);

  useEffect(() => {
    if (successful){
      dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });

      trackingManager.trackFormSubmissionSuccessMessageLoad(
        {
          contentId: undefined,
        },
        getTrackingForm(formResponse)
      );
    }
  }, [successful]);

  return (
    <RaceFormWrapper
      ref={initialSizeRef}
      width={containerWidth}
      isSending={isSending}
      isSent={isSent}
      showFullWidth={showFullCta(isFullScreen)}
    >
      <>
        {isSending || isSent ? null : (
          <>
            <PrefijoWrapper>
              <ThemeProvider theme="main">
                <PrefijoInput name='racePrefix'/>
              </ThemeProvider>
            </PrefijoWrapper>
            <div style={{ paddingTop: '3px' }}>
              <TelefonoInput
                required
                onChangeHandler={() => {
                  setIsPristine(false);
                }}
                onFocusHandler={() => {
                  setIsPristine(false);
                }}
                name='raceCellphone'
              />
            </div>
          </>
        )}

        {showFullCta(isFullScreen) ? (
          <CTAWrapperFullScreen
            isSending={isSending}
            isSent={isSent}
            isPristine={isPristine}
            successful={successful}
            showFullWidth
          >
            <CTA
              tag="button"
              disabled={disabledButton}
              icon={isSending ? <Spinner /> : isSent ? <Checkmark /> : <Phone />}
              onClick={async () => {
                if (!isSending && !isSent) {
                  await sendForm();
                }
              }}
              stretchContent
            >
              {isSending ? 'Enviando' : isSent && successful ? 'Te llamaremos pronto' : 'Te llamamos'}
            </CTA>
          </CTAWrapperFullScreen>
        ) : (
          <CTAWrapper
            width={containerWidth}
            isSending={isSending}
            isSent={isSent}
            isPristine={isPristine}
            showFullWidth={isFullScreen}
            successful={successful}
          >
            <CTA
              disabled={disabledButton}
              tag="button"
              icon={isSending ? <Spinner /> : isSent ? <Checkmark /> : <ArrowRight />}
              onClick={async () => {
                if (!isSending && !isSent) {
                  await sendForm();
                }
              }}
            >
              {isSending ? 'Enviando' : isSent ? 'Te llamaremos pronto' : null}
            </CTA>
          </CTAWrapper>
        )}
      </>
    </RaceFormWrapper>
  );
};

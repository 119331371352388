export const getPersonAttributes = (noPermiteLssi?: boolean, date?: string) => {
return [
  {
    name: 'ID',
    value: 'PERSON',
  },
  {
    name: 'attributes',
    value: 'null',
  },
  {
    name: 'Id',
    value: 'TEST',
  },
  {
    name: 'IsDeleted',
    value: 'false',
  },
  {
    name: 'Name',
    value: 'TEST NAME',
  },
  {
    name: 'TXT_2_Surname__c',
    value: 'TEST SURNAME',
  },
  {
    name: 'LastName',
    value: 'TEST LASTNAME',
  },
  {
    name: 'FirstName',
    value: 'TEST FIRSTNAME',
  },
  {
    name: 'Salutation',
    value: 'Sra.',
  },
  {
    name: 'RecordTypeId',
    value: '0120Y000000FHqQQAW',
  },
  {
    name: 'BillingAddress',
    value: 'null',
  },
  {
    name: 'ShippingAddress',
    value: 'null',
  },
  {
    name: 'PhotoUrl',
    value: 'null',
  },
  {
    name: 'OwnerId',
    value: '0050Y000001UE3gQAG',
  },
  {
    name: 'CreatedDate',
    value: date ? date : '2023-11-14 18:44:24',
  },
  {
    name: 'CreatedById',
    value: '0050Y000001UE3gQAG',
  },
  {
    name: 'LastModifiedDate',
    value: '2021-05-04 13:43:20',
  },
  {
    name: 'LastModifiedById',
    value: '0050Y000001UE3gQAG',
  },
  {
    name: 'SystemModstamp',
    value: '2021-05-04 13:43:20',
  },
  {
    name: 'LastViewedDate',
    value: 'null',
  },
  {
    name: 'LastReferencedDate',
    value: 'null',
  },
  {
    name: 'IsPartner',
    value: 'false',
  },
  {
    name: 'PersonContactId',
    value: '0031n00002SQq1OAAT',
  },
  {
    name: 'IsPersonAccount',
    value: 'true',
  },
  {
    name: 'PersonMailingAddress',
    value: 'null',
  },
  {
    name: 'PersonOtherAddress',
    value: 'null',
  },
  {
    name: 'PersonHasOptedOutOfEmail',
    value: 'false',
  },
  {
    name: 'PersonHasOptedOutOfFax',
    value: 'false',
  },
  {
    name: 'PersonDoNotCall',
    value: 'false',
  },
  {
    name: 'Audi__c',
    value: 'false',
  },
  {
    name: 'Instalacion__c',
    value: 'null',
  },
  {
    name: 'Skoda__c',
    value: 'false',
  },
  {
    name: 'Volkswagen__c',
    value: 'true',
  },
  {
    name: 'COD_Gender__c',
    value: 'M',
  },
  {
    name: 'ID_Person_DIM_IMP__c',
    value: 'TEST_ID_Person_DIM_IMP__c',
  },
  {
    name: 'LCV__c',
    value: 'false',
  },
  {
    name: 'LSSI__c',
    value: noPermiteLssi ? 'NO PERMITE' : 'PERMITE',
  },
  {
    name: 'NUM_Phone_mobile__c',
    value: '600000000',
  },
  {
    name: 'Robinson__c',
    value: 'PERMITE',
  },
  {
    name: 'TXT_Country__c',
    value: 'null',
  },
  {
    name: 'TXT_Email__c',
    value: 'test@test.com',
  },
  {
    name: 'TXT_Location__c',
    value: 'L’Hospitalet de Llobregat',
  },
  {
    name: 'TXT_NIF_CIF__c',
    value: 'null',
  },
  {
    name: 'TXT_Postal_Code__c',
    value: '08001',
  },
  {
    name: 'TXT_Province__c',
    value: 'Barcelona',
  },
  {
    name: 'TXT_natural_legal_person__c',
    value: 'FISICA',
  },
  {
    name: 'Address_extra__c',
    value: 'null',
  },
  {
    name: 'Address_name__c',
    value: 'null',
  },
  {
    name: 'Address_number__c',
    value: 'null',
  },
  {
    name: 'Address_type__c',
    value: 'null',
  },
  {
    name: 'FORM_Full_Address__c',
    value: 'Calle D’Amadeu TORNER 12',
  },
  {
    name: 'CHK_Lead_VN_Audi__c',
    value: 'null',
  },
  {
    name: 'CHK_Lead_VN_LCV__c',
    value: 'null',
  },
  {
    name: 'CHK_Lead_VN_SK__c',
    value: 'null',
  },
  {
    name: 'CHK_Lead_VN_VW__c',
    value: 'false',
  },
  {
    name: 'CHK_Lead_VO_Audi__c',
    value: 'null',
  },
  {
    name: 'CHK_Lead_VO_LCV__c',
    value: 'null',
  },
  {
    name: 'CHK_Lead_VO_SK__c',
    value: 'null',
  },
  {
    name: 'CHK_Lead_VO_VW__c',
    value: 'false',
  },
  {
    name: 'CHK_Prospect_VN_Audi__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VN_LCV__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VN_SK__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VN_VW__c',
    value: 'false',
  },
  {
    name: 'CHK_Prospect_VO_Audi__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VO_LCV__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VO_SK__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VO_VW__c',
    value: 'false',
  },
  {
    name: 'Desired_model_1_VW__c',
    value: 'null',
  },
  {
    name: 'Pissed_off_client_LCV__c',
    value: 'null',
  },
  {
    name: 'Pissed_off_client_Skoda__c',
    value: 'null',
  },
  {
    name: 'Pissed_off_client_VW__c',
    value: 'false',
  },
  {
    name: 'Pissed_off_client__c',
    value: 'null',
  },
  {
    name: 'driver_is_official_dealer_LCV__c',
    value: 'null',
  },
  {
    name: 'driver_is_official_dealer_SK__c',
    value: 'null',
  },
  {
    name: 'driver_is_official_dealer_VW__c',
    value: 'false',
  },
  {
    name: 'driver_is_official_dealer__c',
    value: 'null',
  },
  {
    name: 'driver_vn_act_SK__c',
    value: 'null',
  },
  {
    name: 'driver_vn_act_LCV__c',
    value: 'null',
  },
  {
    name: 'driver_vn_act_VW__c',
    value: 'false',
  },
  {
    name: 'driver_vn_act__c',
    value: 'null',
  },
  {
    name: 'driver_vn_nact_LCV__c',
    value: 'null',
  },
  {
    name: 'driver_vn_nact_Sk__c',
    value: 'null',
  },
  {
    name: 'driver_vn_nact_VW__c',
    value: 'false',
  },
  {
    name: 'driver_vn_nact__c',
    value: 'null',
  },
  {
    name: 'driver_vo_act_LCV__c',
    value: 'null',
  },
  {
    name: 'driver_vo_act_SK__c',
    value: 'null',
  },
  {
    name: 'driver_vo_act_VW__c',
    value: 'false',
  },
  {
    name: 'driver_vo_act__c',
    value: 'null',
  },
  {
    name: 'driver_vo_nact_LCV__c',
    value: 'null',
  },
  {
    name: 'driver_vo_nact_SK__c',
    value: 'null',
  },
  {
    name: 'driver_vo_nact_VW__c',
    value: 'false',
  },
  {
    name: 'driver_vo_nact__c',
    value: 'null',
  },
  {
    name: 'owner_is_offical_dealer_LCV__c',
    value: 'null',
  },
  {
    name: 'owner_is_offical_dealer_SK__c',
    value: 'null',
  },
  {
    name: 'owner_is_offical_dealer_VW__c',
    value: 'false',
  },
  {
    name: 'owner_is_offical_dealer__c',
    value: 'null',
  },
  {
    name: 'owner_vn_act_LCV__c',
    value: 'null',
  },
  {
    name: 'owner_vn_act_SK__c',
    value: 'null',
  },
  {
    name: 'owner_vn_act_VW__c',
    value: 'false',
  },
  {
    name: 'owner_vn_act__c',
    value: 'null',
  },
  {
    name: 'owner_vn_nact_LCV__c',
    value: 'null',
  },
  {
    name: 'owner_vn_nact_SK__c',
    value: 'null',
  },
  {
    name: 'owner_vn_nact_VW__c',
    value: 'false',
  },
  {
    name: 'owner_vn_nact__c',
    value: 'null',
  },
  {
    name: 'owner_vo_act_LCV__c',
    value: 'null',
  },
  {
    name: 'owner_vo_act_SK__c',
    value: 'null',
  },
  {
    name: 'owner_vo_act_VW__c',
    value: 'false',
  },
  {
    name: 'owner_vo_act__c',
    value: 'null',
  },
  {
    name: 'owner_vo_nact_LCV__c',
    value: 'null',
  },
  {
    name: 'owner_vo_nact_SK__c',
    value: 'null',
  },
  {
    name: 'owner_vo_nact_VW__c',
    value: 'false',
  },
  {
    name: 'owner_vo_nact__c',
    value: 'null',
  },
  {
    name: 'COD_province__c',
    value: 'null',
  },
  {
    name: 'Afected_EA_189__c',
    value: 'false',
  },
  {
    name: 'Legal_risk_EA_189__c',
    value: 'false',
  },
  {
    name: 'CHK_Current_Afected_EA_189__c',
    value: 'false',
  },
  {
    name: 'Repaired_EA_189__c',
    value: 'false',
  },
  {
    name: 'CHK_Replacement_fleet__c',
    value: 'false',
  },
  {
    name: 'CHK_V_Parking__c',
    value: 'null',
  },
  {
    name: 'CHK_Audi_CAM__c',
    value: 'null',
  },
  {
    name: 'CHK_Audi_Lounge__c',
    value: 'null',
  },
  {
    name: 'CHK_Audi_Sport_Exclusive_Service__c',
    value: 'null',
  },
  {
    name: 'CHK_Audi_Sport__c',
    value: 'null',
  },
  {
    name: 'CHK_California_roof_repairing__c',
    value: 'null',
  },
  {
    name: 'CHK_Children_zone__c',
    value: 'null',
  },
  {
    name: 'CHK_Client_Shutle__c',
    value: 'null',
  },
  {
    name: 'CHK_Compressed_Natural_Gas_Charger__c',
    value: 'null',
  },
  {
    name: 'CHK_Crafter_Elevator__c',
    value: 'null',
  },
  {
    name: 'CHK_DRM__c',
    value: 'null',
  },
  {
    name: 'CHK_Delivery_Service__c',
    value: 'null',
  },
  {
    name: 'CHK_Do_Not_Contact_AU__c',
    value: 'false',
  },
  {
    name: 'CHK_Do_Not_Contact__c',
    value: 'false',
  },
  {
    name: 'CHK_Dont_send_Com__c',
    value: 'false',
  },
  {
    name: 'CHK_Elite_Body__c',
    value: 'null',
  },
  {
    name: 'CHK_Email_OFF__c',
    value: 'false',
  },
  {
    name: 'CHK_Exclusive_Service_VW__c',
    value: 'null',
  },
  {
    name: 'CHK_Exclusive_Service__c',
    value: 'null',
  },
  {
    name: 'CHK_Fast_Service__c',
    value: 'null',
  },
  {
    name: 'CHK_Free_Wifi__c',
    value: 'null',
  },
  {
    name: 'CHK_HVT_repairs__c',
    value: 'null',
  },
  {
    name: 'CHK_Handicapped__c',
    value: 'null',
  },
  {
    name: 'CHK_Integral_Body_Program__c',
    value: 'null',
  },
  {
    name: 'CHK_Is_Closed_Days__c',
    value: 'null',
  },
  {
    name: 'CHK_Oficial_Audi_Prestige_Service__c',
    value: 'null',
  },
  {
    name: 'CHK_PCI__c',
    value: 'null',
  },
  {
    name: 'CHK_Parking__c',
    value: 'null',
  },
  {
    name: 'CHK_SUM_Diff_Schedule_Vehicle_Recep__c',
    value: 'null',
  },
  {
    name: 'CHK_Service_Plus_Installation__c',
    value: 'null',
  },
  {
    name: 'CHK_TLMK__c',
    value: 'false',
  },
  {
    name: 'CHK_V_Children_zone__c',
    value: 'null',
  },
  {
    name: 'CHK_V_Exclusive_Service_VW__c',
    value: 'null',
  },
  {
    name: 'CHK_V_Free_Wifi__c',
    value: 'null',
  },
  {
    name: 'CHK_V_Handicapped__c',
    value: 'null',
  },
  {
    name: 'CHK_V_Waiting_zone__c',
    value: 'null',
  },
  {
    name: 'CHK_Waiting_zone__c',
    value: 'null',
  },
  {
    name: 'CHK_Working_Zone_Waiting_Clients__c',
    value: 'null',
  },
  {
    name: 'CHK_e_Hibrids__c',
    value: 'null',
  },
  {
    name: 'CHK_e_dealers__c',
    value: 'null',
  },
  {
    name: 'CHK_e_loaders__c',
    value: 'null',
  },
  {
    name: 'Contactable_after_sales__c',
    value: 'false',
  },
  {
    name: 'Contactable_sales__c',
    value: 'false',
  },
  {
    name: 'Es_Autonomo_VN__c',
    value: 'false',
  },
  {
    name: 'Es_Gran_empresa_propiedad_VN__c',
    value: 'false',
  },
  {
    name: 'Es_Pymes_propiedad_VN__c',
    value: 'false',
  },
  {
    name: 'Es_Pymes_renting_VN__c',
    value: 'false',
  },
  {
    name: 'Es_RaC_VN__c',
    value: 'false',
  },
  {
    name: 'Es_VO_no_particular__c',
    value: 'false',
  },
  {
    name: 'Es_gran_empresa_renting_VN__c',
    value: 'false',
  },
  {
    name: 'Es_indefinido_VN__c',
    value: 'false',
  },
  {
    name: 'Es_no_identificado_VN__c',
    value: 'false',
  },
  {
    name: 'Es_particular_VN__c',
    value: 'false',
  },
  {
    name: 'Es_resto_VN__c',
    value: 'false',
  },
  {
    name: 'Excesive_waiting__c',
    value: 'false',
  },
  {
    name: 'FRML_TXT_DPV__c',
    value: 'null',
  },
  {
    name: 'FRML_TXT_Image_Sign__c',
    value: 'null',
  },
  {
    name: 'Informat_cession_aceptance__c',
    value: 'false',
  },
  {
    name: 'Lead_vn_act__c',
    value: 'false',
  },
  {
    name: 'Lead_vn_nact__c',
    value: 'false',
  },
  {
    name: 'Lead_vo_act__c',
    value: 'false',
  },
  {
    name: 'Lead_vo_nact__c',
    value: 'false',
  },
  {
    name: 'Multivehicle__c',
    value: 'false',
  },
  {
    name: 'Standard_contactable_universe_LCV__c',
    value: 'null',
  },
  {
    name: 'Standard_contactable_universe_Skoda__c',
    value: 'null',
  },
  {
    name: 'Standard_contactable_universe_VW__c',
    value: 'false',
  },
  {
    name: 'Standard_contactable_universe__c',
    value: 'null',
  },
  {
    name: 'Unsuscribed__c',
    value: 'false',
  },
  {
    name: 'cond_is_official_dealer_LCV__c',
    value: 'null',
  },
  {
    name: 'cond_is_official_dealer_SK__c',
    value: 'null',
  },
  {
    name: 'cond_is_official_dealer_VW__c',
    value: 'false',
  },
  {
    name: 'cond_is_official_dealer__c',
    value: 'false',
  },
  {
    name: 'cond_vn_act_SK__c',
    value: 'null',
  },
  {
    name: 'prospect_vn_act__c',
    value: 'null',
  },
  {
    name: 'prospect_vn_nact__c',
    value: 'false',
  },
  {
    name: 'prospect_vo_act__c',
    value: 'false',
  },
  {
    name: 'prospect_vo_nact__c',
    value: 'false',
  },
  {
    name: 'et4ae5__HasOptedOutOfMobile__pc',
    value: 'null',
  },
  {
    name: 'et4ae5__Mobile_Country_Code__pc',
    value: 'null',
  },
  {
    name: 'Audi__pc',
    value: 'false',
  },
  {
    name: 'LCV__pc',
    value: 'false',
  },
  {
    name: 'Skoda__pc',
    value: 'false',
  },
  {
    name: 'Volkswagen__pc',
    value: 'true',
  },
];

};

import React from 'react';
import {
  Layout,
  Breakpoints,
  LayoutRowGap,
} from '@volkswagen-onehub/components-core';

interface OneElementTemplateProps {
  element: JSX.Element;
  allowOverflowingContent?: boolean;
}

// Refactorizar
function OneElementTemplate(props: OneElementTemplateProps) {
  const { element, allowOverflowingContent } = props;
  return (
    <Layout
      allowOverflowingContent={allowOverflowingContent ? true : false}
      appearance={{
        [Breakpoints.default]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ],
        [Breakpoints.b960]: [
          { name: '.', columns: 3 },
          { name: 'a', columns: 18 },
          { name: '.', columns: 3 },
        ],
      }}
      rowGap={LayoutRowGap.static300}
    >
      {element}
    </Layout>
  );
}

interface TwoElementsTemplateProps {
  firstElement: JSX.Element;
  secondElement: JSX.Element;
  allowOverflowingContent?: boolean;
}

function TwoElementsTemplate(props: TwoElementsTemplateProps) {
  const { firstElement, secondElement } = props;
  return (
    <Layout
      appearance={{
        [Breakpoints.default]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ],
        [Breakpoints.b960]: [
          { name: '.', columns: 3 },
          { name: 'a', columns: 18 },
          { name: '.', columns: 3 },
        ],
      }}
      rowGap={LayoutRowGap.static300}
    >
      {firstElement}
      {secondElement}
    </Layout>
  );
}

interface ThreeElementsTemplateProps {
  firstElement: JSX.Element;
  secondElement: JSX.Element;
  thirdElement: JSX.Element;
  allowOverflowingContent?: boolean;
}

function ThreeElementsTemplate(props: ThreeElementsTemplateProps) {
  const { firstElement, secondElement, thirdElement } = props;
  return (
    <Layout
      appearance={{
        [Breakpoints.default]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ],
        [Breakpoints.b960]: [
          { name: '.', columns: 3 },
          { name: 'a', columns: 18 },
          { name: '.', columns: 3 },
        ],
      }}
      rowGap={LayoutRowGap.static300}
    > 
      {firstElement}
      {secondElement}
      {thirdElement}
    </Layout>
  );
}

export { OneElementTemplate, TwoElementsTemplate, ThreeElementsTemplate };

import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Spinner,
  styled,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { Alert } from 'src/icons-core-imports';

import { CloseHandleV2 } from '@volkswagen-onehub/layer-manager';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Store } from 'redux';
import {
  createNoDmsSlotAndAssessor,
  getFranjaAndDayFrom,
  weekDay_DayNumber_De_Month,
  useFeatureAppConfig,
  weekDay_Day_Hour,
} from 'src/feature-app';
import { DealersData, OneFormState, Slot, Horario } from 'src/types';

const Content = styled.div`
  background-color: white;
  padding: 44px 31px;
  flex: auto;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 560px) {
    padding: 52px 46px;
  }
  @media screen and (min-width: 960px) {
    padding: var(--size-grid001);
  }
`;

const CTAWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  > div {
    text-align: left;
  }
  > button {
    @media screen and (min-width: 560px) {
      width: auto;
    }
  }
`;

interface InvalidFormProps {
  notSavedField?: string;
  closeLayerCallback?: CloseHandleV2<any, any>;
}

export function InvalidForm(props: InvalidFormProps) {
  const { notSavedField, closeLayerCallback } = props;
  return (
    <Content>
      <Text appearance={TokenTextAppearance.headline300} bold textAlign={TextAlignment.left}>
        ¿Quieres conservar estos últimos cambios?
      </Text>
      <Container
        gutter={ContainerGutter.static500}
        wrap={'always'}
        horizontalAlign={'flex-start'}
        padding={{ top: ContainerPadding.dynamic0100 }}
      >
        <Text textAlign={TextAlignment.left}>
          Si es así, necesitamos que guardes los cambios que has hecho {notSavedField} antes de confirmar tu cita.
        </Text>
        <CTAWrapper>
          <CTA
            tag="button"
            emphasis="primary"
            onClick={(e) => {
              e.preventDefault();
              closeLayerCallback({}, {}, {});
            }}
            stretchContent
          >
            Entendido
          </CTA>
        </CTAWrapper>
      </Container>
    </Content>
  );
}

interface loadingSreenProps {
  isleads?: boolean;
  copy?: string;
}

export function LoadingScreen(props: loadingSreenProps) {
  return (
    <Content>
      <Spinner variant="large" />
      {props.copy ? <Text>{props.copy}</Text> : <Text>Enviando tu petición</Text>}
    </Content>
  );
}

interface KoScreenProps {
  retrySubmit: (e: any) => void;
  closeLayerCallback?: CloseHandleV2<any, any>;
  title?:string;
  bodyCopy?:string;
  isLEM?: boolean;
  hideSecondCTA?: boolean;
}

export function KoScreen(props: KoScreenProps) {
  const { retrySubmit, closeLayerCallback, title, bodyCopy, isLEM, hideSecondCTA } = props;

  return (
    <Content>
      <Container
        gutter={ContainerGutter.dynamic0100}
        wrap={'always'}
        horizontalAlign={'flex-start'}
      >
        <div style={{ textAlign: 'left' }}>
          <Alert variant="large" />
        </div>
        <Text appearance={TokenTextAppearance.headline300} bold textAlign={TextAlignment.left}>
          {title? title : 'Uy, algo ha pasado'}
        </Text>
        <Container
          gutter={ContainerGutter.static500}
          wrap={'always'}
          horizontalAlign={'flex-start'}
        >
          <Text textAlign={TextAlignment.left}>
            {bodyCopy? bodyCopy : 'Por alguna razón no hemos podido recoger tus datos. ¿Lo intentamos de nuevo?'}
            
          </Text>
          <BreakpointWrapper max={Breakpoints.b560}>
            <Container gutter={ContainerGutter.static350} wrap={'always'}>
              <CTA
                tag="button"
                emphasis="primary"
                onClick={(e) => {
                  e.preventDefault();
                  retrySubmit(e);
                  closeLayerCallback({}, {}, {});
                }}
                stretchContent
              >
                Reintentar
              </CTA> 
              { hideSecondCTA ? null :
                isLEM ? (
                  <CTA
                    tag="a"
                    emphasis="secondary"
                    href='https://www.volkswagen.es/es.html'
                    stretchContent
                  >
                    Ir a la web
                  </CTA>
                ) : (
                  <CTA
                    tag="button"
                    emphasis="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      closeLayerCallback({}, {}, {});
                    }}
                    stretchContent
                  >
                    Seguir navegando
                  </CTA>
                )
              }
            </Container>
          </BreakpointWrapper>
          <BreakpointWrapper min={Breakpoints.b560}>
            <Container gutter={ContainerGutter.static350} horizontalAlign={'flex-start'}>
              <CTA
                tag="button"
                emphasis="primary"
                onClick={(e) => {
                  e.preventDefault();
                  retrySubmit(e);
                  closeLayerCallback({}, {}, {});
                }}
              >
                Reintentar
              </CTA> 
              { hideSecondCTA ? null :
                isLEM ? (
                  <div style={{marginLeft:'32px'}}>
                    <CTA
                      tag="a"
                      emphasis="secondary"
                      href='https://www.volkswagen.es/es.html'
                      stretchContent
                    >
                      Ir a la web
                    </CTA>
                  </div>
                ) : (
                  <div style={{marginLeft:'32px'}}>
                    <CTA
                      tag="button"
                      emphasis="secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        closeLayerCallback({}, {}, {});
                      }}
                    >
                      Seguir navegando
                    </CTA>
                  </div>
                )
              }
            </Container>
          </BreakpointWrapper>
          
        </Container>
      </Container>
    </Content>
  );
}

interface IntermitenciaDMSKoProps {
  retrySubmit: (e: any) => void;
  closeLayerCallback?: CloseHandleV2<any, any>;
}

export function IntermitenciaDMSKo(props: IntermitenciaDMSKoProps) {
  const { retrySubmit, closeLayerCallback } = props;
  const [dmsFailed, setDmsFailed] = useState(false);
  const store: Store<OneFormState, any> = useStore();
  const dispatch = useDispatch();
  const config = useFeatureAppConfig();

  const [day, setDay] = useState(null);
  const [franjaHoraria, setFranjaHoraria] = useState(null);
  const [name, setName] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const { horario }: { horario?: Horario } = store.getState().formData.fields;

  useEffect(() => {
    const { sendDmsFailed } = store.getState().formInfo;
    let { slot, dealer }: { slot?: Slot; dealer?: DealersData } = store.getState().formData.fields;

    setDmsFailed(sendDmsFailed);
    const { franjaHoraria } = getFranjaAndDayFrom(slot.from);

    setDay(weekDay_DayNumber_De_Month(slot.from));
    setFranjaHoraria(franjaHoraria.toLowerCase());
    setName(dealer.name);
    setSelectedPoint(slot);
  }, []);

  const handleShowSlots = useCallback((): string | null => {
    let { slot }: { slot?: Slot } = store.getState().formData.fields;

    const finalSlot = getFinalSlot(slot.from);

    return finalSlot ? finalSlot : null;
  }, []);

  const getFinalSlot = (from: number) => {
    const momentSlot = weekDay_Day_Hour(from);
    const capitalizedSlot = momentSlot.charAt(0).toUpperCase() + momentSlot.slice(1);
    return capitalizedSlot.replace('-', ' a las ').concat('h');
  };

  const createNoDmsSlot = async (e: any) => {
    let { slot, dealer }: { slot?: Slot; dealer?: DealersData } = store.getState().formData.fields;
    dealer = {
      ...dealer,
      dmsInfo: {
        ...dealer.dmsInfo,
        dmsAvailable: false,
      },
    };
    const { franjaHoraria, day } = getFranjaAndDayFrom(slot.from);
    const { slot: newSlot, assessor } = createNoDmsSlotAndAssessor(franjaHoraria, day);
    dispatch({ type: 'UPDATE_FIELDS', payload: { slot: newSlot, assessor, dealer } });

    retrySubmit(e);
  };
  return (
    <Content>
      <Container
        gutter={ContainerGutter.static350}
        wrap={'always'}
        horizontalAlign={'flex-start'}
      >
        <Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
          <Text bold>No hemos podido confirmar tu cita </Text>para el{' '}
          <span style={{ textTransform: 'lowercase' }}>{handleShowSlots()}</span>
        </Text>
        <Container
          gutter={ContainerGutter.static500}
          wrap={'always'}
          horizontalAlign={'flex-start'}
        >
          <Text textAlign={TextAlignment.left}>
            ¿Quieres que <span style={{ textTransform: 'capitalize' }}>{name}</span> se ponga en contacto contigo para
            cerrar tu cita el {day} por la {franjaHoraria}?
          </Text>
          <CTAWrapper className="ko-screen">
            <CTA
              tag="button"
              emphasis="primary"
              onClick={async (e) => {
                e.preventDefault();
                await createNoDmsSlot(e);
                closeLayerCallback({}, {}, {});
              }}
              stretchContent
            >
              Consultar con mi taller
            </CTA>
            {
              config.trigger === 'cita-posventa-dealer' ? null : (
                <div style={{marginLeft:'32px'}}>
                  <CTA
                    tag="button"
                    emphasis="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      closeLayerCallback({}, {}, {});
                    }}
                    stretchContent
                  >
                    Buscar una nueva cita
                  </CTA>
                </div>
              )
            }
          </CTAWrapper>
        </Container>
      </Container>
    </Content>
  );
}

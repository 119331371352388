import { Text } from '@volkswagen-onehub/components-core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { yumiwiSend } from 'src/bff';
import { IdPerson, IdVehicle, IdDealer, IdOrder } from 'src/types';
import { DetalleMotivo, MultipleOptionCTAs, MultipleCTASelection, ContactInfoWrapper, Telefono } from 'src/components';
import { FormController, useOneFormContext } from 'src/feature-app';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { formDataBuilder } from 'src/forms/format-fields';
import { DealerCEMSegundaEntrega, CitaCEMSegundaEntrega, ThankYouCEMSegundaEntrega, ConfirmacionCEMSegundaEntrega, getData } from 'src/forms/Cem/CEMSegundaEntrega';
import { sanitizeKvps } from 'src/forms/Cem/helpers';
import { addRemovedStep, getIndexInSteps, removeStep, resetStepsScreenIndex } from 'src/forms/helpers/add-remove-steps';

export interface CEMSegundaEntregaFormProps {
	dealers?: [IdDealer];
	idPerson: IdPerson;
	idVehicle: IdVehicle;
	idDealer: IdDealer;
	idOrder: IdOrder;
}

export enum CEMSegundaEntregaSteps {
	TipoConsulta,
	DetalleConsulta,
	TipoContacto,
	ConfirmacionTelefono,
	SeleccionDealer,
	Calendario,
	Confirmacion,
}

export function CEMSegundaEntregaForm(props: CEMSegundaEntregaFormProps) {
	const {
		dealers,
		idPerson,
		idVehicle,
		idDealer,
		idOrder
	} = props;
	const { initializeForm, handleNextStep } = useOneFormContext();
	const { formData } = useSelector((state: OneFormState) => state);
	const store = useStore();
	const dispatch = useDispatch();//console.log(dealers);

	// Definición de steps

	const isElectrico = idVehicle.DESC_Model__c.substring(0, 2) == 'ID';

	const CTAsTipoConsulta = [
		{ label: 'Acabados y funcionalidades', value: 'Acabados y funcionalidades' },
		{ label: 'Ayuda a la conducción', value: 'Ayuda a la conducción' },
		{ label: isElectrico ? 'Carga y autonomía' : 'Consumo de combustible', value: isElectrico ? 'Carga y autonomía' : 'Consumo de combustible' },
		{ label: 'Conexión a internet y apps', value: 'Conexión a internet y apps' },
		{ label: 'Garantía y mantenimiento', value: 'Garantía y mantenimiento' },
		{ label: 'Otros', value: 'Otros' },
	];

	const CTAsTipoContacto = [
		{ label: 'Llamada', value: 'Teléfono' },
		{ label: 'Videollamada', value: 'Videollamada' },
		{ label: 'Visita en el punto de venta', value: 'Visita' },
	];

	const defaultSteps: Steps[] = [
		{
			title: (
				<>
					<Text bold>¿Sobre qué </Text>
					<Text>quieres hablar con tu experto?</Text>
				</>
			),
			fields: (
				<OneElementTemplateFullScreen
					element={<MultipleOptionCTAs ctaValues={CTAsTipoConsulta} name="tipoConsulta" />}
				/>
			),
			screenIndex: CEMSegundaEntregaSteps.TipoConsulta,
			name: 'TipoConsulta',
			outputs: ['tipoConsulta'],
			outputsText: ['tipoConsulta'],
		},
		{
			title: (
				<>
					<Text>¿Nos das </Text>
					<Text bold>más detalles?</Text>
					<span style={{ whiteSpace: 'nowrap' }}>
						<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
					</span>
				</>
			),
			fields: <OneElementTemplateFullScreen element={<DetalleMotivo label='Escríbelo aquí' name="detalleConsulta" numberOfMaxLength={300} />} />,
			screenIndex: CEMSegundaEntregaSteps.DetalleConsulta,
			name: 'DetalleConsulta',
			outputs: ['detalleConsulta'],
			outputsText: ['detalleConsulta'],
		},
		{
			title: (
				<>
					<Text bold>¿Cómo </Text>
					<Text>prefieres que sea la cita?</Text>
				</>
			),
			fields: (
				<OneElementTemplateFullScreen
					element={<MultipleCTASelection ctaValues={CTAsTipoContacto} name="tipoContacto" nextOnClick />}
				/>
			),
			screenIndex: CEMSegundaEntregaSteps.TipoContacto,
			name: 'TipoContacto',
			outputs: ['tipoContacto'],
			outputsText: ['tipoContacto'],
			ctas: CTAsTipoContacto,
		},
		{
			title: (
				<>
					{idPerson.FirstName}, ¿nos podrías confirmar tus <Text bold>datos de contacto?</Text>
				</>
			),
			fields: (
				<OneElementTemplateFullScreen
					element={
						<ContactInfoWrapper>
							<Telefono paginaConfirmacion={false} required={true} notALayer={true} />
						</ContactInfoWrapper>
					}
				/>
			),
			screenIndex: CEMSegundaEntregaSteps.ConfirmacionTelefono,
			name: 'ConfirmacionTelefono',
			outputs: ['cellphone'],
			outputsText: ['cellphone'],
		},
		(dealers ? {
			title: (
				<>
					<Text bold>¿A qué punto de venta</Text> quieres ir?
				</>
			),
			fields: (
				<DealerCEMSegundaEntrega name="seleccionDealer" dealers={dealers} />
			),
			screenIndex: CEMSegundaEntregaSteps.SeleccionDealer,
			name: 'SeleccionDealer',
			outputs: ['installation-complete'],
			outputsText: ['installation-complete'],
		} : null),
		(dealers ? {
			title: (
				<>
					<Text bold>¿Y cuándo </Text>
					<Text>te va mejor?</Text>
				</>
			),
			fields: (
				<CitaCEMSegundaEntrega fechaName="fechaCita" horaName="horaCita" />
			),
			screenIndex: CEMSegundaEntregaSteps.Calendario,
			name: 'Calendario',
			outputs: ['fechaCita', 'horaCita'],
			outputsText: ['fechaCita', 'horaCita'],
		} : null),
		{
			fields: <ConfirmacionCEMSegundaEntrega idPerson={idPerson} />,
			screenIndex: CEMSegundaEntregaSteps.Confirmacion,
			name: 'Confirmacion',
			outputs: ['confirmacion'],
			outputsText: ['confirmacion'],
		},
	];

	const filteredSteps = defaultSteps.filter((step) => step !== null);


	// Lógica para:
	// añadir DetalleConsulta si TipoConsulta contiene Otros
	// añadir ConfirmacionTelefono si TipoContacto es Teléfono o Videollamada

	const { tipoConsulta, tipoContacto } = formData.fields;

	useEffect(() => {
		if (tipoConsulta) {
			updateSteps('DetalleConsulta', tipoConsulta.includes('Otros'));
		}
		if (tipoContacto) {
			updateSteps('ConfirmacionTelefono', tipoContacto == 'Teléfono' || tipoContacto == 'Videollamada');
		}
	}, [tipoConsulta, tipoContacto]);


	const updateSteps = (stepName: string, conditional: boolean) => {
		const { steps: actualSteps, screenIndex } = store.getState().formInfo;
		let newSteps = [...actualSteps];
		if (conditional) {
			const stepIndex = getIndexInSteps(newSteps, stepName);

			if (stepIndex === -1) {
				newSteps = addRemovedStep(filteredSteps, actualSteps, stepName, screenIndex);
				setTimeout(() => {
					handleNextStep();
				}, 0);
			}
		} else {
			newSteps = removeStep(newSteps, stepName);
		}

		const newStepsWithIndex = resetStepsScreenIndex(newSteps);

		dispatch({ type: 'UPDATE_STEPS', payload: newStepsWithIndex });
		dispatch({ type: 'UPDATE_LAST_STEP', payload: newStepsWithIndex[newStepsWithIndex.length - 1] });
		dispatch({ type: 'SET_NUMBER_OF_SCREENS', payload: newStepsWithIndex.length });
	};


	// Datos de envío

	const CEMSegundaEntregaData: Partial<FormDataGroup> = {
		headers: {},
		fields: {
			name: idPerson.FirstName,
			surname: idPerson.LastName,
			secondSurname: idPerson.TXT_2_Surname__c,
			email: idPerson.TXT_Email__c,
			cellphone: idPerson.NUM_Phone_mobile__c,
			formName: 'cem-segunda_entrega-invitacion',
			formType: 'otherform',
			pageCategory: 'Encuesta',
		}, // Analítica
	};

	const sendForm = (): Promise<boolean> => {
		const { formData }: OneFormState = store.getState();
		const { fields } = formData;

		return new Promise<any>(async (resolve, reject) => {

			const sendData = getData(idPerson, idVehicle, idDealer, idOrder);

			if (fields.cellphone) sendData['fields[NUM_PHONE]'] = fields.cellphone;
			if (fields.seleccionDealer) {
				for (const dealer of dealers) {
					if (dealer.TXT_V_KVPS_code__c == fields.seleccionDealer) {
						sendData['fields[DESC_DEALER]'] = dealer.TXT_V_Installation_Data_Name__c;
						sendData['fields[DESC_DEALER_CODE]'] = dealer.TXT_V_Installation_Code__c;
						sendData['fields[DESC_DEALER_CODE_KVPS]'] = sanitizeKvps(dealer.TXT_V_KVPS_code__c);
					}
				}
			}

			sendData['fields[DESC_AUXFIELD1]'] = true;
			sendData['fields[DESC_AUXFIELD2]'] = fields.tipoConsulta;
			sendData['fields[DESC_AUXFIELD3]'] = fields.detalleConsulta ? fields.detalleConsulta : '';
			sendData['fields[DESC_AUXFIELD4]'] = fields.tipoContacto;
			sendData['fields[CITA_PREVIA_DATE]'] = fields.fechaCita + ' ' + fields.horaCita;
			sendData['fields[CITA_PREVIA_PREFERRED_SCHEDULE]'] = fields.franjaHoraria;
			sendData['fields[DESC_LOPD_GDPR_PERFILADO]'] = fields.tmk ? fields.tmk : 'NO INFORMADO';
			sendData['fields[DESC_LSSI]'] = fields.lssi ? fields.lssi : 'NO INFORMADO';

			const dataStringified = JSON.stringify(sendData);
			const dataParsed = JSON.parse(dataStringified);
			const formData = formDataBuilder(dataParsed);
			const response = await yumiwiSend(formData);

			if (response && response.data && response.data.content && response.data.content.code === '200') {
				dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
				resolve(true);
			} else {
				resolve(false);
			}
		});
	};


	// Inicialización del form

	const getFormInfo = (): {
		firstStep: Steps;
		lastStep: Steps;
		stepsHistory: number[];
		numberOfScreens: number;
		newSteps?: Steps[];
	} => {
		return {
			firstStep: filteredSteps[0],
			lastStep: filteredSteps[filteredSteps.length - 1],
			stepsHistory: [0],
			numberOfScreens: filteredSteps.length,
			newSteps: null,
		};
	};

	useEffect(() => {
		const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

		const CEMSegundaEntregaInfo: Partial<FormInfo> = {
			formStarted: true,
			sendFormCallback: sendForm,
			fullScreen: firstStep.fullScreen ? true : false,
			lastStep,
			numberOfScreens,
			screenIndex: firstStep.screenIndex,
			formTheme: 'main',
			notALayer: true,
			formType: 'inPage',
			step: firstStep,
			steps: newSteps ? newSteps : filteredSteps,
			stepsHistory,
			thankYouComponent: <ThankYouCEMSegundaEntrega idVehicle={idVehicle} />,
		};
		initializeForm(CEMSegundaEntregaInfo, CEMSegundaEntregaData);
	}, [dealers]);

	return filteredSteps ? <FormController steps={filteredSteps} screenType="full-screen" /> : null;
}

import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerPadding,
  styled,
  Text,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultipleCTASelection, ImageCarNoColorBG } from 'src/components';
import { CTAsSiNo } from 'src/feature-app/InputsValues/CTAsObjects';
import { OneElementTemplateFullScreen, useFaContent, useGetIdVehicle } from 'src/feature-app';
import { getVilmaAssets } from 'src/bff';
import { useWindowHeight } from '@react-hook/window-size';
import { OneFormState } from 'src/types';
import { strTitlelize } from 'src/helpers';



const LayoutWrapper = styled.div`
  @media all and (min-width: 960px) {
    display: flex;
  }
`;

const InfoWrapper = styled.div`
  width: 100%;
  padding-left: var(--size-grid002);
  padding-right: var(--size-grid002);
  @media all and (min-width: 960px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: var(--size-grid012);
    padding-left: var(--size-grid002);
    padding-right: 0;
    padding-top: 0;
  }
  @media all and (min-width: 1280px) {
    padding-left: var(--size-grid003);
  }
  @media all and (min-width: 1600px) {
    padding-left: var(--size-grid002);
  }
`;


export const FirstStepCEM = () => {
  const { formData } = useSelector((state: OneFormState) => state);
  const dispatch = useDispatch();
  const [model, setModel] = useState(null);
  const { concesion } = formData.fields;
  const [imageSrc, setImageSrc] = useState(null);
  const [dataVilma, setDataVilma] = useState(null);
  const idVehicle = useGetIdVehicle();
  const idVehicleModel = idVehicle.DESC_Model__c;
  //const isElectrico = idVehicle.DESC_Model__c.substring(0, 2) == 'ID';
  const frame = idVehicle.Frame__c.trim();
  const urlParams = new URLSearchParams(window.location.search);
  const modelUrl = urlParams.get('model');
  const faContent = useFaContent();
  const isPosventa = (faContent.cem === 'POSVENTA' || faContent.encuesta === 'CEM - POSVENTA');
  const height = useWindowHeight();

  const dataVilmaRequest = async () => {
    const data = await getVilmaAssets(frame);
    //Respuesta cuando el bastidor no es correcto {message: 'No image found for VIN [WVWZZZAUZLW025556]', context: 'the requested image is not available'}
    if(data && data.status?.code == 200) {
      setDataVilma(data.content);
    }
  };

  useEffect(() => {
    if (!dataVilma) {
      setImageSrc(null);
    } else if (dataVilma) {
      const Front_Left = dataVilma.find((e: any) => e.view === 'Front_Left');
      Front_Left === undefined ? setImageSrc(null) : setImageSrc(Front_Left.url);
    }
  }, [dataVilma]);

  useEffect(() => {
    if (idVehicleModel !== 'null') {
      setModel(idVehicleModel);
      dataVilmaRequest();
    } else if (modelUrl) {
      setModel(modelUrl);
      dispatch({ type: 'UPDATE_FIELDS', payload: { modelo: modelUrl } });
    } else {
      setModel(null);
    }
  }, []);

	const BodyCopy = () => {
		return (
			<>
				<Text appearance={TokenTextAppearance.headline300}>
					{isPosventa ?
						(
							<>
								Según nuestros datos, visitaste el Servicio Oficial{' '}<Text bold>{strTitlelize(concesion)}</Text>{' '}con tu{' '}<Text bold>{model}</Text>.
							</>
						)
						:
            (
							<>
								Según nuestros registros compraste un nuevo <Text bold>{model}</Text> en nuestro punto de venta <Text bold>{strTitlelize(concesion)}</Text>.
							</>
						)}
				</Text>
				<Text appearance={TokenTextAppearance.copy200} staticSize>
					{isPosventa ?
						(
							<>
								¿Puedes confirmar que estos datos son correctos y que eres la persona que mejor nos puede informar sobre la última visita al taller?
							</>
						)
						:
            (
							<>
								¿Puedes confirmar que estos datos son correctos?
							</>
						)}
				</Text>
				<MultipleCTASelection ctaValues={CTAsSiNo} name='datosCorrectos' ctaWidth='81' nextOnClick />
			</>
		);
	};


	return (
		dataVilma && imageSrc ? (
			<LayoutWrapper className='layout-wrapper'>
				{
					height <= 600 ? null : (
						<BreakpointWrapper max={Breakpoints.b960}>
							<ImageCarNoColorBG noMargins image={imageSrc} modelName={model} />
						</BreakpointWrapper>
					)
				}

				<InfoWrapper className='info-wrapper'>
					<Container padding={{ bottom: ContainerPadding.static350 }} wrap={'always'}>
						<BodyCopy />
					</Container>
				</InfoWrapper>
				<BreakpointWrapper min={Breakpoints.b960}>
					<ImageCarNoColorBG noMargins image={imageSrc} modelName={model} />
				</BreakpointWrapper>
			</LayoutWrapper>
			) : (
				<OneElementTemplateFullScreen
					element={
						<div style={{ paddingTop: '8px' }}>
							<BodyCopy />
						</div>
					}
				/>
			)
	);
};

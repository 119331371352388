import { ApiKeyProviderV1 } from '@volkswagen-onehub/api-key-provider';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { AuthServiceProviderV2 } from '@volkswagen-onehub/authservice';
import { CarConfigurationServiceV2 } from '@volkswagen-onehub/car-configuration-feature-service';
import { EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { FaContentServiceV1 } from '@volkswagen-onehub/fa-content-service';
import { FeatureAppDefinition, FeatureServices } from '@feature-hub/core';
import { FeatureAppEnvironmentContext } from 'src/feature-app/hooks/use-feature-app-environment';
import { FinancialDataServiceV1 } from '@volkswagen-onehub/financial-data-feature-service';
import { HistoryServiceV2 } from '@feature-hub/history-service';
import { ImageServiceV1 } from '@volkswagen-onehub/image-service';
import { initialState } from 'src/feature-app/redux/index';
import { initSentry } from './sentry/';
import { LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { Logger } from '@feature-hub/logger';
import { NavigationServiceV1 } from '@volkswagen-onehub/navigation-service';
import { oneformCoreServiceDefinition } from 'src/feature-service/es-oneform-core-feature-service/index';
import { ReactFeatureApp } from '@feature-hub/react';
import { saveEnvironment } from './save-feature-app';
import { saveFeatureServices } from 'src/feature-app';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { ServiceConfigProviderV1 } from '@volkswagen-onehub/service-config-provider';
import { ThemeProvider } from '@volkswagen-onehub/components-core';
import { TrackingManagerV1 } from '@volkswagen-onehub/tracking-service';
import { UserInteractionServiceV1 } from '@volkswagen-onehub/user-interaction-service';
import * as React from 'react';
import App from './App';
import ErrorBoundary from './sentry/ErrorBoundary';

export type Trigger =
  | 'adhoc-2021-diamadre'
  | 'adhoc-2021-eurocopa'
  | 'adhoc-2021-fidelizacion_clientes'
  | 'adhoc-2021-navidad'
  | 'adhoc-2023-test_id'
  | 'adhoc-2023-concurso_rodaje'
  | 'adhoc-2023-plan_ideal'
  | 'adhoc-lanzamiento-taigo'
  | 'adhoc-suvperheroes'
  | 'autosubmit'
  | 'baja-comunicaciones'
  | 'callme-embedded'
  | 'cita-posventa-dealer'
  | 'cita-posventa-embedded'
  | 'cita-posventa-tile-grid'
  | 'cita-posventa-tile'
  | 'cita-posventa'
  | 'empresas'
  | 'encuestas'
  | 'feedback'
  | 'lead-configurador'
  | 'lead-dealer'
  | 'lead-embedded'
  | 'lead-inpage'
  | 'lead'
  | 'mgm'
  | 'newsletter'
  | 'prospect-embedded'
  | 'prospect-posventa-embedded'
  | 'prospect'
  | 'race'
  | 'sac'
  | 'widget'
  ;
export interface FeatureAppConfig {
  readonly trigger?: Trigger;
}

interface Installation {
  name: string;
  code: string;
}

interface CalculatorData {
  carYear: number;
  familyModelCode?: string;
  familyModelDesc?: string;
  installation: Installation;
  kilometres?: string;
  license?: string;
  licensePlate?: string;
  modelDescr?: string;
  modelKey?: string;
  vin?: string;
}
interface CalculatorService {
  setInstallation?: (installation: Installation) => void;
  getCalculatorData?: () => CalculatorData;
}

export interface Dependencies extends FeatureServices {
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1 | undefined;
  readonly 's2:serialized-state-manager': SerializedStateManagerV1;
  readonly 'layer-manager': LayerManagerV2;
  readonly 'es:oneform-core': EsOneformCoreServiceV1;
  readonly 'car-configuration': CarConfigurationServiceV2;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'service-config-provider': ServiceConfigProviderV1;
  readonly 'fa-content-service': FaContentServiceV1;
  readonly 's2:history': HistoryServiceV2;
  readonly 'financial-data': FinancialDataServiceV1;
  readonly 'vw:authService': AuthServiceProviderV2;
  readonly 'user-interaction-service'?: UserInteractionServiceV1;
  readonly 'image-service'?: ImageServiceV1;
  readonly 'api-key-provider': ApiKeyProviderV1;
  readonly 'calculator-feature-service'?: CalculatorService;
  readonly 's2:logger': Logger;
  readonly 'navigation-service': NavigationServiceV1;

  readonly tracking?: TrackingManagerV1;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies, FeatureAppConfig> = {
  dependencies: {
    featureServices: {
      's2:serialized-state-manager': '^1.0.0',
      'layer-manager': '^2.3.0',
      'es:oneform-core': '^1.0.0',
      'car-configuration': '^2.0.0',
      'locale-service': '^1.0.0',
      'service-config-provider': '^1.0.0',
      'fa-content-service': '^1.0.0',
      's2:history': '^2.0.0',
      'financial-data': '^1.0.0',
      'user-interaction-service': '^1.0.0',
      'image-service': '^1.1.1',
      's2:logger': '^1.0.0',
      'navigation-service': '^1.0.0',
      'api-key-provider': '^1.0.1',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:async-ssr-manager': '^1.0.0',
      tracking: '^1.0.0',
      ['vw:authService']: '2.0.0',
      'calculator-feature-service': '^1.0.0',
    },
  },

  ownFeatureServiceDefinitions: [oneformCoreServiceDefinition],

  create: (env) => {
    let oneFormState = JSON.stringify(initialState);

    let { 's2:async-ssr-manager': asyncSsrManager, 's2:serialized-state-manager': serializedStateManager } =
      env.featureServices;
    // Necesario para que cualquier función pueda acceder a los featureServices sin usar hooks, especialmente
    // para acceder a los service configs.
    saveFeatureServices(env.featureServices);
    saveEnvironment(env);

    const { hub } = initSentry(env.featureServices, env.config);
    // oneFormService.initializeService();
    // We use the presence of the asyncSsrManager to determine whether we are
    // rendered on the server or on the client.
    if (asyncSsrManager) {
      serializedStateManager.register(() => JSON.stringify(initialState));

      asyncSsrManager
        .scheduleRerender
        // (async () =>
        //   (initialState = await fetchInitialState(config, carConfiguration)))()
        ();
    } else {
      const serializedState = serializedStateManager.getSerializedState();
      if (serializedState) {
        oneFormState = serializedState;
      }
    }

    return {
      render: () => (
        <ErrorBoundary hub={hub}>
          <FeatureAppEnvironmentContext.Provider value={env}>
            <ThemeProvider theme="main">
              <App />
            </ThemeProvider>
          </FeatureAppEnvironmentContext.Provider>
        </ErrorBoundary>
      ),
    };
  },
};

export default featureAppDefinition;

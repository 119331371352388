import React, { useEffect, useState } from 'react';
import { CTA } from '@volkswagen-onehub/components-core';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { RenderController } from 'src/feature-app/Screen';
import { OneFormState, Steps } from 'src/types';
import { ThankYouTitle, ThankYouFields } from 'src/components';
import { formDataBuilder } from 'src/forms/format-fields';
import { IdPerson, IdVehicle, IdDealer, IdOrder } from 'src/types';
import { dpsSend } from 'src/bff';
import { getData } from 'src/forms/Cem/CEMSegundaEntrega';

export interface NoInteresaCEMSegundaEntregaFormProps {
	handleError: () => void,
	idPerson: IdPerson;
	idVehicle: IdVehicle;
	idDealer: IdDealer;
	idOrder: IdOrder;
}

export function NoMeInteresaCEMSegundaEntrega(props: NoInteresaCEMSegundaEntregaFormProps) {
	const {
		handleError,
		idPerson,
		idVehicle,
		idDealer,
		idOrder
	} = props;
	const dispatch = useDispatch();
	const { formData } = useSelector((state: OneFormState) => state);
	const { fields } = formData;
	const [send, setSend] = useState(false);
	const [sent, setSent] = useState(false);

	const sendForm = (): Promise<boolean> => {
		setSend(true);
		return new Promise<any>(async (resolve, reject) => {

			const sendData = getData(idPerson, idVehicle, idDealer, idOrder);

			sendData['fields[DESC_AUXFIELD1]'] = false;
			sendData['fields[DESC_LOPD_GDPR_PERFILADO]'] = fields.tmk ? fields.tmk : 'NO INFORMADO';
			sendData['fields[DESC_LSSI]'] = fields.lssi ? fields.lssi : 'NO INFORMADO';

			const dataStringified = JSON.stringify(sendData);
			const dataParsed = JSON.parse(dataStringified);
			const formData = formDataBuilder(dataParsed);
			const response = await dpsSend(formData);

			if (response && response.data && response.data.content && response.data.content.code === '200') {
				setSent(true);
			} else {
				handleError();
			}
		});
	};

	useEffect(() => {
		if (idDealer && !send) sendForm();
	}, [idDealer]);

	const copyBook = <>
		Seguro que terminas conociendo tu Volkswagen como la palma de tu mano. Y siempre puedes <CTA tag="a" emphasis="tertiary" href="https://www.volkswagen.es/es/volkswagen-espana/atencion-cliente.html">contactarnos</CTA> si tienes alguna duda.
	</>;

	const screen: Steps = {
		title: <ThankYouTitle notALayer boldCopyFirst='¡Entendido!' />,
		fields: <ThankYouFields notALayer copyBook={copyBook} />,
	};

	return (
		sent ? <RenderController
			screenType="full-screen"
			title={screen.title}
			fields={screen.fields}
			key={555}
			avoidTrackFormStepLoad
			thankYouPage={true}
		/> : null
	);
}

export const dealerAttributes = [
  {
    name: 'ID',
    value: 'DEALER',
  },
  {
    name: 'attributes',
    value: 'es.vged.dps.salesforce.rest.entities.SfAttributesRest@281c2df7[type=Account,url=/services/data/v53.0/sobjects/Account/0010Y000014bFZOQA2]',
  },
  {
    name: 'Id',
    value: '0010Y00001IPCkUQAX',
  },
  {
    name: 'IsDeleted',
    value: 'null',
  },
  {
    name: 'MasterRecordId',
    value: 'null',
  },
  {
    name: 'Name',
    value: 'CATALUNYA WAGEN',
  },
  {
    name: "TXT_V_Installation_Data_Name__c",
    value: "CATALUNYA WAGEN"
  },
  {
    name: 'LastName',
    value: 'null',
  },
  {
    name: 'FirstName',
    value: 'null',
  },
  {
    name: 'Salutation',
    value: 'null',
  },
  {
    name: 'MiddleName',
    value: 'null',
  },
  {
    name: 'Suffix',
    value: 'null',
  },
  {
    name: 'Type',
    value: 'null',
  },
  {
    name: 'RecordTypeId',
    value: 'null',
  },
  {
    name: 'ParentId',
    value: 'null',
  },
  {
    name: 'BillingStreet',
    value: 'null',
  },
  {
    name: 'BillingCity',
    value: 'null',
  },
  {
    name: 'BillingState',
    value: 'null',
  },
  {
    name: 'BillingPostalCode',
    value: 'null',
  },
  {
    name: 'BillingCountry',
    value: 'null',
  },
  {
    name: 'BillingLatitude',
    value: 'null',
  },
  {
    name: 'BillingLongitude',
    value: 'null',
  },
  {
    name: 'BillingGeocodeAccuracy',
    value: 'null',
  },
  {
    name: 'BillingAddress',
    value: 'null',
  },
  {
    name: 'ShippingStreet',
    value: 'null',
  },
  {
    name: 'ShippingCity',
    value: 'null',
  },
  {
    name: 'ShippingState',
    value: 'null',
  },
  {
    name: 'ShippingPostalCode',
    value: 'null',
  },
  {
    name: 'ShippingCountry',
    value: 'null',
  },
  {
    name: 'ShippingLatitude',
    value: 'null',
  },
  {
    name: 'ShippingLongitude',
    value: 'null',
  },
  {
    name: 'ShippingGeocodeAccuracy',
    value: 'null',
  },
  {
    name: 'ShippingAddress',
    value: 'null',
  },
  {
    name: 'Phone',
    value: 'null',
  },
  {
    name: 'Fax',
    value: 'null',
  },
  {
    name: 'AccountNumber',
    value: 'null',
  },
  {
    name: 'Website',
    value: 'null',
  },
  {
    name: 'PhotoUrl',
    value: 'null',
  },
  {
    name: 'Sic',
    value: 'null',
  },
  {
    name: 'Industry',
    value: 'null',
  },
  {
    name: 'AnnualRevenue',
    value: 'null',
  },
  {
    name: 'NumberOfEmployees',
    value: 'null',
  },
  {
    name: 'Ownership',
    value: 'null',
  },
  {
    name: 'TickerSymbol',
    value: 'null',
  },
  {
    name: 'Description',
    value: 'null',
  },
  {
    name: 'Rating',
    value: 'null',
  },
  {
    name: 'Site',
    value: 'null',
  },
  {
    name: 'OwnerId',
    value: 'null',
  },
  {
    name: 'CreatedDate',
    value: 'null',
  },
  {
    name: 'CreatedById',
    value: 'null',
  },
  {
    name: 'LastModifiedDate',
    value: 'null',
  },
  {
    name: 'LastModifiedById',
    value: 'null',
  },
  {
    name: 'SystemModstamp',
    value: 'null',
  },
  {
    name: 'LastActivityDate',
    value: 'null',
  },
  {
    name: 'LastViewedDate',
    value: 'null',
  },
  {
    name: 'LastReferencedDate',
    value: 'null',
  },
  {
    name: 'IsPartner',
    value: 'null',
  },
  {
    name: 'PersonContactId',
    value: 'null',
  },
  {
    name: 'IsPersonAccount',
    value: 'null',
  },
  {
    name: 'PersonMailingStreet',
    value: 'null',
  },
  {
    name: 'PersonMailingCity',
    value: 'null',
  },
  {
    name: 'PersonMailingState',
    value: 'null',
  },
  {
    name: 'PersonMailingPostalCode',
    value: 'null',
  },
  {
    name: 'PersonMailingCountry',
    value: 'null',
  },
  {
    name: 'PersonMailingLatitude',
    value: 'null',
  },
  {
    name: 'PersonMailingLongitude',
    value: 'null',
  },
  {
    name: 'PersonMailingGeocodeAccuracy',
    value: 'null',
  },
  {
    name: 'PersonMailingAddress',
    value: 'null',
  },
  {
    name: 'PersonMobilePhone',
    value: 'null',
  },
  {
    name: 'PersonEmail',
    value: 'null',
  },
  {
    name: 'PersonTitle',
    value: 'null',
  },
  {
    name: 'PersonDepartment',
    value: 'null',
  },
  {
    name: 'PersonHasOptedOutOfEmail',
    value: 'null',
  },
  {
    name: 'PersonLastCURequestDate',
    value: 'null',
  },
  {
    name: 'PersonLastCUUpdateDate',
    value: 'null',
  },
  {
    name: 'PersonEmailBouncedReason',
    value: 'null',
  },
  {
    name: 'PersonEmailBouncedDate',
    value: 'null',
  },
  {
    name: 'Jigsaw',
    value: 'null',
  },
  {
    name: 'JigsawCompanyId',
    value: 'null',
  },
  {
    name: 'AccountSource',
    value: 'null',
  },
  {
    name: 'SicDesc',
    value: 'null',
  },
  {
    name: 'SalesforceCustomer',
    value: 'null',
  },
  {
    name: 'Active__c',
    value: 'null',
  },
  {
    name: 'Audi__c',
    value: 'null',
  },
  {
    name: 'Customer_Priority__c',
    value: 'null',
  },
  {
    name: 'Instalacion__c',
    value: 'null',
  },
  {
    name: 'NumberofLocations__c',
    value: 'null',
  },
  {
    name: 'SLAExpirationDate__c',
    value: 'null',
  },
  {
    name: 'SLASerialNumber__c',
    value: 'null',
  },
  {
    name: 'SLA__c',
    value: 'null',
  },
  {
    name: 'Skoda__c',
    value: 'null',
  },
  {
    name: 'UpsellOpportunity__c',
    value: 'null',
  },
  {
    name: 'Volkswagen__c',
    value: 'null',
  },
  {
    name: 'Dealer_Code_Vaesa__c',
    value: '0320Y',
  },
  {
    name: 'COD_Gender__c',
    value: 'null',
  },
  {
    name: 'ID_Person_DIM_IMP__c',
    value: 'null',
  },
  {
    name: 'LCV__c',
    value: 'null',
  },
  {
    name: 'LSSI__c',
    value: 'null',
  },
  {
    name: 'NUM_Phone_mobile__c',
    value: 'null',
  },
  {
    name: 'NUM_landline__c',
    value: 'null',
  },
  {
    name: 'PCK_Brand__c',
    value: 'null',
  },
  {
    name: 'Robinson__c',
    value: 'null',
  },
  {
    name: 'TXT_Country__c',
    value: 'null',
  },
  {
    name: 'TXT_Email__c',
    value: 'null',
  },
  {
    name: 'TXT_Location__c',
    value: 'BARCELONA',
  },
  {
    name: 'TXT_NIF_CIF__c',
    value: 'null',
  },
  {
    name: 'TXT_Postal_Code__c',
    value: '08040',
  },
  {
    name: 'TXT_Province__c',
    value: 'BARCELONA',
  },
  {
    name: 'TXT_natural_legal_person__c',
    value: 'null',
  },
  {
    name: 'Address_extra__c',
    value: 'null',
  },
  {
    name: 'Address_name__c',
    value: 'A  Zona Franca',
  },
  {
    name: 'Address_number__c',
    value: '51',
  },
  {
    name: 'Address_type__c',
    value: 'null',
  },
  {
    name: 'FORM_Full_Address__c',
    value: 'null',
  },
  {
    name: 'TXT_Code_Concession__c',
    value: '0320Y',
  },
  {
    name: 'TXT_Installation_Code__c',
    value: '0320Y',
  },
  {
    name: 'TXT_V_Code_Concession__c',
    value: '0320Y',
  },
  {
    name: 'TXT_V_Installation_Code__c',
    value: '0320Y',
  },
  {
    name: 'CHK_Lead_VN_Audi__c',
    value: 'null',
  },
  {
    name: 'CHK_Lead_VN_LCV__c',
    value: 'null',
  },
  {
    name: 'CHK_Lead_VN_SK__c',
    value: 'null',
  },
  {
    name: 'CHK_Lead_VN_VW__c',
    value: 'null',
  },
  {
    name: 'CHK_Lead_VO_Audi__c',
    value: 'null',
  },
  {
    name: 'CHK_Lead_VO_LCV__c',
    value: 'null',
  },
  {
    name: 'CHK_Lead_VO_SK__c',
    value: 'null',
  },
  {
    name: 'CHK_Lead_VO_VW__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VN_Audi__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VN_LCV__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VN_SK__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VN_VW__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VO_Audi__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VO_LCV__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VO_SK__c',
    value: 'null',
  },
  {
    name: 'CHK_Prospect_VO_VW__c',
    value: 'null',
  },
  {
    name: 'DAT_last_received_com_LCV__c',
    value: 'null',
  },
  {
    name: 'DAT_last_received_com_SK__c',
    value: 'null',
  },
  {
    name: 'DAT_last_received_com_VW__c',
    value: 'null',
  },
  {
    name: 'DAT_last_received_com__c',
    value: 'null',
  },
  {
    name: 'DAT_lead_vn_LCV__c',
    value: 'null',
  },
  {
    name: 'DAT_lead_vn_SK__c',
    value: 'null',
  },
  {
    name: 'DAT_lead_vn_VW__c',
    value: 'null',
  },
  {
    name: 'DAT_lead_vn__c',
    value: 'null',
  },
  {
    name: 'DAT_lead_vo_LCV__c',
    value: 'null',
  },
  {
    name: 'DAT_lead_vo_SK__c',
    value: 'null',
  },
  {
    name: 'DAT_lead_vo_VW__c',
    value: 'null',
  },
  {
    name: 'DAT_lead_vo__c',
    value: 'null',
  },
  {
    name: 'Desired_model_1_LCV__c',
    value: 'null',
  },
  {
    name: 'Desired_model_1_Skoda__c',
    value: 'null',
  },
  {
    name: 'Desired_model_1_VW__c',
    value: 'null',
  },
  {
    name: 'Desired_model_1__c',
    value: 'null',
  },
  {
    name: 'Desired_model_2_LCV__c',
    value: 'null',
  },
  {
    name: 'Desired_model_2_Skoda__c',
    value: 'null',
  },
  {
    name: 'Desired_model_2_VW__c',
    value: 'null',
  },
  {
    name: 'Desired_model_2__c',
    value: 'null',
  },
  {
    name: 'Last_comunication_campaign_LCV__c',
    value: 'null',
  },
  {
    name: 'Last_comunication_campaign_SK__c',
    value: 'null',
  },
  {
    name: 'Last_comunication_campaign_VW__c',
    value: 'null',
  },
  {
    name: 'Last_comunication_campaign__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_14_days_LCV__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_14_days_SK__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_14_days_VW__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_14_days__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_21_days_LCV__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_21_days_SK__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_21_days_VW__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_21_days__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_30_days_LCV__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_30_days_SK__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_30_days_VW__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_30_days__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_7_days_LCV__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_7_days_SK__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_7_days_VW__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_7_days__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_90_days_LCV__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_90_days_SK__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_90_days_VW__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_90_days__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_year_LCV__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_year_SK__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_year_VW__c',
    value: 'null',
  },
  {
    name: 'N_hits_last_year__c',
    value: 'null',
  },
  {
    name: 'Pissed_off_client_LCV__c',
    value: 'null',
  },
  {
    name: 'Pissed_off_client_Skoda__c',
    value: 'null',
  },
  {
    name: 'Pissed_off_client_VW__c',
    value: 'null',
  },
  {
    name: 'Pissed_off_client__c',
    value: 'null',
  },
  {
    name: 'driver_is_official_dealer_LCV__c',
    value: 'null',
  },
  {
    name: 'driver_is_official_dealer_SK__c',
    value: 'null',
  },
  {
    name: 'driver_is_official_dealer_VW__c',
    value: 'null',
  },
  {
    name: 'driver_is_official_dealer__c',
    value: 'null',
  },
  {
    name: 'driver_vn_act_SK__c',
    value: 'null',
  },
  {
    name: 'driver_vn_act_LCV__c',
    value: 'null',
  },
  {
    name: 'driver_vn_act_VW__c',
    value: 'null',
  },
  {
    name: 'driver_vn_act__c',
    value: 'null',
  },
  {
    name: 'driver_vn_nact_LCV__c',
    value: 'null',
  },
  {
    name: 'driver_vn_nact_Sk__c',
    value: 'null',
  },
  {
    name: 'driver_vn_nact_VW__c',
    value: 'null',
  },
  {
    name: 'driver_vn_nact__c',
    value: 'null',
  },
  {
    name: 'driver_vo_act_LCV__c',
    value: 'null',
  },
  {
    name: 'driver_vo_act_SK__c',
    value: 'null',
  },
  {
    name: 'driver_vo_act_VW__c',
    value: 'null',
  },
  {
    name: 'driver_vo_act__c',
    value: 'null',
  },
  {
    name: 'driver_vo_nact_LCV__c',
    value: 'null',
  },
  {
    name: 'driver_vo_nact_SK__c',
    value: 'null',
  },
  {
    name: 'driver_vo_nact_VW__c',
    value: 'null',
  },
  {
    name: 'driver_vo_nact__c',
    value: 'null',
  },
  {
    name: 'owner_is_offical_dealer_LCV__c',
    value: 'null',
  },
  {
    name: 'owner_is_offical_dealer_SK__c',
    value: 'null',
  },
  {
    name: 'owner_is_offical_dealer_VW__c',
    value: 'null',
  },
  {
    name: 'owner_is_offical_dealer__c',
    value: 'null',
  },
  {
    name: 'owner_vn_act_LCV__c',
    value: 'null',
  },
  {
    name: 'owner_vn_act_SK__c',
    value: 'null',
  },
  {
    name: 'owner_vn_act_VW__c',
    value: 'null',
  },
  {
    name: 'owner_vn_act__c',
    value: 'null',
  },
  {
    name: 'owner_vn_nact_LCV__c',
    value: 'null',
  },
  {
    name: 'owner_vn_nact_SK__c',
    value: 'null',
  },
  {
    name: 'owner_vn_nact_VW__c',
    value: 'null',
  },
  {
    name: 'owner_vn_nact__c',
    value: 'null',
  },
  {
    name: 'owner_vo_act_LCV__c',
    value: 'null',
  },
  {
    name: 'owner_vo_act_SK__c',
    value: 'null',
  },
  {
    name: 'owner_vo_act_VW__c',
    value: 'null',
  },
  {
    name: 'owner_vo_act__c',
    value: 'null',
  },
  {
    name: 'owner_vo_nact_LCV__c',
    value: 'null',
  },
  {
    name: 'owner_vo_nact_SK__c',
    value: 'null',
  },
  {
    name: 'owner_vo_nact_VW__c',
    value: 'null',
  },
  {
    name: 'owner_vo_nact__c',
    value: 'null',
  },
  {
    name: 'COD_province__c',
    value: '17',
  },
  {
    name: 'Manual_marker__c',
    value: 'null',
  },
  {
    name: 'TXT_V_Address__c',
    value: 'POL.IND. ZONA FRANCA, CALLE A 51',
  },
  {
    name: 'Afected_EA_189__c',
    value: 'null',
  },
  {
    name: 'DAT_prospect_vn_LCV__c',
    value: 'null',
  },
  {
    name: 'DAT_prospect_vn_SK__c',
    value: 'null',
  },
  {
    name: 'DAT_prospect_vn_VW__c',
    value: 'null',
  },
  {
    name: 'DAT_prospect_vn__c',
    value: 'null',
  },
  {
    name: 'DAT_prospect_vo_LCV__c',
    value: 'null',
  },
  {
    name: 'DAT_prospect_vo_SK__c',
    value: 'null',
  },
  {
    name: 'DAT_prospect_vo_VW__c',
    value: 'null',
  },
  {
    name: 'DAT_prospect_vo__c',
    value: 'null',
  },
  {
    name: 'Legal_risk_EA_189__c',
    value: 'null',
  },
  {
    name: 'Age__c',
    value: 'null',
  },
  {
    name: 'CHK_Current_Afected_EA_189__c',
    value: 'null',
  },
  {
    name: 'Document_type__c',
    value: 'null',
  },
  {
    name: 'Repaired_EA_189__c',
    value: 'null',
  },
  {
    name: 'LKP_After_Sales_manager__c',
    value: 'null',
  },
  {
    name: 'LKP_Sales_manager__c',
    value: 'null',
  },
  {
    name: 'Dealer_Code_Imaweb__c',
    value: 'null',
  },
  {
    name: 'ID_Climapro_Installation_Audi__c',
    value: 'null',
  },
  {
    name: 'ID_Climapro_Installation_LCV__c',
    value: 'null',
  },
  {
    name: 'ID_Climapro_Installation_SK__c',
    value: 'null',
  },
  {
    name: 'ID_Climapro_Installation_VW__c',
    value: 'null',
  },
  {
    name: 'Latitude__c',
    value: "41.337717",
  },
  {
    name: 'Longitude__c',
    value: "2.141197",
  },
  {
    name: 'NUM_V_Phone__c',
    value: 'null',
  },
  {
    name: 'Preferred_Installation_Aftersale_Audi__c',
    value: 'null',
  },
  {
    name: 'Preferred_Installation_Aftersale_LCV__c',
    value: 'null',
  },
  {
    name: 'Preferred_Installation_Aftersale_SK__c',
    value: 'null',
  },
  {
    name: 'Preferred_Installation_Aftersale_VW__c',
    value: 'null',
  },
  {
    name: 'Preferred_Installation_Sale_Audi__c',
    value: 'null',
  },
  {
    name: 'Preferred_Installation_Sale_LCV__c',
    value: 'null',
  },
  {
    name: 'Preferred_Installation_Sale_SK__c',
    value: 'null',
  },
  {
    name: 'Preferred_Installation_Sale_VW__c',
    value: 'null',
  },
  {
    name: 'TXT_KVPS_code__c',
    value: '08004',
  },
  {
    name: 'TXT_PV_Address__c',
    value: 'null',
  },
  {
    name: 'TXT_V_KVPS_code__c',
    value: '08004',
  },
  {
    name: 'TXT_V_Postal_Code__c',
    value: '08040',
  },
  {
    name: 'Cod_installation_Imaweb__c',
    value: 'null',
  },
  {
    name: 'FLAG_Baja__c',
    value: 'null',
  },
  {
    name: 'COD_Brand_Imaweb__c',
    value: 'null',
  },
  {
    name: 'NORM_Address_name__c',
    value: 'null',
  },
  {
    name: 'NORM_Address_number__c',
    value: 'null',
  },
  {
    name: 'NORM_Location__c',
    value: 'null',
  },
  {
    name: 'NORM_Postal_Code__c',
    value: 'null',
  },
  {
    name: 'TXT_Business_Group_CIF__c',
    value: 'null',
  },
  {
    name: 'TXT_Description_Concession__c',
    value: 'null',
  },
  {
    name: 'TXT_Email_Contact_Concession__c',
    value: 'null',
  },
  {
    name: 'TXT_Type_Concession__c',
    value: 'null',
  },
  {
    name: 'ID_Climapro_Concession__c',
    value: 'null',
  },
  {
    name: 'TXT_V_Email__c',
    value: 'null',
  },
  {
    name: "aceDealer__c",
    value: "false"
  },
  {
    name: "MPCK_Instalation_Type__c",
    value: "1;2;10"
  },
  {
    name: "FRML_TXT_codVentaProvincia__c",
    value: "08"
  },
  {
    name: 'Latitude_V__c',
    value: "41.337717",
  },
  {
    name: 'Longitude_V__c',
    value: "2.141197",
  },
  {
    name: 'TXT_V_Location__c',
    value: 'BARCELONA',
  },
  {
    name: 'COD_V_province__c',
    value: 'null',
  },
  {
    name: 'Cod_V_installation_Imaweb__c',
    value: 'null',
  },
  {
    name: 'FLAG_V_Baja__c',
    value: 'null',
  },
  {
    name: 'Dealer_V_Code_Vaesa__c',
    value: 'null',
  },
  {
    name: 'ID_V_Climapro_Installation_Audi__c',
    value: 'null',
  },
  {
    name: 'ID_V_Climapro_Installation_LCV__c',
    value: 'null',
  },
  {
    name: 'ID_V_Climapro_Installation_SK__c',
    value: 'null',
  },
  {
    name: 'ID_V_Climapro_Installation_VW__c',
    value: 'null',
  },
  {
    name: 'Dealer_V_Code_Imaweb__c',
    value: 'null',
  },
  {
    name: 'TXT_Area_Manager_V__c',
    value: 'null',
  },
  {
    name: 'TXT_Email_Zone_Manager__c',
    value: 'null',
  },
  {
    name: 'External_ID_Otros__c',
    value: 'null',
  },
  {
    name: 'TXT_Activity__c',
    value: 'null',
  },
  {
    name: 'TXT_Last_Name_1_Manager__c',
    value: 'null',
  },
  {
    name: 'TXT_Surname_2_Manager__c',
    value: 'null',
  },
  {
    name: 'TXT_Area_code__c',
    value: 'null',
  },
  {
    name: 'TXT_Description_Installation__c',
    value: 'null',
  },
  {
    name: 'TXT_prior_appointment__c',
    value: 'null',
  },
  {
    name: 'TXT_Email_After_Sales_PV__c',
    value: 'null',
  },
  {
    name: 'TXT_Email_Manager__c',
    value: 'null',
  },
  {
    name: 'TXT_V_Email_more_info__c',
    value: 'null',
  },
  {
    name: 'TXT_Email_Sender__c',
    value: 'null',
  },
  {
    name: 'DATE_Home__c',
    value: 'null',
  },
  {
    name: 'DATE_Date_Last_Updated__c',
    value: 'null',
  },
  {
    name: 'CHK_Replacement_fleet__c',
    value: 'null',
  },
  {
    name: 'TXT_Installation_Data_Name__c',
    value: 'null',
  },
  {
    name: 'TXT_Manager_Name__c',
    value: 'null',
  },
  {
    name: 'TXT_Observations_Schedule__c',
    value: 'null',
  },
  {
    name: 'TXT_V_Observations_Schedule__c',
    value: 'null',
  },
  {
    name: 'CHK_V_Parking__c',
    value: 'null',
  },
  {
    name: 'TXT_business_name__c',
    value: 'null',
  },
  {
    name: 'TXT_V_business_name__c',
    value: 'null',
  },
  {
    name: 'Adblue_price__c',
    value: 'null',
  },
  {
    name: 'Anyo_matriculacion_otro_vehiculo__c',
    value: 'null',
  },
  {
    name: 'CHK_Audi_CAM__c',
    value: 'null',
  },
  {
    name: 'CHK_Audi_Lounge__c',
    value: 'null',
  },
  {
    name: 'CHK_Audi_Sport_Exclusive_Service__c',
    value: 'null',
  },
  {
    name: 'CHK_Audi_Sport__c',
    value: 'null',
  },
  {
    name: 'CHK_California_roof_repairing__c',
    value: 'null',
  },
  {
    name: 'CHK_Children_zone__c',
    value: 'null',
  },
  {
    name: 'CHK_Client_Shutle__c',
    value: 'null',
  },
  {
    name: 'CHK_Compressed_Natural_Gas_Charger__c',
    value: 'null',
  },
  {
    name: 'CHK_Crafter_Elevator__c',
    value: 'null',
  },
  {
    name: 'CHK_DRM__c',
    value: 'null',
  },
  {
    name: 'CHK_Delivery_Service__c',
    value: 'null',
  },
  {
    name: 'CHK_Do_Not_Contact_AU__c',
    value: 'null',
  },
  {
    name: 'CHK_Do_Not_Contact__c',
    value: 'null',
  },
  {
    name: 'CHK_Dont_send_Com__c',
    value: 'null',
  },
  {
    name: 'CHK_Elite_Body__c',
    value: 'null',
  },
  {
    name: 'CHK_Email_OFF__c',
    value: 'null',
  },
  {
    name: 'CHK_Exclusive_Service_VW__c',
    value: 'null',
  },
  {
    name: 'CHK_Exclusive_Service__c',
    value: 'null',
  },
  {
    name: 'CHK_Fast_Service__c',
    value: 'null',
  },
  {
    name: 'CHK_Free_Wifi__c',
    value: 'null',
  },
  {
    name: 'CHK_HVT_repairs__c',
    value: 'null',
  },
  {
    name: 'CHK_Handicapped__c',
    value: 'null',
  },
  {
    name: 'CHK_Integral_Body_Program__c',
    value: 'null',
  },
  {
    name: 'CHK_Is_Closed_Days__c',
    value: 'null',
  },
  {
    name: 'CHK_Oficial_Audi_Prestige_Service__c',
    value: 'null',
  },
  {
    name: 'CHK_PCI__c',
    value: 'null',
  },
  {
    name: 'CHK_Parking__c',
    value: 'null',
  },
  {
    name: 'CHK_SUM_Diff_Schedule_Vehicle_Recep__c',
    value: 'null',
  },
  {
    name: 'CHK_Service_Plus_Installation__c',
    value: 'null',
  },
  {
    name: 'CHK_TLMK__c',
    value: 'null',
  },
  {
    name: 'CHK_V_Children_zone__c',
    value: 'null',
  },
  {
    name: 'CHK_V_Exclusive_Service_VW__c',
    value: 'null',
  },
  {
    name: 'CHK_V_Free_Wifi__c',
    value: 'null',
  },
  {
    name: 'CHK_V_Handicapped__c',
    value: 'null',
  },
  {
    name: 'CHK_V_Waiting_zone__c',
    value: 'null',
  },
  {
    name: 'CHK_Waiting_zone__c',
    value: 'null',
  },
  {
    name: 'CHK_Working_Zone_Waiting_Clients__c',
    value: 'null',
  },
  {
    name: 'CHK_e_Hibrids__c',
    value: 'null',
  },
  {
    name: 'CHK_e_dealers__c',
    value: 'null',
  },
  {
    name: 'CHK_e_loaders__c',
    value: 'null',
  },
  {
    name: 'CampaignID__c',
    value: 'null',
  },
  {
    name: 'Contactable_after_sales__c',
    value: 'null',
  },
  {
    name: 'Contactable_sales__c',
    value: 'null',
  },
  {
    name: 'Customer_type__c',
    value: 'null',
  },
  {
    name: 'DATE_Date_Photo__c',
    value: 'null',
  },
  {
    name: 'DATE_End_Date_SUM__c',
    value: 'null',
  },
  {
    name: 'DATE_End_Date_Valid__c',
    value: 'null',
  },
  {
    name: 'DATE_Entry_Date__c',
    value: 'null',
  },
  {
    name: 'DATE_Ini_Date_SUM__c',
    value: 'null',
  },
  {
    name: 'DATE_Termination_Date__c',
    value: 'null',
  },
  {
    name: 'DATE_View_End_Period__c',
    value: 'null',
  },
  {
    name: 'DATE_View_Start_Period__c',
    value: 'null',
  },
  {
    name: 'DAT_Customer_registration_SLI__c',
    value: 'null',
  },
  {
    name: 'DAT_last_vehicle__c',
    value: 'null',
  },
  {
    name: 'Email_Business_Group__c',
    value: 'null',
  },
  {
    name: 'Es_Autonomo_VN__c',
    value: 'null',
  },
  {
    name: 'Es_Gran_empresa_propiedad_VN__c',
    value: 'null',
  },
  {
    name: 'Es_Pymes_propiedad_VN__c',
    value: 'null',
  },
  {
    name: 'Es_Pymes_renting_VN__c',
    value: 'null',
  },
  {
    name: 'Es_RaC_VN__c',
    value: 'null',
  },
  {
    name: 'Es_VO_no_particular__c',
    value: 'null',
  },
  {
    name: 'Es_gran_empresa_renting_VN__c',
    value: 'null',
  },
  {
    name: 'Es_indefinido_VN__c',
    value: 'null',
  },
  {
    name: 'Es_no_identificado_VN__c',
    value: 'null',
  },
  {
    name: 'Es_particular_VN__c',
    value: 'null',
  },
  {
    name: 'Es_resto_VN__c',
    value: 'null',
  },
  {
    name: 'Excesive_waiting__c',
    value: 'null',
  },
  {
    name: 'FRML_TXT_DPV__c',
    value: 'null',
  },
  {
    name: 'FRML_TXT_Image_Sign__c',
    value: 'null',
  },
  {
    name: 'Family_unit__c',
    value: 'null',
  },
  {
    name: 'FormID__c',
    value: 'null',
  },
  {
    name: 'Home_another_vehicle_brand__c',
    value: 'null',
  },
  {
    name: 'ID_External_ID__c',
    value: 'null',
  },
  {
    name: 'ID_Vehicle_EID__c',
    value: 'null',
  },
  {
    name: 'Id_Instalacion__c',
    value: 'null',
  },
  {
    name: 'Id_Propietario_PV_AU__c',
    value: 'null',
  },
  {
    name: 'Id_Propietario_PV_LCV__c',
    value: 'null',
  },
  {
    name: 'Id_Propietario_PV_SK__c',
    value: 'null',
  },
  {
    name: 'Id_Propietario_PV_VW__c',
    value: 'null',
  },
  {
    name: 'Id_Propietario_V_AU__c',
    value: 'null',
  },
  {
    name: 'Id_Propietario_V_LCV__c',
    value: 'null',
  },
  {
    name: 'Id_Propietario_V_SK__c',
    value: 'null',
  },
  {
    name: 'Id_Propietario_V_VW__c',
    value: 'null',
  },
  {
    name: 'Informat_cession_aceptance__c',
    value: 'null',
  },
  {
    name: 'Lead_vn_act__c',
    value: 'null',
  },
  {
    name: 'Lead_vn_nact__c',
    value: 'null',
  },
  {
    name: 'Lead_vo_act__c',
    value: 'null',
  },
  {
    name: 'Lead_vo_nact__c',
    value: 'null',
  },
  {
    name: 'Multivehicle__c',
    value: 'null',
  },
  {
    name: 'NUM_Bodywork_Price_Hour__c',
    value: 'null',
  },
  {
    name: 'NUM_Business_Group_Id__c',
    value: 'null',
  },
  {
    name: 'NUM_Fax_Business_Group__c',
    value: 'null',
  },
  {
    name: 'NUM_Id_Operation__c',
    value: 'null',
  },
  {
    name: 'NUM_Mechanical_Price_Hour__c',
    value: 'null',
  },
  {
    name: 'NUM_Number_Photo__c',
    value: 'null',
  },
  {
    name: 'NUM_Oil_Liter_Price__c',
    value: 'null',
  },
  {
    name: 'NUM_Phone_Business_Group__c',
    value: 'null',
  },
  {
    name: 'NUM_Substitution_vehicle__c',
    value: 'null',
  },
  {
    name: 'NUM_Work_Price__c',
    value: 'null',
  },
  {
    name: 'NUM_chassis_number__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_FRI_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_FRI_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_FRI_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_FRI_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_MON_THUR_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_MON_THUR_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_MON_THUR_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_MON_THUR_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_SAT_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_SAT_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_SAT_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_SAT_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_SUN_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_SUN_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_SUN_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_SUM_TIME_SUN_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_Social_Network__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_FRI_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_FRI_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_FRI_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_FRI_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_MON_THUR_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_MON_THUR_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_MON_THUR_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_MON_THUR_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_SAT_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_SAT_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_SAT_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_SAT_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_SUN_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_SUN_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_SUN_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_SUM_TIME_SUN_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_Social_Network__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_FRI_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_FRI_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_FRI_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_FRI_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_MON_THUR_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_MON_THUR_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_MON_THUR_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_MON_THUR_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_SAT_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_SAT_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_SAT_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_SAT_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_SUN_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_SUN_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_SUN_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_V_WIN_TIME_SUN_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_FRI_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_FRI_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_FRI_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_FRI_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_MON_THUR_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_MON_THUR_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_MON_THUR_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_MON_THUR_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_SAT_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_SAT_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_SAT_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_SAT_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_SUN_AFT_END__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_SUN_AFT_STA__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_SUN_MOR_END__c',
    value: 'null',
  },
  {
    name: 'PCK_WIN_TIME_SUN_MOR_STA__c',
    value: 'null',
  },
  {
    name: 'PKL_Exclusion_Motive_PV_AU__c',
    value: 'null',
  },
  {
    name: 'PKL_Exclusion_Motive_PersonAccount__c',
    value: 'null',
  },
  {
    name: 'Profession__c',
    value: 'null',
  },
  {
    name: 'Standard_contactable_universe_LCV__c',
    value: 'null',
  },
  {
    name: 'Standard_contactable_universe_Skoda__c',
    value: 'null',
  },
  {
    name: 'Standard_contactable_universe_VW__c',
    value: 'null',
  },
  {
    name: 'Standard_contactable_universe__c',
    value: 'null',
  },
  {
    name: 'TXT_Accept_Bases__c',
    value: 'null',
  },
  {
    name: 'TXT_Area_Manager_PV__c',
    value: 'null',
  },
  {
    name: 'TXT_Business_Group_Name__c',
    value: 'null',
  },
  {
    name: 'TXT_BussinesGroup__c',
    value: 'null',
  },
  {
    name: 'TXT_Comments_Exclusion__c',
    value: 'null',
  },
  {
    name: 'TXT_Context_ID__c',
    value: 'null',
  },
  {
    name: 'TXT_Contract_brand__c',
    value: 'null',
  },
  {
    name: 'TXT_DPV_Name__c',
    value: 'null',
  },
  {
    name: 'TXT_DPV_gender__c',
    value: 'null',
  },
  {
    name: 'TXT_DPV_treatment__c',
    value: 'null',
  },
  {
    name: 'TXT_Description_Validity__c',
    value: 'null',
  },
  {
    name: 'TXT_Email_Area_Manager__c',
    value: 'null',
  },
  {
    name: 'TXT_Email_CCDPV__c',
    value: 'null',
  },
  {
    name: 'TXT_Email_DPV__c',
    value: 'null',
  },
  {
    name: 'TXT_Email_DV__c',
    value: 'null',
  },
  {
    name: 'TXT_Email_Online_Appointment_PV__c',
    value: 'null',
  },
  {
    name: 'TXT_Email_Online_Appointment_V__c',
    value: 'null',
  },
  {
    name: 'TXT_Email_Zone_Manager_V__c',
    value: 'null',
  },
  {
    name: 'TXT_Facebook__c',
    value: 'null',
  },
  {
    name: 'TXT_Instagram__c',
    value: 'null',
  },
  {
    name: 'TXT_Last_Name_1_Area_Manager_PV__c',
    value: 'null',
  },
  {
    name: 'TXT_Last_Name_1_DPV__c',
    value: 'null',
  },
  {
    name: 'TXT_Last_Name_1_V__c',
    value: 'null',
  },
  {
    name: 'TXT_Last_name_2_DPV__c',
    value: 'null',
  },
  {
    name: 'TXT_Last_name_2_V__c',
    value: 'null',
  },
  {
    name: 'TXT_Manager_Name_PV__c',
    value: 'null',
  },
  {
    name: 'TXT_Name__c',
    value: 'null',
  },
  {
    name: 'TXT_Observations__c',
    value: 'null',
  },
  {
    name: 'TXT_Official_Web_PV__c',
    value: 'null',
  },
  {
    name: 'TXT_Official_Web_V__c',
    value: 'null',
  },
  {
    name: 'TXT_Position__c',
    value: 'null',
  },
  {
    name: 'TXT_Profile__c',
    value: 'null',
  },
  {
    name: 'TXT_Surname_2_Area_Manager_PV__c',
    value: 'null',
  },
  {
    name: 'TXT_Tradename_BusinessGroup__c',
    value: 'null',
  },
  {
    name: 'TXT_Tradename__c',
    value: 'null',
  },
  {
    name: 'TXT_Twitter__c',
    value: 'null',
  },
  {
    name: 'TXT_V_Facebook__c',
    value: 'null',
  },
  {
    name: 'TXT_V_Instagram__c',
    value: 'null',
  },
  {
    name: 'TXT_V_Name__c',
    value: 'null',
  },
  {
    name: 'TXT_V_Tradename__c',
    value: 'null',
  },
  {
    name: 'TXT_V_Twitter__c',
    value: 'null',
  },
  {
    name: 'TXT_V_Web__c',
    value: 'null',
  },
  {
    name: 'TXT_V_gender__c',
    value: 'null',
  },
  {
    name: 'TXT_Web__c',
    value: 'null',
  },
  {
    name: 'TXT_number_plate__c',
    value: 'null',
  },
  {
    name: 'URL_Business_Group_Website__c',
    value: 'null',
  },
  {
    name: 'Unsuscribed__c',
    value: 'null',
  },
  {
    name: 'Use_type__c',
    value: 'null',
  },
  {
    name: 'cond_is_official_dealer_LCV__c',
    value: 'null',
  },
  {
    name: 'cond_is_official_dealer_SK__c',
    value: 'null',
  },
  {
    name: 'cond_is_official_dealer_VW__c',
    value: 'null',
  },
  {
    name: 'cond_is_official_dealer__c',
    value: 'null',
  },
  {
    name: 'cond_vn_act_SK__c',
    value: 'null',
  },
  {
    name: 'prospect_vn_act__c',
    value: 'null',
  },
  {
    name: 'prospect_vn_nact__c',
    value: 'null',
  },
  {
    name: 'prospect_vo_act__c',
    value: 'null',
  },
  {
    name: 'prospect_vo_nact__c',
    value: 'null',
  },
  {
    name: 'et4ae5__HasOptedOutOfMobile__pc',
    value: 'null',
  },
  {
    name: 'et4ae5__Mobile_Country_Code__pc',
    value: 'null',
  },
  {
    name: 'IDClimapro__pc',
    value: 'null',
  },
];

import {
  Container,
  ContainerPadding,
  CTA,
  styled,
  Text,
  ThemeProvider,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { Filtros, NewMap, useMapGeneralController, useOneFormContext } from 'src/feature-app';
import { Close, Locate } from 'src/icons-core-imports';
import { CarouselWidget, CTAMapa, CTAServices, ViewWidgetMapaProps } from 'src/widgets';

//Mobile styled components

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const WrapperCTAMapa = styled.div`
  z-index: 20;
  width: 100%;
  padding-top: 12px;
  position: absolute;

  & button {
    background: #ffffff;
  }
`;

const FilterMobileNavbar = styled.div`
  position: relative;
  top: 0;
  width: 100vw;
  padding: 0px 20px;
  z-index: 20;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
`;

interface VerMasYCarouselPositionProps {
  translateCarousel: boolean;
  userIsGeoLocated: boolean;
}

const VerMasYCarouselPosition = styled.div<VerMasYCarouselPositionProps>`
  position: absolute;
  bottom: 38px;
  height: ${(props) => (props.userIsGeoLocated ? '216px' : '160px')};
  width: 100%;
  transform: ${(props) => (props.translateCarousel ? 'translateY(116px)' : null)};
  @media all and (min-width: 560px) {
    height: ${(props) => (props.userIsGeoLocated ? '244px' : '184px')};
  }
`;
const SoloCardWrapper = styled.div`
  background-color: #ffffff;
  bottom: 8px;
  height: fit-content;
  position: absolute;
  margin: 0 var(--size-grid002);
  width: var(--size-grid020);

  .box-card-wrapper {
    border: none;
    margin-top: 0;
  }
`;
const CTASwrapper = styled.div`
  padding-right: 20px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const VerMasTalleresCTA = styled.div`
  height: 32px;
  padding-right: 16px;
  padding-left: 16px;
  background-color: #ffffff;
  border: 1px solid #001e50;
  border-radius: 500px;
  color: #001e50;
  display: flex;
  align-items: center;
  width: 100px;
`;
const ControllerBackground = styled.div`
  border-radius: 100%;
  background-color: #ffffff;
  height: 44px;
  width: 44px;
  margin-bottom: 12px;
  button {
    border: 1px solid #001e50;
  }
`;

interface CloseButtonProps {
  isTabsClose?: boolean;
}
const CloseButtonWrapper = styled.div<CloseButtonProps>`
  position: absolute;
  padding-bottom: ${(props) => (props.isTabsClose ? '12px' : null)};
  /* background-color: ${(props) => (props.isTabsClose ? '#FFFFFF' : '#FFFFFF')}; */
  background-color: #ffffff;
  width: 100%;
  padding-top: 12px;
  padding-left: 12px;
  z-index: 4;
  @media screen and (min-width: 560px) {
    left: 20px;
  }
  button {
    color: ${(props) => (props.isTabsClose ? '#001E50' : null)};
  }
`;
const listIcon = require('../../feature-app/NewMap/assets/filter-ic_list.svg');

export const ViewMobileWidgetMapa = (props: ViewWidgetMapaProps) => {
  const { handleSeleccionarOnClick, renderPoints, pointsToList, CTAServicesProps, CTAMapaProps } = props;
  const { carPickupSelected, replacementCarSelected } = CTAServicesProps;

  const { handlePreviousStep } = useOneFormContext();

  const { centerInGeoLocatedCenter, userIsGeoLocated, searchIsDealer } = useMapGeneralController();

  //controladores de vista

  const [showFiltros, setShowFiltros] = useState<boolean>(false);
  const [showTalleres, setShowTalleres] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(true);
  const [translateCarousel, setTranselateCarousel] = useState<boolean>(false);

  //otros
  const [gelocalizationActive, setGelocalizationActive] = useState<boolean>(false);

  useEffect(() => {
    if (userIsGeoLocated) {
      setGelocalizationActive(true);
    }
  }, [userIsGeoLocated]);

  const handleShowFiltros = () => {
    setShowFiltros(!showFiltros);
    setShowTalleres(false);
  };
  const handleShowTalleres = () => {
    setShowTalleres(!showTalleres);
    setShowFiltros(false);
  };

  useEffect(() => {
    if (showFiltros === false && showTalleres === false) {
      setShowMap(true);
    } else {
      setShowMap(false);
    }
  }, [showFiltros, showTalleres]);

  return (
    <div className="mobile-map-wrapper">
      {showMap ? (
        <>
          <ThemeProvider theme="main">
            <FilterMobileNavbar
              className="filtros-navbar"
              onClick={(e) => {
                e.preventDefault();
                setTranselateCarousel(false);
              }}
              onTouchStart={(e) => {
                setTranselateCarousel(false);
              }}
            >
              <CTAServices {...CTAServicesProps} />
            </FilterMobileNavbar>
          </ThemeProvider>
          <Wrapper>
            <WrapperCTAMapa>
              <CTAMapa {...CTAMapaProps} />
            </WrapperCTAMapa>
            <div
              style={{
                height: '100%',
                backgroundColor: 'grey',
                position: 'relative',
                top: '0',
                width: '100%',
              }}
            >
              <NewMap
                searchIsDealer={searchIsDealer}
                setTranslateCarousel={setTranselateCarousel}
                translateCarousel={translateCarousel}
                carPickupSelected={carPickupSelected}
                replacementCarSelected={replacementCarSelected}
                isWidget
              />
            </div>
          </Wrapper>

          {pointsToList ? (
            searchIsDealer ? (
              <SoloCardWrapper>
                {renderPoints(pointsToList, carPickupSelected, replacementCarSelected, true)}
              </SoloCardWrapper>
            ) : (
              <VerMasYCarouselPosition
                className="ver-mas-carousel-position"
                translateCarousel={translateCarousel}
                onClick={(e) => {
                  e.preventDefault();
                  setTranselateCarousel(false);
                }}
                onTouchStart={(e) => {
                  e.preventDefault();
                  setTranselateCarousel(false);
                }}
                userIsGeoLocated={userIsGeoLocated}
              >
                <ThemeProvider theme="main">
                  <CTASwrapper>
                    {gelocalizationActive ? (
                      <ControllerBackground
                        onTouchStart={(e) => {
                          e.preventDefault();
                          centerInGeoLocatedCenter();
                          e.stopPropagation();
                        }}
                      >
                        <CTA
                          tag="button"
                          emphasis="secondary"
                          onClick={(e) => {
                            e.preventDefault();
                            centerInGeoLocatedCenter();
                            e.stopPropagation();
                          }}
                          ariaLabel="aria label"
                          icon={<Locate variant="small" />}
                        />
                      </ControllerBackground>
                    ) : null}
                    <VerMasTalleresCTA
                      onTouchStart={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleShowTalleres();
                        setTranselateCarousel(false);
                      }}
                    >
                      <img src={listIcon} style={{ marginRight: '4px' }} />
                      <Text appearance={TokenTextAppearance.label150} staticSize>
                        Ver lista
                      </Text>
                    </VerMasTalleresCTA>
                  </CTASwrapper>
                </ThemeProvider>

                {/* Aunque en principio no debería devolver nunca sólo un resultado, dejo el siguiente
                  render condicional por is acaso */}
                {pointsToList.length === 1 ? (
                  <SoloCardWrapper>
                    {renderPoints(pointsToList, carPickupSelected, replacementCarSelected, true)}
                  </SoloCardWrapper>
                ) : (
                  <CarouselWidget
                    carPickupSelected={carPickupSelected}
                    replacementCarSelected={replacementCarSelected}
                    handleSeleccionarOnClick={handleSeleccionarOnClick}
                    pointsToList={pointsToList}
                  />
                )}
              </VerMasYCarouselPosition>
            )
          ) : null}
        </>
      ) : (
        <>
          {showTalleres ? (
            <div
              style={{
                height: '100%',
                minHeight: '100vh',
                width: '100%',
                backgroundColor: '#FFFFFF',
              }}
            >
              <CloseButtonWrapper isTabsClose={true}>
                <CTA
                  tag="button"
                  emphasis="tertiary"
                  size="small"
                  onClick={(e: any) => {
                    e.preventDefault();
                    handleShowTalleres();
                  }}
                  ariaLabel="aria label"
                  icon={<Close />}
                />
              </CloseButtonWrapper>
              <Container
                padding={{
                  bottom: ContainerPadding.static500,
                  top: ContainerPadding.static520,
                  right: ContainerPadding.static300,
                  left: ContainerPadding.static300,
                }}
                horizontalAlign={'center'}
                stretchContent
                wrap={'always'}
              >
                <CTAServices {...CTAServicesProps} />

                {renderPoints(pointsToList, carPickupSelected, replacementCarSelected, false)}
              </Container>
            </div>
          ) : showFiltros ? (
            <ThemeProvider theme="main">
              <div
                style={{ minHeight: '100vh', height: '100%', width: '100%', backgroundColor: ' white' }}
                className="filtros-wrapper"
              >
                <CloseButtonWrapper style={{ paddingBottom: '12px' }}>
                  <CTA
                    tag="button"
                    emphasis="tertiary"
                    size="small"
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleShowFiltros();
                    }}
                    ariaLabel="aria label"
                    icon={<Close />}
                  />
                </CloseButtonWrapper>
                <Filtros
                  closeFiltros={() => handleShowFiltros()}
                  showFiltros={showFiltros}
                  setShowFiltros={setShowFiltros}
                  onlySuggest={true}
                  concesionario={true}
                />
              </div>
            </ThemeProvider>
          ) : null}
        </>
      )}
    </div>
  );
};

import React, { useState } from 'react';
import {
  Container,
  ContainerGutter,
  ContainerPadding,
  styled,
  InputAppearance,
} from '@volkswagen-onehub/components-core';
import { TextAreaExtended } from 'src/components';
import { useOneFormContext } from 'src/feature-app';
import { useFormContext } from 'react-hook-form';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
interface DetalleMotivoProps {
  hideCTA?: boolean;
}

export function Mensaje(props: DetalleMotivoProps) {
  const { hideCTA } = props;
  const { register } = useFormContext();
  const { handleNextStep } = useOneFormContext();
  const [currentValue, setCurrentValue] = useState('');
  const trackingManager = useTrackingManager();

  const handleChange = (e: any) => {
    const aux = e.currentTarget.value;
    e.preventDefault();
    setCurrentValue(aux);
  };

  return (
    <Container
      gutter={ContainerGutter.static400}
      padding={{ top: ContainerPadding.static500 }}
      wrap={'always'}
      horizontalAlign={'flex-start'}
    >
      <TextAreaExtended
        label="Escríbelo aquí"
        name="messageText"
        onChange={(e: any) => handleChange(e)}
        appearance={InputAppearance.Default}
        isFloating={false}
        innerRef={register({
          required: {
            value: true,
            message: 'Requerido',
          },
        })}
        placeholder="Por ejemplo: Feliz yo de tenerte, mamá <3. Un solo día se te queda corto. Te quiero hoy y los 364 restantes."
        rows={3}
      />
    </Container>
  );
}

import { styled } from '@volkswagen-onehub/components-core';
import { CloseHandleV2, LayerHandleV2 } from '@volkswagen-onehub/layer-manager';
import React, { useEffect, useState } from 'react';
import { useSelector, useStore } from 'react-redux';
import { OneFormProvider, useFeatureAppConfig } from 'src/feature-app';
import { CloseComponent, Navigation } from 'src/feature-app/Screen';
import { OneFormState } from 'src/types';
import { ThankYouComponent } from 'src/feature-app/ThankYouComponent';
import { useGetBoundingClientRect } from 'src/feature-app/hooks';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';

interface ScreenControllerProps {
  closeLayerCallback?: CloseHandleV2<any, any>;
  selectedJourney: JSX.Element;
  handleAreYouSureLayer: () => LayerHandleV2<{}>;
  notALayer?: boolean;
  formType?: 'layer' | 'inPage' | 'module';
}

const ThankYouWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  div {
    height: auto;
    align-items: center;
  }
`;

export function ScreenController(props: ScreenControllerProps) {
  const { closeLayerCallback, selectedJourney, handleAreYouSureLayer, formType } = props;

  const { formInfo, formData } = useSelector((state: OneFormState) => state);
  const { multiStepScreenIndex, screenIndex, triggerExitAnimation, showFinalScreen, lastStep, step } = formInfo;
  const [formClicked, setFormClicked] = useState(false);
  const [colorTheme, setTheme] = useState(null);
  const config = useFeatureAppConfig();

  const trigger = useFeatureAppConfig().trigger;

  //Quitar navegación en pantallas de error de carga del form formato full-screen

  const [formStarted, setFormStarted] = useState(false);
  useEffect(() => {
    if (formInfo.formStarted === false) {
      setFormStarted(false);
    } else {
      setFormStarted(true);
    }
  }, [formInfo]);

  //mapa no compra, si en algun otro form se usa y no coinciden el screenIndex o el multiscreenIndex
  //habrá que añadir un filtro por trigger o un flag en formInfo de que está en el mapa

  const [isMap, setIsMap] = useState<boolean>(false);
  const [soloProgreso, setSoloProgreso] = useState<boolean>(false);

  useEffect(() => {
    if (multiStepScreenIndex === 1 && screenIndex === 5) {
      setIsMap(true);
    } else if (formData.fields.formName === 'no_compra-ya_tengo' && screenIndex === 2) {
      setSoloProgreso(true);
    } else if (formData.fields.formName === 'adhoc-2021-eurocopa' && screenIndex === 0) {
      setIsMap(true);
    } else {
      setIsMap(false);
      setSoloProgreso(false);
    }
  }, [screenIndex, multiStepScreenIndex]);

  useEffect(() => {
    setTheme('inverted');
  }, [trigger]);

  const store = useStore();

  const handleClick = () => {
    setFormClicked(true);
  };

  const [isLastScreen, setIsLastScreen] = useState<boolean>(false);
  const [isConfirmacion, setIsConfirmacion] = useState<boolean>(false);

  useEffect(() => {
    if (step?.name === 'Confirmacion' || step?.name === 'Form') {
      setIsConfirmacion(true);
    } else if (step && step.name !== 'Confirmacion') {
      setIsConfirmacion(false);
    }
    if (step && lastStep) {
      step.screenIndex === lastStep.screenIndex ? setIsLastScreen(true) : setIsLastScreen(false);
    }
  }, [step]);

  //navigation position in full screen

  const [navPositon, setNavPosition] = useState<string>('fixed');
  const viewportHeight = useWindowHeight();

  const { top: topNavigation, getAndUpdateState: getAndUpdateStateNavigation } =
    useGetBoundingClientRect('.navigation-wrapper');

  const { bottom: bottomContent, getAndUpdateState: getAndUpdateStateContent } = useGetBoundingClientRect(
    '.content-controller-fullScreen'
  );

  const handlePosition = () => {
    getAndUpdateStateNavigation();
    getAndUpdateStateContent();
  };

  useEffect(() => {
    if (step && step.name !== 'Confirmacion') {
      document.addEventListener('scroll', handlePosition);

      return () => {
        document.removeEventListener('scroll', handlePosition);
      };
    }
  }, [step]);

  useEffect(() => {
    if (bottomContent === null) {
      setNavPosition('fixed');
      //console.log('1111');
    } else if (bottomContent <= viewportHeight-100) {
      setNavPosition('relative');
      //console.log('2222');
    } else {
      setNavPosition('fixed');
      //console.log('3333');
    }
  }, [topNavigation, bottomContent]);

  return (
    <OneFormProvider closeLayerCallback={closeLayerCallback} handleAreYouSureLayer={handleAreYouSureLayer}>
      <div
        onClick={() => {
          handleClick();
        }}
        className="screen-controller-wrapper"
      >
        {showFinalScreen ? (
          <>
          {
            formType === 'inPage' || formType === 'module' ? null : (
              <CloseComponent
                closeThankYou={true}
                handleAreYouSureLayer={handleAreYouSureLayer}
                notALayer={false}
                colorTheme={colorTheme}
              />
            )
          }
            
            <ThankYouWrapper className="thank-you-wrapper">
              <ThankYouComponent />
            </ThankYouWrapper>
          </>
        ) : formType === 'inPage' || formType === 'module' ? (
          <>
            {selectedJourney}
            {showFinalScreen || isMap || !formStarted || isConfirmacion || formType === 'module'  ? null : (
              <Navigation
                formClicked={formClicked}
                setFormClicked={setFormClicked}
                notALayer={true}
                colorTheme="main"
                soloProgreso={soloProgreso}
                position={navPositon}
              />
            )}
          </>
        ) : (
          <>
            <CloseComponent
              handleAreYouSureLayer={handleAreYouSureLayer}
              notALayer={false}
              colorTheme={colorTheme}
            />

            {selectedJourney}
            {isLastScreen ? null : (
              <Navigation
                formClicked={formClicked}
                setFormClicked={setFormClicked}
                notALayer={false}
                colorTheme={colorTheme}
                soloProgreso={soloProgreso}
              />
            )}
          </>
        )}
      </div>
    </OneFormProvider>
  );
}

import {
  CTA,
  styled,
  Text,
  TokenTextAppearance,
  TokenTextColor
} from '@volkswagen-onehub/components-core';
import React, { useState, useEffect } from 'react';
import { CTAsSiNo } from 'src/feature-app';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { InputError } from 'src/components';
import { CustomSwitch } from '../inputs';
import { useIsComerciales } from 'src/feature-app/hooks';
import { Info } from 'src/icons-core-imports';


const CTAsWrapper = styled.div`
  .error-field {
    width: fit-content !important;
  }
  
  > button {
    width: 81px;
    height: 33px;
    &:first-of-type {
      margin-right: 44px;
    }
  }
  
`;

const PaddingWrapper = styled.div<{ noCompra?: boolean }>`
  width: 100%;
  padding-bottom: 32px;
`;

const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const ItemWrapper = styled.div`
  padding-bottom: 44px;
`;

interface InfoWrapperProps {
  themeColor?: 'main' | 'inverted';
}

const InfoWrapper = styled.div<InfoWrapperProps>`
  margin-left: 16px;
  cursor: pointer;
  svg {
    fill: ${(props) => (props.themeColor === 'main' ? '#001E50' : '#ffffff')};
  }
`;


export const LegalItem = ({ title, onClick, themeColor, name }: any) => {
  const [currentValue, setCurrentValue] = useState<string>(null);
  const { register, setValue, errors } = useFormContext();
  const [isSelected, setIsSelected] = useState(null);
  const getValue = (isSelected: boolean) => isSelected ? 'PERMITE' : 'NO INFORMADO';
  const isComerciales = useIsComerciales();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: getValue(isSelected) } });
  }, [isSelected]);

  useEffect(() => {
    !isComerciales && setIsSelected(false);
  }, [])

  function checkCurrentValue(label: string, name: string) {
    const value = label === 'Sí' ? 'PERMITE' : 'NO PERMITE';
    setCurrentValue(label);
    dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: value } });
    setValue(name, value);
  }

  return (
    <ItemWrapper>
      <PaddingWrapper>
        <QuestionWrapper>
          <Text appearance={TokenTextAppearance.copy200}>{title}</Text>
          <InfoWrapper onClick={onClick} themeColor={themeColor}>
            <Info />
          </InfoWrapper>
        </QuestionWrapper>
      </PaddingWrapper>
      {isComerciales ? (
        <CTAsWrapper>
          {
            CTAsSiNo.map((cta: { label: string }, index: number) => (
              <CTA
                name={name}
                tag="button"
                emphasis={currentValue === cta.label ? 'primary' : 'secondary'}
                ref={(ref) => {
                  register(ref, { required: true });
                }}
                onClick={
                  (e) => {
                    e.preventDefault();
                    checkCurrentValue(cta.label, name);
                  }
                }
                size={'small'}
              >
                {cta.label}
              </CTA >
            ))
          }
          {errors[name] ? <InputError className="error-field">Este campo es obligatorio</InputError> : null}
        </CTAsWrapper >
      ) : (
        <CTAsWrapper>
          <CustomSwitch setSelected={setIsSelected} isSelected={isSelected} />
          <Text color={TokenTextColor.primary}>{isSelected ? 'Aceptado' : 'Aceptar'}</Text>
        </CTAsWrapper>
      )}
    </ItemWrapper>
  );
};

import { DomainAttributesValues } from 'src';

export const arrayToObject = (arr: [DomainAttributesValues]) => {
  if(!arr) return null;
  const obj = {};
  for(const i in arr) {
    obj[arr[i].name] = arr[i].value;
  }
  return obj;
}

export const strTitlelize = (str: string) => {
  if(!str) return '';
  return str
      .toLowerCase()
      .split(' ') // separar palabras para capitalizar
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .split('.') // separar sociedades para capitalizar (S.A., S.L.U.)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('.')
      ;
};
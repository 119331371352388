import dayjs from 'dayjs';
import {
  Breakpoints,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Fieldset,
  Layout,
  LayoutRowGap,
  RadioButton,
  styled,
  Switch,
  Text,
  TextAlignment,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { CloseHandleV2, FocusLayerSizeV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useStore, useSelector } from 'react-redux';
import { Store } from 'redux';
import {
  Apellido,
  AreYouSureLayer,
  Email,
  LegalesFormatoLayer,
  LssiTkmQuestions,
  MatriculaYModelo,
  Nombre,
  SelectExtended,
  Telefono,
  CarlineSelect,
  PoliticaPrivacidad,
  LastNavigation,
  LegalesMontmelo,
} from 'src/components';
import {
  LayerManagerWrapper,
  useFaContent,
  useFeatureAppConfig,
  useFeatureAppEnvironment,
  useFeatureServices,
  useOneFormContext,
  weekDay_DayNumber_Month,
  weekDay_DayNumber_Month_Hour,
} from 'src/feature-app';
import { VehiclesIdentityKit } from 'src/feature-service/es-oneform-core-feature-service/types';
import { TIME_FOR_TRANSFORM } from 'src/globals';
import { OneFormState, Steps } from 'src/types';
import { useIsComerciales } from 'src/feature-app/hooks';
import { getKilometrosInfo, franjaHoraria } from 'src/feature-app/InputsValues';
import { ArrowDown, ArrowUp, ChevronDown, ChevronUp } from '@volkswagen-onehub/icons-core';

const AllWrapper = styled.div`
  padding-top: 82px;
`;

const TopInfoBox = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.44);
  padding: 0 20px;
  width: 100%;
  @media all and (min-width: 560px) {
    padding: 0 24px;
  }
`;

type BorderType = 'top' | 'bottom' | 'none';

type ItemProps = {
  boxItem?: boolean;
  border: BorderType;
  showEdit?: boolean;
  noPaddingTop?: boolean;
  showOperacionesMantenimiento?: boolean;
};

const ItemWrapper = styled.div<ItemProps>`
  width: 100%;
  text-align: left;
  border-bottom: ${(props) => (props.border === 'bottom' ? '1px solid rgba(255,255,255,0.45)' : 'none')};
  border-top: ${(props) => (props.border === 'top' ? '1px solid rgba(255,255,255,0.45)' : 'none')};
  padding: ${(props) => (props.boxItem ? '24px 0' : props.noPaddingTop ? '0 24px 44px' : '44px 24px')};

  .item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: ${(props) => (props.showOperacionesMantenimiento ? '32px' : props.showEdit ? '44px' : '12px')};
    button > * {
      font-weight: ${(props) => (props.showEdit ? 'normal' : 'bold')};
    }
    &--legal {
      padding-bottom: ${(props) => (props.showEdit ? 'unset' : '31.5px')};
    }
  }
  .edit-wrapper {
    display: flex;
    flex-direction: column;
    label:first-of-type {
      margin-bottom: 23px;
    }
  }
  .input-error {
    margin-left: 0px !important;
  }
`;

const SelectWrapper = styled.div`
  margin-bottom: 2px;
  label {
    width: 100%;
    @media all and (min-width: 560px) {
      width: 303px;
    }
    @media all and (min-width: 960px) {
      width: 320px;
    }
  }
`;

const InputWidthWrapper = styled.div`
  width: 100%;
  margin-bottom: 2px;
  @media all and (min-width: 560px) {
    width: 303px;
  }
  @media all and (min-width: 960px) {
    width: 320px;
  }
`;

const RadioButtonWrapper = styled.div`
  border: 1px solid white;
  padding: 20px;
`;

const MotivoCalculadora = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const isCalculadoraForm = (formName: string) => formName === 'cita-posventa-calculadora';

/**
 * En calculadora se tiene que ocultar el CTA ya que el motivo no es editable.
 * @param formName
 * @returns
 */
const showCTAEditMotivo = (formName: string) => !isCalculadoraForm(formName);

/**
 * En calculadora se tiene que ocultar el CTA ya que el taller no es editable.
 * @param formName
 * @returns
 */
const showCTAEditTaller = (formName: string) => formName !== 'cita-posventa-calculadora';

/**
 * En calculadora se tiene que ocultar el CTA ya que el vehículo no es editable.
 * @param formName
 * @returns
 */
const showCTAEditVW = (formName: string) => formName !== 'cita-posventa-maintenance-plan' && formName !== 'cita-posventa-calculadora' && formName !== 'cita-posventa-my-benefits';

/**
 * En maintenance plan se tiene que ocultar los km ya que no se deben mostrar
 * @param formName
 * @returns
 */
const showKMVW = (formName: string) => formName !== 'cita-posventa-maintenance-plan' && formName !== 'cita-posventa-my-benefits';

const getStepNumber = (steps: Steps[], layerName: 'isMotivoLayer' | 'isTimeLayer' | 'isTallerLayer') => {
  const index = steps.findIndex((step) => {
    if (layerName === 'isMotivoLayer' && step.name === 'Motivo') {
      return step;
    }
    if (layerName !== 'isMotivoLayer' && step.name === 'Mapa') {
      return step;
    }
  });
  return index;
};

const formatPlateDate = (plateDate: string) => {
  if (plateDate.includes('-')) {
    return plateDate.split('-')[0];
  }
  return plateDate;
};

export const ConfirmacionCita = () => {
  const { formData, formInfo } = useSelector((state: OneFormState) => state);
  const { userIsAuthenticated } = formInfo;
  const { fields } = formData;
  const { tmk: tmkData, lssi: lssiData, userVehicles, matricula } = formData.fields;
  const dispatch = useDispatch();
  const { handleScreenChange, setShowPreviousStep } = useOneFormContext();
  const { getValues, register, triggerValidation } = useFormContext();
  const faContent = useFaContent();
  const isComerciales = useIsComerciales();
  const { kilometrosValues, millageValues } = getKilometrosInfo(isComerciales);

  const [fechaHora, setFechaHora] = useState<string>('');
  const [cocheAlTaller, setCocheAlTaller] = useState<boolean>(false);
  const [cocheSustitucion, setCocheSustitucion] = useState<boolean>(false);

  const [showEditServicios, setShowEditServicios] = useState<boolean>(false);
  const [showEditSobreVW, setShowEditSobreVW] = useState<boolean>(false);
  const [showEditSobreTi, setShowEditSobreTi] = useState<boolean>(false);

  const [inputValueMatricula, setInputValueMatricula] = useState('');
  const [errorMatricula, setErrorMatricula] = useState(false);
  const [irConOtroCoche, setIrConOtroCoche] = useState(false);

  const [lssi, setLssi] = useState<string>(() => (lssiData ? lssiData : null));
  const [tmk, setTmk] = useState<string>(() => (tmkData ? tmkData : null));
  const [lssiError, setLssiError] = useState<boolean>(false);
  const [tmkError, setTmkError] = useState<boolean>(false);

  const [preselectedDealerForm, setPreselectedDealerForm] = useState(false);
  const config = useFeatureAppConfig();

  const useLoggedJourney = false;

  const today = dayjs();
  const showMontmeloLegales = today.isBetween('2024-06-26', '2024-08-15', 'day', '[]'); // [] dias incluidos, () dias excluidos

  // De momento no es necesario usar todas las funciones del journey logueado/no logueado, tan solo el fill de los datos

  useEffect(() => {
    if (config.trigger === 'cita-posventa-dealer') {
      setPreselectedDealerForm(true);
    } else {
      setPreselectedDealerForm(false);
    }
  }, []);

  //Layer confirmacion edicion:

  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const store: Store<OneFormState> = useStore();
  const env = useFeatureAppEnvironment();

  const handleEdit = (layerName: 'isMotivoLayer' | 'isTimeLayer' | 'isTallerLayer') => {
    const { steps } = formInfo;
    const stepNumber = getStepNumber(steps, layerName);

    handleScreenChange(stepNumber);
    dispatch({ type: 'UPDATE_STEP', payload: steps[stepNumber] });
    if (layerName !== 'isMotivoLayer') {
      dispatch({ type: 'SET_SHOW_CALENDAR_ON_LOAD', payload: layerName === 'isTimeLayer' });
      dispatch({ type: 'UPDATE_MULTISTEP_SCREENINDEX', payload: 1 });
      dispatch({ type: 'SET_NAVIGATION_MOVING_FORWARD', payload: false });

      setTimeout(() => {
        setShowPreviousStep(false);
      }, TIME_FOR_TRANSFORM + 200);
    }
  };

  const handleAreYouSureLayer = (layerName: string) => {
    const layer = layerManager.openFocusLayer(renderAreYouSureLayer, { layerName }, { size: FocusLayerSizeV2.A });
    return layer;
  };

  const renderAreYouSureLayer = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <AreYouSureLayer
          layerName={state.layerName}
          closeLayerCallback={close}
          onClickProp={() => {
            handleEdit(state.layerName);
          }}
        />
      </LayerManagerWrapper>
    );
  };
  //fin códgo layers

  const showLegales = () => userIsAuthenticated && useLoggedJourney && tmk && lssi;

  const getKmInitialValue = () => {
    if (fields.kilometros && Array.isArray(fields.kilometros)) {
      const indexOfMillage = millageValues.findIndex((e) => e[0] === fields.kilometros[0]);
      return indexOfMillage !== -1 ? kilometrosValues[indexOfMillage] : kilometrosValues[kilometrosValues.length - 2];
    } else {
      return kilometrosValues[kilometrosValues.length - 2];
    }
  };

  const [kilometros, setKilometros] = useState(getKmInitialValue());

  const CTAsSiNo = [{ label: 'Sí' }, { label: 'No' }];

  const [millageValue, setMillageValue] = useState<number | number[] | null>(null);

  function updateKilometros(event: any) {
    const newMillVal = event.currentTarget.value;
    setKilometros(newMillVal);
    newMillVal ? setMillageValue(newMillVal) : setMillageValue(0);
    const newIndex = kilometrosValues?.findIndex((value) => value === event.target.value);
    if (newIndex !== -1) {
      const newValues = millageValues[newIndex];
      dispatch({ type: 'UPDATE_FIELDS', payload: { kilometros: newValues } });
    }
  }

  async function updateValues(showEdit: React.Dispatch<React.SetStateAction<boolean>>) {
    const values = getValues();
    const isValid = await triggerValidation();
    if (isValid) {
      dispatch({ type: 'UPDATE_FIELDS', payload: values });
      showEdit(false);
    }
  }

  // función para checkear que no se haya dejado ninguna sección editada sin guardar
  function checkNotSavedFields() {
    if (showEditServicios && !showEditSobreVW && !showEditSobreTi) {
      return 'en Servicios Adicionales';
    } else if (!showEditServicios && showEditSobreVW && !showEditSobreTi) {
      return 'en Sobre tu Volkswagen';
    } else if (!showEditServicios && !showEditSobreVW && showEditSobreTi) {
      return 'en Sobre ti';
    } else if (
      (showEditServicios && showEditSobreVW && !showEditSobreTi) ||
      (showEditServicios && showEditSobreVW && showEditSobreTi) ||
      (showEditServicios && !showEditSobreVW && showEditSobreTi) ||
      (!showEditServicios && showEditSobreVW && showEditSobreTi)
    ) {
      return '';
    } else {
      return null;
    }
  }

  useEffect(() => {
    if (inputValueMatricula === '' || inputValueMatricula.length > 10) {
      setErrorMatricula(true);
    } else {
      setErrorMatricula(false);
    }
  }, [inputValueMatricula]);

  useEffect(() => {
    if (lssi) {
      const values = { lssi: lssi };
      dispatch({ type: 'UPDATE_FIELDS', payload: values });
      setLssiError(false);
    }
    if (tmk) {
      const values = { tmk: tmk };
      dispatch({ type: 'UPDATE_FIELDS', payload: values });
      setTmkError(false);
    }
  }, [lssi, tmk]);

  useEffect(() => {
    dispatch({ type: 'UPDATE_FORM_ENDED', payload: true });

    const selectedSlot = fields.slot;
    let fromFormatted;

    if (fields.dealer.dmsInfo && fields.dealer.dmsInfo.dmsAvailable) {
      const momentSlot = weekDay_DayNumber_Month_Hour(selectedSlot.from);
      const capitalizedSlot =
        momentSlot.charAt(0).toUpperCase() +
        momentSlot.split(',')[0].slice(1) +
        ' de ' +
        momentSlot.split(',')[1].charAt(0).toLowerCase() +
        momentSlot.split(',')[1].slice(1);
      fromFormatted = capitalizedSlot.replace('-', ' a las ').replace(',', ' de ').concat('h');
      setFechaHora(fromFormatted);
    } else {
      const momentSlot = weekDay_DayNumber_Month(selectedSlot.from);
      const horarioTranslate = franjaHoraria.find((h) => h.code === selectedSlot.dayMoment.code);
      const capitalizedSlot =
        momentSlot.charAt(0).toUpperCase() +
        momentSlot.split(',')[0].slice(1) +
        ' de ' +
        momentSlot.split(',')[1].charAt(0).toLowerCase() +
        momentSlot.split(',')[1].slice(1);

      fromFormatted = capitalizedSlot.concat(horarioTranslate.name);
      setFechaHora(fromFormatted);
    }
    if (fields.cocheAlTaller !== null) {
      fields.cocheAlTaller === 'true' ? setCocheAlTaller(true) : setCocheAlTaller(false);
    }
    if (fields.cocheAlTaller !== null) {
      fields.cocheSustitucion === 'true' ? setCocheSustitucion(true) : setCocheSustitucion(false);
    }

    setShowPreviousStep(false);
    if (fields.kilometros || fields.kilometros === 0) {
      setMillageValue(fields.kilometros);
    }

    if (fields.matricula) setInputValueMatricula(fields.matricula);
  }, []);

  useEffect(() => {
    const values = { cocheAlTaller: cocheAlTaller ? 'true' : 'false' };
    dispatch({ type: 'UPDATE_FIELDS', payload: values });
  }, [cocheAlTaller]);

  useEffect(() => {
    const values = { cocheSustitucion: cocheSustitucion ? 'true' : 'false' };
    dispatch({ type: 'UPDATE_FIELDS', payload: values });
  }, [cocheSustitucion]);

  return (
    <AllWrapper className="confirmacion-cita-wrapper">
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 1 },
            { name: 'a', columns: 22 },
            { name: '.', columns: 1 },
          ],
          [Breakpoints.b560]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
        }}
      >
        <Container
          padding={{
            bottom: ContainerPadding.static500,
            left: ContainerPadding.static350,
          }}
        >
          <Text appearance={TokenTextAppearance.headline300} textAlign={TextAlignment.left}>
            Así quedaría tu cita. <Text bold>¿Quieres solicitarla?</Text>
          </Text>
        </Container>
        <TopInfoBox id="top-info-box">
          {faContent?.motivo ? null : <MotivoCita fields={fields} handleAreYouSureLayer={handleAreYouSureLayer} />}
          <ItemWrapper border={faContent?.motivo ? 'none' : 'top'} boxItem>
            <div className="item-header">
              <Text appearance={TokenTextAppearance.headline200} bold>
                Fecha y hora
              </Text>
              <CTA
                tag="button"
                emphasis="tertiary"
                onClick={(e) => {
                  e.preventDefault();
                  handleAreYouSureLayer('isTimeLayer');
                }}
              >
                Editar
              </CTA>
            </div>
            <Text appearance={TokenTextAppearance.copy100}>{fechaHora}</Text>
          </ItemWrapper>
          <ItemWrapper border="top" boxItem>
            <div className="item-header">
              <Text appearance={TokenTextAppearance.headline200} bold>
                Taller
              </Text>
              {preselectedDealerForm || !showCTAEditTaller(fields.formName) ? null : (
                <CTA
                  tag="button"
                  emphasis="tertiary"
                  onClick={(e) => {
                    e.preventDefault();
                    handleAreYouSureLayer('isTallerLayer');
                  }}
                >
                  Editar
                </CTA>
              )}
            </div>
            <Container horizontalAlign={'space-between'}>
              <Text appearance={TokenTextAppearance.copy100} bold>
                <span style={{ textTransform: 'capitalize' }}>{fields.dealer.name}</span>
              </Text>
            </Container>
            <Text appearance={TokenTextAppearance.copy100}>
              {fields.dealer.markerInfo.dealerInfo.address}, {fields.dealer.markerInfo.dealerInfo.zipCode}{' '}
              {fields.dealer.markerInfo.dealerInfo.city}
            </Text>
            {fields.assessor && fields.dealer.dmsInfo && fields.dealer.dmsInfo.dmsAvailable ? (
              <Text appearance={TokenTextAppearance.copy100}>
                Asesor <span style={{ textTransform: 'capitalize' }}>{fields.assessor.name}</span>
              </Text>
            ) : null}
          </ItemWrapper>
        </TopInfoBox>

        {isComerciales || fields.dealer?.dmsInfo && fields.dealer?.dmsInfo?.pickupCarServiceAvailabe ||
          fields.dealer?.dmsInfo && fields.dealer?.dmsInfo?.replacementCarServiceAvailable ? (
          <ItemWrapper border="bottom" showEdit={showEditServicios}>
            <div className="item-header">
              <Text appearance={TokenTextAppearance.headline200} bold>
                Servicios adicionales
              </Text>
              {showEditServicios ? (
                <CTA
                  tag="button"
                  emphasis="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowEditServicios(false);
                  }}
                  size="small"
                >
                  Guardar
                </CTA>
              ) : (
                <CTA
                  tag="button"
                  emphasis="tertiary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowEditServicios(!showEditServicios);
                  }}
                >
                  Editar
                </CTA>
              )}
            </div>
            {showEditServicios ? (
              <Container wrap={'always'} gutter={ContainerGutter.static500}>
                <div className="edit-wrapper">
                  {isComerciales || fields.dealer?.dmsInfo && fields.dealer?.dmsInfo?.replacementCarServiceAvailable ? (
                    <Switch
                      onChange={() => setCocheSustitucion(!cocheSustitucion)}
                      defaultChecked={cocheSustitucion ? true : false}
                    >
                      Coche de sustitución
                    </Switch>
                  ) : null}
                  {isComerciales || fields.dealer?.dmsInfo && fields.dealer?.dmsInfo?.pickupCarServiceAvailabe ? (
                    <Switch
                      onChange={() => setCocheAlTaller(!cocheAlTaller)}
                      defaultChecked={cocheAlTaller ? true : false}
                    >
                      Servicio de recogida y entrega
                    </Switch>
                  ) : null}
                </div>
              </Container>
            ) : (
              <>
                {isComerciales || fields.dealer?.dmsInfo && fields.dealer?.dmsInfo?.replacementCarServiceAvailable ? (
                  <Text appearance={TokenTextAppearance.copy100}>
                    Coche de sustitución: {cocheSustitucion ? 'Sí' : 'No'}
                  </Text>
                ) : null}
                {isComerciales || fields.dealer?.dmsInfo && fields.dealer?.dmsInfo?.pickupCarServiceAvailabe ? (
                  <Text appearance={TokenTextAppearance.copy100}>
                    Servicio de recogida y entrega: {cocheAlTaller ? 'Sí' : 'No'}
                  </Text>
                ) : null}
              </>
            )}
          </ItemWrapper>
        ) : null}

        <ItemWrapper border="bottom" showEdit={showEditSobreVW}>
          <div className="item-header">
            <Text appearance={TokenTextAppearance.headline200} bold>
              Sobre tu Volkswagen
            </Text>
            {showCTAEditVW(fields.formName) ? (
              showEditSobreVW ? (
                <CTA
                  tag="button"
                  emphasis="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    updateValues(setShowEditSobreVW);
                    setIrConOtroCoche(false);
                  }}
                  size="small"
                >
                  Guardar
                </CTA>
              ) : (
                <CTA
                  tag="button"
                  emphasis="tertiary"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowEditSobreVW(!showEditSobreVW);
                  }}
                >
                  Editar
                </CTA>
              )
            ) : null}
          </div>
          {showEditSobreVW ? (
            userVehicles && userIsAuthenticated && useLoggedJourney && !irConOtroCoche ? (
              <>
                <Fieldset legend="">
                  {userVehicles.map((vehicle: VehiclesIdentityKit) => {
                    return (
                      <RadioButtonWrapper>
                        <RadioButton
                          value={vehicle.modelo}
                          label={<VehicleLabel vehicle={vehicle} />}
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch({
                              type: 'UPDATE_FIELDS',
                              payload: {
                                matricula: vehicle.matricula,
                                kilometros: vehicle.kilometros,
                                modelo: vehicle.modelo,
                              },
                            });
                          }}
                          checked={matricula === vehicle.matricula ? true : false}
                        />
                      </RadioButtonWrapper>
                    );
                  })}
                  <RadioButtonWrapper>
                    <RadioButton
                      value="Otro"
                      label="Ir con otro coche"
                      onClick={(e) => {
                        e.preventDefault();
                        setIrConOtroCoche(true);
                      }}
                      checked={false}
                    />
                  </RadioButtonWrapper>
                </Fieldset>
                ;
              </>
            ) : (
              <>
                <Layout
                  rowGap={LayoutRowGap.static450}
                  appearance={{
                    [Breakpoints.default]: [
                      { name: 'a', columns: 24 },
                      { name: 'b', columns: 24 },
                      { name: 'c', columns: 24 },
                    ],
                    [Breakpoints.b960]: [
                      { name: 'a', columns: 24 },
                      { name: 'b', columns: 11 },
                      { name: '.', columns: 2 },
                      { name: 'c', columns: 11 },
                    ],
                  }}
                >
                  <div style={{ marginBottom: '2px' }}>
                    <MatriculaYModelo paginaConfirmacion={true} noPaddingTop={true} />
                  </div>
                  <CarlineSelect noPaddingTop={true} />
                  <SelectWrapper className="select-wrapper">
                    <SelectExtended
                      name="kilometrosLabel"
                      label="Kilómetros"
                      value={kilometros}
                      required
                      options={kilometrosValues}
                      handleChange={(event: any) => {
                        event.preventDefault();
                        updateKilometros(event);
                      }}
                      onClick={() => null}
                      updateFields
                      setOption={setKilometros}
                      isRequired={true}
                    />
                  </SelectWrapper>
                </Layout>
              </>
            )
          ) : (
            <>
              <Text appearance={TokenTextAppearance.copy100}>
                {fields.countryPlate} {fields.matricula}
              </Text>
              <Text appearance={TokenTextAppearance.copy100}>Año {formatPlateDate(fields.anoMatriculacion)}</Text>
              <Text appearance={TokenTextAppearance.copy100}>{fields.modelo}</Text> 
              {showKMVW(fields.formName) ? (
                <Text appearance={TokenTextAppearance.copy100}>{kilometros === 'No estoy seguro' ? null : kilometros}</Text> 
              ) : null } 
            </>
          )}
        </ItemWrapper>

        <ItemWrapper border={showLegales() ? 'none' : 'bottom'} showEdit={showEditSobreTi}>
          <div className="item-header">
            <Text appearance={TokenTextAppearance.headline200} bold>
              Sobre ti
            </Text>
            {showEditSobreTi ? (
              <CTA
                tag="button"
                emphasis="primary"
                onClick={(e) => {
                  e.preventDefault();
                  updateValues(setShowEditSobreTi);
                }}
                size="small"
              >
                Guardar
              </CTA>
            ) : (
              <CTA
                tag="button"
                emphasis="tertiary"
                onClick={(e) => {
                  e.preventDefault();
                  setShowEditSobreTi(!showEditSobreTi);
                }}
              >
                Editar
              </CTA>
            )}
          </div>
          {showEditSobreTi ? (
            <Layout
              rowGap={LayoutRowGap.static450}
              appearance={{
                [Breakpoints.default]: [
                  { name: 'a', columns: 24 },
                  { name: 'b', columns: 24 },
                ],
                [Breakpoints.b960]: [
                  { name: 'a', columns: 11 },
                  { name: '.', columns: 2 },
                  { name: 'b', columns: 11 },
                ],
              }}
            >
              <InputWidthWrapper className="input-width-wrapper">
                <Nombre />
              </InputWidthWrapper>
              <InputWidthWrapper className="input-width-wrapper">
                <Apellido name="surname" label="Primer apellido" required />
              </InputWidthWrapper>
              <InputWidthWrapper className="input-width-wrapper">
                <Apellido name="secondSurname" label="Segundo apellido" />
              </InputWidthWrapper>
              <div style={{ marginBottom: '2px' }}>
                <Telefono paginaConfirmacion={true} required />
              </div>
              <div style={{ marginBottom: '2px' }}>
                <Email paginaConfirmacion={true} required disabled={userIsAuthenticated && useLoggedJourney} />
              </div>
            </Layout>
          ) : (
            <>
              <Text appearance={TokenTextAppearance.copy100}>
                {fields.name} {fields.surname} {fields.secondSurname}
              </Text>
              <Text appearance={TokenTextAppearance.copy100}>
                {fields.prefix} {fields.cellphone}
              </Text>
              <Text appearance={TokenTextAppearance.copy100}>{fields.email}</Text>
            </>
          )}
        </ItemWrapper>

        {/* START Checks legales */}
        {showLegales() ? null : (
          <>
            <div style={{ paddingRight: '24px', paddingLeft: '24px' }}>
              <LssiTkmQuestions title={false} />
            </div>
            {/* END Checks legales */}
            <div style={{ paddingRight: '24px', paddingLeft: '24px', paddingBottom: '44px' }}>
              <PoliticaPrivacidad />
            </div>
          </>
        )}
        <LastNavigation
          checkNotSavedFields={checkNotSavedFields}
          ActivarCheckNotSavedFields
          ContentID="Confirmar cita"
        />
        {fields.formName === 'cita-posventa' && showMontmeloLegales ? (
          <LegalesMontmelo title={true} />
        ) : (
          <LegalesFormatoLayer noPadding={false} title={true} />
        )}
      </Layout>
    </AllWrapper>
  );
};

const VehicleLabel = (props: { vehicle: VehiclesIdentityKit }) => {
  return (
    <Container wrap={'always'}>
      <Text bold>{props.vehicle.matricula}</Text>
      <Text>
        {props.vehicle.modelo} |{props.vehicle.kilometros}
      </Text>
    </Container>
  );
};

const OperacionDiv = styled(MotivoCalculadora) <{ isLast: boolean }>`
  padding-bottom: ${(props) => (props.isLast ? '0px' : '16px')};
`;

const OperacionesDiv = styled.div`
  padding-bottom: 24px;
`;

const MotivoCita = ({ fields, handleAreYouSureLayer }: any) => {
  const [showOperacionesMantenimiento, setShowOperacionesMantenimiento] = useState(false);
  const faContent = useFaContent();

  const formatPvp = (pvp: string) => `${Number(pvp).toFixed(0)}€`;

  const getMotivo = () => {
    let motivo: string
    if (fields.masInformacion == "Actualización Software ID") {
      motivo = 'Actualización software ID'
    } else {
      fields.motivoLabel ? motivo = fields.motivoLabel : motivo = fields.motivo
    }
    return motivo
  }

  const renderMotivoCalculadora = () => {
    return (
      <MotivoCalculadora>
        <Text appearance={TokenTextAppearance.copy100}>Presupuesto total</Text>
        <Text appearance={TokenTextAppearance.copy100}>{formatPvp(faContent?.quotePvp)}</Text>
      </MotivoCalculadora>
    );
  };

  const renderOperacionesMantenimiento = () => {
    return (
      <>
        <OperacionesDiv>
          {faContent?.quoteOperations
            ? faContent?.quoteOperations.map((operation, indx) => {
              return (
                <OperacionDiv isLast={indx === faContent.quoteOperations.length - 1}>
                  <Text appearance={TokenTextAppearance.copy100}>{operation.name}</Text>
                  <Text appearance={TokenTextAppearance.copy100}>{formatPvp(operation.pvp)}</Text>
                </OperacionDiv>
              );
            })
            : null}
        </OperacionesDiv>
        <MotivoCalculadora>
          <Text appearance={TokenTextAppearance.headline200} bold>
            Presupuesto Total
          </Text>
          <Text appearance={TokenTextAppearance.headline200} bold>
            {formatPvp(faContent?.quotePvp)}
          </Text>
        </MotivoCalculadora>
      </>
    );
  };

  return (
    <ItemWrapper border="none" boxItem showOperacionesMantenimiento={showOperacionesMantenimiento}>
      <div className="item-header">
        <Text appearance={TokenTextAppearance.headline200} bold>
          {isCalculadoraForm(fields.formName) ? 'Operaciones de mantenimiento' : 'Motivo de tu cita'}
        </Text>
        {showCTAEditMotivo(fields.formName) ? (
          <CTA
            tag="button"
            emphasis="tertiary"
            onClick={(e) => {
              e.preventDefault();
              handleAreYouSureLayer('isMotivoLayer');
            }}
          >
            Editar
          </CTA>
        ) : isCalculadoraForm(fields.formName) ? (
          <CTA
            tag="button"
            size="small"
            icon={showOperacionesMantenimiento ? <ChevronUp variant="small" /> : <ChevronDown variant="small" />}
            emphasis="primary"
            onClick={(e) => {
              e.preventDefault();
              setShowOperacionesMantenimiento(!showOperacionesMantenimiento);
            }}
          >
            {showOperacionesMantenimiento ? 'Cerrar' : 'Ver más'}
          </CTA>
        ) : null}
      </div>
      {isCalculadoraForm(fields.formName) ? (
        showOperacionesMantenimiento ? null : (
          renderMotivoCalculadora()
        )
      ) : (
        <Text appearance={TokenTextAppearance.copy100}>
          {getMotivo()}
        </Text>
      )}
      {showOperacionesMantenimiento ? renderOperacionesMantenimiento() : null}
    </ItemWrapper>
  );
};

import {
  Breakpoints,
  Layout,
  LayoutRowGap,
  Text,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import {
  CarlineSelect,
  PantallaNombreApellido,
  MultipleCTASelection,
  RatingStars,
  PantallaIntroduccionStars,
  DetalleMotivo,
} from 'src/components';
import { Steps } from 'src/types';
import { ChecksEnvio, ctasCheckEnvio } from 'src/forms/Cem/ChecksEnvio';
import { ConfirmacionCem } from 'src/forms/Cem/ConfirmacionCem';
import { ChecksPublicacion } from 'src/forms/Cem/ChecksPublicacion';
import {
  CTAsSiNo,
  ctaTestDrive,
  ctaContactoSatisfaccion,
} from 'src/feature-app/InputsValues/CTAsObjects';
import { FirstStepCEM } from './FirstStepCEM';

enum CemSteps {
  DatosCorrectos,
  DatosIncorrectos,
  Modelo,
  ConfirmacionConcesion,
  Explicacion,
  SatisfaccionTrato,
  SatisfaccionTratoMotivo,
  SatisfaccionOrganizacion,
  SatisfaccionOrganizacionMotivo,
  SatisfaccionAsesor,
  SatisfaccionAsesorMotivo,
  SatisfaccionConcesion,
  SatisfaccionConcesionMotivo,
  AspectoSatisfaccionMotivo,
  TestDrive,
  ContactoSatisfaccion,
  SatisfaccionVehiculo,
  SatisfaccionVehiculoMotivo,
  EnviarConcesion,
  DatosPersonales,
  PublicarRespuestas,
  Confirmacion,
}

function OneElementTemplateFullScreen(props: any) {
  const { element, allowOverflowingContent } = props;
  return (
    <Layout
      allowOverflowingContent={allowOverflowingContent ? true : false}
      appearance={{
        [Breakpoints.default]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ],
        [Breakpoints.b960]: [
          { name: '.', columns: 3 },
          { name: 'a', columns: 18 },
          { name: '.', columns: 3 },
        ],
        [Breakpoints.b1280]: [
          { name: '.', columns: 5 },
          { name: 'a', columns: 14 },
          { name: '.', columns: 5 },
        ],
        [Breakpoints.b1920]: [
          { name: '.', columns: 6 },
          { name: 'a', columns: 12 },
          { name: '.', columns: 6 },
        ],
        [Breakpoints.b2560]: [
          { name: '.', columns: 7 },
          { name: 'a', columns: 10 },
          { name: '.', columns: 7 },
        ],
      }}
      rowGap={LayoutRowGap.static300}
    >
      {element}
    </Layout>
  );
}

export const getStepsVenta = (isElectrico?: boolean) => {

  const ctaDatosIncorrectos = [
    { label: 'Marca incorrecta', value: 'Marca' },
    { label: 'Modelo incorrecto', value: 'Modelo' },
    { label: 'Punto de venta incorrecto', value: 'Instalación' },
    { label: 'Persona de contacto incorrecta', value: 'Contacto' },
  ];

  const stepsVenta: Steps[] = [
  {
    fields: <FirstStepCEM />,
    screenIndex: CemSteps.DatosCorrectos,
    name: 'DatosCorrectos',
    alternateTitle: <Text>¿Puedes confirmar que estos datos son correctos?</Text>,
    outputs: ['datosCorrectos'],
    outputsText: ['datosCorrectos'],
    trueOrFalseQuestion: true,
  },
  {
    title: (
      <>
        Por favor, <Text bold>¿puedes confirmar qué dato no es correcto?</Text>
      </>
    ),
    fields: (
      <OneElementTemplateFullScreen
        element={<MultipleCTASelection ctaValues={ctaDatosIncorrectos} name="datosIncorrectos" nextOnClick />}
      />
    ),
    screenIndex: CemSteps.DatosIncorrectos,
    name: 'DatosIncorrectos',
    outputs: ['datosIncorrectos'],
    outputsText: ['datosIncorrectos'],
  },
  {
    title: <Text bold>¿Compraste tu vehículo en [Concesionario]?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={<MultipleCTASelection ctaValues={CTAsSiNo} name='confirmacionConcesion' nextOnClick ctaWidth="81"/>}
      />
    ),
    screenIndex: CemSteps.ConfirmacionConcesion,
    name: 'ConfirmacionConcesion',
    outputs: ['confirmacionConcesion'],
    outputsText: ['confirmacionConcesion'],
  },
  {
    title: <Text bold>¿Cuál es el modelo correcto?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <div style={{ marginBottom: '2px' }}>
            <CarlineSelect isCEMVenta useCarline />
          </div>
        }
      />
    ),
    screenIndex: CemSteps.Modelo,
    name: 'Modelo',
    outputs: ['modelo'],
    outputsText: ['modelo'],
  },
  {
    title: (
      <>
        A continuación,{' '}
        <Text bold>
          indica tu nivel de satisfacción con cada uno de estos aspectos relacionados con la compra de tu coche.
        </Text>
      </>
    ),
    fields: <PantallaIntroduccionStars bodyCopy="Valora del 1 al 5, a mayor número de estrellas más satisfacción." />,
    screenIndex: CemSteps.Explicacion,
    name: 'Explicacion',
  },
  {
    title: <Text bold>El trato recibido</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <RatingStars
            name="satisfaccionTrato"
            nextOnClick={true}
            textGood="satisfecho/a"
            textBad="insatisfecho/a"
            CTASinRespuesta
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionTrato,
    name: 'SatisfaccionTrato',
    outputs: ['satisfaccionTrato'],
    outputsText: ['satisfaccionTrato'],
    outputsIsStars: true,
  },
  {
    title: <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <DetalleMotivo
            required={true}
            name="satisfaccionTratoMotivo"
            showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados."
            numberOfMaxLength={300}
            CTALabel="Sin respuesta"
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionTratoMotivo,
    name: 'SatisfaccionTratoMotivo',
    outputs: ['satisfaccionTratoMotivo'],
    outputsText: ['satisfaccionTratoMotivo'],
  },
  {
    title: <Text bold>La organización del punto de venta</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <div style={{ paddingTop: '8px' }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Por ejemplo, en cuanto a la petición de la cita, la prueba de conducción o la entrega del coche.
            </Text>
            <RatingStars
              name="satisfaccionOrganizacion"
              nextOnClick={true}
              textGood="satisfecho/a"
              textBad="insatisfecho/a"
              CTASinRespuesta
            />
          </div>
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionOrganizacion,
    name: 'SatisfaccionOrganizacion',
    outputs: ['satisfaccionOrganizacion'],
    outputsText: ['satisfaccionOrganizacion'],
    outputsIsStars: true,
  },
  {
    title: <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <DetalleMotivo
            required={true}
            name="satisfaccionOrganizacionMotivo"
            showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados."
            numberOfMaxLength={300}
            CTALabel="Sin respuesta"
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionOrganizacionMotivo,
    name: 'SatisfaccionOrganizacionMotivo',
    outputs: ['satisfaccionOrganizacionMotivo'],
    outputsText: ['satisfaccionOrganizacionMotivo'],
  },
  {
    title: <Text bold>El asesoramiento recibido por parte del asesor comercial Volkswagen</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <RatingStars
            name="satisfaccionAsesor"
            nextOnClick={true}
            textGood="satisfecho/a"
            textBad="insatisfecho/a"
            CTASinRespuesta
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionAsesor,
    name: 'SatisfaccionAsesor',
    outputs: ['satisfaccionAsesor'],
    outputsText: ['satisfaccionAsesor'],
    outputsIsStars: true,
  },
  {
    title: <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <DetalleMotivo
            required={true}
            name="satisfaccionAsesorMotivo"
            showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados."
            numberOfMaxLength={300}
            CTALabel="Sin respuesta"
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionAsesorMotivo,
    name: 'SatisfaccionAsesorMotivo',
    outputs: ['satisfaccionAsesorMotivo'],
    outputsText: ['satisfaccionAsesorMotivo'],
  },
  {
    title: <Text bold>¿Cuál es tu nivel de satisfacción con nuestros servicios prestados como punto de venta?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <div style={{ paddingTop: '8px' }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Pensando en el proceso de compra en general de tu nuevo vehículo.
            </Text>
            <RatingStars
              name="satisfaccionConcesion"
              nextOnClick={true}
              textGood="satisfecho/a"
              textBad="insatisfecho/a"
              CTASinRespuesta
            />
          </div>
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionConcesion,
    name: 'SatisfaccionConcesion',
    outputs: ['satisfaccionConcesion'],
    outputsText: ['satisfaccionConcesion'],
    outputsIsStars: true,
  },
  {
    title: <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <DetalleMotivo
            required={true}
            name="satisfaccionConcesionMotivo"
            showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados."
            numberOfMaxLength={300}
            CTALabel="Sin respuesta"
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionConcesionMotivo,
    name: 'SatisfaccionConcesionMotivo',
    outputs: ['satisfaccionConcesionMotivo'],
    outputsText: ['satisfaccionConcesionMotivo'],
  },
  {
    title: <Text bold>¿Hay algún aspecto con el que no estuvieras satisfecho y que no hayas mencionado aún?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <DetalleMotivo
            required={true}
            name="aspectoSatisfaccionMotivo"
            showRecomendation="Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados."
            numberOfMaxLength={300}
            CTALabel="Sin respuesta"
          />
        }
      />
    ),
    screenIndex: CemSteps.AspectoSatisfaccionMotivo,
    name: 'AspectoSatisfaccionMotivo',
    outputs: ['aspectoSatisfaccionMotivo'],
    outputsText: ['aspectoSatisfaccionMotivo'],
  },
  {
    title: <Text bold>¿Te ofreció el asesor comercial realizar una prueba de conducción?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={<MultipleCTASelection ctaValues={ctaTestDrive} name="testDrive" nextOnClick />}
      />
    ),
    screenIndex: CemSteps.TestDrive,
    name: 'TestDrive',
    outputs: ['testDrive'],
    outputsText: ['testDrive'],
  },
  {
    title: (
      <>
        <Text bold>
        ¿Te ha contactado [Concesionario] para preguntarte si estás satisfecho con tu [Modelo] después de entregártelo?
        </Text>
      </>
    ),
    fields: (
      <OneElementTemplateFullScreen
        element={<MultipleCTASelection ctaValues={ctaContactoSatisfaccion} name="contactoSatisfaccion" nextOnClick />}
      />
    ),
    screenIndex: CemSteps.ContactoSatisfaccion,
    name: 'ContactoSatisfaccion',
    outputs: ['contactoSatisfaccion'],
    outputsText: ['contactoSatisfaccion'],
    trueOrFalseQuestion: true,
  },
  {
    title: (
      <>
        Finalmente nos gustaría saber si{' '}
        <Text bold>
          podemos enviar tus respuestas junto con tu nombre
        </Text>
        {' '}al punto de venta [Concesionario] y el bastidor del vehículo, y puedan usarlos para análisis adicionales.
      </>
    ),
    fields: <OneElementTemplateFullScreen element={<ChecksEnvio name="enviarConcesion" />} />,
    screenIndex: CemSteps.EnviarConcesion,
    name: 'EnviarConcesion',
    outputs: ['enviarConcesion'],
    outputsText: ['enviarConcesion'],
    ctas: ctasCheckEnvio(false, false)
  },
  {
    title: (
      <>
        Por favor, <Text bold>introduce o comprueba tus datos:</Text>
      </>
    ),
    fields: (
      <OneElementTemplateFullScreen
        element={
          <div style={{ paddingBottom: '2px' }}>
            <PantallaNombreApellido />
          </div>
        }
      />
    ),
    screenIndex: CemSteps.DatosPersonales,
    name: 'DatosPersonales',
    outputs: ['name', 'surname', 'secondSurname'],
    outputsText: ['name', 'surname', 'secondSurname'],
  },
  {
    title: (
      <Text bold>
        ¿Podríamos publicar tus respuestas en la web oficial de Volkswagen y en la de [Concesionario] sin mencionar tu
        nombre?
      </Text>
    ),
    fields: (
      <OneElementTemplateFullScreen
        element={
          <div style={{ paddingTop: '8px' }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Para compartir tu experiencia con otros clientes.
            </Text>
            <ChecksPublicacion name="publicarRespuestas" />
          </div>
        }
      />
    ),
    screenIndex: CemSteps.PublicarRespuestas,
    name: 'PublicarRespuestas',
    outputs: ['publicarRespuestas'],
    outputsText: ['publicarRespuestas'],
  },
  {
    fields: <ConfirmacionCem />,
    screenIndex: CemSteps.Confirmacion,
    name: 'Confirmacion',
    outputs: ['confirmacion'],
    outputsText: ['confirmacion'],
  },
];

  return stepsVenta;
}
import React from 'react';
import { useIsComerciales } from 'src/feature-app/hooks';
import { LssiTkmQuestionsComerciales, LssiTkmQuestionsTurismos } from '.';

export interface LssiTkmQuestionsProps {
  title: boolean;
  themeColor?: 'main' | 'inverted';
  noCompra?: boolean;
}

export function LssiTkmQuestions(props: LssiTkmQuestionsProps) {
  const isComerciales = useIsComerciales();

  return isComerciales ? <LssiTkmQuestionsComerciales {...props} /> : <LssiTkmQuestionsTurismos {...props} />;
}

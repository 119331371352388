import React, { useEffect, useState } from 'react';
import {
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  styled,
  Text,
  TextAlignment,
  BreakpointWrapper,
  Breakpoints,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { CloseHandleV2 } from '@volkswagen-onehub/layer-manager';
import { useDispatch, useSelector } from 'react-redux';
import { useTrackingManager, useFeatureAppConfig, useFeatureAppEnvironment } from 'src/feature-app';
import { OneFormState } from 'src/types';
import { removeConfigurationHash } from 'src/feature-app/remove-configuration-hash';

const Content = styled.div`
  background-color: white;
  padding: 44px 28px;
  flex: auto;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 560px) {
    padding: 52px 46px;
  }
  @media screen and (min-width: 960px) {
    padding: var(--size-grid001);
  }
`;

const copys = [
  {
    name: 'isCloseLayer',
    pregunta: '¿Seguro que quieres salir?',
    bodyCopy: 'Si lo haces, no se enviará el formulario.',
  },
  {
    name: 'isMotivoLayer',
    pregunta: '¿Seguro que quieres cambiar el motivo de tu visita?',
    bodyCopy: 'Si lo cambias, puede que tengas elegir un taller distinto. ',
  },
  {
    name: 'isTallerLayer',
    pregunta: '¿Seguro que quieres cambiar el taller para tu visita?',
    bodyCopy: 'Si lo cambias, puede que tengas que elegir una hora distinta.',
  },
  {
    name: 'isTimeLayer',
    pregunta: '¿Seguro que quieres cambiar la hora de tu visita?',
    bodyCopy: 'Si la cambias, puede que tengas que elegir un taller distinto.',
  },
];

interface AreYouSureProps {
  layerName: string;
  closeLayerCallback?: CloseHandleV2<any, any>;
  closeOneForm?: CloseHandleV2<any, any>;
  onClickProp: () => void;
}

export const AreYouSureLayer = (props: AreYouSureProps) => {
  const { layerName, closeLayerCallback, closeOneForm, onClickProp } = props;
  const dispatch = useDispatch();
  const [layerInfo, setLayerInfo] = useState<any>(null);
  const trackingManager = useTrackingManager();
  const { screenIndex } = useSelector((state: OneFormState) => state.formInfo);
  const config = useFeatureAppConfig();
  const env = useFeatureAppEnvironment();

  useEffect(() => {
    const aux = copys.filter((a) => a.name === layerName);
    setLayerInfo(aux);
  }, []);

  return (
    <Content>
      {layerInfo ? (
        <>
          <Text appearance={TokenTextAppearance.headline300} bold textAlign={TextAlignment.left}>
            {layerInfo[0].pregunta}
          </Text>
          <Container
            gutter={ContainerGutter.static500}
            wrap={'always'}
            horizontalAlign={'flex-start'}
            padding={{ top: ContainerPadding.dynamic0100 }}
          >
            {
              config.trigger === 'cita-posventa-dealer' && layerName !== 'isCloseLayer' ? null : (
                <Text textAlign={TextAlignment.left}>{layerInfo[0].bodyCopy}</Text>
              )
            }
          </Container>
          {layerName === 'isCloseLayer' ? (
            <>
              <BreakpointWrapper max={Breakpoints.b560}>
                <Container
                  horizontalAlign={'flex-start'}
                  padding={{ top: ContainerPadding.static500 }}
                  gutter={ContainerGutter.static350}
                  stretchContent
                >
                  <CTA
                    tag="button"
                    emphasis="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      closeLayerCallback({}, {}, {});
                    }}
                    stretchContent
                  >
                    No, quiero seguir
                  </CTA>
                  <CTA
                    tag="button"
                    emphasis="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch({ type: 'RESET_STATE' });
                      closeLayerCallback({}, {}, {});
                      closeOneForm({}, {}, {});
                      if (config.trigger === 'lead-configurador') {
                        removeConfigurationHash();
                      }
                      if (env.done) {
                        env.done();
                      }
                    }}
                    stretchContent
                  >
                    Sí, estoy seguro
                  </CTA>
                </Container>
              </BreakpointWrapper>
              <BreakpointWrapper min={Breakpoints.b560}>
                <Container
                  horizontalAlign={'flex-start'}
                  padding={{ top: ContainerPadding.static500 }}
                  gutter={ContainerGutter.static350}
                >
                  <div style={{ marginRight: '20px' }}>
                    <CTA
                      tag="button"
                      emphasis="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        closeLayerCallback({}, {}, {});
                      }}
                    >
                      No, quiero seguir
                    </CTA>
                  </div>
                  <CTA
                    tag="button"
                    emphasis="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      trackingManager.trackFormCloseClick(
                        {
                          contentId: 'Cerrar',
                        },
                        { FormStart: screenIndex === 0 ? true : false }
                      );
                      dispatch({ type: 'RESET_STATE' });
                      closeLayerCallback({}, {}, {});
                      closeOneForm({}, {}, {});
                      if (config.trigger === 'lead-configurador') {
                        removeConfigurationHash();
                      }
                      if (env.done) {
                        env.done();
                      }
                    }}
                  >
                    Sí, estoy seguro
                  </CTA>
                </Container>
              </BreakpointWrapper>
            </>
          ) : (
            <>
              <BreakpointWrapper max={Breakpoints.b560}>
                <Container
                  horizontalAlign={'flex-start'}
                  padding={{ top: ContainerPadding.static500 }}
                  gutter={ContainerGutter.static350}
                  stretchContent
                >
                  <CTA
                    tag="button"
                    emphasis="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      onClickProp();
                      closeLayerCallback({}, {}, {});
                      if (config.trigger === 'lead-configurador') {
                        removeConfigurationHash();
                      }
                      if (env.done) {
                        env.done();
                      }
                    }}
                    stretchContent
                  >
                    Sí, estoy seguro
                  </CTA>
                  <CTA
                    tag="button"
                    emphasis="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      closeLayerCallback({}, {}, {});
                    }}
                    stretchContent
                  >
                    No, quiero seguir en esta página
                  </CTA>
                </Container>
              </BreakpointWrapper>
              <BreakpointWrapper min={Breakpoints.b560}>
                <Container
                  horizontalAlign={'flex-start'}
                  padding={{ top: ContainerPadding.static500 }}
                  gutter={ContainerGutter.static350}
                >
                  <div style={{ marginRight: '20px' }}>
                    <CTA
                      tag="button"
                      emphasis="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        onClickProp();
                        closeLayerCallback({}, {}, {});
                        if (config.trigger === 'lead-configurador') {
                          removeConfigurationHash();
                        }
                      }}
                    >
                      Sí, estoy seguro
                    </CTA>
                  </div>
                  <CTA
                    tag="button"
                    emphasis="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      closeLayerCallback({}, {}, {});
                    }}
                  >
                    No, quiero seguir en esta página
                  </CTA>
                </Container>
              </BreakpointWrapper>
            </>
          )}
        </>
      ) : null}
    </Content>
  );
};

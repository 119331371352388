import { getOneformBFFServiceConfig } from 'src/feature-app';

const handleErrorInRetrievingExternalConfig = (): null => {
  console.error('Error retrieving OneForm BFF service config provider.');
  return null;
};

export const getOneformBFFUrl = (): string | null => {
  const oneformBFF = getOneformBFFServiceConfig();


  if (!oneformBFF) {
    return handleErrorInRetrievingExternalConfig();
  }

  return oneformBFF.urlOrigin + oneformBFF.urlPath;
};


export const isHighRating = (rating: string) => Number(rating) !== 0 && (Number(rating) > 3);
export const isLowRating = (rating: string) => Number(rating) !== 0 && Number(rating) <= 3;
export const isNoRating = (rating: string) => rating === 'Sin respuesta';

export const allHighRatings = (ratings: [...any]) => {
  for (let rating of ratings) {
    if (Number(rating) <= 3 || rating == 'Sin respuesta') {
      return false;
    }
  }
  return true;
};

export const allLowRatings = (ratings: [...any]) => {
  for (let rating of ratings) {
    if (Number(rating) > 3 || rating == 'Sin respuesta') {
      return false;
    }
  }
  return true;
};

export const allNoRatings = (ratings: [...any]) => {
  for (let rating of ratings) {
    if (rating != 'Sin respuesta') {
      return false;
    }
  }
  return true;
};

export const allHighOrNoRatings = (ratings: [...any]) => {
  for (let rating of ratings) {
    if (Number(rating) <= 3) {
      return false;
    }
  }
  return true;
};
export const getTestPerson = (noPermiteLssi?: boolean, date?: string) => {
	return [
		{
			name: "ID",
			value: "PERSON"
		},
		{
			name: "attributes",
			value: "es.vged.ndpsbackend.salesforce.rest.entities.SfAttributesRest@1aeef0f5[type=Account,url=/services/data/v53.0/sobjects/Account/0011X00001CebbnQAB]"
		},
		{
			name: "Id",
			value: "0011X00001CebbnQAB"
		},
		{
			name: "IsDeleted",
			value: "false"
		},
		{
			name: "MasterRecordId",
			value: "null"
		},
		{
			name: "Name",
			value: "MARIO LOPEZ2 MORENO2"
		},
		{
			name: "LastName",
			value: "LOPEZ2 MORENO2"
		},
		{
			name: "FirstName",
			value: "MARIO"
		},
		{
			name: "MiddleName",
			value: "null"
		},
		{
			name: "Salutation",
			value: "null"
		},
		{
			name: "Type",
			value: "null"
		},
		{
			name: "RecordTypeId",
			value: "0121i000000cHHfAAM"
		},
		{
			name: "ParentId",
			value: "null"
		},
		{
			name: "BillingAddress",
			value: "SfAddressRest(city=null, country=724, countryCode=ES, geocodeAccuracy=null, latitude=null, longitude=null, postalCode=null, state=null, stateCode=null, street=null)"
		},
		{
			name: "ShippingAddress",
			value: "SfAddressRest(city=null, country=724, countryCode=ES, geocodeAccuracy=null, latitude=null, longitude=null, postalCode=null, state=null, stateCode=null, street=null)"
		},
		{
			name: "PhotoUrl",
			value: "/services/images/photo/0011X00001CebbnQAB"
		},
		{
			name: "Phone",
			value: "null"
		},
		{
			name: "Fax",
			value: "null"
		},
		{
			name: "AccountNumber",
			value: "null"
		},
		{
			name: "WebSite",
			value: "null"
		},
		{
			name: "Sic",
			value: "null"
		},
		{
			name: "Industry",
			value: "null"
		},
		{
			name: "AnnualRevenue",
			value: "null"
		},
		{
			name: "NumberOfEmployees",
			value: "null"
		},
		{
			name: "Ownership",
			value: "null"
		},
		{
			name: "TickerSymbol",
			value: "null"
		},
		{
			name: "Description",
			value: "null"
		},
		{
			name: "Rating",
			value: "null"
		},
		{
			name: "Site",
			value: "null"
		},
		{
			name: "CurrencyIsoCode",
			value: "EUR"
		},
		{
			name: "OwnerId",
			value: "0051i000002usITAAY"
		},
		{
			name: "CreatedDate",
			value: date ? date : "2023-09-07T23:10:20.000+0000"
		},
		{
			name: "CreatedById",
			value: "0051i000002usITAAY"
		},
		{
			name: "LastModifiedDate",
			value: "2023-12-01T10:16:29.000+0000"
		},
		{
			name: "LastModifiedById",
			value: "0051i000002usITAAY"
		},
		{
			name: "SystemModstamp",
			value: "2023-12-01T10:16:29.000+0000"
		},
		{
			name: "LastActivityDate",
			value: "null"
		},
		{
			name: "LastViewedDate",
			value: "2023-12-14T09:56:46.000+0000"
		},
		{
			name: "LastReferencedDate",
			value: "2023-12-14T09:56:46.000+0000"
		},
		{
			name: "IsPartner",
			value: "false"
		},
		{
			name: "PersonContactId",
			value: "0031X00000zej2YQAQ"
		},
		{
			name: "IsPersonAccount",
			value: "true"
		},
		{
			name: "ChannelProgramName",
			value: "null"
		},
		{
			name: "ChannelProgramLevelName",
			value: "null"
		},
		{
			name: "PersonMailingAddress",
			value: "SfAddressRest(city=NO VALIDO, country=724, countryCode=ES, geocodeAccuracy=null, latitude=null, longitude=null, postalCode=08980, state=8, stateCode=8, street=null)"
		},
		{
			name: "PersonOtherAddress",
			value: "SfAddressRest(city=null, country=724, countryCode=ES, geocodeAccuracy=null, latitude=null, longitude=null, postalCode=null, state=null, stateCode=null, street=null)"
		},
		{
			name: "PersonMobilePhone",
			value: "675758541"
		},
		{
			name: "PersonHomePhone",
			value: "null"
		},
		{
			name: "PersonOtherPhone",
			value: "null"
		},
		{
			name: "PersonAssistantPhone",
			value: "null"
		},
		{
			name: "PersonEmail",
			value: "mario.lopez@volkswagengroup.es"
		},
		{
			name: "PersonTitle",
			value: "null"
		},
		{
			name: "PersonDepartment",
			value: "null"
		},
		{
			name: "PersonAssistantName",
			value: "null"
		},
		{
			name: "PersonLeadSource",
			value: "null"
		},
		{
			name: "PersonBirthDate",
			value: "null"
		},
		{
			name: "PersonHasOptedOutOfEmail",
			value: "false"
		},
		{
			name: "PersonHasOptedOutOfFax",
			value: "false"
		},
		{
			name: "PersonDoNotCall",
			value: "false"
		},
		{
			name: "PersonLastCURequestDate",
			value: "null"
		},
		{
			name: "PersonLastCUUpdateDate",
			value: "null"
		},
		{
			name: "PersonEmailBouncedReason",
			value: "null"
		},
		{
			name: "PersonEmailBouncedDate",
			value: "null"
		},
		{
			name: "PersonIndividualId",
			value: "0PK1X000008VQpKWAW"
		},
		{
			name: "Jigsaw",
			value: "null"
		},
		{
			name: "JigsawCompanyId",
			value: "null"
		},
		{
			name: "AccountSource",
			value: "null"
		},
		{
			name: "SicDesc",
			value: "null"
		},
		{
			name: "RCY_A4WcDateSent__c",
			value: "null"
		},
		{
			name: "RCY_DealerLeadRoutingPreference__c",
			value: "External System"
		},
		{
			name: "RCY_BrandFlagAUDI__c",
			value: "true"
		},
		{
			name: "RCY_BrandFlagSEAT__c",
			value: "false"
		},
		{
			name: "RCY_BrandFlagSKODA__c",
			value: "false"
		},
		{
			name: "RCY_BrandFlagVWCV__c",
			value: "true"
		},
		{
			name: "RCY_BrandFlagVW__c",
			value: "true"
		},
		{
			name: "Dealership__c",
			value: "null"
		},
		{
			name: "ExtKey__c",
			value: "null"
		},
		{
			name: "KVPSCode__c",
			value: "null"
		},
		{
			name: "PreferredCommunicationChannel__c",
			value: "null"
		},
		{
			name: "RCY_AccountBrand__c",
			value: "Volkswagen"
		},
		{
			name: "RCY_AccountEmail__c",
			value: "null"
		},
		{
			name: "RCY_BrandLogo__c",
			value: "<img src=\"/resource/VWLogo\" alt=\"Volkswagen\" style=\"height:25px; width:30px;\" border=\"0\"/>"
		},
		{
			name: "RCY_BrandSearch__c",
			value: "Volkswagen"
		},
		{
			name: "RCY_BusinessHours__c",
			value: "null"
		},
		{
			name: "RCY_ContractStatus__c",
			value: "null"
		},
		{
			name: "RCY_DealerActive__c",
			value: "false"
		},
		{
			name: "RCY_DealerStatus__c",
			value: "null"
		},
		{
			name: "RCY_DealerType__c",
			value: "null"
		},
		{
			name: "RCY_ExternalAccountID__c",
			value: "54147334_Volkswagen"
		},
		{
			name: "RCY_FleetSize__c",
			value: "null"
		},
		{
			name: "RCY_GroupID__c",
			value: "null"
		},
		{
			name: "RCY_IntegrationSource__c",
			value: "null"
		},
		{
			name: "RCY_InternationalBusinessAgreement__c",
			value: "false"
		},
		{
			name: "RCY_NumberOfLightCommercialVehicles__c",
			value: "null"
		},
		{
			name: "RCY_NumberOfPassengerCars__c",
			value: "null"
		},
		{
			name: "RCY_OpeningHours__c",
			value: "null"
		},
		{
			name: "RCY_Services__c",
			value: "null"
		},
		{
			name: "RCY_TaxNumber__c",
			value: "null"
		},
		{
			name: "RCY_ValidationIdentifier__c",
			value: "null"
		},
		{
			name: "RCY_BeneficiaryCountry__c",
			value: "null"
		},
		{
			name: "RCY_BeneficiaryName__c",
			value: "null"
		},
		{
			name: "RCY_BeneficiaryType__c",
			value: "null"
		},
		{
			name: "RCY_CollectionDate__c",
			value: "null"
		},
		{
			name: "RCY_Country__c",
			value: "null"
		},
		{
			name: "RCY_DealerMarketNumber__c",
			value: "null"
		},
		{
			name: "RCY_DealerNumber__c",
			value: "null"
		},
		{
			name: "RCY_Email__c",
			value: "null"
		},
		{
			name: "RCY_EtronWcDateSent__c",
			value: "null"
		},
		{
			name: "RCY_IsA4WcSent__c",
			value: "false"
		},
		{
			name: "RCY_IsEtronWcSent__c",
			value: "false"
		},
		{
			name: "RCY_MasterDataSource__c",
			value: "null"
		},
		{
			name: "RCY_PreferredDealerKVPSID__c",
			value: "null"
		},
		{
			name: "RCY_SEMAGMigrationID__c",
			value: "null"
		},
		{
			name: "RCY_SubscriberKey__c",
			value: "null"
		},
		{
			name: "VGED_IsAudi__c",
			value: "false"
		},
		{
			name: "VGED_IsLCV__c",
			value: "false"
		},
		{
			name: "VGED_IsSkoda__c",
			value: "false"
		},
		{
			name: "VGED_IsVolkswagen__c",
			value: "false"
		},
		{
			name: "VGED_BusinessAccountSource__c",
			value: "null"
		},
		{
			name: "VGED_ClientStage__c",
			value: "null"
		},
		{
			name: "VGED_Email2__c",
			value: "mario.vwgroup@gmail.com"
		},
		{
			name: "VGED_Email3__c",
			value: "null"
		},
		{
			name: "VGED_Fax1__c",
			value: "null"
		},
		{
			name: "VGED_IsFleet__c",
			value: "false"
		},
		{
			name: "VGED_LegacySFId__c",
			value: "null"
		},
		{
			name: "VGED_MasterAccount__c",
			value: "null"
		},
		{
			name: "VGED_MobilePhone2__c",
			value: "690807844"
		},
		{
			name: "VGED_MobilePhone3__c",
			value: "null"
		},
		{
			name: "VGED_NumberDeliveredOrdersAudi__c",
			value: "0.0"
		},
		{
			name: "VGED_NumberDeliveredOrdersLCV__c",
			value: "0.0"
		},
		{
			name: "VGED_NumberDeliveredOrdersSkoda__c",
			value: "0.0"
		},
		{
			name: "VGED_NumberDeliveredOrdersVW__c",
			value: "0.0"
		},
		{
			name: "VGED_OpenOpportunities__c",
			value: "0.0"
		},
		{
			name: "VGED_VolumeOpenOpportunities__c",
			value: "0.0"
		},
		{
			name: "VGED_ExpectedDeliveryDate__c",
			value: "null"
		},
		{
			name: "VGED_CustomerType__c",
			value: "F"
		},
		{
			name: "VGED_2Surname__c",
			value: "LOPEZ"
		},
		{
			name: "VGED_FinanceCoResponsibility__c",
			value: "0"
		},
		{
			name: "VGED_Remarks__c",
			value: "null"
		},
		{
			name: "VGED_InactiveRecord__c",
			value: "false"
		},
		{
			name: "VGED_BusinessName__c",
			value: "null"
		},
		{
			name: "VGED_TypeOfDocument__c",
			value: "null"
		},
		{
			name: "VGED_DealerCodeVaesa__c",
			value: "null"
		},
		{
			name: "VGED_IDClimapro__c",
			value: "null"
		},
		{
			name: "VGED_MDMSyncStatus__c",
			value: "Synced"
		},
		{
			name: "VGED_TechnicalMdmStatus__c",
			value: "null"
		},
		{
			name: "VGED_NumOfRetriesMDM__c",
			value: "null"
		},
		{
			name: "VGED_UpdateProcessAllowed__c",
			value: "false"
		},
		{
			name: "RCY_BEVAgency__c",
			value: "false"
		},
		{
			name: "VGED_RoadName__c",
			value: "null"
		},
		{
			name: "VGED_RoadNumber__c",
			value: "null"
		},
		{
			name: "VGED_RoadType__c",
			value: "null"
		},
		{
			name: "VGED_DesynchronizedAccount__c",
			value: "false"
		},
		{
			name: "RCY_SecondaryField__c",
			value: "Volkswagen"
		},
		{
			name: "VGED_Concession__c",
			value: "null"
		},
		{
			name: "VGED_DealerCode__c",
			value: "null"
		},
		{
			name: "VGED_CommercialName__c",
			value: "null"
		},
		{
			name: "VGED_Test__c",
			value: "false"
		},
		{
			name: "VGED_OutOfService__c",
			value: "false"
		},
		{
			name: "RCY_FleetAgency__c",
			value: "false"
		},
		{
			name: "RCY_HelperPersonAccountfields__c",
			value: "MARIO LOPEZ2 MORENO2, Homephone: , Mobile: 675758541"
		},
		{
			name: "RCY_Retail__c",
			value: "false"
		},
		{
			name: "et4ae5__HasOptedOutOfMobile__pc",
			value: "false"
		},
		{
			name: "et4ae5__Mobile_Country_Code__pc",
			value: "US"
		},
		{
			name: "RCY_IDKID__pc",
			value: "null"
		},
		{
			name: "RCY_GDPRStatusTimeStamp__pc",
			value: "null"
		},
		{
			name: "RCY_GDPRStatus__pc",
			value: "null"
		},
		{
			name: "RCY_MasterDataSource__pc",
			value: "null"
		},
		{
			name: "Customer_Number__pc",
			value: "null"
		},
		{
			name: "ExtKey__pc",
			value: "null"
		},
		{
			name: "RCY_AcademicTitle__pc",
			value: "null"
		},
		{
			name: "RCY_Birthdate__pc",
			value: "null"
		},
		{
			name: "RCY_BrandFlagAUDI__pc",
			value: "false"
		},
		{
			name: "RCY_BrandFlagSEAT__pc",
			value: "false"
		},
		{
			name: "RCY_BrandFlagSKODA__pc",
			value: "false"
		},
		{
			name: "RCY_BrandFlagVWCV__pc",
			value: "false"
		},
		{
			name: "RCY_BrandFlagVW__pc",
			value: "true"
		},
		{
			name: "RCY_ContactBrand__pc",
			value: "null"
		},
		{
			name: "RCY_CorrespondenceGreeting__pc",
			value: "MARIO LOPEZ2 MORENO2"
		},
		{
			name: "RCY_ExternalID__pc",
			value: "null"
		},
		{
			name: "RCY_GDPRRequestInProgress__pc",
			value: "false"
		},
		{
			name: "RCY_Mobile__pc",
			value: "675758541"
		},
		{
			name: "RCY_Phone__pc",
			value: "null"
		},
		{
			name: "RCY_PreferredChannelOfContact__pc",
			value: "null"
		},
		{
			name: "RCY_PreferredLanguage__pc",
			value: "null"
		},
		{
			name: "RCY_StopMarketingAudi__pc",
			value: "false"
		},
		{
			name: "RCY_StopMarketingSeat__pc",
			value: "false"
		},
		{
			name: "RCY_StopMarketingSkoda__pc",
			value: "false"
		},
		{
			name: "RCY_StopMarketingVWCV__pc",
			value: "false"
		},
		{
			name: "RCY_StopMarketingVW__pc",
			value: "false"
		},
		{
			name: "RCY_StopMarketing__pc",
			value: "false"
		},
		{
			name: "RCY_ValidationIdentifier__pc",
			value: "null"
		},
		{
			name: "RCY_LastMarketingPressureMeasuredDate__pc",
			value: "null"
		},
		{
			name: "RCY_MarketingPressureCall__pc",
			value: "Green"
		},
		{
			name: "RCY_MarketingPressureEmail__pc",
			value: "Green"
		},
		{
			name: "RCY_MarketingPressureLetter__pc",
			value: "Green"
		},
		{
			name: "RCY_MarketingPressureSMS__pc",
			value: "Green"
		},
		{
			name: "RCY_MarketingPressureTotal__pc",
			value: "Green"
		},
		{
			name: "RCY_NumberOfCalls__pc",
			value: "null"
		},
		{
			name: "RCY_NumberOfEmails__pc",
			value: "null"
		},
		{
			name: "RCY_NumberOfLetters__pc",
			value: "null"
		},
		{
			name: "RCY_NumberOfSMS__pc",
			value: "null"
		},
		{
			name: "RCY_NumberOfTotalCommunications__pc",
			value: "null"
		},
		{
			name: "RCY_BirthPlace__pc",
			value: "null"
		},
		{
			name: "RCY_ConsentDealer__pc",
			value: "false"
		},
		{
			name: "RCY_ConsentNSC__pc",
			value: "null"
		},
		{
			name: "RCY_ConsentOEM__pc",
			value: "false"
		},
		{
			name: "RCY_CustomerKey__pc",
			value: "null"
		},
		{
			name: "RCY_Language__pc",
			value: "null"
		},
		{
			name: "RCY_LastCommercialEmailname__pc",
			value: "null"
		},
		{
			name: "RCY_MarketingIndependentOfChannel__pc",
			value: "false"
		},
		{
			name: "RCY_OEMPushNotificationConsent__pc",
			value: "false"
		},
		{
			name: "RCY_PersonType__pc",
			value: "null"
		},
		{
			name: "RCY_RecommendationExpirationDate__pc",
			value: "null"
		},
		{
			name: "RCY_RecommendationLatestExpirationDate__pc",
			value: "null"
		},
		{
			name: "RCY_RegistrationDate__pc",
			value: "null"
		},
		{
			name: "RCY_SurveyNSC__pc",
			value: "null"
		},
		{
			name: "RCY_SurveyOEM__pc",
			value: "false"
		},
		{
			name: "VGED_EventParticipation__pc",
			value: "false"
		},
		{
			name: "RCY_BrandFlagCUPRA__pc",
			value: "false"
		},
		{
			name: "VGED_IsFleet__pc",
			value: "false"
		},
		{
			name: "VGED_PrimaryContact__pc",
			value: "false"
		},
		{
			name: "VGED_Role__pc",
			value: "null"
		},
		{
			name: "VGED_Interest__pc",
			value: "null"
		},
		{
			name: "VGED_LegacySFId__pc",
			value: "null"
		},
		{
			name: "VGED_2Surname__pc",
			value: "null"
		},
		{
			name: "VGED_RoadName__pc",
			value: "null"
		},
		{
			name: "VGED_RoadNumber__pc",
			value: "null"
		},
		{
			name: "VGED_RoadType__pc",
			value: "null"
		},
		{
			name: "RCY_DeletedMarketingCloud__pc",
			value: "false"
		},
		{
			name: "RCY_FleetAgency__pc",
			value: "false"
		},
		{
			name: "RCY_MCReadyBrandAUDI__pc",
			value: "false"
		},
		{
			name: "RCY_MCReadyBrandCUPRA__pc",
			value: "false"
		},
		{
			name: "RCY_MCReadyBrandSEAT__pc",
			value: "false"
		},
		{
			name: "RCY_MCReadyBrandSKODA__pc",
			value: "false"
		},
		{
			name: "RCY_MCReadyBrandVWCV__pc",
			value: "false"
		},
		{
			name: "RCY_MCReadyBrandVW__pc",
			value: "true"
		},
		{
			name: "RCY_Retail__pc",
			value: "false"
		},
		{
			name: "RCY_BEVAgency__pc",
			value: "false"
		},
		{
			name: "VGED_DischargeBDNDate__pc",
			value: "null"
		},
		{
			name: "VGED_TerminationBDNDate__pc",
			value: "null"
		},
		{
			name: "VGED_IdCard__pc",
			value: "null"
		}
	]
};
(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("styled-components"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-dom", "styled-components"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("react-dom"), require("styled-components")) : factory(root["react"], root["react-dom"], root["styled-components"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})((typeof self !== 'undefined' ? self : this), (__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__7111__, __WEBPACK_EXTERNAL_MODULE__1608__) => {
return 
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getCarlines, getCarlinesCitaLegacy } from 'src/bff';
import { GeneralInputWidthWrapper, IDBuzzFakeInput } from 'src/components';
import { isNull, useFaContent } from 'src/feature-app';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { useIsComerciales } from 'src/feature-app/hooks';
import { CarlineBase, CarlinesWithSalesgroups, OneFormState } from 'src/types';
import { SelectExtended } from 'src/components';
import { InputError, TextInputExtended } from 'src/components/inputs';
import {
  formatCEMVenta,
  formatAllSalesgroups,
  formatAllCarlines,
  filterCarlineFlag,
  getFormattedSalesgroups,
  filterPreselectedFaContent,
  filterPreselectedFaContentSalesgroups,
  findCarline,
  findCarlineFromSalesgroup,
  thereIsCarlinesInFaContent,
  thereIsSalesgroupsInFaContent,
  isSalesGroupMode,
  isMixedMode,
} from 'src/components/CarlineSelect';
export interface SelectExtendedList {
  code: string;
  name: string;
  key: string;
}
interface CarlineSelectProps {
  useCarline?: boolean;
  isCEMVenta?: boolean;
  notALayer?: boolean;
  noPaddingTop?: boolean;
  alphabetical?: boolean;
  nextOnSelect?:boolean
}

export function CarlineSelect(props: CarlineSelectProps) {
  const { useCarline, notALayer, noPaddingTop, isCEMVenta, alphabetical, nextOnSelect } = props;
  const { register, errors } = useFormContext();
  const [errorModelo, setErrorModelo] = useState(false);
  const { formData, dealersInfo, formInfo } = useSelector((state: OneFormState) => state);
  const { fields } = formData;
  const { screenIndex } = formInfo;
  const { carlines } = dealersInfo;
  const { error, handleNextStep } = useOneFormContext();
  const [list, setList] = useState<SelectExtendedList[]>(null);
  const dispatch = useDispatch();
  const [modelo, setModelo] = useState(null);
  const faContent = useFaContent();
  const screenIndexRef = useRef(null);
  const isComerciales = useIsComerciales();


  /**
   * En modo mixed se fija puede fijar un salesgroup al principio y en caso de que se acabe eligiendo un carline, se ha de eliminar la primera selección.
   */
  const dispatchEmptySalesgroup = () => dispatchSetSalesgroup(null, null);
  const dispatchSalesgroup = (id: number, name: string) => dispatchSetSalesgroup(id, name);

  const dispatchSetSalesgroup = (id: number, name: string) => {
    dispatch({
      type: 'SET_SALESGROUP',
      payload: {
        salesGroupId: id,
        salesGroupName: name,
      },
    });
  };

  const updateCarlineCode = (carlineCode: number, salesgroupName?: string) => {
    if (carlines) {
      let carline: CarlineBase | CarlinesWithSalesgroups;

      if (isSalesGroupMode(faContent)) {
        carline = findCarlineFromSalesgroup(carlines, carlineCode);
        if (carline) {
          dispatchSalesgroup(carlineCode, salesgroupName);
        } else {
          carline = findCarline(carlines, carlineCode);
        }
      } else if (isMixedMode(faContent)) {
        // Primero buscamos carline
        carline = findCarline(carlines, carlineCode);

        dispatchEmptySalesgroup();

        if (!carline) {
          // Buscamos salesgroup
          carline = findCarlineFromSalesgroup(carlines, carlineCode);
          dispatchSalesgroup(carlineCode, salesgroupName);
        }
      } else {
        carline = findCarline(carlines, carlineCode);
        // dispatchEmptySalesgroup();
      }
      dispatch({ type: 'SET_SELECTED_CARLINE', payload: carline });
      if (!isCEMVenta) {
        dispatch({ type: 'UPDATE_FIELDS', payload: { carlineCode: carline.code } });
      }
    }
  };

  /**
   * Inicializa la lista del selector
   * @param list
   */
  const handleUpdateList = (list: SelectExtendedList[]) => {
    if (!list) {
      return;
    }
    setList(list);
    if (fields.modelo) {
      const modeloInList = list.find((listValue) => listValue.name === fields.modelo);
      // setModelo(modeloInList.name);
      changeModeloFields(modeloInList.name, list);
      updateCarlineCode(Number(modeloInList.code));
    } else {
      // Valor inicial del modelo vacío
      changeModeloFields('', list);
    }
  };

  const initializeSelectWithCarline = async () => {
    // Eliminamos todos los carlines que no tienen el flag correspondiente del formulario activado.
    const filteredCarlines = filterCarlineFlag(carlines, fields.formularioFlag);
    let carlinesFromFaContent: CarlineBase[];
    let allCarlines: any[];

    if (thereIsCarlinesInFaContent(faContent)) {
      // Filtramos los carlines del FA Content.
      allCarlines = faContent.carlines.filter(c => c.toString() != "")

      if (/[0-9]/.test(allCarlines[0])) {
        carlinesFromFaContent = filteredCarlines.filter((c) => allCarlines.map(Number).includes(c.code));
      } else {
        carlinesFromFaContent = filteredCarlines.filter((c) => allCarlines.map(String).includes(c.name));
      }
      console.log('carllineSelect', carlinesFromFaContent);
      // const carlinesFromFaContent = filterPreselectedFaContent(filteredCarlines, faContent.carlines);
      const carlinesFormatted = formatAllCarlines(carlinesFromFaContent, alphabetical);
      handleUpdateList(carlinesFormatted);
      return;
    } else if (useCarline) {
      if (!carlines) {
        const newCarlines = await getCarlines();
        const newfilteredCarlines = filterCarlineFlag(newCarlines, fields.formularioFlag);
        const carlinesFormatted = formatAllCarlines(newfilteredCarlines, alphabetical);
        handleUpdateList(carlinesFormatted);
        return;
      }
      // En caso de que se necesiten los carlines de getCarlines de forma genérica.
      const carlinesFormatted = formatAllCarlines(filteredCarlines, alphabetical);
      handleUpdateList(carlinesFormatted);
      return;
    } else {
      // Para la pantalla de modelo y matrícula de cita se necesitan unos modelos específicos.
      const carlines = await getCarlinesCitaLegacy(isComerciales);
      handleUpdateList(carlines);
    }
  };

  const initializeSelectWithSalesgroup = async () => {
    // Eliminamos todos los carlines que no tienen el flag correspondiente del formulario activado.
    const filteredCarlines = filterCarlineFlag(carlines, fields.formularioFlag);

    if (thereIsSalesgroupsInFaContent(faContent)) {
      const salesgroupsFromFaContent = filterPreselectedFaContentSalesgroups(filteredCarlines, faContent.salesgroups);
      const formatedSalesgroups = formatAllSalesgroups(salesgroupsFromFaContent);
      if (!formatedSalesgroups || formatedSalesgroups.length === 0) {
        initializeSelectWithCarline();
        return;
      }
      handleUpdateList(formatedSalesgroups);
      return;
    } else if (useCarline) {
      // En caso de que se necesiten los carlines de getSalesgroup.
      const formatedSalesgroups = getFormattedSalesgroups(filteredCarlines);
      if (!formatedSalesgroups || formatedSalesgroups.length === 0) {
        initializeSelectWithCarline();
        return;
      }
      handleUpdateList(formatedSalesgroups);
      return;
    }
  };
  const initializeSelectWithMixed = async () => {
    // Eliminamos todos los carlines que no tienen el flag correspondiente del formulario activado.
    const filteredCarlines = filterCarlineFlag(carlines, fields.formularioFlag);

    let result: SelectExtendedList[] = [];

    if (thereIsSalesgroupsInFaContent(faContent)) {
      const salesgroupsFromFaContent = filterPreselectedFaContentSalesgroups(filteredCarlines, faContent.salesgroups);
      const formatedSalesgroups = formatAllSalesgroups(salesgroupsFromFaContent);
      result = [...result, ...formatedSalesgroups];
    }
    if (thereIsCarlinesInFaContent(faContent)) {
      const carlinesFromFaContent = filterPreselectedFaContent(filteredCarlines, faContent.carlines);
      const carlinesFormatted = formatAllCarlines(carlinesFromFaContent, alphabetical);
      result = [...result, ...carlinesFormatted];
    }
    if (!result || result.length === 0) {
      initializeSelectWithCarline();
    }
    handleUpdateList(result);
  };

  const initializeSelectWithCEMVenta = async () => {
    // Eliminamos todos los carlines que no tienen el flag correspondiente del formulario activado.
    const newCarlines = await getCarlines();
    const newfilteredCarlines = filterCarlineFlag(newCarlines, fields.formularioFlag);
    const carlinesFormatted = formatCEMVenta(newfilteredCarlines);

    handleUpdateList(carlinesFormatted);
    return;
  };

  useEffect(() => {
    if (isSalesGroupMode(faContent)) {
      initializeSelectWithSalesgroup();
    } else if (isMixedMode(faContent)) {
      initializeSelectWithMixed();
    } else if (isCEMVenta) {
      initializeSelectWithCEMVenta();
    } else {
      initializeSelectWithCarline();
    }
  }, []);

  useEffect(() => {
    // Hack para guardar la información del carline únicamente al pasar a la siguiente pantalla y no enviar datos
    // de analítica del carline seleccionado por defecto.

    if (isNull(screenIndexRef.current)) {
      screenIndexRef.current = screenIndex;
    } else {
      if (useCarline) {
        const carline = list.find((carline: any) => carline.name === modelo);
        if (carline) {
          if (isSalesGroupMode(faContent) || isMixedMode(faContent)) {
            updateCarlineCode(Number(carline.code), carline.name);
          } else {
            updateCarlineCode(Number(carline.code));
          }
        }
      }
    }
  }, [screenIndex]);

  useEffect(() => {
    errors.modelo || (error && !modelo ) ? setErrorModelo(true) : setErrorModelo(false);
  }, [errors, error]);

  const changeModeloFields = (model: string, list: SelectExtendedList[]) => {
    setModelo(model);
    const modeloList = list?.find((element) => element.name === model);
    dispatch({ type: 'UPDATE_FIELDS', payload: { modeloList } });
  };

  function handleChangeModelo(event: any) {
    const newModelo = event.currentTarget.value;

    if (newModelo) {
      changeModeloFields(newModelo, list);
    }

    if (newModelo !== '') {
      setErrorModelo(false);
    }

    if (nextOnSelect) {
      handleNextStep();
    }
  }

  return (
    <GeneralInputWidthWrapper notALayer={notALayer} noPaddingTop={noPaddingTop}>
      {isComerciales && fields?.idBuzzExclusive ?
        <>
          <IDBuzzFakeInput
            name="modelo"
            isFloating={true}
            defaultValue="ID. Buzz"
            value="ID. Buzz"
            required
            updateFields
            changeModel={changeModeloFields}
            setOption={setModelo}
            isRequired={true}
          />
        </>
      :
      list ? (
        <>
          <SelectExtended
            name="modelo"
            label="Selecciona tu modelo"
            isFloating={true}
            defaultValue=""
            value={modelo}
            required
            options={list}
            handleChange={handleChangeModelo}
            onClick={() => {}}
            updateFields
            setOption={setModelo}
            isRequired={true}
          />
        </>
      ) : (
        <div style={{ paddingBottom: '2px' }}>
          <TextInputExtended
            innerRef={register({
              required: {
                value: true,
                message: 'Requerido',
              },
            })}
            name="modelo"
            label="Modelo"
            isFloating
          />
        </div>
      )}
      {errorModelo ? <InputError className="input-error">Este campo es obligatorio</InputError> : null}
      {<input name="modelo" hidden value={modelo} />}
    </GeneralInputWidthWrapper>
  );
}

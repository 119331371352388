import { Checkbox, CTA, styled, Text, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIsComerciales } from 'src/feature-app/hooks';
import { InputError } from 'src/components';

interface politicaPrivacidadProps {
  color?: string;
}

const WhiteLinkWrapper = styled.span<politicaPrivacidadProps>`
  a {
    color: ${(props) => (props.color ? props.color : '#ffffff')};
    font-weight: bold;
  }
`;

export const PoliticaPrivacidad = (props: politicaPrivacidadProps) => {
  const { register, errors, clearError } = useFormContext();
  const isComerciales = useIsComerciales();
  const [checked, setChecked] = useState(!isComerciales);
  return (
    <div style={{ display: 'flex', paddingBottom: '44px', flexDirection: 'column', gap: '12px' }}>
      <div style={{ display: 'flex', gap: '12px' }}>
        {isComerciales &&
          <Checkbox
            name="politicaPrivacidad"
            onClick={() => {
              if (!checked) {
                clearError('politicaPrivacidad');
              }
              setChecked(!checked);
            }}
            ref={register({
              required: { value: true, message: 'Requerido' },
              validate: (value) => {
                return value === 'PERMITIDO';
              },
            })}
            value={checked ? 'PERMITIDO' : 'NO PERMITIDO'}
          />

        }

        <Text appearance={TokenTextAppearance.copy200}>
          {isComerciales ? 'Acepto' : 'Enviando este formulario declaras estar informado de'} la{' '}
          <WhiteLinkWrapper color={props.color}>
            <CTA
              tag="a"
              href={
                isComerciales
                  ? 'https://www.volkswagen-comerciales.es/es/volkswagen-espana/datos-privacidad.html'
                  : 'https://www.volkswagen.es/es/volkswagen-espana/politica-privacidad.html'
              }
              target="_blank"
              emphasis="tertiary"
            >
              política de privacidad.
            </CTA>
          </WhiteLinkWrapper>
        </Text>
      </div>
      {errors && errors.politicaPrivacidad ? (
        <InputError maxWidth="fit-content">Necesitamos esta información para seguir</InputError>
      ) : null}
    </div>
  );
};

import React, { Ref, SyntheticEvent, useEffect, useState } from 'react';
import { TextInput, InputAppearance, TextInputProps } from '@volkswagen-onehub/components-core';
import { useFormContext } from 'react-hook-form';
import { useFeatureServices, useOneFormContext, useTrackingManager } from 'src/feature-app';
import { FieldType } from 'src/feature-service/es-oneform-core-feature-service/types';
import { useSelector } from 'react-redux';
import { OneFormState } from 'src/types';

export interface TextInputExtendedProps extends TextInputProps {
  name: FieldType;
  innerRef?: Ref<HTMLInputElement>;
  /**
   * En caso de que el input esté junto con un CTA, se debe de pasar el label del CTA para en caso de que se seleccione el CTA no se refleje en el input.
   */
  alternateCTAText?: string;
  type?: string;
  disabled?: boolean;
  // errors: any;
}

export function TextInputExtended(props: TextInputExtendedProps) {
  const { innerRef, name, onBlur, required, alternateCTAText, onChange, label, type, onFocus, disabled } = props;
  const { errors, register, setValue } = useFormContext();
  const { handleNextStep } = useOneFormContext();
  const trackingManager = useTrackingManager();

  const oneFormFeatureService = useFeatureServices()['es:oneform-core'];
  const { formData } = useSelector((state: OneFormState) => state);
  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    const value = formData.fields[name];

    if (value && value !== alternateCTAText && value != 'null' && value != 'false' && value != '0') {
      setCurrentValue(value);
    }
  }, []);

  const centerInputInMobile = (target: EventTarget & HTMLInputElement) => {
    if (target && window.innerWidth < 959) {
      target.scrollIntoView({ block: 'center' });
    }
  };

  return (
    <TextInput
      {...props}
      appearance={errors[name] ? InputAppearance.Error : disabled ? InputAppearance.Disabled : InputAppearance.Default}
      ref={innerRef ? innerRef : register}
      onChange={(e: SyntheticEvent<HTMLInputElement>) => {
        setCurrentValue(e.currentTarget.value);
        if (onChange) {
          onChange(e);
        }
      }}
      onBlur={(e: SyntheticEvent<HTMLInputElement>) => {
        if (onBlur) onBlur(e);
        setValue(name, e.currentTarget.value);
        oneFormFeatureService.setField(name, e.currentTarget.value);
      }}
      onFocus={(e: any) => {
        centerInputInMobile(e.target);
        trackingManager.trackFormFieldClick(
          {
            contentId: label,
          },
          {
            FormFieldName: name,
          }
        );
        if (onFocus) {
          onFocus(e);
        }
      }}
      type={type ? type : 'text'}
      isFloating={!currentValue ? true : false}
      value={currentValue}
    />
  );
}

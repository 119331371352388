import React from 'react';
import {
  Layout,
  Breakpoints,
  LayoutRowGap,
  styled,
} from '@volkswagen-onehub/components-core';

interface OneElementTemplateProps {
  element: JSX.Element;
  allowOverflowingContent?: boolean;
  noPadding?: boolean;
}

// Refactorizar
export function OneElementTemplateFullScreen(props: OneElementTemplateProps) {
  const { element, allowOverflowingContent, noPadding = false } = props;
  return (
    <Layout
      allowOverflowingContent={allowOverflowingContent ? true : false}
      appearance={noPadding ? 
        {
          [Breakpoints.default]: [
            { name: 'a', columns: 24 }
          ]
        }
        :
        {
        [Breakpoints.default]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ],
        [Breakpoints.b960]: [
          { name: '.', columns: 3 },
          { name: 'a', columns: 18 },
          { name: '.', columns: 3 },
        ],
        [Breakpoints.b1280]: [
          { name: '.', columns: 5 },
          { name: 'a', columns: 14 },
          { name: '.', columns: 5 },
        ],
        [Breakpoints.b1920]: [
          { name: '.', columns: 6 },
          { name: 'a', columns: 12 },
          { name: '.', columns: 6 },
        ],
        [Breakpoints.b2560]: [
          { name: '.', columns: 7 },
          { name: 'a', columns: 10 },
          { name: '.', columns: 7 },
        ],
      }}
      rowGap={LayoutRowGap.static300}
    >
      {element}
    </Layout>
  );
}

interface TwoElementsTemplateProps {
  firstElement: JSX.Element;
  secondElement: JSX.Element;
  allowOverflowingContent?: boolean;
}

export function TwoElementsTemplateFullScreen(props: TwoElementsTemplateProps) {
  const { firstElement, secondElement } = props;
  return (
    <Layout
      appearance={{
        [Breakpoints.default]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ],
        [Breakpoints.b960]: [
          { name: '.', columns: 3 },
          { name: 'a', columns: 18 },
          { name: '.', columns: 3 },
        ],
        [Breakpoints.b1280]: [
          { name: '.', columns: 5 },
          { name: 'a', columns: 14 },
          { name: '.', columns: 5 },
        ],
        [Breakpoints.b1920]: [
          { name: '.', columns: 6 },
          { name: 'a', columns: 12 },
          { name: '.', columns: 6 },
        ],
        [Breakpoints.b2560]: [
          { name: '.', columns: 7 },
          { name: 'a', columns: 10 },
          { name: '.', columns: 7 },
        ],
      }}
      rowGap={LayoutRowGap.static300}
    >
      {firstElement}
      {secondElement}
    </Layout>
  );
}

interface ThreeElementsTemplateProps {
  firstElement: JSX.Element;
  secondElement: JSX.Element;
  thirdElement: JSX.Element;
  allowOverflowingContent?: boolean;
}

export function ThreeElementsTemplateFullScreen(props: ThreeElementsTemplateProps) {
  const { firstElement, secondElement, thirdElement } = props;
  return (
    <Layout
      appearance={{
        [Breakpoints.default]: [
          { name: '.', columns: 2 },
          { name: 'a', columns: 20 },
          { name: '.', columns: 2 },
        ],
        [Breakpoints.b960]: [
          { name: '.', columns: 3 },
          { name: 'a', columns: 18 },
          { name: '.', columns: 3 },
        ],
        [Breakpoints.b1280]: [
          { name: '.', columns: 5 },
          { name: 'a', columns: 14 },
          { name: '.', columns: 5 },
        ],
        [Breakpoints.b1920]: [
          { name: '.', columns: 6 },
          { name: 'a', columns: 12 },
          { name: '.', columns: 6 },
        ],
        [Breakpoints.b2560]: [
          { name: '.', columns: 7 },
          { name: 'a', columns: 10 },
          { name: '.', columns: 7 },
        ],
      }}
      rowGap={LayoutRowGap.static300}
    > 
      {firstElement}
      {secondElement}
      {thirdElement}
    </Layout>
  );
}

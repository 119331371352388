import React, { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  FormController,
  useFaContent,
  useFeatureAppConfig,
  useFeatureServices,
  useOneFormContext,
} from 'src/feature-app';
import { VehiclesIdentityKit } from 'src/feature-service/es-oneform-core-feature-service/types';
import { removeStep, resetStepsScreenIndex } from 'src/forms/helpers/add-remove-steps';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import { ThankYouNuevaCita } from './ThankYouNuevaCita';

interface NuevaCitaPosventaFormProps {
  isCitaPosventaDealer?: boolean;
}

interface FormProps {
  defaultSteps: Steps[];
  sendForm: any;
  firstStep: Steps;
  CitaPosventaData: Partial<FormDataGroup>;
}

export function NuevaCitaPosventaFormLogged({ defaultSteps, sendForm, firstStep, CitaPosventaData }: FormProps) {
  const { initializeForm, nextScreenIndex } = useOneFormContext();
  const { formData, formInfo, dealersInfo } = useSelector((state: OneFormState) => state);
  const dispatch = useDispatch();

  const oneFormService = useFeatureServices()['es:oneform-core'];
  const { person } = oneFormService.get();
  const config = useFeatureAppConfig();

  const userHasFavouriteDealer = (steps: Steps[]) => {
    if (person?.favouriteDealer) {
      const selectedDealer = dealersInfo.dealers?.find((dealer) => dealer.kvps === person.favouriteDealer);
      if (selectedDealer) {
        // Sobreescribir el selected dealer en el caso de cita posventa da problemas a la hora de cargar el calendario.
        if (config.trigger !== 'cita-posventa-dealer') {
          dispatch({ type: 'SET_SELECTED_DEALER', payload: selectedDealer });
          dispatch({ type: 'UPDATE_SUGGESTION', payload: selectedDealer });
        }
        dispatch({ type: 'SET_FAVOURITE_DEALER', payload: selectedDealer });
        dispatch({ type: 'SET_SHOW_CALENDAR_ON_LOAD', payload: true });
      }
    }

    return steps;
  };
  const userHasPersonalData = (steps: Steps[]) => {
    let personalDataSteps = [...steps];

    if (person?.name && person?.surname) {
      personalDataSteps = removeStep(personalDataSteps, 'NombreApellido');
    }
    if (person?.cellphone) {
      personalDataSteps = removeStep(personalDataSteps, 'Telefono');
    }
    personalDataSteps = removeStep(personalDataSteps, 'Email');
    personalDataSteps = resetStepsScreenIndex(personalDataSteps);
    return personalDataSteps;
  };

  const userHasVehicleData = (steps: Steps[]) => {
    let vehicleDataSteps = [...steps];

    if (person?.userVehicles && person?.userVehicles.length > 0) {
      const { userVehicles } = person;
      let mainVehicle: VehiclesIdentityKit;
      if (userVehicles.length === 1) {
        mainVehicle = userVehicles[0];
      } else {
        mainVehicle = userVehicles.find((vehicle) => vehicle.isMainVehicle);
      }

      dispatch({
        type: 'UPDATE_FIELDS',
        payload: { matricula: mainVehicle.matricula, kilometros: mainVehicle.kilometros, modelo: mainVehicle.modelo },
      });

      // Consultar si se eliminan las páginas en caso de que falte algún dato (año de matriculación no parece estar disponible desde identity kit)
      if (mainVehicle.matricula && mainVehicle.modelo && mainVehicle.anoMatriculacion) {
        vehicleDataSteps = removeStep(vehicleDataSteps, 'Matricula');
      }
      if (mainVehicle.kilometros) {
        vehicleDataSteps = removeStep(vehicleDataSteps, 'Kilometros');
      }

      vehicleDataSteps = resetStepsScreenIndex(vehicleDataSteps);
    }
    return vehicleDataSteps;
  };

  const getLoggedSteps = (steps: Steps[]) => {
    let finalSteps = [...steps];
    finalSteps = userHasFavouriteDealer(finalSteps);
    finalSteps = userHasPersonalData(finalSteps);
    finalSteps = userHasVehicleData(finalSteps);

    return finalSteps;
  };

  useEffect(() => {
    const finalSteps = getLoggedSteps(defaultSteps);

    const lastStep = finalSteps[finalSteps.length - 1];

    const CitaPosventaInfo: Partial<FormInfo> = {
      formEnded: false,
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens: defaultSteps.length,
      screenIndex: firstStep.screenIndex,
      step: firstStep,
      steps: finalSteps,
      notALayer: false,
      formType: 'layer',
      stepsHistory: [],
      thankYouComponent: <ThankYouNuevaCita />,
    };

    initializeForm(CitaPosventaInfo, CitaPosventaData);
  }, []);

  return <FormController steps={defaultSteps} screenType="layer-screen" />;
}

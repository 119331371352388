import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { MagnifierLabel, CloseButton, SuggestionWrapper, StyledWrapper } from 'src/feature-app/NewMap';
import { Close, Magnifier } from 'src/icons-core-imports';
import { Text, ThemeProvider, styled, CTA, TokenTextAppearance, TokenTextColor } from '@volkswagen-onehub/components-core';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { getVehiculosModelo } from 'src/bff';
import { modelCar, OneFormState } from 'src/types';
import { useFormContext } from 'react-hook-form';
import { useDropdowPosition } from 'src/feature-app/hooks';

interface SuggestModeloProps {
  notALayer: boolean;
  marginTop?: string;
  fieldsName: string;
  required: boolean;
  outputsSeparados?: boolean;
  filterVW?:boolean;
  nextOnClick?: boolean;
  alternatCTA?: boolean;
}

const FechaCompraWrapper = styled.div<SuggestModeloProps>`
  margin-top: ${(props) => props.marginTop ? props.marginTop : '44px'};
  width: var(--size-grid020);
  position:relative;
  overflow: visible ;
  margin-left: ${(props) => (props.notALayer ? null : 'var(--size-grid002)')};

  @media all and (max-height: 624px) {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '24px')};
  }

  @media all and (min-width: 560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid013)' : '303px')};
  }
  @media all and (min-width: 960px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid008)' : '320px')};
    margin-left: ${(props) => (props.notALayer ? null : '122px')};
  }
  @media all and (min-width: 1280px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid006)' : '320px')};
  }
  @media all and (min-width: 1920px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid005)' : '320px')};
  }
  @media all and (min-width: 2560px) {
    width: ${(props) => (props.notALayer ? 'var(--size-grid004)' : '320px')};
  }
`;

const ModeloInteresWrapper = styled.div<SuggestModeloProps>`
  display: ${(props)=> props.alternatCTA ? 'flex' : null};
  flex-wrap: ${(props)=> props.alternatCTA ? 'wrap' : null};

  @media all and (min-width: 560px) {
    gap: ${(props)=> props.alternatCTA ? '44px' : null};
  }
`;

const ButtonWidth = styled.div`
  margin-top: 44px;

 button {
    width: 81px;
  }
`;

const escapeRegexCharacters = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};
// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value: string, list: modelCar[]) => {
  if (value.length < 2) return [];
  const escapedValue = escapeRegexCharacters(value.trim());

  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp(escapedValue, 'i');

  return list.filter((car: modelCar) => regex.test(car.title));
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion: modelCar) => suggestion.title;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion: modelCar) => (
  <SuggestionWrapper>
    <Text appearance={TokenTextAppearance.copy200} color={TokenTextColor.inherit}>
      {suggestion.title}
    </Text>
  </SuggestionWrapper>
);

export const SuggestModelo = (props: SuggestModeloProps) => {
  const { notALayer, marginTop, fieldsName, required, outputsSeparados, filterVW, nextOnClick, alternatCTA } = props;
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]);
  const [list, setList] = useState(null);
  const { register, clearError } = useFormContext();
  const [value, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(false); // Controla que una vez seleccionado un valor en el input, no siga buscando en google maps.
  const suggestRef = useRef(null);
  const { formInfo, formData } = useSelector((state: OneFormState) => state);
  const { formTheme } = formInfo;
  const [suggestion, setSuggestion] = useState<modelCar>(null);
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [ isNotAnswered, setIsNotAnswered ] = useState(true);
  const [ buttonEmphasis, setButtonEmphasis ] = useState('secondary');
  const { setIsValueSelected } = useOneFormContext();

  const { setDisableIntroNextStep, setError, handleNextStep } = useOneFormContext();

  useEffect(() => {

    getVehiculosModelo().then((modelos) => {
      let modelosToSet = modelos;
      if (filterVW) {
        modelosToSet = modelos.filter(modelo => modelo.brand !== 'Volkswagen');
      }
      setList(modelosToSet);
      if (formData.fields[fieldsName]) {
        if(formData.fields.brand === 'false' && formData.fields.model === 'false') {
          setButtonEmphasis('primary');
          setSuggestion(null);
          setValue('');
          setIsNotAnswered(false);
        } else {
          const modeloSuggestion = modelosToSet.find((model) => model.title === formData.fields[fieldsName]);
          setValue(formData.fields[fieldsName]);
          setSuggestion(modeloSuggestion);
        }
      }
    });
  }, []);


  const onChange = (e: any, { newValue }: { newValue: string }) => {
    if (setSelectedValue) {
      setSelectedValue(false);
    }
    if (newValue === '') {
      setSuggestion(null);
      setSelectedValue(true);
    }
    setError(false);
    setValue(newValue);
  };
  
  //desplegar el dropdown para arriba o para abajo según la posicinón del input en el window
  const { goUp, getAndUpdateState } = useDropdowPosition('.react-autosuggest__container');

  const onClick = () => {
    getAndUpdateState();
  };

  const onSuggestionsFetchRequested = async ({ value }: { value: string }) => {
    const suggestions = getSuggestions(value, list);
    setSuggestions(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputPlaceholder = 'Escribe aquí el modelo';
  const inputProps = {
    placeholder: inputPlaceholder,
    value,
    onChange,
    onClick,
    onBlur: () => { setDisableIntroNextStep(false); },
    disabled: false,
  };

  const handleDispatch = (outputsSeparados: boolean, suggestion: modelCar)=> {
    if(outputsSeparados) {
      dispatch({ type: 'UPDATE_FIELDS', payload: { 'brand': suggestion.brand } });
      dispatch({ type: 'UPDATE_FIELDS', payload: { 'model': suggestion.model } });
    } else {
      dispatch({ type: 'UPDATE_FIELDS', payload: { [fieldsName]: suggestion.name } });
    }
  };
  
  

  return (
    <ModeloInteresWrapper alternatCTA notALayer={notALayer} marginTop={marginTop} fieldsName={fieldsName} required>
      <FechaCompraWrapper notALayer={notALayer} marginTop={marginTop} fieldsName={fieldsName} required>
        <StyledWrapper
          formTheme={formTheme}
          inputFocus={inputFocus}
          ref={suggestRef}
          onFocus={() => {
            {/*setTimeout(() => {
              suggestRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
            }, 400);*/}
            setInputFocus(true);
          }}
          onBlur={() => setInputFocus(false)}
          isLocating={false}
          isFiltrosSuggest={false}
          isModeloSuggest={true}
          goUp={goUp}
          notALayer={notALayer}
        >
          <MagnifierLabel className="magnifier-label" formTheme={formTheme} isLocating={false} isFiltrosSuggest={false}>
            <Magnifier />
          </MagnifierLabel>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={(event, { suggestion, suggestionValue }) => {
              setSuggestion(suggestion);
              handleDispatch(outputsSeparados, suggestion);
              setSelectedValue(true);
              if(nextOnClick) {
                setTimeout(() => {
                  handleNextStep();
                }, 0);
              }
            }}

            inputProps={inputProps}
          />
          {value !== '' && (
            <ThemeProvider theme={formTheme}>
              <CloseButton
                isLocating={false}
                isFiltrosSuggest={true}
                onClick={(e) => {
                  e.preventDefault(); 
                  setValue('');
                  // setFormValue(fieldsName, '')
                  setSuggestion(null);
                }}
              >
                <Close />
              </CloseButton>
            </ThemeProvider>
          )}
        </StyledWrapper>
        
        <input
          type='hidden'
          name={fieldsName ? fieldsName : 'modelo'}
          value={suggestion ? getSuggestionValue(suggestion) : null}
          ref={ required ? register({ required: isNotAnswered }) : register({ required: false }) }
        />
      </FechaCompraWrapper>
      { alternatCTA ? (
      <ButtonWidth>
        <CTA
          emphasis={buttonEmphasis}
          tag="button"
          size={'small'}
          onClick={(e) => {
            e.preventDefault();
            setSuggestion({brand:'false', id: null, name: 'false', model: 'false', title: 'false'});
            handleDispatch(outputsSeparados, {brand:'false', id: null, name: 'false', model: 'false', title: 'Escribe aquí el modelo'});
            setSelectedValue(true);
            if(nextOnClick) {
              setTimeout(() => {
                handleNextStep();
              }, 0);
            }
          }}
        >No</CTA>
      </ButtonWidth>
      ) : null}
    </ModeloInteresWrapper>
  );
};

import { CTA, Text, TextAlignment, TokenTextAppearance, WhiteSpace } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CitaPreviaTile,
  CitaPreviaTileGrid,
  TriggerProps,
  useFaContent,
  useFeatureAppConfig,
  useFeatureServices,
  useGetDealers,
  useTransformToHtml,
} from 'src/feature-app';
import { useGetAuthService } from 'src/feature-app/hooks/use-get-auth-service';
import { OneFormState } from 'src/types';
import { NuevaCitaPosventaFormController } from '../forms/CitaPosventa/NuevaCitaPosventa';
import { CitaPosventaDefaultTrigger, CitaPosventaStageTrigger } from './';
export interface TriggerCitaProps extends TriggerProps {
  theme?: 'main' | 'inverted' | 'image';
  getCtaLabel: () => string;
  getBodyCopy: (textAlignment?: TextAlignment) => JSX.Element;
  getTitle: () => {
    __html: string;
  };
  getCtaRegister: (copy: string) => JSX.Element;
  getIntro: () => string;
  setSelectedFocusJourney?: any
}

export function CitaPosventaTrigger({ setSelectedJourney, setCTALabel, setSelectedFocusJourney }: TriggerProps) {
  const config = useFeatureAppConfig();
  const faContent = useFaContent();
  const userInteractionService = useFeatureServices()['user-interaction-service'];
  const { formData, formInfo } = useSelector((state: OneFormState) => state);
  const { userIsAuthenticated } = formInfo;
  const { name } = formData.fields;
  const { dealerName } = useGetDealers();
  const authService = useGetAuthService();
  const useLoggedJourney = false; // De momento no es necesario usar todas las funciones del journey logueado/no logueado, tan solo el fill de los datos

  const [theme, setTheme] = useState<'main' | 'inverted' | 'image'>('inverted');

  const getCtaLabel = () => (faContent?.cta ? faContent.cta : 'Pide cita en tu taller');

  const getIntro = () => (faContent?.intro ? faContent.intro : '');

  const alternativeHeadline = '<b>¿Necesitas pasar por el taller?</b>';
  const getTitle = () => {
    if (userIsAuthenticated && useLoggedJourney) {
      return faContent?.['headline-logged']
        ? useTransformToHtml(faContent?.['headline-logged'])
        : useTransformToHtml('<b>Usuario Logueado</b>');
    }
    return faContent?.headline ? useTransformToHtml(faContent.headline) : useTransformToHtml(alternativeHeadline);
  };

  const getBodyCopy = (textAlignment?: TextAlignment) => {
    if (userIsAuthenticated && useLoggedJourney) {
      return faContent?.['bodycopy-logged'] ? (
        <Text textAlign={textAlignment ? textAlignment : TextAlignment.center} appearance={TokenTextAppearance.copy200}>
          <div dangerouslySetInnerHTML={useTransformToHtml(faContent?.['bodycopy-logged'].replace('%name%', name))} />
        </Text>
      ) : (
        <Text textAlign={textAlignment ? textAlignment : TextAlignment.center} appearance={TokenTextAppearance.copy200}>
          <div dangerouslySetInnerHTML={useTransformToHtml('<b>hola</b>')} />
        </Text>
      );
    }
    return faContent?.bodycopy ? (
      <Text textAlign={textAlignment ? textAlignment : TextAlignment.center} appearance={TokenTextAppearance.copy200}>
        <div dangerouslySetInnerHTML={useTransformToHtml(faContent.bodycopy)} />
      </Text>
    ) : dealerName ? (
      <Text textAlign={textAlignment ? textAlignment : TextAlignment.center} appearance={TokenTextAppearance.copy200}>
        Elige el día y la hora que prefieras para que cuiden de tu Volkswagen en
        <Text whiteSpace={WhiteSpace.nowrap}> {dealerName}</Text>.
      </Text>
    ) : (
      <Text textAlign={textAlignment ? textAlignment : TextAlignment.center} appearance={TokenTextAppearance.copy200}>
        Elige el día, la hora y el Servicio Oficial que prefieras para que cuiden de tu Volkswagen.
      </Text>
    );
  };

  const getCtaRegister = (copy: string) => {
    return (
      <CTA
        tag="button"
        emphasis="tertiary"
        onClick={(e) => {
          e.preventDefault();
          authService.login();
        }}
      >
        {copy}
      </CTA>
    );
  };

  useEffect(() => {
    if (faContent?.modo === 'Light') {
      setTheme('main');
    }
    if (faContent?.userInteraction === 'true') {
      userInteractionService.register('es-oneform::cita-posventa', {
        action: () => {
          // setCTALabel(getCtaLabel());
          setSelectedJourney(<NuevaCitaPosventaFormController />);
        },
      });
    }
  }, []);

  return config.trigger === 'cita-posventa-tile-grid' ? (
    <CitaPreviaTileGrid setSelectedJourney={setSelectedJourney} />
  ) : config.trigger === 'cita-posventa-tile' ? (
    <CitaPreviaTile setSelectedJourney={setSelectedJourney} />
  ) : faContent.isCitaPosventaStageOrDefault === 'Stage' ? (
    <CitaPosventaStageTrigger
      setSelectedJourney={setSelectedJourney}
      setCTALabel={setCTALabel}
      theme={theme}
      getBodyCopy={getBodyCopy}
      getTitle={getTitle}
      getCtaLabel={getCtaLabel}
      getCtaRegister={getCtaRegister}
      getIntro={getIntro}
    />
  ) : (
    <CitaPosventaDefaultTrigger
    setSelectedFocusJourney={setSelectedFocusJourney}
      setSelectedJourney={setSelectedJourney}
      setCTALabel={setCTALabel}
      theme={theme}
      getBodyCopy={getBodyCopy}
      getTitle={getTitle}
      getCtaLabel={getCtaLabel}
      getCtaRegister={getCtaRegister}
      getIntro={getIntro}
    />
  );
}

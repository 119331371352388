import { Breakpoints, styled, Text, TokenTextAppearance, Layout } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { OneFormState } from 'src/types';
import { LssiTkmQuestions, PoliticaPrivacidad, LastNavigation } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';

const GeneralconfirmacionWrapper = styled.div`
	
`;
const ContentWrapper = styled.div`
	min-height: calc(100vh - 227px);
	width: 100%;
	display: flex;
	align-items: center;
	@media all and (min-width: 960px) {
		min-height: calc(100vh - 227px);
	}
	.content-wrapper {
		width: 100%;
	}
`;

export const ConfirmacionFeedback = () => {
	const dispatch = useDispatch();
	const { fields } = useSelector((state: OneFormState) => state.formData);
	const [lssiPincode, setLssiPincode] = useState('PERMITE');

	/*useEffect(() => {
		if (fields) {
		 setLssiPincode(fields.lssiPincode);
		}
	}, [fields]);
 
	useEffect(() => {
		if (lssiPincode === 'PERMITE') {
		 dispatch({ type: 'UPDATE_FIELDS', payload: { lssi: 'NO INFORMADO', tkm: 'NO INFORMADO' } });
		} 
	}, [lssiPincode]);*/

	return (
		<GeneralconfirmacionWrapper>
			<Layout
				allowOverflowingContent
				appearance={{
					[Breakpoints.default]: [
						{ name: '.', columns: 2 },
						{ name: 'a', columns: 20 },
						{ name: '.', columns: 2 },
					],
					[Breakpoints.b960]: [
						{ name: '.', columns: 3 },
						{ name: 'a', columns: 18 },
						{ name: '.', columns: 3 },
					],
					[Breakpoints.b1280]: [
						{ name: '.', columns: 5 },
						{ name: 'a', columns: 14 },
						{ name: '.', columns: 5 },
					],
					[Breakpoints.b1920]: [
						{ name: '.', columns: 6 },
						{ name: 'a', columns: 12 },
						{ name: '.', columns: 6 },
					],
					[Breakpoints.b2560]: [
						{ name: '.', columns: 7 },
						{ name: 'a', columns: 10 },
						{ name: '.', columns: 7 },
					],
				}}
			>
				<ContentWrapper className='content-wrapper'>
					<div className='content-wrapper'>
						{lssiPincode === 'PERMITE' ? (
							<div style={{ paddingBottom: '32px' }}>
								<Text appearance={TokenTextAppearance.headline300}>
									<Text bold>¿Listo</Text> para enviar?
								</Text>
							</div>
						) : (
							<Text appearance={TokenTextAppearance.headline300} bold>
								Mantente al día
							</Text>
						)}

						{lssiPincode === 'PERMITE' ? null : (
							<LssiTkmQuestions
								title={false}
								themeColor="main"
								noCompra={true}
							/>
						)}
						<PoliticaPrivacidad color="#ffffff" />

						<LastNavigation
							KoTitle="Uy, algo ha pasado"
							KoBody="Por alguna razón no hemos podido guardar tu respuesta. ¿Lo intentamos de nuevo?"
							loadingCopy="Enviando tu respuesta"
						/>
					</div>
				</ContentWrapper>
			</Layout>
			{lssiPincode === 'PERMITE' ? (null) : (null)}
		</GeneralconfirmacionWrapper>
	);
};

import React, { useEffect, useState } from 'react';
import { useStore, useSelector, useDispatch } from 'react-redux';
import { OneFormState, IdDealer } from 'src/types';
import { Breakpoints, Text, Layout, Fieldset, RadioButton, Container, ContainerPadding } from '@volkswagen-onehub/components-core';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { strTitlelize } from 'src/helpers';

interface DealerCEMSegundaEntregaProps {
	name: string;
	dealers?: [IdDealer];
}

export const DealerCEMSegundaEntrega = (props: DealerCEMSegundaEntregaProps) => {
	const {
		name,
		dealers
	} = props;
	const dispatch = useDispatch();
	const { handleNextStep } = useOneFormContext();
	const { formData } = useSelector((state: OneFormState) => state);
	const { fields } = formData;
	const [dealer, setDealer] = useState<IdDealer>(dealers ? dealers[0] : null);

	const handleSelectDealer = (e: any, dealer: IdDealer) => {
		setDealer(dealer);
		handleNextStep(e);
	};

	useEffect(() => {
		dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: strTitlelize(dealer.TXT_V_KVPS_code__c) } });
		dispatch({ type: 'SET_SELECTED_INSTALLATION', payload: dealer });
	}, [dealer]);

	return (
		<Layout appearance={{
			[Breakpoints.default]: [
				{ name: '.', columns: 5 },
				{ name: 'a', columns: 12 },
				{ name: '.', columns: 5 },
			]
		}}
		>
			<Container padding={{ top: ContainerPadding.dynamic0100 }}>
				<Fieldset>
					{dealers ? dealers.map((dealer: IdDealer, index) => {
						const value = strTitlelize(dealer.TXT_V_KVPS_code__c);
						const label = <>
							<Text bold>{strTitlelize(dealer.TXT_V_Installation_Data_Name__c)}</Text>{' '}
							<Text>{strTitlelize(dealer.TXT_V_Address__c)}</Text>
							<Text>{dealer.TXT_V_Postal_Code__c} {strTitlelize(dealer.TXT_V_Location__c)}</Text>
						</>;
						return (<RadioButton
							onClick={(e) => {
								handleSelectDealer(e, dealer);
							}}
							value={value}
							label={label}
							name={name}
							defaultChecked={fields[name] ? fields[name] == value : index == 0}
						/>);
					}) : null}
				</Fieldset>
			</Container>
		</Layout >
	);
};

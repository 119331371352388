import axios, { AxiosResponse } from 'axios';
import { SelectExtendedList } from 'src/components';
import { getOneformBFFCdnUrl, getOneformBFFCdnApiKey, getMolecularCdnUrl, reportErrorSentry } from 'src/bff';
import { DomainResponse } from 'src/types';

export interface CarlinesCitaResponse {
  content: SelectExtendedList[];
}

export const getCarlinesCita = async (isComerciales?: boolean): Promise<DomainResponse> => {
  const groupBrand = isComerciales ? 'N' : 'V';
  const oneformCdn = getOneformBFFCdnUrl();
  const oneformBFFApiKey = getOneformBFFCdnApiKey();

  try {
    const response = await axios.get(`${oneformCdn}/eapi/carlines?group_brand=${groupBrand}`,
      {
        timeout: 10000,
        headers: {
          'X-Api-Key': oneformBFFApiKey
        }
      }
    );

    return response.data;
  } catch (err) {
    reportErrorSentry(err);
    return null;
  }
};

export const getCarlinesCitaLegacy = async (isComerciales?: boolean): Promise<SelectExtendedList[]> => {
  const molecularCdn = getMolecularCdnUrl();
  const groupBrand = isComerciales ? 'N' : 'V';
  try {
    const response: AxiosResponse<CarlinesCitaResponse> = await axios.get(
      `${molecularCdn}/request-eapi-carlines.php?group_brand=${groupBrand}`
    );

    // Actualmente devuelve <html> 503 Service Temporarily... y no se detecta como error
    if (typeof response.data.content === 'string') {
      return null;
    }
    return response.data.content;
  } catch (error) {
    reportErrorSentry(error);
    return null;
  }
};

import React, { useEffect } from 'react';
import { Text, styled } from '@volkswagen-onehub/components-core';
import { OneElementTemplate } from 'src/feature-app/Screen';
import { Email, PantallaNombreApellido, Telefono, CarlineSelect, ContactInfoWrapper } from 'src/components';
import { MapaEmpresas, EmpresaInfoComponent, ConfirmacionEmpresas, ThankYouEmpresas } from 'src/forms';
import { useDispatch, useStore } from 'react-redux';
import { oneformBFFNdpsSend } from 'src/bff';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import {
  formatPrefix,
  FormController,
  removeWhiteSpace,
  useFaContent,
  useGetInstalaciones,
  useOneFormContext,
} from 'src/feature-app';
import { useInitializeLeadForm } from '../Leads';
import { Fleet } from './Fleet';
import { formatHeaders, formatFlags, formDataBuilder, getDpsFields } from '../format-fields';
import { useGetWaidLitera } from 'src/feature-app/hooks/use-get-waid-litera';

export enum EmpresasFormSteps {
  Modelo,
  Mapa,
  NombreApellido,
  EmailTelefono,
  Empresa,
  Fleet,
  Confirmacion,
}

export function EmpresasForm() {
  const { initializeForm } = useOneFormContext();
  const store = useStore();
  const installations = useGetInstalaciones('VW_DDB_FRM_VWEMPRESAS'); // Inicialización de las instalaciones
  const dispatch = useDispatch();
  const faContent = useFaContent();
  const waid = useGetWaidLitera();

  const defaultSteps: Steps[] = [
    {
      title: (
        <>
          <Text bold>¿Qué modelo </Text>te interesa para tu flota?
        </>
      ),
      fields: <OneElementTemplate element={<div style={{marginBottom: '2px'}}><CarlineSelect useCarline={true} /></div>} />,
      screenIndex: EmpresasFormSteps.Modelo,
      name: 'Modelo',
      outputs: ['modelo'],
      outputsText: ['modelo'],
    },
    {
      screenIndex: EmpresasFormSteps.Mapa,
      name: 'Mapa',
      multiStep: true,
      component: <MapaEmpresas formName="venta" showHorario={false} />,
    },
    {
      title: (
        <>
          Estamos deseando hacerte una propuesta. Pero antes, nos gustaría saber <Text bold>quién eres</Text>
        </>
      ),
      fields: <OneElementTemplate element={<div style={{marginBottom: '2px'}}><PantallaNombreApellido /></div>} allowOverflowingContent />,
      screenIndex: EmpresasFormSteps.NombreApellido,
      name: 'NombreApellido',
      outputs: ['name', 'surname', 'secondSurname'],
      outputsText: ['name', 'surname', 'secondSurname'],
      showOverflow: true,
    },
    {
      title: (
        <>
          <Text bold>¿En qué teléfono e email </Text>podemos localizarte?
        </>
      ),
      fields: (
        <OneElementTemplate
          element={
            <ContactInfoWrapper>
              <Telefono paginaConfirmacion={false} required={true} />
              <Email paginaConfirmacion={false} required={true} paddingTop='24px'/>
            </ContactInfoWrapper>
          }
        />
      ),
      screenIndex: EmpresasFormSteps.EmailTelefono,
      name: 'EmailTelefono',
      outputs: ['cellphone', 'email'],
      outputsText: ['cellphone', '/', 'email'],
    },
    {
      title: (
        <>
          Ahora, nos gustaría conocer algunos detalles sobre <Text bold>tu empresa</Text>
        </>
      ),
      fields: <OneElementTemplate element={<div style={{marginBottom: '2px'}}><EmpresaInfoComponent /></div>} />,
      screenIndex: EmpresasFormSteps.Empresa,
      name: 'Empresa',
      outputs: ['businessName', 'CIF', 'postalCode'],
      outputsText: ['businessName', '/', 'CIF', '/', 'postalCode'],
    },
    {
      title: (
        <>
          <Text bold>¿Cuántos coches </Text>necesitas para tu{' '}
          <span style={{ whiteSpace: 'nowrap' }}>
            flota?
            <sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
          </span>
        </>
      ),
      fields: <OneElementTemplate element={<Fleet />} />,
      screenIndex: EmpresasFormSteps.Fleet,
      name: 'Fleet',
      outputs: ['fleet'],
      outputsText: ['fleet'],
    },
    {
      fields: <ConfirmacionEmpresas />,
      screenIndex: EmpresasFormSteps.Confirmacion,
      name: 'Confirmacion',
      isLastStep: true,
      hidePreviousStep: true,
    },
  ];


  const EmpresasFormData: Partial<FormDataGroup> = {
    headers: {
      FORM_NAME: 'vw-oneform-empresas',
      FORM_OBJECTIVE: 'Más Información',
      FORM_ID: waid ? waid : 'B10C01_LDEMPR_WA1',
      DPS_TOKEN: 'VW_DDB_FRM_VWEMPRESAS',
    },
    flags: {
      PERSON_RGPD_ADAPTED: 'true',
      FLAG_IS_MYSTERY_LEAD: 'false',
      FLAG_RELACION_MARCA_SIN_IC: 'false',
      FLAG_SF_INTEGRATION: 'true',
      FLAG_ALLOWS_DATA_ENRICHMENT: 'false',
      // FLAG_ISQUALIFIED: 'false',
      // FLAG_MAILRECEPCIONLEAD: 'true',
      // REQUIERE_CONTACTO_DEALER: 'false',
    },
    fields: {
      formName: 'empresas',
      formType: 'contact',
      pageCategory: 'Más Información',
      formularioFlag: 'masInformacion',
    }, // Analítica
  };

  const sendForm = (): Promise<boolean> => {
    const { formData, dealersInfo }: OneFormState = store.getState();

    const { fields, flags, headers } = formData;

    const { selectedInstallation, carlines } = dealersInfo;

    const {
      VGED_CommercialName__c,
      ShippingStreet,
      VGED_DealerCode__c,
      ShippingStateCode,
      KVPSCode__c,
      Id
    } = selectedInstallation;


    return new Promise<any>(async (resolve, reject) => {
      const { tc, today, referalUrl, url, device } = getParamsForm();

      const data = {
        ...formatHeaders(headers),

        'headers[FORM_URL]': url,
        'headers[ORIGIN_DEVICE_TYPE]': device,
        'headers[FORM_DATE_INSERTION]': today,
        'headers[ORIGIN_TRACKING_CODE]': tc,
        'headers[FORM_VERSION]': `vw-oneform-empresas-${process.env.VERSION}`,

        ...formatFlags(flags),

        'fields[DPS_BRAND]': 'Volkswagen',
        'fields[SF_LEAD_RECORDTYPE_ID]': '0121i000000D7NyAAK',
        'fields[SF_LEAD_RECORDTYPE_NAME]': 'Sales Assistant',
        'fields[SF_LEAD_SALES_INITIAL_ACTION]': 'Direct to Sales Assistant',
        'fields[PERSON_NAME]': fields.name,
        'fields[PERSON_SURNAME_1]': fields.surname,
        'fields[PERSON_SURNAME_2]': fields.secondSurname,
        'fields[PERSON_EMAIL]': removeWhiteSpace(fields.email),
        'fields[PERSON_PHONE_MOBILE_FULL]': `${formatPrefix(fields.prefix)}${fields.cellphone}`,
        'fields[PERSON_PHONE_MOBILE]': fields.cellphone,
        'fields[PERSON_PHONE_PREFIX]': formatPrefix(fields.prefix),
        'fields[PERSON_POSTAL_CP]': fields.postalCode,
        'fields[COMPANY_NAME]': fields.businessName,
        'fields[COMPANY_CIF]': fields.CIF,
        'fields[COMMENTS_PERSON]': 'Tamaño flota: ' + fields.fleet,
        'fields[SF_LEAD_INSTALLATION_ADDRESS]': ShippingStreet,
        'fields[SF_LEAD_INSTALLATION_CODE]': VGED_DealerCode__c,
        'fields[SF_LEAD_INSTALLATION_ID]': Id,
        'fields[SF_LEAD_INSTALLATION_NAME]': VGED_CommercialName__c,
        'fields[SF_LEAD_INSTALLATION_PROVINCE_CODE]': ShippingStateCode.toString().padStart(2, '0'),
        // 'fields[VEH_INTEREST_FUEL]': 'PENDIENTE',
        'fields[VEH_INTEREST_BRAND]': 'Volkswagen',
        'fields[VEH_INTEREST_ID]': fields.carlineCode,
        'fields[VEH_INTEREST_MODEL]': fields.modelo,
        'fields[VEH_INTEREST_NEW_OR_USED]': 'New Car',
        'fields[PERSON_RGPD_COM_COMERCIALES]': fields.lssi ? fields.lssi : 'NO INFORMADO',
        'fields[PERSON_RGPD_PERFILADO]': fields.tmk ? fields.tmk : 'NO INFORMADO',
        ...getDpsFields(faContent),
      };

      delete data['headers[DETALLE_TIPO_PETICION_SIM]'];
      delete data['headers[DESC_FORM_DETAIL]'];

      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formData = formDataBuilder(dataParsed);

      const response = await oneformBFFNdpsSend(formData);

      if (response && response.data && response.data.status && response.data.status.code && response.data.status.code == '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const { loading, steps, firstStep, lastStep } = useInitializeLeadForm(defaultSteps);

  useEffect(() => {
    if (loading) {
      return;
    }

    const EmpresasFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens: steps.length,
      screenIndex: firstStep.screenIndex,
      step: firstStep,
      steps: steps,
      notALayer: false,
      formType: 'layer',
      formTheme: 'inverted',
      stepsHistory: [0],
      thankYouComponent: <ThankYouEmpresas />,
    };

    initializeForm(EmpresasFormInfo, EmpresasFormData);
  }, [loading]);

  return loading ? null : <FormController steps={steps} screenType="layer-screen" />;
}

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFeatureServices, useFaContent, useTrackingManager } from 'src/feature-app';
import { DpsData, EsOneformCoreServiceV1 } from 'src/feature-service/es-oneform-core-feature-service/types';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { CEMSegundaEntregaValoracionForm } from 'src/forms/Cem/CEMSegundaEntrega';
import { GenericErrorLayer } from 'src/components/layers';
import { createPincodeEntity } from 'src/forms/dps-utils';
import { getInstallationsLegacy } from 'src/bff';
import { DomainAttributesValues } from 'src';
import { LoadingEncuestas } from 'src/components';
import { arrayToObject } from 'src/helpers';


export function CEMSegundaEntregaValoracionController() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [usado, setUsado] = useState(false);
	const [caducado, setCaducado] = useState(false);

	const [idPerson, setIdPerson] = useState(null);
	const [idVehicle, setIdVehicle] = useState(null);
	const [idDealer, setIdDealer] = useState(null);
	const [idOrder, setIdOrder] = useState(null);

	const [installations, setInstallations] = useState(null);
	const [installationCode, setInstallationCode] = useState(null);
	const [dealer, setDealer] = useState(null);
	const [allDealers, setAllDealers] = useState(null);
	const [dealerName, setDealerName] = useState<string>(null);

	const oneFormService = useFeatureServices()['es:oneform-core'] as EsOneformCoreServiceV1;
	const dispatch = useDispatch();
	const faContent = useFaContent();
	const { tc, url, device } = getParamsForm();

	const handleNext = (value: any) => { };
	const handleComplete = () => {
		const dpsData = oneFormService.getDpsData();

		if (dpsData.pincode === null || dpsData.pincode.noPincodeFound || dpsData.pincode.pincodeError) {
			handleError();
		} else if (dpsData.pincode.pincodeUsed) {
			setUsado(true);
			setLoading(false);
		} else {
			setIdPerson(createPincodeEntity(dpsData, 'PERSON'));
			setIdVehicle(createPincodeEntity(dpsData, 'VEHICLE'));
			setIdDealer(createPincodeEntity(dpsData, 'DEALER'));
			setIdOrder(createPincodeEntity(dpsData, 'ORDER'));

			if (dpsData.dealer) {
				setInstallationCode(dpsData.dealer);
			}
			else if (typeof idDealer.TXT_V_Installation_Code__c != 'undefined') {
				setInstallationCode(idDealer.TXT_V_Installation_Code__c);
			}
			else if (typeof idDealer.TXT_Installation_Code__c != 'undefined') {
				setInstallationCode(idDealer.TXT_Installation_Code__c);
			}
			setLoading(false);
		}
	};

	// Obtenemos todas las instalaciones
	useEffect(() => {
		if (installationCode && !allDealers) {
			getInstallationsLegacy().then((dealers) => {
				const allDealers = dealers.map((inst) => inst.attributes);
				setAllDealers(allDealers);
			});
		}
	}, [installationCode]);

	// Guardamos la instalacion por codigo de instalacion
	useEffect(() => {
		if (allDealers && !dealer) {
			for (const d of allDealers) {
				d.find((ele: DomainAttributesValues) => {
					if (
						typeof ele.value != 'undefined' 
						&& typeof ele.name != 'undefined' 
						&& ele.name == 'TXT_V_Installation_Code__c'
						&& ele.value == installationCode
					) {
						setDealer(arrayToObject(d));
					}
					else if (
						typeof ele.value != 'undefined' 
						&& typeof ele.name != 'undefined' 
						&& ele.name == 'TXT_Installation_Code__c'
						&& ele.value == installationCode
					) {
						setDealer(arrayToObject(d));
					}
				});
			}
		}
	}, [allDealers]);

	const handleError = () => {
		setError(true);
		setLoading(false);
	};

	useEffect(() => {
		const oneFormCoreDpsIsLoaded = oneFormService.getLoadedState();

		oneFormCoreDpsIsLoaded.subscribe({
			next(value) {
				handleNext(value);
			},
			error(err) {
				console.error(err);
				handleError();
			},
			complete() {
				handleComplete();
			},
		});
	}, []);

	if (loading) {
		return <LoadingEncuestas />;
	} else if (error) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, algo ha pasado"
					bodycopy="Por alguna razón no hemos podido cargar el cuestionario."
					ctaLayer="Reintentar"
					notShowCTA={true}
					icon={false}
					notALayer={true}
				/>
			</>
		);
	} else if (usado) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, ya has respondido a este cuestionario"
					bodycopy="Parece que ya nos has contado cómo fue tu experiencia. ¿Quieres añadir algo más?"
					ctaLayer="Contacta con nosotros"
					ctaType="a"
					urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
					icon={false}
					notALayer={true}
				/>
			</>
		);
	} else if (caducado) {
		return (
			<>
				<GenericErrorLayer
					title="Uy, este formulario ha caducado"
					bodycopy="Parece que el cuestionario ya no está disponible. ¿Quieres contarnos cómo ha sido tu experiencia?"
					ctaLayer="Contacta con nosotros"
					ctaType="a"
					urlHref="https://formulario.volkswagen.es/sac?formid=vw-sac"
					icon={false}
					notALayer={true}
				/>
			</>
		);
	}
	else {
		return (
			<CEMSegundaEntregaValoracionForm
				handleError={handleError}
				getDealer={dealer}
				idPerson={idPerson}
				idVehicle={idVehicle}
				idDealer={idDealer}
				idOrder={idOrder}
			/>
		);
	}
}

import React from 'react';
import { Wrapper } from 'src/feature-app/NewMap/components/CommonStyledComponents';

export const CenterMarker = React.memo((props: { lat: number; lng: number }) => {
  return (
    <Wrapper className="geoloc">
      <svg width="44px" height="44px" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" fill="rgb(0, 30, 80)">
        <circle r="22" className="circle-beat" />
        <circle cx="22" cy="22" r="4.539" />
      </svg>
    </Wrapper>
  );
});

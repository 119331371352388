import {
  Breakpoints, Container,
  ContainerPadding, Layout, LayoutRowGap, Text,

  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React from 'react';
import {
  CarlineSelect, DetalleMotivo, MultipleCTASelection,
  MultipleOptionCTAs, PantallaIntroduccionStars, PantallaNombreApellido, RatingStars
} from 'src/components';

import { ctaEnvioCEM, ctaAlternativaTransporte, ctaDatosIncorrectosPosventa, ctaMotivoVisita, ctaTrabajosAdicionales } from 'src/feature-app/InputsValues/CTAsObjects';
import { ChecksEnvio, ctasCheckEnvio } from 'src/forms/Cem/ChecksEnvio';
import { ChecksPublicacion } from 'src/forms/Cem/ChecksPublicacion';
import { ConfirmacionCem } from 'src/forms/Cem/ConfirmacionCem';
import { FirstStepCEM } from './FirstStepCEM';
import { Steps } from 'src/types';

function OneElementTemplateFullScreen(props: any) {
    const { element, allowOverflowingContent } = props;
    return (
      <Layout
        allowOverflowingContent={allowOverflowingContent ? true : false}
        appearance={{
          [Breakpoints.default]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.b960]: [
            { name: '.', columns: 3 },
            { name: 'a', columns: 18 },
            { name: '.', columns: 3 },
          ],
          [Breakpoints.b1280]: [
            { name: '.', columns: 5 },
            { name: 'a', columns: 14 },
            { name: '.', columns: 5 },
          ],
          [Breakpoints.b1920]: [
            { name: '.', columns: 6 },
            { name: 'a', columns: 12 },
            { name: '.', columns: 6 },
          ],
          [Breakpoints.b2560]: [
            { name: '.', columns: 7 },
            { name: 'a', columns: 10 },
            { name: '.', columns: 7 },
          ],
        }}
        rowGap={LayoutRowGap.static300}
      >
        {element}
      </Layout>
    );
  }

enum CemSteps {
  DatosCorrectos,
  DatosIncorrectos,
  Modelo,
  Explicacion,
  SatisfaccionTrato,
  SatisfaccionTratoMotivo,
  SatisfaccionOrganizacion,
  SatisfaccionOrganizacionMotivo,
  SatisfaccionTrabajoRealizado,
  SatisfaccionTrabajoRealizadoMotivo,
  SatisfaccionConcesion,
  SatisfaccionConcesionMotivo,
  AspectoSatisfaccionMotivo,
  Informado,
  AlternativaTransporte,
  TiempoSatisfaccion,
  MotivoVisita,
  SatisfaccionVehiculo,
  SatisfaccionVehiculoMotivo,
  EnviarConcesion,
  DatosPersonales,
  PublicarRespuestas,
  Confirmacion,
}

export const stepsPosventa: Steps[] = [
  {
    fields: <FirstStepCEM />,
    screenIndex: CemSteps.DatosCorrectos,
    name: 'DatosCorrectos',
    alternateTitle: <Text>¿Puedes confirmar que estos datos son correctos y que eres la persona que mejor nos puede informar sobre la última visita al taller?</Text>,
    outputs: ['datosCorrectos'],
    outputsText: ['datosCorrectos'],
  },
  {
    title: <>Por favor,{' '}<Text bold>¿puedes confirmar qué dato no es correcto?</Text></>,
    fields: (
      <OneElementTemplateFullScreen
        element={<MultipleCTASelection ctaValues={ctaDatosIncorrectosPosventa} name='datosIncorrectosPosventa' nextOnClick />}
      />
    ),
    screenIndex: CemSteps.DatosIncorrectos,
    name: 'DatosIncorrectosPosventa',
    outputs: ['datosIncorrectosPosventa'],
    outputsText: ['datosIncorrectosPosventa'],
  },
  {
    title: <><Text bold>¿Cuál es el modelo correcto?</Text></>,
    fields: <OneElementTemplateFullScreen element={<div style={{ marginBottom: '2px' }}><CarlineSelect /></div>} />,
    screenIndex: CemSteps.Modelo,
    name: 'Modelo',
    outputs: ['modelo'],
    outputsText: ['modelo'],
  },
  {
    title: (
      <>A continuación,{' '}<Text bold>indica tu nivel de satisfacción con cada uno de estos aspectos relacionados con tu visita al Servicio Oficial.</Text></>
    ),
    fields: <PantallaIntroduccionStars bodyCopy='Valora del 1 al 5, siendo 1 insatisfecho/a y 5 totalmente satisfecho/a.' />,
    screenIndex: CemSteps.Explicacion,
    name: 'Explicacion',
  },
  {
    title: <Text bold>El trato recibido</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <RatingStars
            name='satisfaccionTrato'
            nextOnClick={true}
            textGood='satisfecho/a'
            textBad='insatisfecho/a'
            CTASinRespuesta
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionTrato,
    name: 'SatisfaccionTrato',
    outputs: ['satisfaccionTrato'],
    outputsText: ['satisfaccionTrato'],
    outputsIsStars: true
  },
  {
    title: <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <DetalleMotivo
            required={true}
            name='satisfaccionTratoMotivo'
            showRecomendation='Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados.'
            numberOfMaxLength={300}
            CTALabel='Sin respuesta'
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionTratoMotivo,
    name: 'SatisfaccionTratoMotivo',
    outputs: ['satisfaccionTratoMotivo'],
    outputsText: ['satisfaccionTratoMotivo'],
  },
  {
    title: (
      <Text bold>
        La organización de [Concesionario]
      </Text>
    ),
    fields: (
      <OneElementTemplateFullScreen
        element={
          <div style={{ paddingTop: '8px' }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Por ejemplo: el establecimiento de la cita, tiempos de espera en el taller, la fecha de entrega o el tiempo que estuvo el coche en el taller.            </Text>
            <RatingStars
              name='satisfaccionOrganizacion'
              nextOnClick={true}
              textGood='satisfecho/a'
              textBad='insatisfecho/a'
              CTASinRespuesta
            />
          </div>
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionOrganizacion,
    name: 'SatisfaccionOrganizacion',
    outputs: ['satisfaccionOrganizacion'],
    outputsText: ['satisfaccionOrganizacion'],
    outputsIsStars: true
  },
  {
    title: <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <DetalleMotivo
            required={true}
            name='satisfaccionOrganizacionMotivo'
            showRecomendation='Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados.'
            numberOfMaxLength={300}
            CTALabel='Sin respuesta'
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionOrganizacionMotivo,
    name: 'SatisfaccionOrganizacionMotivo',
    outputs: ['satisfaccionOrganizacionMotivo'],
    outputsText: ['satisfaccionOrganizacionMotivo'],
  },
  {
    title: (
      <Text bold>La calidad del trabajo realizado en tu [Modelo]</Text>
    ),
    fields: (
      <OneElementTemplateFullScreen
        element={
          <RatingStars
            name='satisfaccionTrabajoRealizado'
            nextOnClick={true}
            textGood='satisfecho/a'
            textBad='insatisfecho/a'
            CTASinRespuesta
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionTrabajoRealizado,
    name: 'SatisfaccionTrabajoRealizado',
    outputs: ['satisfaccionTrabajoRealizado'],
    outputsText: ['satisfaccionTrabajoRealizado'],
    outputsIsStars: true
  },
  {
    title: <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <DetalleMotivo
            required={true}
            name='satisfaccionTrabajoRealizadoMotivo'
            showRecomendation='Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados.'
            numberOfMaxLength={300}
            CTALabel='Sin respuesta'
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionTrabajoRealizadoMotivo,
    name: 'SatisfaccionTrabajoRealizadoMotivo',
    outputs: ['satisfaccionTrabajoRealizadoMotivo'],
    outputsText: ['satisfaccionTrabajoRealizadoMotivo'],
  },
  {
    title: (
      <>
        Pensando en tu última visita a [Concesionario],{' '}
        <Text bold>
          ¿cuál es tu nivel de satisfacción con nuestros servicios en general?
        </Text>
      </>
    ),
    alternateTitle: (
      <Text bold>
        ¿Cuál es tu nivel de satisfacción con nuestros servicios en general?
      </Text>
    ),
    fields: (
      <OneElementTemplateFullScreen
        element={
          <div style={{ paddingTop: '8px' }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Pensando en tu última visita.
            </Text>
            <RatingStars
              name='satisfaccionConcesion'
              nextOnClick={true}
              textGood='satisfecho/a'
              textBad='insatisfecho/a'
              CTASinRespuesta
            />
          </div>
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionConcesion,
    name: 'SatisfaccionConcesion',
    outputs: ['satisfaccionConcesion'],
    outputsText: ['satisfaccionConcesion'],
    outputsIsStars: true
  },
  {
    title: <Text bold>¿Cuál es la razón por la que has dado esta puntuación?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <DetalleMotivo
            required={true}
            name='satisfaccionConcesionMotivo'
            showRecomendation='Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados.'
            numberOfMaxLength={300}
            CTALabel='Sin respuesta'
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionConcesionMotivo,
    name: 'SatisfaccionConcesionMotivo',
    outputs: ['satisfaccionConcesionMotivo'],
    outputsText: ['satisfaccionConcesionMotivo'],
  },
  {
    title: <Text bold>¿Hay algún aspecto con el que no estuvieras satisfecho y que no hayas mencionado aún?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <DetalleMotivo
            required={true}
            name='aspectoSatisfaccionMotivo'
            showRecomendation='Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados.'
            numberOfMaxLength={300}
            CTALabel='Sin respuesta'
          />
        }
      />
    ),
    screenIndex: CemSteps.AspectoSatisfaccionMotivo,
    name: 'AspectoSatisfaccionMotivo',
    outputs: ['aspectoSatisfaccionMotivo'],
    outputsText: ['aspectoSatisfaccionMotivo'],
  },
  {
    title: <Text bold>¿Se te informó de la realización de trabajos adicionales a los inicialmente acordados?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <MultipleCTASelection ctaValues={ctaTrabajosAdicionales} name='informado' nextOnClick />
        }
      />
    ),
    screenIndex: CemSteps.Informado,
    name: 'Informado',
    outputs: ['informado'],
    outputsText: ['informado'],
    ctas: ctaTrabajosAdicionales,
  },
  {
    title: <><Text bold>¿Te ofreció el Servicio Oficial alguna alternativa de de movilidad para volver al trabajo o a casa tras dejar tu vehiculo en el taller?</Text></>,
    fields: (
      <OneElementTemplateFullScreen element={<MultipleCTASelection ctaValues={ctaAlternativaTransporte} name='alternativaTransporte' nextOnClick />} />
    ),
    screenIndex: CemSteps.AlternativaTransporte,
    name: 'AlternativaTransporte',
    outputs: ['alternativaTransporte'],
    outputsText: ['alternativaTransporte'],
  },
  {
    title: (
      <>
        <Text bold>¿Cuál es tu nivel de satisfacción con la gestión que hizo el Servicio Oficial de tu tiempo? </Text>
      </>
    ),
    fields: (
      <OneElementTemplateFullScreen
        element={
          <div style={{ paddingTop: '8px' }}>

            <Text appearance={TokenTextAppearance.copy200} staticSize>
              El tiempo total desde que entró y desde que salió tu vehículo del taller.</Text>
            <RatingStars
              name='tiempoSatisfaccion'
              nextOnClick={true}
              textGood='satisfecho/a'
              textBad='insatisfecho/a'
              CTASinRespuesta
            />
          </div>}
      />
    ),
    screenIndex: CemSteps.TiempoSatisfaccion,
    name: 'TiempoSatisfaccion',
    outputs: ['tiempoSatisfaccion'],
    outputsText: ['tiempoSatisfaccion'],
    outputsIsStars: true
  },
  {
    title: (
      <>
        ¿Cuál o cuáles de los aspectos de la siguiente lista fueron <Text bold> el motivo de tu última visita al Servicio Oficial?</Text>
        <Container padding={{ top: ContainerPadding.dynamic0100 }}>
          <Text appearance={TokenTextAppearance.copy200} staticSize>
            Puedes elegir tres opciones
          </Text>
        </Container>
      </>),
    alternateTitle: (
      <>
        ¿Cuál o cuáles de los aspectos de la siguiente lista fueron <Text bold> el motivo de tu última visita al Servicio Oficial?</Text>
      </>
    ),
    fields: (
      <OneElementTemplateFullScreen
        element={
          <MultipleOptionCTAs
            ctaValues={ctaMotivoVisita}
            name='motivoVisita'
            minSelected={1}
            maxSelected={3}
            ignoreHandleClick={true}
          />
        }
      />
    ),
    screenIndex: CemSteps.MotivoVisita,
    name: 'MotivoVisita',
    outputs: ['motivoVisita'],
    outputsText: ['motivoVisita'],
  },
  {
    title: <Text bold>Pensando en tu actual [Modelo], ¿cuál es tu nivel de satisfacción?</Text>,
    fields: (
      <OneElementTemplateFullScreen
        element={
          <RatingStars
            name='satisfaccionVehiculo'
            nextOnClick={true}
            textGood='satisfecho/a'
            textBad='insatisfecho/a'
            CTASinRespuesta
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionVehiculo,
    name: 'SatisfaccionVehiculo',
    outputs: ['satisfaccionVehiculo'],
    outputsText: ['satisfaccionVehiculo'],
    outputsIsStars: true
  },
  {
    title: (
      <>
        <Text bold>Por favor háblanos de tu experiencia con tu nuevo [Modelo]</Text>
      </>
    ),
    fields: (
      <OneElementTemplateFullScreen
        element={
          <DetalleMotivo
            required={true}
            name='satisfaccionVehiculoMotivo'
            showRecomendation='Por favor, no menciones nombres propios, direcciones, números de teléfono o descripciones que permitan
            identificarte a ti o a nuestros empleados.'
            numberOfMaxLength={300}
            CTALabel='Sin respuesta'
          />
        }
      />
    ),
    screenIndex: CemSteps.SatisfaccionVehiculoMotivo,
    name: 'SatisfaccionVehiculoMotivo',
    outputs: ['satisfaccionVehiculoMotivo'],
    outputsText: ['satisfaccionVehiculoMotivo'],
  },
  {
    title: (
      <>
        Finalmente nos gustaría saber si{' '}
        <Text bold>
          podemos enviar tus respuestas junto con tu nombre
        </Text>
        {' '}al Servicio Oficial [Concesionario] y el bastidor del vehículo, y puedan usarlos para análisis adicionales.
      </>
    ),
    fields: (
      <OneElementTemplateFullScreen
        element={<ChecksEnvio name="enviarConcesion" isPosventa />}
      />
    ),
    screenIndex: CemSteps.EnviarConcesion,
    name: 'EnviarConcesion',
    outputs: ['enviarConcesion'],
    outputsText: ['enviarConcesion'],
    ctas: ctasCheckEnvio(true, false)
  },
  {
    title: (
      <>
        Por favor,{' '}<Text bold>introduce o comprueba tus datos:</Text>
      </>
    ),
    fields: (
      <OneElementTemplateFullScreen
        element={
          <div style={{ marginBottom: '2px' }}>
            <PantallaNombreApellido />
          </div>
        }
      />
    ),
    screenIndex: CemSteps.DatosPersonales,
    name: 'DatosPersonales',
    outputs: ['name', 'surname', 'secondSurname'],
    outputsText: ['name', 'surname', 'secondSurname'],
  },
  {
    title: (
      <>
        <Text bold>¿Podríamos publicar tus respuestas en la web oficial de Volkswagen y en la de [Concesionario] sin mencionar tu nombre?</Text>
      </>
    ),
    fields: (
      <OneElementTemplateFullScreen
        element={
          <div style={{ paddingTop: '8px' }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Para compartir tu experiencia con otros clientes.
            </Text>
            <ChecksPublicacion name="publicarRespuestas" />
          </div>
        }
      />
    ),
    screenIndex: CemSteps.PublicarRespuestas,
    name: 'PublicarRespuestas',
    outputs: ['publicarRespuestas'],
    outputsText: ['publicarRespuestas'],
    ctas: ctaEnvioCEM,
  },
  {
    fields: <ConfirmacionCem />,
    screenIndex: CemSteps.Confirmacion,
    name: 'Confirmacion',
    outputs: ['confirmacion'],
    outputsText: ['confirmacion'],
  },
];

import { styled, Text } from '@volkswagen-onehub/components-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useOneFormContext } from 'src/feature-app';
import { RenderController } from 'src/feature-app/Screen';
import LoadScriptInstance from 'src/feature-app/NewMap/Loadscript';
import { LocateUser } from 'src/forms/CitaPosventa/NuevaCitaPosventa';
import { TIME_FOR_TRANSFORM } from 'src/globals';
import { DealersData, OneFormState, Steps } from 'src/types';
import { updateMapVariables } from 'src/feature-app/';
import { JourneyCitaPosventaWrapper } from 'src/forms/CitaPosventa/MapaCita/JourneyCitaPosventaWrapper';

export enum MapaCitaSteps {
  Dealer,
  SeleccionCita,
  Horario,
}

interface MapaCitaProps {
  showHorario?: boolean;
  formName?: string;
}

const SaltoDeLinea = styled.div`
  display: none;
  @media all and (min-width: 736px) {
    display: inline;
  }
`;

const isLastScreen = (steps: Steps[], multiStepScreenIndex: number) => steps.length - 1 === multiStepScreenIndex;
const isFirstScreen = (multiStepScreenIndex: number) => multiStepScreenIndex === 0;

export function NewMapaCita(props: MapaCitaProps) {
  const { formName, showHorario } = props;
  const dispatch = useDispatch();
  const store = useStore();
  const { formData, formInfo, dealersInfo } = useSelector((state: OneFormState) => state);
  const { dealer }: { dealer?: DealersData } = formData.fields;
  const { selectedDealer } = dealersInfo;
  const { firstMapLoad, multiStepScreenIndex, navigationMovingForward } = formInfo;

  const {
    handleScreenChange,
    setNextMultiStep,
    setNextMultiStepScreenIndex,
    setPreviousMultiStep,
    setWaitForPreviousStep,
    waitForPreviousStep,
    setIsFullScreen,
    setShowPreviousStep,
  } = useOneFormContext();

  const steps: Steps[] = [
    {
      title: (
        <Text>
          Bien, empecemos con los detalles de tu visita.{' '}
          <SaltoDeLinea>
            <br />
          </SaltoDeLinea>
          <Text bold>
            ¿A dónde quieres llevar tu{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              coche?<sup style={{ top: 'auto', fontSize: '100%' }}>*</sup>
            </span>
          </Text>
        </Text>
      ),
      fields: <LocateUser />,
      screenIndex: MapaCitaSteps.Dealer,
      name: 'MapSearch',
      outputs: ['location'],
      outputsText: ['location'],
      showOverflow: true,
    },
    {
      screenIndex: MapaCitaSteps.SeleccionCita,
      name: 'MapSelect',
      alternateTitle: (
        <Text>
          <Text bold>Esta es la cita</Text> que has elegido
        </Text>
      ),
      title: <></>,
      fields: <JourneyCitaPosventaWrapper />,
      outputs: ['slot.from', 'dealer.name'],
      outputsText: ['slot.from', 'en el taller', 'dealer.name'],
      fullScreen: true,
      multiStepLastScreen: true,
      hidePreviousStep: true,
    },
  ];

  const [selectedStep, setSelectedStep] = useState<Steps>(steps[0]);

  /**
   * Hay que esperar a que se cree el mapa de google para tener acceso a window.google
   */
  const onMapLoad = () => {
    handleFirstLoad();
  };

  //
  //
  //
  // Falta añadir la parte del dealer precargado
  //
  //
  //
  const handleFirstLoad = async () => {
    if (firstMapLoad) {
      // Inicialización
      if (dealer) {
        initializeMultiStep(dealer);
        return;
      } else if (selectedDealer) {
        initializeMultiStep(selectedDealer);
        return;
      } else {
        initializeMultiStep();
      }
    } else {
      if (navigationMovingForward || navigationMovingForward === null || navigationMovingForward === undefined) {
        // Inicialización cuando se va hacia adelante una vez inicializado el mapa o después de cerrar el layer y abrir en el mapa.
        initializeMultiStep();
      } else {
        // En caso de que estemos en la última pantalla, desactivamos el wait for next step.
        const newWaitForNextStep = !isLastScreen(steps, multiStepScreenIndex);
        // Lo mismo para previous, solo le desactivamos si estamos en la primera.
        const newWaitForPreviousStep = !isFirstScreen(multiStepScreenIndex);

        updateWaitForNextAndPreviousStep(newWaitForNextStep, newWaitForPreviousStep);
        updateScreenChangeVariables(multiStepScreenIndex);

        timeoutPreviousStep();
        // En caso de volver al mapa con el cta de editar en confirmación se muestra el previous step, por lo que añadimos este timeout para asegurarnos de que no se muestra.
        timeoutPreviousStep(1000);
      }
    }
    dispatch({ type: 'UPDATE_FIELDS', payload: { horario: 'before' } }); // Al eliminar la pantalla de horario seleccionamos por defecto "before" para preseleccionar el primer slot disponible.

    setNextMultiStep(() => nextMapaCita);
    setPreviousMultiStep(() => previousMapaCita);
  };

  const initializeMultiStep = async (dealer?: DealersData) => {
    if (dealer) {
      await updateMapVariables(dispatch, dealer);
      updateWaitForNextAndPreviousStep(false, true);
      updateScreenChangeVariables(steps.length - 1);
      timeoutPreviousStep();
    } else {
      updateWaitForNextAndPreviousStep(true, false);
      updateScreenChangeVariables(0);
    }
  };

  const updateWaitForNextAndPreviousStep = (waitForNextStep: boolean, waitForPreviousStep: boolean) => {
    if (waitForNextStep !== null) {
      dispatch({ type: 'UPDATE_WAIT_FOR_NEXT_STEP', payload: waitForNextStep });
    }
    if (waitForPreviousStep !== null) {
      setWaitForPreviousStep(waitForPreviousStep);
    }
  };

  const nextMapaCita = () => {
    const { multiStepScreenIndex } = store.getState().formInfo;

    handleScreenChange();

    // Una vez avanzamos dentro de los multisteps se activa el wait
    // previous step para volver hacia atrás dentro del propio multistep.
    if (!waitForPreviousStep || multiStepScreenIndex === 0) {
      updateWaitForNextAndPreviousStep(null, true);
    }

    setTimeout(() => {
      updateScreenChangeVariables(multiStepScreenIndex + 1);
      // Si estamos en el último paso del multistep se desactiva el
      // wait next step para poder salir del multistep y avanzar hacia la siguiente pantalla.
      if (steps[multiStepScreenIndex + 1].multiStepLastScreen) {
        updateWaitForNextAndPreviousStep(false, null);
        if(multiStepScreenIndex === 0) {
          setShowPreviousStep(false);
        } else {
          setShowPreviousStep(true);
        } 
      }
    }, TIME_FOR_TRANSFORM);
  };

  const previousMapaCita = () => {
    const { multiStepScreenIndex, waitForNextStep } = store.getState().formInfo;

    handleScreenChange();

    // Se desactiva el wait previous step en la segunda pantalla para que
    // en el caso de volver hacia atrás en la primera salte de step y no busque dentro de los multisteps.
    if (multiStepScreenIndex === 1) {
      updateWaitForNextAndPreviousStep(null, false);
    }

    if (!waitForNextStep) {
      updateWaitForNextAndPreviousStep(true, null);
    }

    // Se fija en la pantalla previa a la primera para que cuando se cambie
    // a la primera pantalla el CTA de anterior pase del multistep al step anterior.
    setTimeout(() => {
      updateScreenChangeVariables(multiStepScreenIndex - 1);
    }, TIME_FOR_TRANSFORM);
  };

  /**
   * En algunas ocasiones no funciona correctamente el setShowPreviousStep(false) por lo que conviene aplicar un timeout.
   */
  const timeoutPreviousStep = (ms?: number) => {
    setTimeout(
      () => {
        setShowPreviousStep(false);
      },
      ms ? ms : TIME_FOR_TRANSFORM
    );
  };

  const updateScreenChangeVariables = (newMultiStepScreenIndex: number) => {
    setNextMultiStepScreenIndex(newMultiStepScreenIndex);
    dispatch({ type: 'UPDATE_MULTISTEP_SCREENINDEX', payload: newMultiStepScreenIndex });
    setSelectedStep(steps[newMultiStepScreenIndex]);
    setIsFullScreen(steps[newMultiStepScreenIndex].fullScreen);
    setShowPreviousStep(!steps[newMultiStepScreenIndex].hidePreviousStep);
  };

  useEffect(() => {
    dispatch({ type: 'UPDATE_MULTISTEPS', payload: steps });

    handleFirstLoad();

    return () => {
      dispatch({ type: 'UPDATE_FIRST_MAP_LOAD', payload: false });
      setShowPreviousStep(true);
      updateWaitForNextAndPreviousStep(false, false);
    };
  }, []);

  return (
    <LoadScriptInstance onMapLoad={onMapLoad}>
      <RenderController
        screenType="layer-screen"
        title={selectedStep.title}
        fields={selectedStep.fields}
        key={selectedStep.screenIndex}
      />
    </LoadScriptInstance>
  );
}

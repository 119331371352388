import { IAuthServiceV2 } from '@volkswagen-onehub/authservice';
import { getOneHubConfig } from 'src/feature-app';
import { IEsOnehubVWConfig, IdentityKitCompleteMatch, HasPersonResponse } from './';

export const existPerson = async (authServiceInstance: IAuthServiceV2, identitykitId: string): Promise<HasPersonResponse> => {
  const onehubConfig = getOneHubConfig();
  const endpoint = 'one-hub-vw-1.0/match-person/has-person';

  const response = await authServiceInstance.get(
    onehubConfig.customConfig?.resourceHostId,
    endpoint + '?identitykitId=' + identitykitId,
    {
      'Content-Type': 'application/json',
    }
  );

  if (!response.ok) {
    throw new Error(`GET request failed with code ${response.status}`);
  }

  return response.json() as Promise<HasPersonResponse>;
};

export const matchPerson = async (
  authServiceInstance: IAuthServiceV2,
  identityKitCompleteMatch: IdentityKitCompleteMatch
): Promise<HasPersonResponse> => {
  const onehubConfig = getOneHubConfig();
  const endpoint = 'one-hub-vw-1.0/match-person';

  const response = await authServiceInstance.put(
    onehubConfig.customConfig?.resourceHostId,
    endpoint,
    JSON.stringify(identityKitCompleteMatch)
  );

  if (!response.ok) {
    throw new Error(`GET request failed with code ${response.status}`);
  }

  return response.json() as Promise<HasPersonResponse>;
};

import React from 'react';
import { JourneyCitaPosventaController } from 'src/forms/CitaPosventa';
import { MapGeneralController } from 'src/feature-app/NewMap/MapGeneralController';

/**
 * Este componente está creado únicamente para que JourneyCitaPosventaController tenga acceso a las variables de MapGeneralController.
 */
export const JourneyCitaPosventaWrapper = () => {
  return (
    <MapGeneralController>
      <JourneyCitaPosventaController />
    </MapGeneralController>
  );
};

import {
  Breakpoints,
  Container,
  ContainerPadding,
  styled,
  Text,
  Layout,
  BreakpointWrapper,
  ContainerGutter,
  CTA,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { CloseHandleV2, FocusLayerSizeV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import React, { useState } from 'react';
import { useStore } from 'react-redux';
import {
  PoliticaPrivacidad,
  LastNavigation,
  LabelInputWidthWrapper,
  Nombre,
  Apellido,
  Telefono,
  Legales,
  Email,
  CodigoPostal,
  LegalItem,
} from 'src/components';
import { LayerManagerWrapper, useFeatureAppEnvironment, useFeatureServices, useGetIdPerson } from 'src/feature-app';
import { IdPerson } from 'src/types';

const GridWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-gap: 10%;
  padding-bottom: 6%;
  padding-top: 3%;
`;

const GridItem = styled.div <{ row: string }>`
  grid-row: ${props => props.row};
`;

const TextBlue = styled(Text)`
  color: #001e50;
`;

const InputErrorLegales = styled.p`
  position: relative;
  margin-top: 10px;
  background-color: #e4002c;
  color: white;
  width: max-content;
  max-width: 280px;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 35px;
`;

const InputWrapper = styled.div`
  input, span:first-child, div:last-child {
    width: 320px;
  }
`;

interface ConcursoRodajeProps {
  idPerson?: IdPerson;
  sendForm?: () => Promise<boolean>;
}

const infoLayerCopies = {
  lssi: {
    title: 'Estarás mejor informado.',
    body: 'Si nos das tu consentimiento desde Volkswagen Group España Distribución, S.A. como responsable de la marca Volkswagen podremos enviarte por cualquier medio, ya sea por teléfono, correo electrónico, sms o aplicaciones de mensajería instantánea información comercial lo que puede incluir, a título enunciativo, pero no limitativo, descuentos, novedades, próximos lanzamientos, invitaciones a eventos o a concursos, entre otras comunicaciones comerciales.',
  },
};

const legalesRodaje = 'https://crm.volkswagen.es/email/2023/newsletter/trimestre_jun23/BBLL_Rodaje_NL_2023.pdf';

export const ConcursoRodajeConfirmacion = (props: ConcursoRodajeProps) => {
  const { idPerson } = props;
  const [compartir, setCompartir] = useState<string>(null);
  const [compartirError, setCompartirError] = useState<boolean>(false);
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const env = useFeatureAppEnvironment();
  const store = useStore();

  const handleInfoLayer = (
    key: 'lssi',
    renderFn: (state: any, close: CloseHandleV2<any, any>) => JSX.Element
  ) => {
    const layer = layerManager.openFocusLayer(
      renderFn,
      { ...infoLayerCopies[key] },
      { size: FocusLayerSizeV2.A, userCloseable: true }
    );

    return layer;
  };

  const renderInfo = (state: { title: string; body: string }, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <Container wrap={'always'} padding={ContainerPadding.static450}>
          <Container
            gutter={ContainerGutter.static200}
            padding={{ bottom: ContainerPadding.static300 }}
            wrap={'always'}
          >
            <Text bold>{state.title}</Text>
            <Text>{state.body}</Text>
          </Container>
          <CTA
            tag='button'
            emphasis='primary'
            onClick={(e) => {
              e.preventDefault();
              close({}, {}, {});
            }}
            size={'small'}
          >
            Entendido
          </CTA>
        </Container>
      </LayerManagerWrapper>
    );
  };

  return (
    <>
      <Layout
        appearance={{
          [Breakpoints.default]: [
            { name: 'a', columns: 24 },
          ],
          [Breakpoints.b1280]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ]
        }}
      >
        <Container padding={ContainerPadding.static400}>
          <div style={{ maxWidth: '1300px' }}>
            <Text appearance={TokenTextAppearance.headline300}>
              <Text bold>Acompáñanos en el rodaje de nuestro próximo spot</Text>{' '}
              y descubre cómo sucede la magia detrás de cámara.
              Completa tus datos y ¡ven a vivirlo!
            </Text>
          </div>
        </Container>
      </Layout >
      <Layout
        allowOverflowingContent
        appearance={{
          [Breakpoints.default]: [
            { name: 'a', columns: 24 },
            { name: 'b', columns: 24 },
          ],
          [Breakpoints.b1280]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 13 },
            { name: '.', columns: 1 },
            { name: 'b', columns: 6 },
            { name: '.', columns: 2 },
          ]
        }}
      >
        <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
          <BreakpointWrapper min={Breakpoints.b1280}>
            <GridWrapper>
              <GridItem row="1">
                <LabelInputWidthWrapper notALayer={true}>
                  <InputWrapper>
                    <Nombre setFieldValue />
                  </InputWrapper>
                </LabelInputWidthWrapper>
              </GridItem>
              <GridItem row="1">
                <LabelInputWidthWrapper notALayer={true}>
                  <InputWrapper>
                    <Apellido setFieldValue name="surname" required={true} label="Primer apellido" />
                  </InputWrapper>
                </LabelInputWidthWrapper>
              </GridItem>
              <GridItem row="2">
                <div style={{ paddingTop: '35px', width: '318px' }}>
                  <InputWrapper>
                    <CodigoPostal setFieldValue />
                  </InputWrapper>
                </div>
              </GridItem>
              <GridItem row="2">
                <Telefono required name="telefono" />
              </GridItem>
              <GridItem row="2">
                <LabelInputWidthWrapper notALayer={true}>
                  <InputWrapper>
                    <Email setEmailField required />
                  </InputWrapper>
                </LabelInputWidthWrapper>
              </GridItem>
            </GridWrapper>
          </BreakpointWrapper>

          <BreakpointWrapper max={Breakpoints.b1280}>
            <LabelInputWidthWrapper notALayer={true}>

              <Nombre setFieldValue />
            </LabelInputWidthWrapper>
            <LabelInputWidthWrapper notALayer={true}>
              <Apellido setFieldValue name="surname" required={true} label="Primer apellido" />
            </LabelInputWidthWrapper>
            <LabelInputWidthWrapper notALayer={true}>
              <CodigoPostal setFieldValue />
            </LabelInputWidthWrapper>
            <LabelInputWidthWrapper notALayer={true}>
              <Email name='email' setEmailField required />
            </LabelInputWidthWrapper>
            <div style={{ paddingBottom: '35px' }}>
              <Telefono required name="telefono" />
            </div>
          </BreakpointWrapper>
        </div>
      </Layout>

      <Layout
        appearance={{
          [Breakpoints.b1280]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 14 },
            { name: '.', columns: 8 },
          ],
          [Breakpoints.default]: [
            { name: 'a', columns: 24 },
            { name: 'b', columns: 24 },
          ],
        }}>
        {!idPerson &&
          <Container padding={{ left: ContainerPadding.static400, right: ContainerPadding.static400 }}>
            <LegalItem
              title={'¿Te gustaría también recibir descuentos y novedades de Volkswagen?'}
              onClick={() => handleInfoLayer('lssi', renderInfo)}
              themeColor={'main'}
              name='lssi'
            />
          </Container>
        }
        <Container padding={{ left: ContainerPadding.static400, right: ContainerPadding.static400 }}>
          <Text appearance={TokenTextAppearance.copy200}>
            Enviando el formulario declaro haber leído y estar informado acerca del tratamiento de mis datos personales y declaro aceptar las condiciones de participación recogidas en las{' '}
            <CTA tag="a" href={legalesRodaje} target="_blank" emphasis="tertiary">
              <b>bases legales</b>
            </CTA>.
            <br />
            <br />
          </Text>
          <PoliticaPrivacidad color="#001e50" />
        </Container>

        {compartirError && <InputErrorLegales>Necesitamos esta información para seguir</InputErrorLegales>}
        <Container padding={{ left: ContainerPadding.static400, right: ContainerPadding.static400 }}>
          <LastNavigation
            label='Enviar'
            compartir={compartir}
            setCompartirError={setCompartirError}
            loadingCopy="Enviando tu respuesta"
            KoTitle="Uy, algo ha pasado"
            KoBody="Por alguna razón no hemos podido guardar tu respuesta. ¿Lo intentamos de nuevo?"
          />
        </Container>
      </Layout>
      <Layout
        allowOverflowingContent
        appearance={{
          [Breakpoints.b1280]: [
            { name: '.', columns: 2 },
            { name: 'a', columns: 20 },
            { name: '.', columns: 2 },
          ],
          [Breakpoints.default]: [
            { name: 'a', columns: 24 },
          ],
        }}
      >
        {/*        <Container padding={ContainerPadding.static400}>
          <BreakpointWrapper min={Breakpoints.b1280}>
            <TextBlue appearance={TokenTextAppearance.copy100}>
              Consulta las bases legales de la promoción{' '}
              <CTA tag="a" href={legalesRodaje} target="_blank" emphasis="tertiary">
                <b>aquí</b>
              </CTA>.
            </TextBlue>
          </BreakpointWrapper>
          <BreakpointWrapper max={Breakpoints.b1280}>
            <Container padding={ContainerPadding.static300}>
              <TextBlue appearance={TokenTextAppearance.copy100}>
                Consulta las bases legales de la promoción{' '}
                <CTA tag="a" href={legalesRodaje} target="_blank" emphasis="tertiary">
                  <b>aquí</b>
                </CTA>.
              </TextBlue>
            </Container>
          </BreakpointWrapper >
        </Container> */}
      </Layout>
    </>
  );
};

import { Breakpoints, BreakpointWrapper } from '@volkswagen-onehub/components-core';
import { CloseHandleV2, FocusLayerSizeV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { AvailabilityErrorLayer } from 'src/components';
import {
  getSlots,
  isNull,
  LayerManagerWrapper,
  NewDealerCard,
  useFeatureAppConfig,
  useFeatureAppEnvironment,
  useMapGeneralController,
  weekDay_Day_Hour,
} from 'src/feature-app';
import { LoadingLayer, ReservaCitaLayer } from 'src/forms/CitaPosventa/NuevaCitaPosventa';
import {
  Assessors,
  Availability,
  CalendarSlot,
  DealersData,
  DmsInfo,
  Horario,
  OneFormState,
  SelectedTab,
  Points,
  Steps,
} from 'src/types';
import { CalendarioCita, getFirstAndLastDayOfMonth } from 'src/feature-app/CalendarioCita/';
import { useOneFormContext } from 'src/feature-app/OneForm';
import { useFeatureServices } from 'src/feature-app/hooks/use-feature-services';
import {
  getDistanceFromLatLngCenter,
  createNoDmsSlotAndAssessor,
  LayerDesktopView,
  LayerMobileView
} from 'src/feature-app/NewMap';
import { getEarliestSlot, sortPointsByDistance } from 'src/feature-app/NewMap/sort-points';
import { useIsComerciales } from 'src/feature-app/hooks';

interface AvailabilityHistory {
  dealerkvps: string;
  availabilityHistory: AvailabilityHistoryDealer[];
}

interface AvailabilityHistoryDealer {
  dealer: DmsInfo;
  from: number;
  to: number;
  availability: Availability;
}

export const JourneyCitaPosventaController = () => {
  const { formData, formInfo, dealersInfo } = useSelector((state: OneFormState) => state);
  const { horario, dealer }: { horario?: Horario; dealer?: DealersData } = formData.fields;
  const { formEnded, showCalendarOnLoad } = formInfo;
  const { points, selectedDealer } = dealersInfo;
  const { handleNextStep, handleScreenChange, setIsFullScreen } = useOneFormContext();
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const store = useStore();
  const dispatch = useDispatch();
  const env = useFeatureAppEnvironment();
  const config = useFeatureAppConfig();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  // const [selectedPoint, handleSelectedPoint] = useState(null);
  const [loading, setLoading] = useState(false);
  const [preSelectedOrderValue, setPreSelectedOrderValue] = useState<SelectedTab>('ubicacion');
  const pointsByTimeRef = useRef(null);
  const pointsByDistanceRef = useRef(null);
  const isComerciales = useIsComerciales();

  const availabilityHistory = useRef<AvailabilityHistory[]>([]);
  const loadingLayer = useRef(null);
  const {
    clusters,
    centerToCalculateDistance,
    superClusterRef,
    cardsDistanceRef,
    cardsTimeRef,
    selectedPoint,
    handleSelectedPoint,
  } = useMapGeneralController();

  const [pointsToList, setPointsToList] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);

  useEffect(() => {
    if ((formEnded && dealer && showCalendarOnLoad) || selectedDealer || showCalendarOnLoad) {
      const point = points.find((point: Points) => {
        if (point.properties.dealer.kvps === dealer?.kvps || point.properties.dealer.kvps === selectedDealer?.kvps) {
          return point;
        }
      });
      handleShowCalendarOnClick(point);
    }
  }, []);

  //
  // Este useEffect sustituye a los anteriores.
  //
  useEffect(() => {
    if (clusters && superClusterRef.current) {
      const pointsByDistance = sortPointsByDistance(
        clusters,
        centerToCalculateDistance.current,
        superClusterRef.current
      );
      pointsByDistanceRef.current = pointsByDistance;
      setPointsToList(pointsByDistance);
    }
  }, [clusters, superClusterRef.current]);

  const getAvailability = async (
    dealer: DealersData,
    start: number,
    end: number,
    skipHandleAvailability?: boolean
  ): Promise<Availability> => {
    // if (!dealer.dmsInfo || !dealer.dmsInfo.dmsAvailable) {
    //   return null;
    // }

    const cachedAvailability = checkIfAvailabilityIsInCache(dealer, start);

    if (cachedAvailability) {
      return cachedAvailability;
    } else {
      setLoading(true);
      const response = await getSlots(config, dealer, start, end);
      const availability = response.data;

      setLoading(false);
      if (!availability.content.calendar) {
        // Error desde VGED
        // handleAvailabilityErrorLayer();
        // Nuevo layer sin conexión DMS
        saveAvailabilityInCache(dealer, start, end, availability);
        return null;
      } else {
        saveAvailabilityInCache(dealer, start, end, availability);
        return availability;
      }
    }
  };

  const checkIfAvailabilityIsInCache = (dealer: DealersData, start: number): Availability | null => {
    const dealerHistory = availabilityHistory.current.find((a) => a.dealerkvps === dealer.kvps);

    if (dealerHistory) {
      const availabiliyDealer = dealerHistory.availabilityHistory.find(
        (dealerAv) => dealerAv.from === start || start < dealerAv.to
      );
      return availabiliyDealer ? availabiliyDealer.availability : null;
    }
    return null;
  };

  const getAvailabilityFromDealer = (kvps: string): AvailabilityHistory | null => {
    const dealerHistory = availabilityHistory.current.find((a) => a.dealerkvps === kvps);

    return dealerHistory ? dealerHistory : null;
  };

  const saveAvailabilityInCache = (dealer: DealersData, start: number, end: number, availability: Availability) => {
    const dealerInAvailability = availability.content.dealer;
    const availabilityHistoryDealer: AvailabilityHistoryDealer = {
      dealer: dealerInAvailability ? dealerInAvailability : dealer.dmsInfo,
      from: start,
      to: end,
      availability,
    };

    const dealerHistoryIndex = availabilityHistory.current.findIndex((a) => a.dealerkvps === dealer.kvps);
    // Si ya existe el dealer se añade a la array de availability.
    if (dealerHistoryIndex !== -1) {
      availabilityHistory.current[dealerHistoryIndex].availabilityHistory.push(availabilityHistoryDealer); // Testear
    } else {
      // Si no existe el dealer se crea una entrada en el histórico.
      availabilityHistory.current = [
        ...availabilityHistory.current,
        {
          dealerkvps: dealer.kvps,
          availabilityHistory: [availabilityHistoryDealer],
        },
      ];
    }
  };

  const getRandomInt = (max: number): number => {
    const min = 1;
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleDmsMapExit = (slot: CalendarSlot | CalendarSlot[], point: Points) => {
    const { dealer } = point.properties;
    const dealerAvailability = getAvailabilityFromDealer(dealer.kvps);
    if (dealerAvailability && dealerAvailability.availabilityHistory[0].dealer) {
      const dealerFromDms = dealerAvailability.availabilityHistory[0].dealer;
      dealer.dmsInfo = dealerFromDms;
    }
    const { assessors } = dealer.dmsInfo;

    let assessor: Assessors;
    // En ocasiones un mismo slot puede tener distintos asesores
    // por lo que hay que seleccionar uno de manera aleatoria.
    if (Array.isArray(slot)) {
      const max = slot.length;
      const index = getRandomInt(max) - 1;
      // slot = slot[selectedIndex];
      assessor = findAssessor(slot[index], assessors);
    } else {
      assessor = findAssessor(slot, assessors);
    }

    if (!assessor) {
      assessor = {
        code: 'Undefined',
        name: 'Todos',
      };
    }

    handleDealerFlags(dealer);

    dispatch({
      type: 'UPDATE_FIELDS',
      payload: { assessor, slot, dealer },
    });
    // setWaitForNextStep(false);
    if (!formEnded) {
      handleNextStep();
    }
  };

  const findAssessor = (slot: CalendarSlot, assessors: Assessors[]): Assessors => {
    return assessors.find((assessor) => {
      return assessor.name === slot.assessor || assessor.code === slot.assessorCode;
    });
  };

  const handleNoDmsMapExit = (franjaHoraria: string, day: string, point: Points) => {
    let { dealer } = point.properties;
    const dealerAvailability = getAvailabilityFromDealer(dealer.kvps);
    if (dealerAvailability && dealerAvailability.availabilityHistory[0].dealer) {
      const dealerFromDms = dealerAvailability.availabilityHistory[0].dealer;
      dealer.dmsInfo = dealerFromDms;
    }

    // Validamos que la cita a crear sea sin DMS mediante el flag dmsAvailable en false
    if(dealer && dealer.dmsInfo){
      dealer.dmsInfo.dmsAvailable = false;
    }

    const { slot, assessor } = createNoDmsSlotAndAssessor(franjaHoraria, day);

    handleDealerFlags(dealer);

    dispatch({
      type: 'UPDATE_FIELDS',
      payload: { slot, assessor, dealer },
    });

    if (!formEnded) {
      handleNextStep();
    }
  };

  const handleShowSlots = useCallback((point: Points): string | null => {
    const { slots } = point.properties?.dealer;
    let finalSlot;

    if (!isNull(slots)) {
      finalSlot = getFinalSlot(getEarliestSlot(slots));

      return finalSlot ? finalSlot : null;
    }

    return null;
  }, []);

  const getFinalSlot = (from: number) => {
    if (!from) {
      return null;
    }
    const momentSlot = weekDay_Day_Hour(from);
    const capitalizedSlot = momentSlot.charAt(0).toUpperCase() + momentSlot.slice(1);
    return capitalizedSlot.replace('-', ' a las ').concat('h');
  };

  const handleShowCalendarOnClick = useCallback(async (point: Points) => {
    const { firstDayOfMonth, lastDayOfMonth } = getFirstAndLastDayOfMonth();
    // if (point.properties.dealer.dmsInfo && point.properties.dealer.dmsInfo.dmsAvailable) {
    const availability = await getAvailability(point.properties.dealer, firstDayOfMonth, lastDayOfMonth);
    // if (availability && availability.content.calendar) {
    //   handleSelectedPoint(point);
    //   setShowCalendar(true);
    // }
    // } else {
    handleSelectedPoint(point);
    setShowCalendar(true);
    // }
  }, []);

  const handleHideCalendarOnClick = () => {
    handleSelectedPoint(null);
    setShowCalendar(false);
  };

  const handleDealerFlags = (dealer: DealersData) => {
    const { formInfo, formData } = store.getState();
    const { steps } = formInfo;
    const { initialSteps, formEnded }: { initialSteps: Steps[]; formEnded: boolean } = formInfo;
    const {
      cocheSustitucion,
      cocheAlTaller,
      dealer: pastDealer,
    }: {
      cocheSustitucion: 'true' | 'false';
      cocheAlTaller: 'true' | 'false';
      dealer: DealersData;
    } = formData.fields;
    let newSteps = [...steps];

    if (isComerciales) {
      return;
    }

    if (!dealer || !dealer.dmsInfo || !dealer.dmsInfo.pickupCarServiceAvailabe) {
      const tallerIndex = newSteps.findIndex((step) => step.name === 'CocheAlTaller');
      newSteps.splice(tallerIndex, 1);
    }

    if (!dealer || !dealer.dmsInfo || !dealer.dmsInfo.replacementCarServiceAvailable) {
      const sustitucionIndex = newSteps.findIndex((step) => step.name === 'CocheSustitucion');
      newSteps.splice(sustitucionIndex, 1);
    }

    const newStepsWithIndex = newSteps.map((step, index) => {
      step.screenIndex = index;
      return step;
    });

    // Pasamos de dealer sin dms a uno con dms después de llegar a la pantalla de confirmación y volvemos a atrás con editar.
    let newIndex: number;
    let newCocheAlTaller = cocheAlTaller;
    let newCocheSustitucion = cocheSustitucion;
    if (formEnded) {
      // dispatch({ type: 'UPDATE_FIELDS', payload: { cocheSustitucion: null, cocheAlTaller: null } });
      dispatch({ type: 'UPDATE_FORM_ENDED', payload: false });

      if (pastDealer.kvps !== dealer.kvps) {
        if (dealer.dmsInfo.pickupCarServiceAvailabe) {
          if (
            cocheAlTaller === 'false' &&
            pastDealer &&
            pastDealer.dmsInfo &&
            !pastDealer.dmsInfo.pickupCarServiceAvailabe
          ) {
            newIndex = newStepsWithIndex.findIndex((step) => step.name === 'CocheAlTaller');
            newCocheAlTaller = null;
          }
        }
        if (dealer.dmsInfo.replacementCarServiceAvailable) {
          if (
            cocheAlTaller === 'false' &&
            pastDealer &&
            pastDealer.dmsInfo &&
            !pastDealer.dmsInfo.replacementCarServiceAvailable
          ) {
            newIndex = newStepsWithIndex.findIndex((step) => step.name === 'CocheSustitucion');
            newCocheSustitucion = null;
          }
        }
      }
    }

    dispatch({ type: 'UPDATE_STEPS', payload: newStepsWithIndex });
    dispatch({
      type: 'UPDATE_LAST_STEP',
      payload: newStepsWithIndex[newStepsWithIndex.length - 1],
    });
    dispatch({
      type: 'SET_NUMBER_OF_SCREENS',
      payload: newStepsWithIndex.length,
    });
    // Solo se llega a esta parte después de finalizar el journey y volver al mapa para editar la concesión.
    if (formEnded) {
      newIndex = newIndex ? newIndex : newStepsWithIndex.length - 1;
      dispatch({
        type: 'UPDATE_FIELDS',
        payload: {
          cocheSustitucion: newCocheSustitucion,
          cocheAlTaller: newCocheAlTaller,
        },
      });

      dispatch({
        type: 'UPDATE_STEP',
        payload: newStepsWithIndex[newIndex],
      });
      handleScreenChange(newIndex);
      if (newStepsWithIndex[newIndex].name !== 'Confirmacion') {
        dispatch({ type: 'UPDATE_FULLSCREEN', payload: false });
        setIsFullScreen(false);
      }
    }
  };

  const renderCalendar = (): JSX.Element => {
    const { centerToCalculateDistance, userIsGeoLocated } = useMapGeneralController();

    return (
      <CalendarioCita
        selectedPoint={selectedPoint}
        availability={null}
        getAvailability={getAvailability}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot} // Comprobar
        handleDmsMapExit={handleDmsMapExit}
        handleNoDmsMapExit={handleNoDmsMapExit}
        center={centerToCalculateDistance.current}
        horario={horario}
        handleHideCalendarOnClick={handleHideCalendarOnClick}
        userIsGeoLocated={userIsGeoLocated}
      />
    );
  };

  const renderPoints = (
    points: Points[],
    carPickupSelected?: boolean,
    replacementCarSelected?: boolean
  ): JSX.Element[] => {
    return pointsByDistanceRef.current
      ? pointsByDistanceRef.current.map((point: Points, index: number) => {
        if (!point.properties.cluster) {
          const { dealer } = point.properties;
          const distanceFromCenter = getDistanceFromLatLngCenter(centerToCalculateDistance.current, point);

          return (
            <div
              key={`${point.properties.dealer.kvps}-${index}`}
              className={`${point.properties.dealer.kvps}`}
              ref={(el) => {
                cardsDistanceRef.current[index] = el;
              }}
            >
              <NewDealerCard
                handleShowSlots={handleShowSlots}
                key={`${point.properties.dealer.kvps}-${index}-card`}
                distanceFromCenter={distanceFromCenter}
                handleShowCalendarOnClick={handleShowCalendarOnClick}
                handleSeleccionarOnClick={handleSeleccionarOnClick}
                isCarousel={false}
                slots={dealer.slots}
                dealerInfo={dealer}
                point={point}
                isCitaPosventa={true}
                isFirstDesktop={index === 0 ? true : false}
                carPickupSelected={carPickupSelected}
                replacementCarSelected={replacementCarSelected}
              />
            </div>
          );
        }
        return null;
      })
      : null;
  };

  // Gestión del point seleccionado y del boton de seleccionar (dms)
  const handleSeleccionarOnClick = useCallback((point: any) => {
    handleSelectedPoint(point);
    handleReservaCitaLayer(point);
  }, []);

  //render layers reservar cita
  const renderReservaCita = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <ReservaCitaLayer
          handleShowCalendarOnClick={handleShowCalendarOnClick}
          handleDmsMapExit={handleDmsMapExit}
          handleNoDmsMapExit={handleNoDmsMapExit}
          handleShowSlots={handleShowSlots}
          selectedPoint={state.selectedPoint}
          getAvailability={getAvailability}
          closeLayerCallback={close}
        />
      </LayerManagerWrapper>
    );
  };

  const renderAvailabilityErrorLayer = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <AvailabilityErrorLayer closeLayerCallback={close} />
      </LayerManagerWrapper>
    );
  };
  const renderLoadingLayer = () => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <LoadingLayer copy="Consultando con la agenda del taller" />
      </LayerManagerWrapper>
    );
  };

  //manejadores layers
  const handleReservaCitaLayer = (point: Points) => {
    layerManager.openFocusLayer(renderReservaCita, { selectedPoint: point }, { size: FocusLayerSizeV2.A });
  };
  const handleAvailabilityErrorLayer = () => {
    layerManager.openFocusLayer(renderAvailabilityErrorLayer, {}, { size: FocusLayerSizeV2.A });
  };

  const handleLoadingLayer = () => {
    if (loading) {
      const layer = layerManager.openFocusLayer(renderLoadingLayer, {}, { size: FocusLayerSizeV2.A });
      loadingLayer.current = layer;
    } else {
      if (loadingLayer.current) {
        loadingLayer.current.close();
        loadingLayer.current = null;
      }
    }
  };

  useEffect(() => {
    handleLoadingLayer();
  }, [loading]);

  // availability functions

  return (
    <div className="cita-posventa-MAP-controller">
      <>
        <BreakpointWrapper max={Breakpoints.b960}>
          <LayerMobileView
            handleSeleccionarOnClick={handleSeleccionarOnClick}
            handleShowSlots={handleShowSlots}
            renderPoints={renderPoints}
            handleShowCalendarOnClick={handleShowCalendarOnClick}
            renderCalendar={renderCalendar}
            showCalendar={showCalendar}
            preSelectedOrderValue={preSelectedOrderValue}
            setPreSelectedOrderValue={setPreSelectedOrderValue}
            pointsToList={pointsToList}
            isPosventa={true}
            services={true}
            paddingTopList={isComerciales ? '56px' : null}
          />
        </BreakpointWrapper>

        <BreakpointWrapper min={Breakpoints.b960}>
          <LayerDesktopView
            handleSeleccionarOnClick={handleSeleccionarOnClick}
            handleShowSlots={handleShowSlots}
            renderPoints={renderPoints}
            renderCalendar={renderCalendar}
            showCalendar={showCalendar}
            preSelectedOrderValue={preSelectedOrderValue}
            setPreSelectedOrderValue={setPreSelectedOrderValue}
            pointsToList={pointsToList}
            isPosventa={true}
            services={true}
            paddingBottomList={isComerciales ? '32px' : null}
          />
        </BreakpointWrapper>
      </>
    </div>
  );
};

import {
  FeatureServiceBinder,
  FeatureServiceEnvironment,
  FeatureServices,
  SharedFeatureService,
} from '@feature-hub/core';
import { Store } from 'redux';
import { getPincode } from 'src/bff';
import { FormDataFields } from 'src/types';
import {
  DpsData,
  EsOneformCoreServiceV1,
  FieldType,
  OneFormDataV1 as OneformData,
  OneFormDataV1,
  PersonV1,
} from './types';
import { BehaviorSubject } from 'rxjs';
import { VumClient } from '@volkswagen-onehub/myvw_services/dist/vum/VumClient';
import { ServiceConfigProviderV1 } from '@volkswagen-onehub/service-config-provider';
import parsePhoneNumber from 'libphonenumber-js';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { checkIfIsTestPincode, getTestPincode } from './pincode-data';
import { AuthServiceProviderV2, IAuthServiceV2 } from '@volkswagen-onehub/authservice';
import { ProfileServiceV2Impl, UserService } from '@vwdfive/myvw-services';
import { existPerson } from 'src/feature-service/identity-kit-service';

export interface SharedEsOneformCoreService extends SharedFeatureService {
  readonly '1.0.0': FeatureServiceBinder<EsOneformCoreServiceV1>;
}

export interface RequiredFeatureServices extends FeatureServices {
  readonly 'service-config-provider': ServiceConfigProviderV1;
}

export const oneformCoreServiceDefinition = {
  id: 'es:oneform-core',

  dependencies: {
    featureServices: {
      'service-config-provider': '^1.0.0',
      'fa-content-service': '^1.0.0',
    },
  },

  optionalDependencies: {
    featureServices: { ['vw:authService']: '2.0.0' },
  },

  create(env: FeatureServiceEnvironment<RequiredFeatureServices>) {
    let oneFormData = {} as OneFormDataV1;
    let dpsData = {} as DpsData;
    const oneFormCoreDpsIsLoaded: BehaviorSubject<any> = new BehaviorSubject(false);

    let { 'service-config-provider': serviceConfig, ['vw:authService']: provider } = env.featureServices;

    const getLoadedState = () => oneFormCoreDpsIsLoaded;
    const get = () => oneFormData;
    const getDpsData = (): DpsData => dpsData;

    const v1 = () => ({
      featureService: {
        getLoadedState,

        get,
        getDpsData,

        set(updatedData: OneFormDataV1) {
          oneFormData = { ...updatedData };
        },

        setDpsData(updatedData: DpsData) {
          dpsData = { ...dpsData, ...updatedData };
        },

        async initializeService(useAuth: boolean, useBff?: boolean) {
          await this.checkData(useAuth, useBff);
          oneFormCoreDpsIsLoaded.complete();
        },

        async checkLogin() {

          //const authService = getAuthService(serviceConfig, provider as AuthServiceProviderV2);
          const authService: IAuthServiceV2 = (provider as AuthServiceProviderV2)?.register('market');
          const endpoint = serviceConfig.configs['customer-profile']?.urlOrigin;
          const profileService = new ProfileServiceV2Impl(authService, endpoint);
          const isAuthenticated = await authService.isAuthenticated();
          const userService = new UserService(authService);

          if (!isAuthenticated) {
            return;
          }
          const profile = await profileService.getUserProfile();
          const email = await userService.getCurrentUserEmail();
          // const vumClient = new VumClient(endpoint, authService);
          // const aa = await vumClient.getUserVehicleRelations();
          //DATOS PERSONALES
          if (profile.phoneNumber) {
            const parsedPhone = parsePhoneNumber(profile.phoneNumber);
            const isValidPhone = isValidPhoneNumber(profile.phoneNumber);
            if (isValidPhone) {
              this.setField('prefix', `+${parsedPhone.countryCallingCode}`);
              this.setField('cellphone', parsedPhone.nationalNumber);
            }
          }
          this.setField('name', profile.firstName);
          this.setField('surname', profile.lastName);
          this.setField('email', email);

          const identitykitId = await userService.getCurrentUserId();
          this.setField('identitykitId', identitykitId);
          const personResponse = await existPerson(authService, identitykitId);
          this.setField('climaproUserStatus', personResponse.data?.userStatus);
          this.setField('climaproId', personResponse.data?.climaproId);

          // this.setField('cellphone', '600000000');
          //this.setField('userIsAuthenticated', isAuthenticated ? 'true' : 'false');

          // VEHÍCULOS
          // this.setField('userVehicles', [
          //   { matricula: 'A0000BC', kilometros: '30000', modelo: 'Arteon', anoMatriculacion: '' , isMainVehicle: false },
          //   { matricula: 'B0000BC', kilometros: '60000', modelo: 'T-Roc', anoMatriculacion: '' , isMainVehicle: true },
          // ]);
          // this.setField('matricula', 'A0000BC');

          // DEALER
          // this.setField('favouriteDealer', '00031');

          // LEGALES
          // this.setField('lssi', 'PERMITIDO');
          // this.setField('tmk', 'PERMITIDO');
          
        },

        async checkPincode(useBff?: boolean, isRetry?: boolean) {
          try {
            const pincode = this.getUrlParams('pincode');

            if (pincode && checkIfIsTestPincode(pincode)) {
             // console.log(getTestPincode(pincode),'pincode');
              this.setDpsData({
                pincode: {
                  pincodeResponse: getTestPincode(pincode),
                  pincodeError: false,
                  pincodeUsed: false,
                  noPincodeFound: false,
                },
              });
              return;
            }
            if (pincode) {
              const pincodeData = await getPincode(pincode, useBff);

              this.setDpsData({
                pincode: {
                  pincodeResponse: pincodeData.pincodeData,
                  pincodeError: pincodeData.pincodeError,
                  pincodeUsed: pincodeData.pincodeUsed,
                  noPincodeFound: false,
                },
              });
              // person.map((att) => {
              //   // this.setField(att.name, att.value);
              // });
            } else {
              this.setDpsData({
                pincode: {
                  pincodeResponse: null,
                  pincodeError: null,
                  pincodeUsed: null,
                  noPincodeFound: true,
                },
              });
            }
          } catch (error) {
            oneFormCoreDpsIsLoaded.error(error);
          }
        },

        checkUrl() {
          const dealerKvps = this.getUrlParams('dealer');
          const motivo = this.getUrlParams('motivo');
          const contactado = this.getUrlParams('contactado');
          const rating = this.getUrlParams('rating');
          const push = this.getUrlParams('push');
          const estado = this.getUrlParams('estado');
          const waid = this.getUrlParams('wa') || this.getUrlParams('waid');
          const cem = this.getUrlParams('cem');
          const mgm = this.getUrlParams('mgm');
          const adviser = this.getUrlParams('adviser');
          const or = this.getUrlParams('or');
          const dat = this.getUrlParams('dat');
          const env = this.getUrlParams('env');
          const fe = this.getUrlParams('fe');
          const dealer = this.getUrlParams('dealer');
          const kvps = this.getUrlParams('kvps');
          const campaign = this.getUrlParams('campaign');
          const model = this.getUrlParams('model');
          const interes = this.getUrlParams('interes');

          if (motivo) {
            this.setField('motivo', motivo);
          }
          if (dealerKvps) {
            this.setField('dealerKvps', dealerKvps);
          }
          if (contactado) {
            this.setDpsData({ contactado });
          }
          if (rating) {
            this.setDpsData({ rating });
          }
          if (push) {
            this.setDpsData({ push });
          }
          if (estado) {
            this.setDpsData({ estado });
          }
          if (waid) {
            this.setDpsData({ waid });
          }
          if (cem) {
            this.setDpsData({ cem });
          }
          if (mgm) {
            this.setDpsData({ mgm });
          }
          if (adviser) {
            this.setDpsData({ adviser });
          }
          if (or) {
            this.setDpsData({ or });
          }
          if (dat) {
            this.setDpsData({ dat });
          }
          if (env) {
            this.setDpsData({ env });
          }
          if (fe) {
            this.setDpsData({ fe });
          }
          if (dealer) {
            this.setDpsData({ dealer });
          }
          if (kvps) {
            this.setDpsData({ kvps });
          }
          if (campaign) {
            this.setDpsData({ campaign });
          }
          if (model) {
            this.setDpsData({ model });
          }
          if (interes) {
            this.setDpsData({ interes });
          }
        },

        async checkData(useAuth: boolean, useBff?: boolean) {
          await this.checkPincode(useBff);
          if (useAuth && getDpsData().pincode.noPincodeFound) {
            await this.checkLogin();
          }
          this.checkUrl();
        },

        getUrlParams(param: string) {
          if (typeof window === 'undefined') {
            return null;
          }
          if (window && window.location) {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(param);
          }
          return null;
        },

        async retryPincodeLoad(useBff?: boolean) {
          await this.checkPincode(useBff);
        },

        setField(field: FieldType | string, value: any): void {
          const { person } = this.get();
          const updatedPerson: PersonV1 = { ...person };
          updatedPerson[field] = value;
          const updatedOneFormData: OneformData = { ...oneFormData, person: { ...updatedPerson } };
          this.set(updatedOneFormData);
        },

        subscribeToFields(store: Store<any>): void {
          const { fields }: { fields: FormDataFields } = store.getState().formData;
          Object.keys(fields).forEach((field) => {
            this.setField(field, fields[field]);
          });
        },
      },
    });

    return { '1.0.0': v1 };
  },
};

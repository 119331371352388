export const getTestLead = (date?: string) => {
	return [
		{
			name: "ID",
			value: "LEAD"
		},
		{
			name: "attributes",
			value: "es.vged.ndpsbackend.salesforce.rest.entities.SfAttributesRest@29d482e8[type=Lead,url=/services/data/v53.0/sobjects/Lead/00Q1i00000DjYfEEAV]"
		},
		{
			name: "Id",
			value: "00Q1i00000DjYfEEAV"
		},
		{
			name: "IsDeleted",
			value: "false"
		},
		{
			name: "MasterRecordId",
			value: "null"
		},
		{
			name: "LastName",
			value: "Test Vged"
		},
		{
			name: "FirstName",
			value: "Test Lara"
		},
		{
			name: "Salutation",
			value: "null"
		},
		{
			name: "MiddleName",
			value: "null"
		},
		{
			name: "Name",
			value: "Test Lara Test Vged"
		},
		{
			name: "RecordTypeId",
			value: "0121i000000D7NyAAK"
		},
		{
			name: "Title",
			value: "null"
		},
		{
			name: "Company",
			value: "null"
		},
		{
			name: "Address",
			value: "SfAddressRest(city=null, country=724, countryCode=ES, geocodeAccuracy=null, latitude=null, longitude=null, postalCode=null, state=null, stateCode=null, street=null)"
		},
		{
			name: "Phone",
			value: "null"
		},
		{
			name: "MobilePhone",
			value: "612345678"
		},
		{
			name: "Fax",
			value: "null"
		},
		{
			name: "Email",
			value: "test.vged@gmail.com"
		},
		{
			name: "Website",
			value: "null"
		},
		{
			name: "PhotoUrl",
			value: "/services/images/photo/00Q1i00000DjYfEEAV"
		},
		{
			name: "Description",
			value: "null"
		},
		{
			name: "LeadSource",
			value: "null"
		},
		{
			name: "Status",
			value: "Ready for Routing"
		},
		{
			name: "Industry",
			value: "null"
		},
		{
			name: "Rating",
			value: "null"
		},
		{
			name: "CurrencyIsoCode",
			value: "EUR"
		},
		{
			name: "AnnualRevenue",
			value: "null"
		},
		{
			name: "NumberOfEmployees",
			value: "null"
		},
		{
			name: "OwnerId",
			value: "0051i000004Ur0bAAC"
		},
		{
			name: "HasOptedOutOfEmail",
			value: "false"
		},
		{
			name: "IsConverted",
			value: "false"
		},
		{
			name: "ConvertedDate",
			value: "null"
		},
		{
			name: "ConvertedAccountId",
			value: "null"
		},
		{
			name: "ConvertedContactId",
			value: "null"
		},
		{
			name: "ConvertedOpportunityId",
			value: "null"
		},
		{
			name: "IsUnreadByOwner",
			value: "true"
		},
		{
			name: "CreatedDate",
			value: date ? date : "2023-07-12T14:28:35.000+0000"
		},
		{
			name: "CreatedById",
			value: "0051i000004Ur0bAAC"
		},
		{
			name: "LastModifiedDate",
			value: "2023-10-03T12:24:18.000+0000"
		},
		{
			name: "LastModifiedById",
			value: "0051i000002usITAAY"
		},
		{
			name: "SystemModstamp",
			value: "2023-10-03T12:24:18.000+0000"
		},
		{
			name: "LastActivityDate",
			value: "null"
		},
		{
			name: "DoNotCall",
			value: "false"
		},
		{
			name: "HasOptedOutOfFax",
			value: "false"
		},
		{
			name: "LastViewedDate",
			value: "2023-12-14T09:57:05.000+0000"
		},
		{
			name: "LastReferencedDate",
			value: "2023-12-14T09:57:05.000+0000"
		},
		{
			name: "LastTransferDate",
			value: "2023-07-12"
		},
		{
			name: "PartnerAccountId",
			value: "null"
		},
		{
			name: "Jigsaw",
			value: "null"
		},
		{
			name: "JigsawContactId",
			value: "null"
		},
		{
			name: "EmailBouncedReason",
			value: "null"
		},
		{
			name: "EmailBouncedDate",
			value: "null"
		},
		{
			name: "IndividualId",
			value: "0PK1X000008UZ0QWAW"
		},
		{
			name: "et4ae5__Mobile_Country_Code__c",
			value: "US"
		},
		{
			name: "et4ae5__HasOptedOutOfMobile__c",
			value: "false"
		},
		{
			name: "Brand__c",
			value: "Volkswagen"
		},
		{
			name: "Dealership__c",
			value: "0011i00001dahM6AAI"
		},
		{
			name: "ExpectedPurchaseDate__c",
			value: "null"
		},
		{
			name: "ExtKey__c",
			value: "nDPS-000000000000000000593"
		},
		{
			name: "InterestCar__c",
			value: "a0R1i00000B2ZOiEAN"
		},
		{
			name: "RCY_AcademicTitle__c",
			value: "null"
		},
		{
			name: "RCY_Case__c",
			value: "null"
		},
		{
			name: "RCY_ExternalRoutingCheck__c",
			value: "Do Not Route"
		},
		{
			name: "RCY_CommissionNumber__c",
			value: "null"
		},
		{
			name: "RCY_ForwardingMechanism__c",
			value: "Automatic"
		},
		{
			name: "RCY_ForwardingStatus__c",
			value: "Not Forwarded"
		},
		{
			name: "RCY_InterestType__c",
			value: "null"
		},
		{
			name: "RCY_Language__c",
			value: "null"
		},
		{
			name: "RCY_LeadRole__c",
			value: "null"
		},
		{
			name: "RCY_Mobile__c",
			value: "612345678"
		},
		{
			name: "RCY_NewsletterDuplicateCheck__c",
			value: "True"
		},
		{
			name: "RCY_NewsletterRequestCaptureDate__c",
			value: "null"
		},
		{
			name: "RCY_NewsletterUnsubscriptionDate__c",
			value: "null"
		},
		{
			name: "RCY_Phone__c",
			value: "null"
		},
		{
			name: "RCY_PreferredCommunication__c",
			value: "null"
		},
		{
			name: "RCY_PurchaseMethod__c",
			value: "null"
		},
		{
			name: "RCY_RelatedAccount__c",
			value: "null"
		},
		{
			name: "RCY_StatusReason__c",
			value: "null"
		},
		{
			name: "RCY_VIN__c",
			value: "null"
		},
		{
			name: "RCY_ValidationIdentifier__c",
			value: "null"
		},
		{
			name: "RCY_VehicleCategory__c",
			value: "New Car"
		},
		{
			name: "RCY_AnonymizeLead__c",
			value: "false"
		},
		{
			name: "RCY_AverageAnnualMileage__c",
			value: "null"
		},
		{
			name: "RCY_BrandFlagAUDI__c",
			value: "false"
		},
		{
			name: "RCY_BrandFlagSEAT__c",
			value: "false"
		},
		{
			name: "RCY_BrandFlagSKODA__c",
			value: "false"
		},
		{
			name: "RCY_BrandFlagVWCV__c",
			value: "false"
		},
		{
			name: "RCY_BrandFlagVW__c",
			value: "true"
		},
		{
			name: "RCY_DealerValidation__c",
			value: "false"
		},
		{
			name: "RCY_DoubleConsentCaptureDate__c",
			value: "null"
		},
		{
			name: "RCY_DoubleConsentMailSentOn__c",
			value: "null"
		},
		{
			name: "RCY_DuplicateCheckAttribute__c",
			value: "null"
		},
		{
			name: "RCY_FleetSize__c",
			value: "null"
		},
		{
			name: "RCY_InterestCarValidation__c",
			value: "false"
		},
		{
			name: "RCY_LMBrandSearch__c",
			value: "Volkswagen"
		},
		{
			name: "RCY_LMCorrespondenceGreeting__c",
			value: "Test Lara Test Vged"
		},
		{
			name: "RCY_LeadEntryPoint__c",
			value: "null"
		},
		{
			name: "RCY_MarketValidation__c",
			value: "false"
		},
		{
			name: "RCY_NumberOfCarsToPurchase__c",
			value: "null"
		},
		{
			name: "RCY_OriginalConfigurationCode__c",
			value: "null"
		},
		{
			name: "RCY_RelatedBusinessAccount__c",
			value: "null"
		},
		{
			name: "RCY_RelatedContact__c",
			value: "null"
		},
		{
			name: "RCY_SetToOpenAtDealer__c",
			value: "null"
		},
		{
			name: "RCY_SetToRouted__c",
			value: "null"
		},
		{
			name: "RCY_StopMarketing__c",
			value: "false"
		},
		{
			name: "RCY_TimeSinceOpenAtDealer__c",
			value: "null"
		},
		{
			name: "RCY_TimeSinceRouted__c",
			value: "null"
		},
		{
			name: "RCY_extCorrelationId__c",
			value: "null"
		},
		{
			name: "RCY_LastMarketingPressureMeasuredDate__c",
			value: "null"
		},
		{
			name: "RCY_MarketingPressureCall__c",
			value: "Green"
		},
		{
			name: "RCY_MarketingPressureLetter__c",
			value: "Green"
		},
		{
			name: "RCY_MarketingPressureSMS__c",
			value: "Green"
		},
		{
			name: "RCY_MarketingPressureTotal__c",
			value: "Green"
		},
		{
			name: "RCY_NumberOfCalls__c",
			value: "null"
		},
		{
			name: "RCY_NumberOfEmails__c",
			value: "null"
		},
		{
			name: "RCY_NumberOfLetters__c",
			value: "null"
		},
		{
			name: "RCY_NumberOfSMS__c",
			value: "null"
		},
		{
			name: "RCY_NumberOfTotalCommunications__c",
			value: "null"
		},
		{
			name: "RCY_Opportunity__c",
			value: "null"
		},
		{
			name: "RCY_RelatedBusinessAccountFilled__c",
			value: "false"
		},
		{
			name: "RCY_RelatedContactFilled__c",
			value: "false"
		},
		{
			name: "RCY_VoucherActivationDeadline__c",
			value: "null"
		},
		{
			name: "RCY_VoucherCode__c",
			value: "null"
		},
		{
			name: "RCY_VoucherCreationDate__c",
			value: "null"
		},
		{
			name: "RCY_VoucherRedemptionDeadline__c",
			value: "null"
		},
		{
			name: "RCY_VoucherStatus__c",
			value: "Sent"
		},
		{
			name: "RCY_VoucherType__c",
			value: "null"
		},
		{
			name: "VGED_RoadName__c",
			value: "null"
		},
		{
			name: "VGED_RoadNumber__c",
			value: "null"
		},
		{
			name: "VGED_RoadType__c",
			value: "null"
		},
		{
			name: "VGED_InitialMgntType__c",
			value: "Direct to Sales Assistant"
		},
		{
			name: "VGED_ModelOfInterest__c",
			value: "null"
		},
		{
			name: "VGED_TechnicalMdmStatus__c",
			value: "null"
		},
		{
			name: "VGED_TechnicalSalesAssistantStatus__c",
			value: "null"
		},
		{
			name: "VGED_BrandObservations__c",
			value: "null"
		},
		{
			name: "VGED_CIF__c",
			value: "null"
		},
		{
			name: "VGED_CallCenterObservations__c",
			value: "null"
		},
		{
			name: "VGED_2Surname__c",
			value: "null"
		},
		{
			name: "VGED_AdditionalInformation__c",
			value: "null"
		},
		{
			name: "VGED_AdvisorName__c",
			value: "null"
		},
		{
			name: "VGED_AppointmentDateAndTime__c",
			value: "null"
		},
		{
			name: "VGED_Birthdate__c",
			value: "null"
		},
		{
			name: "VGED_Campaign__c",
			value: "7011i0000002CjUAAU"
		},
		{
			name: "VGED_NumOfRetriesMDM__c",
			value: "1.0"
		},
		{
			name: "VGED_CommercialPermits__c",
			value: "PERMITE"
		},
		{
			name: "RCY_LicensePlate__c",
			value: "null"
		},
		{
			name: "RCY_MileageKm__c",
			value: "null"
		},
		{
			name: "VGED_CurrentVehicleBrand__c",
			value: "null"
		},
		{
			name: "VGED_CurrentVehicleModel__c",
			value: "null"
		},
		{
			name: "RCY_Model__c",
			value: "null"
		},
		{
			name: "VGED_DateAndTimeClosedByCC__c",
			value: "null"
		},
		{
			name: "VGED_DateAndTimeOfFirstCapture__c",
			value: "2023-07-11T00:00:00.000+0000"
		},
		{
			name: "VGED_DateAndTimeSentToDealer__c",
			value: "null"
		},
		{
			name: "VGED_DateAndTimeToCC__c",
			value: "2023-10-03T12:24:17.000+0000"
		},
		{
			name: "RCY_NextContactPoint1__c",
			value: "null"
		},
		{
			name: "VGED_FlagCRMOrigin__c",
			value: "false"
		},
		{
			name: "RCY_RegistrationDate__c",
			value: "null"
		},
		{
			name: "RCY_LeadSubtype__c",
			value: "null"
		},
		{
			name: "VGED_CRMBusinessModelofInterest__c",
			value: "a1i1i00000NRQwGAAX"
		},
		{
			name: "VGED_CustomizationPath__c",
			value: "Generico_Presupuesto"
		},
		{
			name: "VGED_IsCompany__c",
			value: "false"
		},
		{
			name: "VGED_LeadEnrichment__c",
			value: "false"
		},
		{
			name: "VGED_LinkVehicleOfInterest__c",
			value: "null"
		},
		{
			name: "VGED_MonitorDealerCall__c",
			value: "true"
		},
		{
			name: "VGED_OfferDetails__c",
			value: "null"
		},
		{
			name: "VGED_OfferPrice__c",
			value: "null"
		},
		{
			name: "VGED_OnlineBookingDetails__c",
			value: "null"
		},
		{
			name: "VGED_TechnicalTrackBackStatus__c",
			value: "null"
		},
		{
			name: "VGED_PreferredChannelForContact__c",
			value: "Email"
		},
		{
			name: "VGED_PreferredTimeSlotForContact__c",
			value: "null"
		},
		{
			name: "VGED_ReceivedOnSalesAssistant__c",
			value: "false"
		},
		{
			name: "VGED_URL__c",
			value: "null"
		},
		{
			name: "VGED_UserId__c",
			value: "null"
		},
		{
			name: "VGED_UserNIF__c",
			value: "null"
		},
		{
			name: "RCY_Agreement__c",
			value: "null"
		},
		{
			name: "RCY_BrandFlagCUPRA__c",
			value: "false"
		},
		{
			name: "RCY_DeletedMarketingCloud__c",
			value: "false"
		},
		{
			name: "RCY_GDPRStatusTimeStamp__c",
			value: "null"
		},
		{
			name: "RCY_GDPRStatus__c",
			value: "null"
		},
		{
			name: "RCY_MCReadyBrandAUDI__c",
			value: "false"
		},
		{
			name: "RCY_MCReadyBrandCUPRA__c",
			value: "false"
		},
		{
			name: "RCY_MCReadyBrandSEAT__c",
			value: "false"
		},
		{
			name: "RCY_MCReadyBrandSKODA__c",
			value: "false"
		},
		{
			name: "RCY_MCReadyBrandVWCV__c",
			value: "false"
		},
		{
			name: "RCY_MCReadyBrandVW__c",
			value: "true"
		},
		{
			name: "RCY_PassedExpressedInterestMilestone__c",
			value: "false"
		},
		{
			name: "RCY_PassedIntenttoPurchaseMilestone__c",
			value: "true"
		},
		{
			name: "RCY_PassedQualifiedMilestone__c",
			value: "false"
		},
		{
			name: "RCY_SalesModel__c",
			value: "Retail"
		},
		{
			name: "RCY_BypassLeadStatus__c",
			value: "false"
		},
		{
			name: "RCY_DealerComment__c",
			value: "null"
		},
		{
			name: "RCY_DealershipSharing__c",
			value: "null"
		},
		{
			name: "RCY_FAMDealershipSharing__c",
			value: "null"
		},
		{
			name: "RCY_IsDealershipCreated__c",
			value: "false"
		},
		{
			name: "RCY_LeadRecordTypeFilter__c",
			value: "VGED_SalesAssistant"
		},
		{
			name: "RCY_ReasonOfSendingToNSC__c",
			value: "null"
		},
		{
			name: "VGED_AdditionalSourceInformation__c",
			value: "null"
		},
		{
			name: "VGED_MysteryLead__c",
			value: "false"
		},
		{
			name: "VGED_Profiling__c",
			value: "NO INFORMADO"
		},
		{
			name: "VGED_Test__c",
			value: "false"
		},
		{
			name: "VGED_CaptureChannel__c",
			value: "null"
		},
		{
			name: "VGED_CaptureEvent__c",
			value: "null"
		},
		{
			name: "VGED_City__c",
			value: "null"
		},
		{
			name: "VGED_CodeOfInterestCarline__c",
			value: "30204"
		},
		{
			name: "VGED_ConfiguratorMatch__c",
			value: "null"
		},
		{
			name: "VGED_CurrentVehicleAge__c",
			value: "null"
		},
		{
			name: "VGED_DNINIF__c",
			value: "null"
		},
		{
			name: "VGED_FinancingType__c",
			value: "null"
		},
		{
			name: "VGED_InExchangeVehicleBrand__c",
			value: "null"
		},
		{
			name: "VGED_InExchangeVehicleFuel__c",
			value: "null"
		},
		{
			name: "VGED_InExchangeVehicleMileage__c",
			value: "null"
		},
		{
			name: "VGED_InExchangeVehicleModelVersion__c",
			value: "null"
		},
		{
			name: "VGED_InExchangeVehicleModel__c",
			value: "null"
		},
		{
			name: "VGED_InExchangeVehicleRegistrationPlate__c",
			value: "null"
		},
		{
			name: "VGED_InExchangeVehicleRegistrationYear__c",
			value: "null"
		},
		{
			name: "VGED_IsClient__c",
			value: "false"
		},
		{
			name: "VGED_IsExclient__c",
			value: "false"
		},
		{
			name: "VGED_Mileage__c",
			value: "null"
		},
		{
			name: "VGED_OKAPICodeOfModelOfInterest__c",
			value: "null"
		},
		{
			name: "VGED_PostalCode__c",
			value: "null"
		},
		{
			name: "VGED_Province__c",
			value: "null"
		},
		{
			name: "VGED_RegistrationPlate__c",
			value: "null"
		},
		{
			name: "VGED_RoadComplement__c",
			value: "null"
		},
		{
			name: "VGED_TelemarketingClosure__c",
			value: "null"
		},
		{
			name: "VGED_WebSection__c",
			value: "null"
		},
		{
			name: "VGED_IsLeadEnriched__c",
			value: "false"
		},
		{
			name: "VGED_CarConfigurationURL__c",
			value: "null"
		},
		{
			name: "VGED_DetailsOfModelOfInterest__c",
			value: "null"
		},
		{
			name: "VGED_ExtraInfoModelOfInterest__c",
			value: "null"
		},
		{
			name: "VGED_IDFormCampaign__c",
			value: "7011i0000002CclAAE"
		},
		{
			name: "VGED_MasterQuestionnaireId__c",
			value: "140.0"
		},
		{
			name: "VGED_NameFormCampaign__c",
			value: "VW_B01_C01_RRSS_LoadRecords"
		},
		{
			name: "VGED_VehicleConfigurationDetails__c",
			value: "null"
		},
		{
			name: "VGED_AdvertisementId__c",
			value: "null"
		},
		{
			name: "VGED_BookingCancelledReservation__c",
			value: "false"
		},
		{
			name: "VGED_BookingDays__c",
			value: "1.0"
		},
		{
			name: "VGED_ImawebDealerId__c",
			value: "null"
		},
		{
			name: "VGED_ImawebRecordId__c",
			value: "null"
		}
	]
};
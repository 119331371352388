import { useWindowWidth } from '@react-hook/window-size';
import {
  Breakpoints,
  BreakpointWrapper,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Layout,
  styled,
  Text,
  TokenTextAppearance,
  TokenTextColor,
} from '@volkswagen-onehub/components-core';
import { Close } from '@volkswagen-onehub/icons-core/dist/Close';
import { Phone } from '@volkswagen-onehub/icons-core/dist/Phone';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  ScreenController,
  TriggerProps,
  useFeatureAppConfig,
  useOneFormContext,
  useFeatureServices,
  LayerManagerWrapper,
  useTrackingManager,
  useFeatureAppEnvironment,
  useOnScreen,
} from 'src/feature-app';
import { RaceForm, useRaceFormController } from 'src/forms';
import { GenericErrorLayer } from 'src/components';
import { OneFormState } from 'src/types';
import { CloseHandleV2, FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';

const RaceDiv = styled.div`
  position: fixed;
  right: 10px;
  bottom: 5%;
  z-index: 2;

  & button {
    background-color: #ffaa27;
    border: 2px solid #ffaa27;
    color: #001e50;
  }

  & button[disabled] {
    background-color: rgb(223, 228, 232);
    border: 2px solid rgb(223, 228, 232);
  }
`;
export function RaceTrigger(props: TriggerProps) {
  const config = useFeatureAppConfig();
  return config.trigger === 'race' ? (
    <ScreenController
      selectedJourney={<RaceTriggerFullScreen />}
      closeLayerCallback={close}
      handleAreYouSureLayer={() => null}
      formType='module'
    />
  ) : (
    <RaceTriggerPopUp {...props} />
  );
}

const Box = styled.div`
  width: 100%;
  border: 2px solid #ffaa27;
  height: 100%;
`;

const StyledImage = styled.img`
  height: auto;
  width: 100%;

  @media screen and (min-width: 960px) {
    width: var(--size-grid008);
  }

  @media screen and (min-width: 1280px) {
    width: var(--size-grid007);
  }
  @media screen and (min-width: 1920px) {
    width: var(--size-grid006);
  }
`;

const ImageWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  @media screen and (min-width: 960px) {
    margin-bottom: 0px;
  }
`;
const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 960px) {
    width: var(--size-grid009);
  }
  @media screen and (min-width: 1280px) {
    width: var(--size-grid010);
  }
  @media screen and (min-width: 1920px) {
    width: var(--size-grid011);
  }
`;
const ImageAndContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 960px) {
    flex-direction: row;
    gap: var(--size-grid001);
  }

  @media screen and (min-width: 2560px) {
    padding-top: calc(var(--size-grid001) / 2);
    padding-bottom: calc(var(--size-grid001) / 2);
  }
`;

export const RaceTriggerFullScreen = () => {
  const { raceCellphone } = useSelector((state: OneFormState) => state.formData.fields);
  const { isSent, isSending, isPristine, disabledButton, error, sendForm, reset, setIsPristine, successful, retry } =
    useRaceFormController(raceCellphone);
  const ref = useRef(null);
  const containerRef = useRef(null);
  const isVisible = useOnScreen(containerRef);
  const [copyWidth, setCopyWidth] = useState(0);
  const { initializeForm } = useOneFormContext();
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const store = useStore();
  const env = useFeatureAppEnvironment();
  const layerManager = useFeatureServices()['layer-manager'];
  const trackingManager = useTrackingManager();
  const firstView = useRef(true);

  useEffect(() => {
    if (ref) {
      setCopyWidth(ref.current.offsetWidth);
    }
  }, [windowWidth]);

  useEffect(() => {
    // Disparamos el evento de analítica solo cuando el trigger es visible en pantalla.
    if (isVisible && firstView.current) {
      trackingManager.trackFormLoadRace();
      firstView.current = false;
    }
  }, [isVisible]);

  useEffect(() => {
    if (raceCellphone && isPristine) {
      // bug al enviar el formulario y volver a abrirlo, se puede reenviar sin introducir teléfono.
      dispatch({ type: 'UPDATE_FIELDS', payload: { raceCellphone: undefined } });
    }
  }, [raceCellphone, isPristine]);

  useEffect(() => {
    if (error) {
      handleErrorLayer();
    }
  }, [error]);

  useEffect(() => { }, []);

  const renderErrorScreen = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <GenericErrorLayer
          title="Uy, algo ha pasado"
          bodycopy="Por alguna razón no hemos podido guardar tu petición. ¿Lo intentamos de nuevo?"
          ctaLayer="Reintentar"
          onClick={() => {
            retry();
            close({}, {}, {});
          }}
          icon={false}
          ctaTextAlign="left"
          secondCta={
            <CTA
              tag="button"
              emphasis="secondary"
              onClick={(e: any) => {
                reset();
                close({}, {}, {});
              }}
            >
              Volver a probar más tarde
            </CTA>
          }
        />
      </LayerManagerWrapper>
    );
  };

  const handleErrorLayer = () => {
    layerManager.openFocusLayer(renderErrorScreen, {}, { size: FocusLayerSizeV2.A });
  };

  return (
    <>
      <div ref={containerRef} className="race-trigger">
        <Layout
          appearance={{
            [Breakpoints.default]: [
              { name: '.', columns: 1 },
              { name: 'a', columns: 22 },
              { name: '.', columns: 1 },
            ],
            [Breakpoints.b560]: [
              { name: '.', columns: 2 },
              { name: 'a', columns: 20 },
              { name: '.', columns: 2 },
            ],
          }}
        >
          <Box>

            <Container
              padding={{
                top: { 0: ContainerPadding.grid002, 960: ContainerPadding.grid001, 2560: ContainerPadding.static0 },
                bottom: { 0: ContainerPadding.grid002, 960: ContainerPadding.grid001, 2560: ContainerPadding.static0 },
                right: {
                  0: ContainerPadding.grid001,
                  560: ContainerPadding.grid002,
                  960: ContainerPadding.grid001,
                  2560: ContainerPadding.grid002,
                },
                left: {
                  0: ContainerPadding.grid001,
                  560: ContainerPadding.grid002,
                  960: ContainerPadding.grid001,
                  2560: ContainerPadding.grid002,
                },
              }}
            >
              <ImageAndContentWrapper>
                <ImageWrapper>
                  <BreakpointWrapper max={Breakpoints.b960}>
                    <StyledImage
                      src="https://cdn.volkswagen.es/repository/app/vw-oneform/assets/images/race_mobile.png"
                      alt="Race"
                    />
                  </BreakpointWrapper>
                  <BreakpointWrapper min={Breakpoints.b960}>
                    <StyledImage
                      src="https://cdn.volkswagen.es/repository/app/vw-oneform/assets/images/race_desktop.png"
                      alt="Race"
                    />
                  </BreakpointWrapper>
                </ImageWrapper>
                <ContentWrapper>
                  <div>
                    <Text appearance={TokenTextAppearance.headline300} bold>
                      Habla con un experto
                    </Text>
                    <div style={{ paddingTop: '12px' }} ref={ref}>
                      <Text appearance={TokenTextAppearance.copy200}>
                        ¿Te gustaría preguntar algo sobre la mecánica de tu coche? ¿Se ha encendido una luz que no
                        identificas? Tenemos las respuestas que necesitas.
                      </Text>
                    </div>
                  </div>
                  <RaceForm
                    isSent={isSent}
                    isSending={isSending}
                    isPristine={isPristine}
                    disabledButton={disabledButton}
                    sendForm={sendForm}
                    copyWidth={copyWidth}
                    isFullScreen={true}
                    setIsPristine={setIsPristine}
                    successful={successful}
                  />
                  <Legal />
                </ContentWrapper>
              </ImageAndContentWrapper>
            </Container>
          </Box>
        </Layout>
      </div>
    </>
  );
};

export const RaceTriggerPopUp = ({ setSelectedFocusJourney, setCTALabel, selectedFocusJourney }: TriggerProps) => {
  const [ctaDisabled, setCtaDisabled] = useState(false);
  const trackingManager = useTrackingManager();

  useEffect(() => {
    if (!selectedFocusJourney && ctaDisabled) {
      setCtaDisabled(false);
    }
  }, [selectedFocusJourney]);

  useEffect(() => {
    trackingManager.trackFormLoadRace();
  }, []);

  return (
    <RaceDiv>
      <CTA
        tag="button"
        disabled={ctaDisabled}
        icon={<Phone />}
        onClick={() => {
          setSelectedFocusJourney(<RacePopUp setCtaDisabled={setCtaDisabled} />);
          setCTALabel('Te llamamos');
          setCtaDisabled(true);
        }}
        stretchContent
      >
        Habla con un experto
      </CTA>
    </RaceDiv>
  );
};

const TitleDiv = styled.div`
  background-color: #f2ad47;
`;
const TitleInside = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseDiv = styled.div`
  cursor: pointer;
`;

interface RacePopUpProps {
  setCtaDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const RacePopUp = ({ setCtaDisabled }: RacePopUpProps) => {
  const { closeLayer } = useOneFormContext();
  const { fields } = useSelector((state: OneFormState) => state.formData);
  const { raceCellphone } = fields;
  const { isSent, isSending, isPristine, disabledButton, error, sendForm, retry, setIsPristine, successful } =
    useRaceFormController(raceCellphone);
  const ref = useRef(null);
  const [copyWidth, setCopyWidth] = useState(0);
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (ref) {
      setCopyWidth(ref.current.offsetWidth);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (raceCellphone && isPristine) {
      // bug al enviar el formulario y volver a abrirlo, se puede reenviar sin introducir teléfono.
      dispatch({ type: 'UPDATE_FIELDS', payload: { raceCellphone: undefined } });
    }
  }, [raceCellphone, isPristine]);

  return (
    <>
      <TitleDiv>
        <Container
          stretchContent
          padding={{
            top: ContainerPadding.dynamic0100,
            right: ContainerPadding.static300,
            bottom: ContainerPadding.dynamic0100,
            left: { 0: ContainerPadding.grid002, 960: ContainerPadding.grid001 },
          }}
        >
          <TitleInside>
            <Text appearance={TokenTextAppearance.headline300} bold>
              Habla con un experto
            </Text>
            <CloseDiv
              onClick={() => {
                closeLayer();
                setCtaDisabled(false);
              }}
            >
              <Close />
            </CloseDiv>
          </TitleInside>
        </Container>
      </TitleDiv>

      <Container
        wrap={'always'}
        stretchContent
        padding={{
          top: { 0: ContainerPadding.grid002, 960: ContainerPadding.grid001 },
          left: { 0: ContainerPadding.grid002, 960: ContainerPadding.grid001 },
          right: { 0: ContainerPadding.grid002, 960: ContainerPadding.grid001 },
          bottom: { 0: ContainerPadding.grid002, 960: ContainerPadding.grid001 },
        }}
      >
        {!error ? (
          <>
            <div ref={ref}>
              <Text appearance={TokenTextAppearance.copy200}>
                ¿Te gustaría preguntar algo sobre la mecánica de tu coche? ¿Se ha encendido una luz que no identificas?
                Tenemos las respuestas que necesitas.
              </Text>
            </div>
            <RaceForm
              isSent={isSent}
              isSending={isSending}
              isPristine={isPristine}
              disabledButton={disabledButton}
              sendForm={sendForm}
              copyWidth={copyWidth}
              isFullScreen={false}
              setIsPristine={setIsPristine}
              successful={successful}
            />
            <Legal />
          </>
        ) : (
          <ErrorPage close={closeLayer} retry={retry} setCtaDisabled={setCtaDisabled} />
        )}
      </Container>
    </>
  );
};

interface ErrorPageProps {
  retry: () => void;
  close: () => void;
  setCtaDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const LegalWrapper = styled.div`
  color: #6a767d;
`;

const Legal = () => {
  return (
    <LegalWrapper>
      <Text color={TokenTextColor.inherit} appearance={TokenTextAppearance.copy100}>
        Tu número de teléfono será tratado por Volkswagen Group España Distribución, S.A.U. como responsable del
        tratamiento con el fin de contactar contigo. Si quieres más información, puedes consultar nuestra{' '}
        <Text bold>
          <CTA
            tag="a"
            href="https://www.volkswagen.es/es/volkswagen-espana/datos-privacidad.html"
            target="_blank"
            emphasis="tertiary"
          >
            política de privacidad
          </CTA>
        </Text>.
      </Text>
    </LegalWrapper>
  );
};

const ErrorPage = ({ retry, close, setCtaDisabled }: ErrorPageProps) => {
  return (
    <>
      <Container
        wrap={'always'}
        stretchContent
        gutter={ContainerGutter.static200}
        padding={{ bottom: ContainerPadding.static350 }}
      >
        <Text bold appearance={TokenTextAppearance.headline300}>
          Uy, algo ha pasado
        </Text>
        <Text appearance={TokenTextAppearance.copy200}>
          Por alguna razón no hemos podido guardar tu petición. ¿Lo intentamos de nuevo?
        </Text>
      </Container>
      <Container
        wrap={'always'}
        stretchContent
        gutter={ContainerGutter.static300}
      // padding={{ bottom: ContainerPadding.static350 }}
      >
        <CTA
          tag="button"
          emphasis="primary"
          onClick={() => {
            retry();
          }}
        >
          Reintentar
        </CTA>
        <CTA
          tag="button"
          emphasis="secondary"
          onClick={() => {
            close();
            setCtaDisabled(false);
          }}
        >
          Volver a probar más tarde
        </CTA>
      </Container>
    </>
  );
};

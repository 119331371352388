import {
  styled
} from '@volkswagen-onehub/components-core';

//Layer styles

export const MapAndTabsWrapper = styled.div<{ showFilters: boolean }>`
  display: flex;
  .wrapper {
    width: 50%;
    height: 100vh;
    &--tabs {
      background-color: #001e50;
      overflow-y: hidden;
      color: white;
    }
    &--map {
      z-index: 4;
      height: 100%;
      background-color: grey;
    }
  }
  .opacity-layer {
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: ${(props) => (props.showFilters ? 6 : -1)};
    opacity: ${(props) => (props.showFilters ? 1 : 0)};
    transition: opacity 0.4s;
  }
`;

export const FilterButton = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  button {
    background: #ffffff;
  }
`;

export const FiltrosWrapperLayer = styled.div`
  min-height: 100vh;
  width: 50%;
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  transform: translate3d(100%, 0, 0);
  display: none;
  .back-btn {
    position: absolute;
    z-index: 4;
    top: 20px;
    left: 24px;
    @media screen and (min-width: 1600px) {
      top: 24px;
    }
  }
  &.filtros-enter {
    display: block;
    transform: translate3d(100%, 0, 0);
  }
  &.filtros-enter-active {
    display: block;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s;
  }
  &.filtros-enter-done {
    display: block;
    transform: translate3d(0, 0, 0);
  }
  &.filtros-exit {
    display: block;
    transform: translate3d(0, 0, 0);
  }
  &.filtros-exit-active {
    display: block;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s;
  }
  &.filtros-exit-done {
    display: none;
    transform: translate3d(100%, 0, 0);
  }
`;

export const ListWrapper = styled.div<{paddingBottom?: string}>`
  height: calc(100vh - 120px);
  overflow-y: auto;
  @media all and (min-width: 960px) {
    padding-bottom: ${(props)=> props.paddingBottom ? props.paddingBottom : null};
  }
`;

export const SmallerSVG = styled.div`
  svg {
    height: 12px;
    width: 12px;
  }
  button {
    height: 24px;
    min-height: 24px; 
  }
  div{
    font-size: 12px;
  }
`;

//Mobile Layer styled components

export const FilterMobileNavbar = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  padding: 8px 20px;
  z-index: 20;
  background-color: #ffffff;  
`;

export const CTAsNavigationWrapper = styled.div<{services?: boolean}>`
  display:flex;
  justify-content: space-between;
  padding-bottom: ${(props) => props.services ? '16px' : null} ;
`;

interface VerMasYCarouselPositionProps {
  translateCarousel: boolean;
  userIsGeoLocated: boolean;
}

export const VerMasYCarouselPosition = styled.div<VerMasYCarouselPositionProps>`
  position: absolute;
  bottom: 8px;
  height: fit-content;
  width: 100%;
`;
export const SoloCardWrapper = styled.div`
  background-color: #001e50;
  bottom: 8px;
  height: fit-content;
  position: absolute;
  margin: 0 var(--size-grid002);
  width: var(--size-grid020);
  .box-card-wrapper {
    border: none;
    margin-top: 0;
  }
`;

export const CTASwrapper = styled.div`
  padding-right: 20px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const VerMasTalleresCTA = styled.div`
  height: 32px;
  padding-right: 16px;
  padding-left: 16px;
  background-color: #ffffff;
  border: 1px solid #001e50;
  border-radius: 500px;
  color: #001e50;
  display: flex;
  align-items: center;
  width: 100px;
`;

export const ControllerBackground = styled.div`
  border-radius: 100%;
  background-color: #ffffff;
  height: 44px;
  width: 44px;
  margin-bottom: 12px;
  button {
    border: 1px solid #001e50;
  }
`;

interface CloseButtonProps {
  isTabsClose?: boolean;
  isFiltrosClose?: boolean;
}
export const CloseButtonWrapper = styled.div<CloseButtonProps>`
  position: absolute;
  padding-bottom: ${(props) => (props.isTabsClose || props.isFiltrosClose ? '12px' : null)};
  background-color: ${(props) => (props.isTabsClose ? '#001E50' : '#FFFFFF')};
  width: 100%;
  padding-top: 12px;
  padding-left: 12px;
  z-index: 4;
  top: ${(props) => (props.isTabsClose || props.isFiltrosClose ? '0' : null)};
  @media screen and (min-width: 560px) {
    left: 20px;
  }
  button {
    color: ${(props) => (props.isTabsClose ? '#FFFFFF' : null)};
  }
`;

export const MoblieListWrapper = styled.div<{paddingTop?: string}>`
  padding-top: ${(props)=> props.paddingTop ? props.paddingTop : null};
`;

import React, { useState, useEffect } from 'react';
import {
  Container,
  ContainerGutter,
  ContainerPadding,
  InputAppearance,
  Text,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { InputButton, TextAreaExtended, PaddingWrapper } from 'src/components';
import { useOneFormContext } from 'src/feature-app';
import { useFormContext } from 'react-hook-form';
import { FieldType } from 'src/feature-service/es-oneform-core-feature-service/types';

interface DetalleMotivoProps {
  hideCTA?: boolean;
  CTALabel?: string;
  name?: FieldType;
  label?: string;
  limitedCharacters?: boolean;
  numberOfMaxLength?: number;
  showRecomendation?: string | JSX.Element;
  required?: boolean;
}

export function DetalleMotivo(props: DetalleMotivoProps) {
  const { hideCTA, CTALabel, name, label, limitedCharacters, numberOfMaxLength, showRecomendation, required } = props;
  const { register, setValue } = useFormContext();
  const { handleNextStep } = useOneFormContext();
  const [showButton, setShowButton] = useState(true);
  const [currentValue, setCurrentValue] = useState('');

  const adMaxLength = (max: number) => {
    const aux = document.getElementsByTagName('textarea');
    aux[0].setAttribute('maxlength', `${max}`);
  };

  useEffect(() => {
    if (limitedCharacters) adMaxLength(numberOfMaxLength);
  }, []);

  useEffect(() => {
    if (hideCTA) {
      setShowButton(false);
    }
  }, []);

  const handleChange = (e: any) => {
    const aux = e.currentTarget.value;
    e.preventDefault();
    setCurrentValue(aux);
    if (!hideCTA && (aux.length <= 2 || aux === '')) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  return (
    <PaddingWrapper>
      <Container
        gutter={ContainerGutter.static400}
        wrap={'always'}
        horizontalAlign={'flex-start'}
      >
        <div>
          <TextAreaExtended
            label={label ? label : 'Escríbelo aquí'}
            name={name ? name : 'masInformacion'}
            onChange={(e: any) => handleChange(e)}
            appearance={InputAppearance.Default}
            isFloating
            innerRef={register({
              required: {
                value: required ? required : false,
                message: '',
              },
            })}
            maxLength={numberOfMaxLength}
            alternateCTAText={CTALabel ? CTALabel : 'No, eso es todo'}
          />
          {
            showRecomendation ? (
              <Container padding={{top: ContainerPadding.static200}}>
                <Text appearance={TokenTextAppearance.copy100} staticSize>
                  {showRecomendation}
                </Text>
              </Container>
            ) : null
          }
        </div>
        {
          hideCTA ? null :(
            <>
              {showButton ? (
                <InputButton
                  tag='button'
                  name={name ? name : 'masInformacion'}
                  label={CTALabel ? CTALabel : 'No, eso es todo'}
                  key={10}
                  onClick={(e) => {
                    setValue('masInformacion', '');
                    handleNextStep(e);
                  }}
                  innerRef={register({
                    required: {
                      value: required ? required : false,
                      message: '',
                    },
                  })}
                />
              ) : (
                <div style={{ height: '44px' }} />
              )}
            </>
          )
        }  
      </Container>
    </PaddingWrapper>
  );
}

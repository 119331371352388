import React, { useState, useEffect, useRef } from 'react';
import { styled, Text, TextInput, Layout, Breakpoints, Container, ContainerGutter, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import { useSelector } from 'react-redux';
import { OneFormState } from 'src/types';
import { useDispatch } from 'react-redux';
import { useFeatureServices, useOneFormContext } from 'src/feature-app';
import { Info } from '@volkswagen-onehub/icons-core';
import { CloseHandleV2, FocusLayerSizeV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';
import { Close } from '@volkswagen-onehub/icons-core';


type WrapperProps = {
  disabled?: boolean;
  themeColor?: string;
};

const SVGSize = styled.div`
  position: relative;
  top: 41px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1000;

  
  @media all and (min-width: 560px) {
    width: 303px;
  }
  @media all and (min-width: 960px) {
    width: 320px;
  }
  div{
    cursor:pointer;
  }
  svg {
    height: 20px;
    width: auto;
  }
`;



const SelectWrapper = styled.div<WrapperProps>`
  & span::before {
   display: none;
  }
  svg {
    fill: ${(props) => (props.themeColor === 'main' ? '#001E50' : '#ffffff')};
  }

  label {
    color: ${(props) => (props.themeColor === 'main' ? '#001E50' : '#ffffff')};

    option {
      color: #001e50;
      padding-left: 12px;
    }
  }
  input{
    cursor: default;
  }
`;
interface SelectProps {
  name: string;
  value?: any;
  defaultValue?: string;
  isFloating?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  getDealers?: (provincia: string) => void;
  setFormValue?: (selValueIndex: number) => void;
  onClick?: (e?: any) => void;
  changeModel?: any;
  setOption?: any;
  updateFields?: boolean;
  isRequired: boolean;
  innerRef?: any;
}

export const IDBuzzFakeInput = React.forwardRef((props: SelectProps, ref: React.Ref<any>) => {
  const [selItem, setSelItem] = useState<string | null>(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const { handlePreviousStep } = useOneFormContext();
  const [keyValuePair, setKeyValuePair] = useState<string>();
  const { name, updateFields, value, changeModel, setOption } = props;
  const wrapperRef = useRef<HTMLHeadingElement>(null);
  const dispatch = useDispatch();
  const { formInfo } = useSelector((state: OneFormState) => state);
  const handleClickOutside = (e: any) => {
    if (!wrapperRef.current.contains(e.target)) {
      setShowOptions(false);
    }
  };

  const Content = styled.div`
  background-color: white;
  padding: 44px 31px;
  flex: auto;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 560px) {
    padding: 52px 46px;
  }
  @media screen and (min-width: 960px) {
    padding: var(--size-grid001);
  }
  p{
    background-color: none;
  }
`;

  const CloseDiv = styled.div`
  position: absolute;
  top: var(--size-dynamic0050);
  right: var(--size-dynamic0050);
  cursor: pointer;
`;

  const CTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  p{
    text-decoration: underline #001E50;
    text-underline-offset: 8px;
    padding-bottom: 8px;
    color: #001E50;
    cursor: pointer;
    :hover{
      background-color: rgb(194, 202, 207);
    }
  }
`;

  const renderIDBuzzInfo = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <Content>
        <CloseDiv
          onClick={() => close({}, {}, {})}
        >
          <Close />
        </CloseDiv>
        <Layout
          appearance={{
            [Breakpoints.default]: [
              { name: '.', columns: 2 },
              { name: 'a', columns: 20 },
              { name: '.', columns: 2 },
            ],
          }}
        >
          <Container gutter={ContainerGutter.static150} wrap={'always'}>
            <Text appearance={TokenTextAppearance.headline300}>
              Este taller ofrece servicios en
              exclusiva para el <Text bold>ID. Buzz</Text>
            </Text>
            <CTAWrapper onClick={(e) => {
              e.preventDefault();
              handlePreviousStep(e);
              close({}, {}, {});
            }}>
              <p>Haz clic aquí para cambiar de instalación.</p>
            </CTAWrapper>
          </Container>
        </Layout>
      </Content>
    );
  };

  const handleIdBuzzInfo = () => {
    layerManager.openFocusLayer(renderIDBuzzInfo, {}, { size: FocusLayerSizeV2.A, userCloseable: true })
  }

  useEffect(()=>{
    setSelItem('ID. Buzz')
    changeModel('ID. Buzz')
    setOption('ID. Buzz')
  },[])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    // función que se llama cuando la componente se desmonta
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef.current]);

  useEffect(() => {
    updateFields
      ? dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: keyValuePair ? keyValuePair : value } })
      : undefined;
  }, [keyValuePair, value]);

  useEffect(() => {
    if (value) {
      setTimeout(() => {
        setSelItem(value);
      }, 0);
    }
  }, [value]);

  return (
    <SelectWrapper
      disabled={props.disabled}
      ref={wrapperRef}
      themeColor={formInfo.formTheme}
    >
      <SVGSize>
        <div onClick={() => {
          handleIdBuzzInfo()
        }}>
          <Info />
        </div>
      </SVGSize>
      <TextInput label="Modelo" value="ID. Buzz" />
    </SelectWrapper>
  );
});

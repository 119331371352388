import { DomainAttributesValues } from 'src';
import { reportErrorSentry } from 'src/bff';
import { DpsData } from 'src/feature-service/es-oneform-core-feature-service/types';

export const handleDescWrongData = (datosIncorrectos: 'Marca' | 'Instalación' | 'Contacto' | 'Modelo') => {
  if (!datosIncorrectos) return '';

  switch (datosIncorrectos) {
    case 'Marca':
      return '1';
    case 'Modelo':
      return '2';
    case 'Instalación':
      return '3';
    case 'Contacto':
      return '4';
    default:
      return '';
  }
};

export const sanitizeKvps = (kvps: string)  => {
  return kvps.replace('ESP', '').replace('V', '').replace('N', '');
}

export const formatSinRespuestaValue = (answer: string) => {
  if (!answer) return undefined;
  if (answer == 'Sin respuesta' || Number(answer) == -1) return '9';
  return answer;
};

interface Data {
  [key: string]: string;
}

class CEMDataError extends Error {
  constructor(message) {
    super(message);
    this.name = 'CEMDataError';
  }
}

export const checkUndefinedsInData = (data: Data, form: 'Venta' | 'Posventa') => {
  Object.entries(data).map((entry) => {
    const [field, data] = entry;
    if (data?.includes('undefined')) {
      const error = new CEMDataError(`Undefined en el formulario CEM ${form}. ${field} tiene el valor ${data}`);
      reportErrorSentry(error);
    }
  });
};

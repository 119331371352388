import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { dpsSend } from 'src/bff';
import {
  Text,
  CTA,
  Container,
  ContainerPadding,
  ContainerGutter,
  InputAppearance,
  Layout,
  Breakpoints,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { OneElementTemplateFullScreen } from 'src/feature-app/Screen';
import { FidelizacionLastScreenFields } from './FidelizacionLastScreenFields';
import { TextAreaExtended, MultipleOptionCTAs, RatingStars } from 'src/components';
import { getParamsForm } from 'src/feature-app/get-params-form';
import { FormDataGroup, FormInfo, OneFormState, Steps } from 'src/types';
import {
  FormController,
  useOneFormContext
} from 'src/feature-app';
import { useFormContext } from 'react-hook-form';
import { ThankYouFidelizacion } from '.';
import { formDataBuilder } from '../../format-fields';
import { FidelizacionFormProps } from './FidelizacionController';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { useInitializeFidelizacionForm } from './use-initialize-fidelizacion-form';

const CTAsObtenerNuevas = [
  { label: 'Participando en encuestas, en experiencias, en sorteos, en eventos...', value: 'lealtad' },
  { label: 'Con Volkswagen Long Drive, utilizando el servicio de recogida y entrega, pidiendo cita online...', value: 'relacion' },
  { label: 'Otras formas', value: 'otros' },
];

const CTAsListadoVentajas = [
  { label: 'Mantenimiento incluido', value: 'mantenimiento' },
  { label: 'Garantías Volkswagen', value: 'garantias' },
  { label: 'Asistencia en carretera', value: 'asistencia' },
  { label: 'Coche de sustitución', value: 'coche-sustitucion' },
  { label: 'Revisión Pre-ITV gratuita', value: 'pre-itv' },
  { label: 'Servicio de recogida y entrega', value: 'recogida-entrega' },
  { label: 'Volkswagen Long Drive¹', value: 'long-drive' },
  { label: 'Experiencias de conducción²', value: 'experiencias' },
  { label: 'We: Coche conectado³', value: 'we' },
];

export enum FidelizacionSteps {
  QuieroTener,
  MasConoces,
  ListadoVentajas,
  Valoracion,
  ServicioPosventa,
  ProcesoCompra,
  OpcionesFinanciacion,
  VentajasBeneficions,
  InformacionExclusiva,
  InformacionTecnologia,
  OtrosServicios,
  Confirmacion,
}

export function FidelizacionForm(props: FidelizacionFormProps) {
  const { dpsData, idPerson, trackFormLoad } = props;
  const { formData, formInfo } = useSelector((state: OneFormState) => state);
  const { obtenerNuevas } = formData.fields;
  const { screenIndex } = formInfo;
  const { initializeForm, nextStep, handleNextStep } = useOneFormContext();
  const trackingManager = useTrackingManager();
  const store = useStore();
  const { register } = useFormContext();
  const [currentValue, setCurrentValue] = useState('');
  const dispatch = useDispatch();

  const handleChange = (e: any) => {
    const aux = e.currentTarget.value;
    e.preventDefault();
    setCurrentValue(aux);
  };

  const stepsForm: Steps[] = [
    {
      title: (
        <>
          ¿Qué beneficios y ventajas <Text bold>te gustaría tener</Text> como cliente Volkswagen?
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen element={
          <Container
            gutter={ContainerGutter.static400}
            padding={{ top: ContainerPadding.static500 }}
            wrap={'always'}
            horizontalAlign={'flex-start'}
          >
            <TextAreaExtended
              label="Escríbelo aquí"
              name="quieroTener"
              onChange={(e: any) => handleChange(e)}
              appearance={InputAppearance.Default}
              isFloating={false}
              innerRef={register({
                required: {
                  value: true,
                  message: 'Requerido',
                },
              })}
              placeholder="Ejemplo: Hace 7 años que soy clienta de Volkswagen, y me encantaría que mi fidelidad se viera recompensada con descuentos, ofertas exclusivas e incluso experiencias con otros miembros de la comunidad."
              rows={3}
              maxLength={1500}
            />
          </Container>
        }
        />
      ),
      screenIndex: FidelizacionSteps.QuieroTener,
      name: 'QuieroTener',
      outputs: ['quieroTener'],
      outputsText: ['quieroTener'],
    },
    {
      title: (
        <>
          ¿Cuáles son los beneficios y ventajas de Volkswagen <Text bold>que más conoces/utilizas</Text>?
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen element={
          <Container
            gutter={ContainerGutter.static400}
            padding={{ top: ContainerPadding.static500 }}
            wrap={'always'}
            horizontalAlign={'flex-start'}
          >
            <TextAreaExtended
              label="Escríbelo aquí"
              name="masConoces"
              onChange={(e: any) => handleChange(e)}
              appearance={InputAppearance.Default}
              isFloating={false}
              innerRef={register({
                required: {
                  value: true,
                  message: 'Requerido',
                },
              })}
              placeholder="Ejemplo: Me parece muy útil el servicio de recogida y entrega de Volkswagen. A veces hacer un hueco para ir al taller es misión imposible, ¡así que toda acción que te facilite la vida es bienvenida!"
              rows={3}
              maxLength={350}
            />
          </Container>
        }
        />
      ),
      screenIndex: FidelizacionSteps.MasConoces,
      name: 'MasConoces',
      outputs: ['masConoces'],
      outputsText: ['masConoces'],
    },
    {
      title: (
        <>
          Hemos listado algunas de nuestras ventajas como cliente de la familia Volkswagen. ¿Cuáles te parecen más atractivas?
          <Container padding={{ top: ContainerPadding.dynamic0020 }}>
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Selecciona 3 opciones.
            </Text>
          </Container>
        </>
      ),
      alternateTitle: (
        <>
          Hemos listado algunas de nuestras ventajas como cliente de la familia Volkswagen. ¿Cuáles te parecen más atractivas?
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen element={
          <Container
            wrap={'always'}
            padding={{ top: ContainerPadding.dynamic0050 }}
            gutter={ContainerGutter.static400}
          >
            <MultipleOptionCTAs ignoreHandleClick={true} ctaValues={CTAsListadoVentajas} minSelected={3} maxSelected={3} name="listadoVentajas" />
            <Text appearance={TokenTextAppearance.copy100} staticSize>
              <ol>
                <li>Planes de mantenimiento Volkswagen Long Drive (Standard y Advance)</li>
                <li>Race Tours y otras experiencias de conducción para clientes</li>
                <li>Servicios de conectividad como We Connect, We Connect GO...</li>
              </ol>
            </Text>
          </Container>}
        />
      ),
      screenIndex: FidelizacionSteps.ListadoVentajas,
      name: 'ListadoVentajas',
      outputs: ['listadoVentajas'],
      outputsText: ['listadoVentajas'],
    },
    {
      title: (
        <>
          ¿Qué <Text bold>importancia</Text> le darías a los siguientes conceptos?
        </>
      ),
      fields: (
        <Layout
          allowOverflowingContent
          appearance={{
            [Breakpoints.default]: [
              { name: '.', columns: 2 },
              { name: 'a', columns: 20 },
              { name: '.', columns: 2 },
            ],
            [Breakpoints.b960]: [
              { name: '.', columns: 3 },
              { name: 'a', columns: 18 },
              { name: '.', columns: 3 },
            ],
            [Breakpoints.b1280]: [
              { name: '.', columns: 5 },
              { name: 'a', columns: 14 },
              { name: '.', columns: 5 },
            ],
            [Breakpoints.b1920]: [
              { name: '.', columns: 6 },
              { name: 'a', columns: 12 },
              { name: '.', columns: 6 },
            ],
            [Breakpoints.b2560]: [
              { name: '.', columns: 7 },
              { name: 'a', columns: 10 },
              { name: '.', columns: 7 },
            ],
          }}
        >
          <Container
            wrap={'always'}
            padding={{ top: ContainerPadding.dynamic0050 }}
            gutter={ContainerGutter.static400}
          >
            <Text appearance={TokenTextAppearance.copy200} staticSize>
              Como <Text bold> cliente de Volkswagen</Text>, siendo 1 "mayor importancia" y 5 "menor importancia"
            </Text>
            <CTA
              tag="button"
              emphasis="primary"
              onClick={(e) => {
                trackingManager.trackFormStepButtonClick({
                  contentId: 'Siguiente',
                });
                e.preventDefault();
                nextStep();
              }}
              ariaLabel="aria label"
            >
              Empezar
            </CTA>
          </Container>
        </Layout>
      ),
      screenIndex: FidelizacionSteps.Valoracion,
      name: 'Valoracion',
    },
    {
      title: (
        <>
          <Text bold>Servicio de Posventa</Text>: trato, precio y planes de mantenimiento.
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<RatingStars name="servicioPosventa" nextOnClick={true} textGood='mayor importancia' textBad='menor importancia' />}
        />
      ),
      screenIndex: FidelizacionSteps.ServicioPosventa,
      name: 'ServicioPosventa',
      outputs: ['servicioPosventa'],
      outputsText: ['servicioPosventa'],
      outputsIsStars: true
    },
    {
      title: (
        <>
          <Text bold>Proceso de compra o renovación</Text>: personalización por ser cliente, ofrecimiento de prueba test drive, asesoramiento comercial...
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<RatingStars name="procesoCompra" nextOnClick={true} textGood='mayor importancia' textBad='menor importancia' />}
        />
      ),
      screenIndex: FidelizacionSteps.ProcesoCompra,
      name: 'ProcesoCompra',
      outputs: ['procesoCompra'],
      outputsText: ['procesoCompra'],
      outputsIsStars: true
    },
    {
      title: (
        <>
          <Text bold>Opciones de financiación en la venta</Text>: nuevas fórmulas, condiciones ventajosas...
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<RatingStars name="opcionesFinanciacion" nextOnClick={true} textGood='mayor importancia' textBad='menor importancia' />}
        />
      ),
      screenIndex: FidelizacionSteps.OpcionesFinanciacion,
      name: 'OpcionesFinanciacion',
      outputs: ['opcionesFinanciacion'],
      outputsText: ['opcionesFinanciacion'],
      outputsIsStars: true
    },
    {
      title: (
        <>
          <Text bold>Ventajas y Beneficios</Text> como descuentos, premios, entradas a eventos, posibilidad de participar en foros de la marca...
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<RatingStars name="ventajasBeneficions" nextOnClick={true} textGood='mayor importancia' textBad='menor importancia' />}
        />
      ),
      screenIndex: FidelizacionSteps.VentajasBeneficions,
      name: 'VentajasBeneficions',
      outputs: ['ventajasBeneficions'],
      outputsText: ['ventajasBeneficions'],
      outputsIsStars: true
    },
    {
      title: (
        <>
          <Text bold>Información exclusiva sobre novedades de la marca y del sector</Text>: nuevos lanzamientos, productos o funcionalidades, recomendaciones, noticias...
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<RatingStars name="informacionExclusiva" nextOnClick={true} textGood='mayor importancia' textBad='menor importancia' />}
        />
      ),
      screenIndex: FidelizacionSteps.InformacionExclusiva,
      name: 'InformacionExclusiva',
      outputs: ['informacionExclusiva'],
      outputsText: ['informacionExclusiva'],
      outputsIsStars: true
    },
    {
      title: (
        <>
          <Text bold>Innovación y Tecnología</Text>: coche conectado, coche autónomo, sostenibilidad...
        </>
      ),
      fields: (
        <OneElementTemplateFullScreen
          element={<RatingStars name="informacionTecnologia" nextOnClick={true} textGood='mayor importancia' textBad='menor importancia' />}
        />
      ),
      screenIndex: FidelizacionSteps.InformacionTecnologia,
      name: 'InformacionTecnologia',
      outputs: ['informacionTecnologia'],
      outputsText: ['informacionTecnologia'],
      outputsIsStars: true
    },
    {
      title: (
        <>
          <Text bold>Otros Servicios</Text>: servicio de atención al cliente, garantía de movilidad Volkswagen, asistencia...
        </>
      ),
      fields: <FidelizacionLastScreenFields />,
      screenIndex: FidelizacionSteps.OtrosServicios,
      name: 'OtrosServicios',
      outputs: ['otrosServicios'],
      outputsText: ['otrosServicios'],
      isLastStep: true
    },
  ];

  const FidelizacionFormData: Partial<FormDataGroup> = {
    headers: {
      DESC_BRAND: 'VW',
      DESC_VN_VO: 'VN',
      DESC_GDPR_ADAPTED: 'true',
    },
    fields: { formName: 'adhoc-2021-fidelizacion_clientes', formType: 'otherform', pageCategory: 'Fidelización Clientes 2021' }, // Analítica
  };

  const getFormInfo = (): {
    firstStep: Steps;
    lastStep: Steps;
    stepsHistory: number[];
    numberOfScreens: number;
    newSteps?: Steps[];
  } => {
    return {
      firstStep: steps[0],
      lastStep: steps[steps.length - 1],
      stepsHistory: [0],
      numberOfScreens: steps.length,
      newSteps: null,
    };
  };

  const sendForm = (): Promise<boolean> => {
    const { formData }: OneFormState = store.getState();
    const { fields } = formData;

    return new Promise<any>(async (resolve, reject) => {
      const { tc, today, referalUrl, url, device } = getParamsForm();

      const data = {
        'headers[COD_FORM_ID]': `vw-oneform-${fields.formName}`,
        'headers[PINCODE]': dpsData.pincode.pincodeResponse.params.pincode,
        'headers[DESC_BRAND]': 'VW',
        'headers[DESC_CAMPAIGNBLOCK]': '16. VW One Shots',
        'headers[DESC_DEVICETYPE]': device,
        'headers[DESC_FORMOBJECTIVE]': 'Encuesta',
        'headers[DESC_FORM_DETAIL]': fields.pageCategory,
        'headers[DESC_ID_CAMPAIGN]': '7011n000000JqqSAAS',
        'headers[DESC_ID_CLIMAPRO]': idPerson.ID_Person_DIM_IMP__c,
        'headers[DESC_URLORIGIN]': url,
        'headers[FECHA_INSERTION_DATE]': today,
        'headers[TC]': tc,
        'headers[DESC_TOKEN]': 'VW_DDB_FRM_ENCUESTA_FIDELIZACION_CLIENTES',

        'fields[DESC_FORM_VERSION]': `vw-oneform-${fields.formName}-${process.env.VERSION}`,
        'fields[M_OBSERVATION]': fields.quieroTener,
        'fields[DESC_AUXFIELD1]': fields.masConoces,
        'fields[DESC_AUXFIELD2]': fields.listadoVentajas,
        'fields[DESC_AUXFIELD5]': fields.servicioPosventa,
        'fields[DESC_AUXFIELD6]': fields.procesoCompra,
        'fields[DESC_AUXFIELD7]': fields.opcionesFinanciacion,
        'fields[DESC_AUXFIELD8]': fields.ventajasBeneficions,
        'fields[DESC_AUXFIELD9]': fields.informacionExclusiva,
        'fields[DESC_AUXFIELD10]': fields.informacionTecnologia,
        'fields[DESC_AUXFIELD11]': fields.otrosServicios,
        'fields[DESC_NAME]': idPerson.FirstName,
        'fields[DESC_SURNAME_1]': idPerson.LastName,
        'fields[DESC_SURNAME_2]': idPerson.TXT_2_Surname__c,
        'fields[DESC_EMAIL_ADDRESS]': idPerson.TXT_Email__c,
        'fields[NUM_CELLPHONE]': idPerson.NUM_Phone_mobile__c,
        'fields[DESC_LOPD]': 'PERMITE'
      };
      const dataStringified = JSON.stringify(data);
      const dataParsed = JSON.parse(dataStringified);

      const formData = formDataBuilder(dataParsed);

      const response = await dpsSend(formData);

      if (response && response.data && response.data.content && response.data.content.code === '200') {
        dispatch({ type: 'SET_FORM_RESPONSE', payload: response.data });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const { loading, steps, firstStep, lastStep } = useInitializeFidelizacionForm(stepsForm, null);

  useEffect(() => {
    const { firstStep, lastStep, stepsHistory, numberOfScreens, newSteps } = getFormInfo();

    const FidelizacionFormInfo: Partial<FormInfo> = {
      formStarted: true,
      sendFormCallback: sendForm,
      fullScreen: firstStep.fullScreen ? true : false,
      lastStep,
      numberOfScreens: steps.length,
      screenIndex: firstStep.screenIndex,
      formTheme: 'main',
      notALayer: true,
      step: firstStep,
      steps: newSteps ? newSteps : steps,
      stepsHistory: [0],
      thankYouComponent: <ThankYouFidelizacion />,
    };
    if (!loading) {
      initializeForm(FidelizacionFormInfo, FidelizacionFormData, true);
      trackFormLoad();
    }
  }, [loading]);


  return loading || !steps ? null : <FormController steps={steps} screenType="full-screen" />;
}

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useTrackingManager, getTrackingForm } from 'src/feature-app';
import { RenderController } from 'src/feature-app/Screen';
import { OneFormState, Steps } from 'src/types';
import { ThankYouTitle, ThankYouFields } from 'src/components';
import { IdVehicle } from 'src/types';

interface ThankYouCEMSegundaEntregaProps {
	idVehicle?: IdVehicle;
}

export const ThankYouCEMSegundaEntrega = (props: ThankYouCEMSegundaEntregaProps) => {
	const { idVehicle } = props;
	const { formInfo, formData } = useSelector((state: OneFormState) => state);
	const { formResponse } = formInfo;
	const { fields } = formData;

	const trackingManager = useTrackingManager();
	const dispatch = useDispatch();

	useEffect(() => {
		// Thank you page no es gestionado como un step pero sí lo haremos en la analítica. Por eso Modificamos formInfo después del submit y antes del RESET
		const screenIndexThankyou = formInfo.numberOfScreens;

		dispatch({ type: 'SET_FORM_SUBMITTED', payload: true });
		dispatch({ type: 'SET_FORM_INFO', payload: { step: { screenIndex: screenIndexThankyou, name: 'ThankYou' } } });

		trackingManager.trackFormSubmissionSuccessMessageLoad(
			{
				contentId: undefined,
			},
			getTrackingForm(formResponse)
		);
	}, []);

	const fechaCita = dayjs(fields.fechaCita, 'YYYY-MM-DD').format('DD/MM/YYYY');
	const copyTitle = '¡Te esperamos el ' + fechaCita + ' a las ' + fields.horaCita + '!';
	const copyBook = <>
		Muy pronto te contactará tu experto Volkswagen para cerrar vuestra cita{idVehicle ? ' y darte hasta el último detalle sobre tu ' + idVehicle.DESC_Model__c : ''}.
	</>;

	const screen: Steps = {
		title: <ThankYouTitle notALayer boldCopyFirst={copyTitle} />,
		fields: <ThankYouFields notALayer copyBook={copyBook} />,
	};
	return (
		<RenderController
			screenType="full-screen"
			title={screen.title}
			fields={screen.fields}
			key={555}
			avoidTrackFormStepLoad
			thankYouPage={true}
		/>
	);
};

import React, { useState, useEffect, useRef } from 'react';
import { styled, Text, TokenTextAppearance } from '@volkswagen-onehub/components-core';
import { ChevronDown, ChevronUp, Checkmark } from 'src/icons-core-imports';

import { useDispatch } from 'react-redux';

type WrapperProps = {
  disabled?: boolean;
};
type DropwDownProps = {
  showOptions: boolean;
  itemWidth: number;
  disabled?: boolean;
  error?: boolean;
  selectY?: number;
  atTheBottom?: boolean;
};
type LabelProps = {
  disabled: boolean;
  isActive: boolean;
};

const SelectWrapper = styled.div<WrapperProps>`
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  text-align: left;
`;
const DropDown = styled.div<DropwDownProps>`
  width: ${(props) => (props.itemWidth ? `${props.itemWidth}px` : '100%')};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid ${(props) => (props.error ? '#E4002C' : '#DFE4E8')};
  padding: 8px 0 5px;
  color: ${(props) => (props.disabled ? '#DFE4E8' : '#FFFFFF')};
  .sel-item {
    margin: 0;
    div {
      margin: 0;
    }
    @media screen and (min-width: 560px) {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 85%;
      * {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 85%;
      }
    }
  }
`;
const OptionsList = styled.ul<DropwDownProps>`
  display: ${(props) => (props.showOptions ? 'block' : 'none')};
  width: ${(props) => (props.itemWidth ? `${props.itemWidth}px` : 'auto')};
  background-color: white;
  z-index: 100;
  height: auto;
  /* max-height: ${(props) => (props.selectY ? `calc(100vh - ${props.selectY}px)` : '256px')}; */
  max-height: 256px;
  overflow-y: auto;
  position: ${(props) => (props.atTheBottom ? null : 'absolute')};
  padding-left: 0;
  margin-top: 0;
  li:last-child {
    padding-bottom: ${(props) => (props.atTheBottom ? '0' : '31px')};
  }
`;
const Option = styled.li`
  position: relative;
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #dfe4e8;
  padding-left: 40px;
  color: #001e50;
  svg {
    position: absolute;
    left: 6px;
    top: 10px;
  }
  &:hover {
    transition: 0.3s;
    background-color: #f6f6f6;
  }
  &.selected {
    border-bottom: 1px solid #001e50 !important;
  }
`;
const Label = styled.label<LabelProps>`
  position: absolute;
  width: 100%;
  transform: ${(props) => (props.isActive ? 'translate(0, -20px)' : 'translate(0, 4px)')};
  @media all and (min-width: 1600px) {
    transform: ${(props) => (props.isActive ? 'translate(0, -20px)' : 'translate(0, -1px)')};
  }
  letter-spacing: ${(props) => (props.isActive ? '0.00938em' : 'inherit')};
  transform-origin: top left;
  transition: all 150ms ease-in;
  color: ${(props) => (props.disabled ? '#DFE4E8' : '#001E50')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  > div {
    margin: 0;
  }
`;

interface SelectProps {
  options: Array<any> | Array<JSX.Element>;
  name: string;
  label: string;
  value?: any;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  getDealers?: (provincia: string) => void;
  setFormValue?: (selValueIndex: number) => void;
  onClick?: (e?: any) => void;
  setOption?: React.Dispatch<React.SetStateAction<any>>;
  updateFields?: boolean;
  atTheBottom: boolean;
}

export const CustomSelect = React.forwardRef((props: SelectProps, ref: React.Ref<any>) => {
  const [selItem, setSelItem] = useState<string | null>(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectWidth, setSelectWitdh] = useState<number>(null);
  const [selectY, setSelectY] = useState<number>(null);
  const [keyValuePair, setKeyValuePair] = useState();
  const { name, options, setOption, updateFields, value, atTheBottom } = props;
  const wrapperRef = useRef<HTMLHeadingElement>(null);
  const dispatch = useDispatch();

  const optionsToggle = () => {
    if (!props.disabled) {
      setShowOptions(!showOptions);
    }
  };

  const handleClickOutside = (e: any) => {
    if (!wrapperRef.current.contains(e.target)) {
      setShowOptions(false);
    }
  };

  const setItem = (option: any, index: number) => {
    if (!props.disabled) {
      if (typeof option !== 'object') {
        setSelItem(option);
        if (setOption) setOption(option);
        // if (props.name === 'provincia') props.getDealers(option);
      } else {
        const dealerName = option.props.children[0].props.children;
        setSelItem(dealerName);
      }
      if (props.setFormValue) props.setFormValue(index);
      setShowOptions(false);
    }
  };

  useEffect(() => {
    setSelItem(null);
  }, [props.options]);

  useEffect(() => {
    const width = wrapperRef.current ? wrapperRef.current.offsetWidth : 0;
    const height = wrapperRef.current ? wrapperRef.current.offsetTop : 0;
    setSelectWitdh(width);
    setSelectY(height);
    document.addEventListener('mousedown', handleClickOutside);
    // función que se llama cuando la componente se desmonta
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef.current]);

  useEffect(() => {
    updateFields
      ? dispatch({ type: 'UPDATE_FIELDS', payload: { [name]: keyValuePair ? keyValuePair : value } })
      : undefined;
  }, [keyValuePair, value]);

  useEffect(() => {
    if (value) {
      setTimeout(() => {
        setSelItem(value);
      }, 0);
    }
  }, [value]);

  //Adaptación max-height ul según viewrport

  return (
    <SelectWrapper disabled={props.disabled} ref={wrapperRef}>
      <Label
        onClick={() => optionsToggle()}
        isActive={selItem !== null ? true : false}
        disabled={props.disabled}
        className="select-label"
      >
        <Text appearance={selItem !== null ? TokenTextAppearance.copy100 : TokenTextAppearance.copy200}>
          {props.label}
          {/* {props.required ? <span>*</span> : null} */}
        </Text>
      </Label>
      <DropDown
        onClick={() => {
          optionsToggle();
          props.onClick();
        }}
        defaultValue={value}
        showOptions={showOptions}
        itemWidth={selectWidth}
        disabled={props.disabled}
        error={props.error}
        className="drop-down"
      >
        <p className="sel-item">
          <Text appearance={TokenTextAppearance.copy200}>{selItem}</Text>
        </p>
        {showOptions ? <ChevronUp /> : <ChevronDown />}
      </DropDown>
      <OptionsList
        showOptions={showOptions}
        itemWidth={selectWidth}
        selectY={selectY}
        atTheBottom={atTheBottom}
        className="ul-select-list"
      >
        {options.map((option: any, index: number) => {
          if (typeof option === 'string') {
            return (
              <Option
                onClick={() => {
                  setItem(option, index);
                }}
                key={index}
                className={selItem == option ? 'selected' : 'option'}
              >
                {selItem == option ? <Checkmark /> : null}
                <p>{option}</p>
              </Option>
            );
          } else {
            return (
              <Option
                onClick={() => {
                  setItem(option.name, index);
                  setKeyValuePair(option.code);
                }}
                key={index}
                className={selItem == option.name ? 'selected' : 'option'}
              >
                {selItem == option.name ? <Checkmark /> : null}
                <p>{option.name}</p>
              </Option>
            );
          }
        })}
      </OptionsList>
      <input type="hidden" value={props.value} name={props.name} ref={ref} required />
    </SelectWrapper>
  );
});

import { styled } from '@volkswagen-onehub/components-core';
import React from 'react';
import { Apellido, Nombre, PaddingWrapper, LabelInputWidthWrapper } from '.';
import { Trato } from './Trato';
import { useIsComerciales } from 'src/feature-app/hooks';

interface pantallaNombreApellidoProps {
  notALayer?: boolean;
  isComerciales?: boolean;
}

const ContentWrapper = styled.div`
  gap: 44px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: 960px) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 0;
  }
`;

export const PantallaNombreApellido = ({ notALayer }: pantallaNombreApellidoProps) => {
  const isComerciales = useIsComerciales();

  return (
    <PaddingWrapper>
      <ContentWrapper className="nombre-apellido-wrapper" >
        {isComerciales ? <Trato /> : null}
        <LabelInputWidthWrapper notALayer={notALayer}>
          <Nombre />
        </LabelInputWidthWrapper>
        <LabelInputWidthWrapper notALayer={notALayer}>
          <Apellido name="surname" required={true} label="Primer apellido" />
        </LabelInputWidthWrapper>
        <LabelInputWidthWrapper notALayer={notALayer}>
          <Apellido name="secondSurname" label="Segundo apellido" required={false} />
        </LabelInputWidthWrapper>
      </ContentWrapper>
    </PaddingWrapper>
  );
};

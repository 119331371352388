import { isMobileOnly, isTablet } from 'react-device-detect';

export const getParamsForm = () => {
  let tc: string;
  if (typeof s !== 'undefined' && typeof s.persCmp !== 'undefined') {
    tc = s.persCmp;
  } else {
    tc = 'undefined';
  }

  const date = new Date();
  const device = isMobileOnly ? 'mobile' : isTablet ? 'tablet' : 'desktop';
  const location = window.location.href
  const referalUrl = document.referrer.substring(0, 30);
  const today = date.toISOString().replace("T"," ").split(".")[0];
  //const today = date.toISOString().slice(0, 10) + ' ' + date.toLocaleString('es-ES').slice(-8);
  const url = window.location.origin + window.location.pathname;

  return {
    device,
    location,
    referalUrl,
    tc,
    today,
    url,
  };
};

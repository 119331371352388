import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import {
  Breakpoints,
  Container,
  ContainerGutter,
  ContainerPadding,
  CTA,
  Image,
  Layout,
  styled,
  Text,
  TextAlignment,
  ThemeProvider,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import React, { useState } from 'react';
import { useFaContent } from 'src/feature-app';
import { TriggerCitaProps } from 'src/triggers';
import { NuevaCitaPosventaFormController } from '../forms/CitaPosventa/NuevaCitaPosventa';
import { useDispatch } from 'react-redux';


const MediaWrapper = styled.div<{ scrollY: number }>`
  transform: ${(props) => `translateY(${props.scrollY / 2}px)`};
  grid-area: 1 / 1 / 2 / 25;
  overflow: hidden;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 70vh;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 66% 50%;
  }
`;

const CTAWrapper = styled.div`
  display: inline-block;

  & a {
    color: #ffffff;
    font-weight: bold;
  }
  & svg {
    fill: #ffffff;
    width: 17px;
    height: 14px;
  }
`;

const ContentStage = styled.div<{ themeColor: string }>`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${(props) =>
    props.themeColor === 'inverted' ? 'radial-gradient(circle at bottom, #00437a 0%, #001e50 100%)' : '#FFFFFF'};

  & > div {
    width: 100%;
  }
`;

const ContainerNew = styled.div`
  display: grid;
  grid-template-rows: 70vh;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(24, 1fr);
`;

export const CitaPosventaStageTrigger = ({
  setSelectedJourney,
  setCTALabel,
  theme,
  getTitle,
  getBodyCopy,
  getCtaLabel,
  getIntro,
}: TriggerCitaProps) => {
  const [scrollY, setScrollY] = useState(-window.scrollY);
  const faContent = useFaContent();
  const dispatch = useDispatch();

  useScrollPosition(({ prevPos, currPos }) => {
    setScrollY(currPos.y);
  }, []);

  return (
    <ContainerNew>
      <MediaWrapper scrollY={scrollY}>
        <Image src={faContent?.stageCitaImgSrc} alt="Volkswagen" />
      </MediaWrapper>
      <div style={{ gridArea: '2 / 1 / 3 / 25' }}>
        <ThemeProvider theme={theme}>
          <Layout
            appearance={{
              [Breakpoints.default]: [{ name: 'a', columns: 24 }],
              [Breakpoints.b560]: [
                { name: '.', columns: 4 },
                { name: 'a', columns: 16 },
                { name: '.', columns: 4 },
              ],
              [Breakpoints.b960]: [
                { name: 'a', columns: 12 },
                { name: '.', columns: 12 },
              ],
            }}
          >
            <ContentStage themeColor={theme}>
              <Container
                wrap={'always'}
                padding={{
                  bottom: ContainerPadding.dynamic0250,
                  top: ContainerPadding.dynamic0100,
                  left: ContainerPadding.grid002,
                  right: ContainerPadding.grid002,
                }}
              >
                <Container
                  horizontalAlign={'flex-start'}
                  wrap={'always'}
                  gutter={ContainerGutter.dynamic0100}
                >
                  <Text textAlign={TextAlignment.left} bold appearance={TokenTextAppearance.headline300}>
                    {getIntro()}
                  </Text>
                  <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.headline400}>
                    {/* <Text bold>Como en Volkswagen,</Text> en ningún sitio */}
                    <div dangerouslySetInnerHTML={getTitle()} />
                  </Text>
                  <Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy200}>
                    {getBodyCopy(TextAlignment.left)}
                  </Text>
                </Container>
                <Container
                  wrap={'always'}
                  gutter={ContainerGutter.static400}
                  padding={{ top: ContainerPadding.dynamic0150 }}
                >
                  <CTA
                    tag="button"
                    onClick={() => {
                      dispatch({ type: 'RESET_STATE' });
                      setCTALabel(getCtaLabel());
                      setSelectedJourney(<NuevaCitaPosventaFormController />);
                    }}
                  >
                    {getCtaLabel()}
                  </CTA>
                </Container>
              </Container>
            </ContentStage>
          </Layout>
        </ThemeProvider>
      </div>
    </ContainerNew>
  );
};

import {
  styled,
  Container,
  ContainerPadding,
  CTA,
  Text,
  ContainerGutter,
  InputAppearance,
  BreakpointWrapper,
  Breakpoints,
  TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import { CloseHandleV2, FocusLayerSizeV2, LayerHandleV2, LayerManagerV2 } from '@volkswagen-onehub/layer-manager';

import {
  InputError,
  PoliticaPrivacidad,
  Email,
  MultipleCTASelection,
  TextAreaExtended,
  KoScreen,
  LoadingScreen,
  Nombre,
  Apellido,
  GeneralInputWidthWrapper,
} from 'src/components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import {
  LayerManagerWrapper,
  useFeatureAppEnvironment,
  useFeatureServices,
  useOneFormContext,
  useTrackingManager,
} from 'src/feature-app';
import { OneFormState, IdPerson } from 'src/types';

const TextAreaWrapper = styled.div`
  label {
    width: 100%;
  }
`;

const PaddingWrapper = styled.div`
`;

const NameLastNameWrapper = styled.div`
  padding-bottom: 2px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  @media all and (min-width: 960px) {
    flex-direction: row;
    gap: 32px;
  }
`;

const MotivoBaja = () => {
  const { getValues } = useFormContext();
  const { motivoBaja } = getValues();

  const motivosOptions = [
    {
      label: 'Ya no me interesa el contenido',
      value: 'No me interesa el contenido'
    },
    {
      label: 'Recibo demasiadas comunicaciones vuestras',
      value: 'Recibo demasiadas comunicaciones'
    },
    {
      label: 'Tengo otras razones',
      value: 'Otras razones'
    },
  ];

  return (
    <>
      <MultipleCTASelection ctaValues={motivosOptions} name="motivoBaja" wrapperWidth='350px' marginTop='8px'/>
    </>
  );
};

interface BajaComunicacionesProps {
  idPerson?: IdPerson;
}

export const BajaComunicacionesFields = (props: BajaComunicacionesProps) => {
  const { idPerson } = props;
  const [emailError, setEmailError] = useState<boolean>(false);
  const [motivoError, setMotivoError] = useState<boolean>(false);
  const [ masInfoValue, setMasInfoValue ] = useState(null);
  const [ nameError, setNameError ] = useState<boolean>(false);

  return (
    <div>
      <PaddingWrapper>
        
        {
          idPerson && idPerson.FirstName && idPerson.LastName && idPerson.PersonEmail ? (
            <>
              <Text appearance={TokenTextAppearance.headline300}>
              ¿Por qué quieres <Text bold>dar de baja el email {idPerson.PersonEmail}?</Text>
              </Text>
              <Container padding={{ top: ContainerPadding.dynamic0050, bottom: ContainerPadding.dynamic0150 }}>
                <Text appearance={TokenTextAppearance.copy200}>
                  Nos da pena decirte adiós tan pronto. Por eso, antes de darte de baja, nos encantaría entender por qué te
                  vas para mejorar la próxima vez.
                </Text>
              </Container>
            </>
          ) : (
            <>
              <Text appearance={TokenTextAppearance.headline300}>
                ¿Seguro que <Text bold>quieres darte de baja?</Text>
              </Text>
              <Container padding={{ top: ContainerPadding.dynamic0050, bottom: ContainerPadding.dynamic0150 }}>
                <Text appearance={TokenTextAppearance.copy200}>
                  Nos da pena decirte adiós tan pronto. Por eso, antes de darte de baja, nos encantaría entender por qué te
                  vas para mejorar la próxima vez.
                </Text>
              </Container>
            
              <Field
                question="Confírmanos tus datos para continuar con tu petición de baja"
                isRequired={true}
                emailError={nameError}
                input={
                  <>
                    <BreakpointWrapper max={Breakpoints.b960}>
                      <NameLastNameWrapper>
                      <GeneralInputWidthWrapper noPaddingTop>
                          <Nombre />
                        </GeneralInputWidthWrapper>
                        <GeneralInputWidthWrapper noPaddingTop>
                          <Apellido required name='surname'/>
                        </GeneralInputWidthWrapper>
                        <GeneralInputWidthWrapper noPaddingTop>
                          <Apellido name='secondSurname' label='Segundo apellido'/>
                        </GeneralInputWidthWrapper>
                        <Email paginaConfirmacion />
                      </NameLastNameWrapper>
                    </BreakpointWrapper>
                    <BreakpointWrapper min={Breakpoints.b960}>
                      <NameLastNameWrapper>
                        <GeneralInputWidthWrapper noPaddingTop>
                          <Nombre />
                        </GeneralInputWidthWrapper>
                        <GeneralInputWidthWrapper noPaddingTop>
                          <Apellido required name='surname'/>
                        </GeneralInputWidthWrapper>
                      </NameLastNameWrapper>
                      <NameLastNameWrapper>
                        <GeneralInputWidthWrapper >
                          <Apellido name='secondSurname' label='Segundo apellido'/>
                        </GeneralInputWidthWrapper>
                        <GeneralInputWidthWrapper >
                          <Email paginaConfirmacion required/>
                        </GeneralInputWidthWrapper>
                      </NameLastNameWrapper>
                    </BreakpointWrapper>
                  </>
                }
              />
            </>
          )
        }

        <Field
          question="¿Por qué quieres darte de baja? "
          isRequired={true}
          motivoError={motivoError}
          input={<MotivoBaja />}
        />
        <Field
          question="Si quieres añadir algo más, te leemos"
          input={
            <TextAreaWrapper>
              <TextAreaExtended
                label="Explícanos por qué quieres darte de baja."
                name="masInformacion"
                appearance={InputAppearance.Default}
                isFloating
                maxLength={2000}
                onChange={(e)=>{
                  setMasInfoValue(e.currentTarget.value);
                }}
              />
            </TextAreaWrapper>
          }
        />
        <PoliticaPrivacidad color="#001E50"/>
        <LastNavigation 
          setEmailError={setEmailError} 
          setMotivoError={setMotivoError} 
          masInfoValue={masInfoValue} 
          setNameError= {setNameError}
          idPerson={idPerson}
        />
      </PaddingWrapper>
    </div>
  );
};

interface FieldProps {
  question?: string;
  input: JSX.Element;
  isRequired?: boolean;
  emailError?: boolean;
  motivoError?: boolean;
}
const Field = (props: FieldProps) => {
  const { question, input, isRequired, emailError, motivoError } = props;

  return (
    <Container
      padding={{ bottom: ContainerPadding.dynamic0150 }}
      gutter={ContainerGutter.static300}
      wrap={'always'}
    >
      {
        question ? (
          <Text appearance={TokenTextAppearance.headline200} bold>
            {question} {isRequired ? <sup>*</sup> : null}
          </Text>
        ) : null
      }
      
      {input}
      {emailError || motivoError ? <InputError>Necesitamos esta información para seguir</InputError> : null}
    </Container>
  );
};

interface lastNavigationsProps {
  setEmailError: (value: boolean) => void;
  setMotivoError: (value: boolean) => void;
  setNameError: (value: boolean) => void;
  masInfoValue?: null | string;
  idPerson?: IdPerson;
}

function LastNavigation(props: lastNavigationsProps) {
  const { setEmailError, setMotivoError, masInfoValue, idPerson, setNameError } = props;
  // const dispatch = useDispatch();
  const { handleShowFinalScreen, handleSubmit } = useOneFormContext();
  const { getValues, triggerValidation } = useFormContext();
  const [layerVisible, setLayerVisible] = useState<boolean>(false);
  const [koLayer, setKoLayer] = useState<LayerHandleV2<any>>(null);
  const [koScreen, setKoScreen] = useState<boolean>(false);
  const layerManager = useFeatureServices()['layer-manager'] as LayerManagerV2;
  const store = useStore();
  const env = useFeatureAppEnvironment();
  const dispatch = useDispatch();
  const trackingManager = useTrackingManager();
  const { formData } = useSelector((state: OneFormState) => state);

  //Loading and Ko layers

  const handleKoLayer = () => {
    const layer = layerManager.openFocusLayer(renderKoLayer, {}, { size: FocusLayerSizeV2.A });
    setKoLayer(layer);
  };

  const renderKoLayer = (state: any, close: CloseHandleV2<any, any>) => {
    const NoCompraTitle = 'Uy, algo ha pasado';
    const NoCompraBody = 'Por alguna razón no hemos podido enviar tu petición. ¿Lo intentamos de nuevo?';

    return (
      <LayerManagerWrapper store={store} env={env}>
        <KoScreen
          retrySubmit={retrySubmit}
          closeLayerCallback={close}
          title={NoCompraTitle}
          bodyCopy={NoCompraBody}
          isLEM={true}
        />
      </LayerManagerWrapper>
    );
  };

  const handleLoadingScreenLayer = () => {
    const layer = layerManager.openFocusLayer(renderLoadingScreen, {}, { size: FocusLayerSizeV2.A });

    return layer;
  };

  const renderLoadingScreen = (state: any, close: CloseHandleV2<any, any>) => {
    return (
      <LayerManagerWrapper store={store} env={env}>
        <LoadingScreen copy="Enviando tu petición" />
      </LayerManagerWrapper>
    );
  };



  async function handleConfirmarCita(e: any) {

    //activar o desactivar mensajes de error
    const values = await getValues();

    if( idPerson && idPerson.PersonEmail ) {
      setEmailError(false);
    } else if (values.email) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }

    if( idPerson && idPerson.FirstName && idPerson.LastName) {
      setNameError(false);
    } else if ( values.name && values.surname ) {
      setNameError(false);
    } else {
      setNameError(true);
    }

    if (values.motivoBaja) {
      setMotivoError(false);
    } else {
      setMotivoError(true);
    }

    trackingManager.trackFormCTAClick({
      contentId: 'Confirmar la baja',
    });

    const isValid = await triggerValidation();

    if (isValid) {
      const loadingLayer = handleLoadingScreenLayer();
      setLayerVisible(true);

      dispatch({ type: 'UPDATE_FIELDS', payload: { 'email': idPerson && idPerson.PersonEmail  ? idPerson.PersonEmail  : values.email } });
      dispatch({ type: 'UPDATE_FIELDS', payload: { 'motivoBaja': values.motivoBaja } });
      dispatch({ type: 'UPDATE_FIELDS', payload: { 'masInformacion': masInfoValue }});
      dispatch({ type: 'UPDATE_FIELDS', payload: { 'name': idPerson && idPerson.FirstName ? idPerson.FirstName : values.name }});
      dispatch({ type: 'UPDATE_FIELDS', payload: { 'surname': idPerson && idPerson.LastName ? idPerson.LastName : values.surname }});
      dispatch({ type: 'UPDATE_FIELDS', payload: { 'secondSurname': idPerson && idPerson.MiddleName ? idPerson.MiddleName : values.secondSurname }});

      const value = await handleSubmit(e);
      loadingLayer.close();

      if (value) {
        handleShowFinalScreen();
      } else {
        handleKoLayer();
      }
    }
  }

  async function retrySubmit(e: any) {
    if (koLayer) {
      koLayer.close();
    }
    setKoScreen(false);
    const loadingLayer = handleLoadingScreenLayer();
    const value = await handleSubmit(e);
    loadingLayer.close();
    if (value) {
      handleShowFinalScreen();
    } else {
      handleKoLayer();
      setKoScreen(true);
    }
  }

  useEffect(() => {
    if (!layerVisible && koScreen) setKoScreen(false);
  }, [layerVisible]);

  useEffect(() => {
    dispatch({ type: 'UPDATE_FORM_ENDED', payload: true });
  }, []);

  return (
    <>
      <CTA
        tag="button"
        type="submit"
        emphasis="primary"
        onClick={(e) => {
          e.preventDefault();
          handleConfirmarCita(e);
        }}
        stretchContent={window.innerWidth <= 560}
      >
        Confirmar la baja
      </CTA>
    </>
  );
}
